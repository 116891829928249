<template>
  <div>
    <loader :loading="loading"/>
    <form @submit.prevent="submit" class="form" v-if="schema">
      <json-form :schema="schema" v-model="model" :errors="errors"/>
      <button type="submit" class="btn btn-primary btn-sm w-100">
        <Icon prefix="fas" name="save" class="mr-2"/>
        {{ $t('save') }}
      </button>
    </form>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator';
import { FormComponent } from '@/mixins/FormComponent';
import { mixins } from 'vue-class-component';
import { parseEndpoint, getRoute } from '@/router';
import { AxiosResponse } from 'axios';

@Component
export default class EntityEdit extends mixins(FormComponent) {
  protected model: any = {};

  protected created() {
    this.setEndpointAndLoad();
  }

  @Watch('$route.params.property')
  protected setEndpointAndLoad() {
    const { params, name, meta } = this.$route;
    this.endpoint = parseEndpoint(meta?.endpoint, { ...params });
    this.loadData();
  }

  protected afterLoad(response: AxiosResponse) {
    const title: string = this.schema && this.schema.title ? this.schema.title : '';
    if (title) {
      this.$store.commit('bars/setRightBarTitle', this.$t(title + '.header'));
    }
    this.$store.commit('bars/setRightBarFormSubmit', this.submit);
  }
}
</script>
