<template>
  <div class="card">
    <div class="card-head justify-content-between">
      <h2 class="h5">{{ $t('ftpsettings.title') }}</h2>
      <div class="mr-2">
      <router-link class="btn btn-primary btn-sm mr-1" :to="{name: 'settings.businessunit.ftpsettings.edit'}" v-text="data.connection ? $t('ftpsettings.edit') : $t('ftpsettings.create')"/>
      <button v-if="data.connection && data.connection.location === 'internal'" class="btn btn-danger btn-sm mr-1" @click="refreshPassword()">{{$t('ftpsettings.refresh_password')}}</button>
      </div>
    </div>
    <div class="card-body"  v-if="data.connection">
    <field label="ftpsettings.connection.protocol" :value="data.connection.protocol" />
    <field label="ftpsettings.connection.url" :value="data.connection.url" />
    <field label="ftpsettings.connection.port" :value="data.connection.port.toString()" />
    <field label="ftpsettings.connection.username" :value="data.connection.username" />
    <field  label="ftpsettings.connection.password" :value="data.connection.password" />
  </div>
  </div>
</template>

<script lang='ts'>
import { Component, Watch } from 'vue-property-decorator';
import api from '@/api';
import { parseEndpoint } from '@/router';
import Field from '@/components/global/Field.vue';
import { API } from '@/mixins/API';
interface FtpSettings {
  connection?: {
    location: 'internal'|'external',
    protocol: 'sftp'|'ftp'|'ftps',
    url: string,
    port: number,
    username: string,
    password: string,
  }
}
@Component({
  components: { Field },
})
export default class FtpSettingsList extends API  {

  protected data: FtpSettings = {};
  protected async created() {
    await this.loadData();
  }

  @Watch('endpoint')
  protected async loadData() {
    this.data = await this.get(this.endpoint);
  }
  protected refreshPassword() {
    this.$store.dispatch('modal/open', {
      body: this.$t('ftpsettings.refresh_password.confirm.body'),
      actions: [
        {
          label: this.$t('modal.label.cancel') as string,
          class: 'btn-dark',
          action: async () => this.$store.dispatch('modal/close'),
        },
        {
          label: this.$t('modal.label.submit') as string,
          class: 'btn-primary',
          action: async () => {
            await api.post(`${this.endpoint}/refresh-password`).then(() => this.loadData());
            this.$store.dispatch('modal/close');
          },
        },
      ],
    });
  }
  private get endpoint():string {
    return parseEndpoint(this.$route.meta?.endpoint, this.$route.params);
  }
}
</script>
