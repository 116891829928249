<template>
  <div class="card">
    <div class="card-body">
      <data-table
        :model="model"
        :endpoint="endpoint"
        @action="doAction"
        ref="emailList"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ListComponent } from '@/mixins/ListComponent';
import { EmitterAction } from '@/models';

@Component
export default class MissingDataList extends mixins(ListComponent) {
  protected doAction(action: EmitterAction) {
    this.setDataTable();
    if (action.emit === 'view') {
      this.$router.push({
        name: 'debtor.edit',
        params: { debtorId: action.id as string },
      });
    }
  }
  private get endpoint(): string {
    const type = this.$route.params.dataType

    return `/report/statistics/missing-data-analysis/${type}`;
  }
}
</script>

<style scoped lang="scss">
</style>
