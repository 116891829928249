<template>
  <div class="card h-100">
    <loader :loading="loading" />
    <div class="card-head py-0 pr-0 justify-content-between">
      <h2 class="h5" v-show="!showCurrencyDropdown">{{ $t('debtors.overview.financial-information.title') }}</h2>
      <div class="d-flex align-items-center ml-auto">
        <div class="d-flex select-none" v-if="financialInfo && financialInfo.available_currencies.length > 1">
          <transition name="fade">
            <div v-if="showCurrencyDropdown" class="d-flex">
              <div
                v-for="option in availableCurrencySymbols"
                :key="option.currency"
                v-text="option.symbol"
                @click="onCurrencyChange(option.currency)"
                class="text-xl text-muted cursor-pointer mx-1 currency option"
              />
            </div>
          </transition>
          <div class="mx-3">
            <div class="text-xl text-muted cursor-pointer currency" v-text="activeCurrencySymbol" @click.self="toggleCurrencyChange"/>
          </div>
        </div>
        <div
          @click="loadGraydon"
          :title="$t('debtors.overview.enable-graydon')"
          class="nav-link nav-link-right graydon-button">
          <img src="@/assets/img/graydon.svg" alt="graydon" height="18px">
        </div>
      </div>
    </div>
    <div class="card-body p-0">
      <table v-if="financialInfo" class="table mb-0 h-100">
        <tbody>
        <tr>
          <td class="p-3">
            <span class="text-xl">{{ financialInfo.openAmount | currency(currency) }}</span>
            <span>{{ $t('debtors.overview.financial-information.open') }}</span>
          </td>
          <td class="p-3">
            <span class="text-xl">{{ financialInfo.expiredAmount | currency(currency) }}</span>
            <span>{{ $t('debtors.overview.financial-information.expired') }}</span>
          </td>
        </tr>
        <tr>
          <td class="p-3">
            <span class="text-xl">{{ financialInfo.invoiceCount }}</span>
            <span>{{ $t('debtors.overview.financial-information.invoices') }}</span>
          </td>
          <td class="p-3">
            <span class="text-xl">{{ financialInfo.creditLimit | currency(debtorCurrency) }}</span>
            <span>{{ $t('debtors.overview.financial-information.limit') }}</span>
            <icon
              v-if="financialInfo.creditLimitExceeded"
              name="exclamation-circle"
              class="text-danger ml-1"
            />
          </td>
          <td class="p-3">
            <span class="text-xl">{{ financialInfo.receivableCosts | currency(debtorCurrency) }}</span>
            <span>{{ $t('debtors.overview.financial-information.receivable-costs') }}</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { Watch } from 'vue-property-decorator';
import Component, { mixins } from 'vue-class-component';
import { DebtorFinancialInformation } from '@/models';
import { API } from '@/mixins/API';
import { EventBus } from '@/services/EventBus';
import currencyToSymbolMap from 'currency-symbol-map/currency-symbol-map';

@Component({})
export default class FinancialInfo extends mixins(API) {
  private financialInfo: DebtorFinancialInformation | null = null;
  private selectedCurrency: string|null = null;
  private showCurrencyDropdown: boolean = false;

  private onFormSubmit = () => this.loadFinancialInfo();

  private async created() {
    EventBus.$on('form.submit.successful', this.onFormSubmit);

    await this.loadFinancialInfo();
  }

  private beforeDestroy() {
    EventBus.$off('form.submit.successful', this.onFormSubmit);
  }

  @Watch('selectedCurrency')
  private async loadFinancialInfo() {
    this.financialInfo = await this.get(
      `/debtor/${this.$route.params.debtorId}/financial-overview`,
      { params: { currency: this.selectedCurrency } },
    );
  }
@Watch('$route.params.debtorId')
private resetCurrencySelectionAndReload() {
    this.selectedCurrency = null;

    this.loadFinancialInfo();
}
  private loadGraydon() {
    this.$emit('onEnableGraydon', true);
  }
  private onCurrencyChange(currency: string) {
    this.showCurrencyDropdown = false;
    this.selectedCurrency = currency;
  }
  private get currency(): string {
    return this.financialInfo?.currency ?? 'EUR';
  }
  private get activeCurrencySymbol(): string {
    return currencyToSymbolMap(this.currency) ?? this.currency;
  }
  private get debtorCurrency(): string {
    return this.financialInfo?.debtor_currency ?? 'EUR';
  }
  private get availableCurrencySymbols(): Array<{ currency:string, symbol:string }> {
    return this.financialInfo?.available_currencies
      .filter(item => item !== this.currency)
      .map((item) => ({ currency: item, symbol: currencyToSymbolMap(item) ?? item })) ?? [];
  }
  private toggleCurrencyChange(): void {
    this.showCurrencyDropdown = !this.showCurrencyDropdown;
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/colors';
@import '~@/assets/scss/_variables';

table {
  border-collapse: collapse;
  table-layout: fixed;
  color: $dark;
}

table td,
table th {
  border: 1px solid $medium;
}

table tr:first-child td {
  border-top: 0;
}

table tr:last-child td {
  border-bottom: 0;
}

table tr td:first-child,
table tr th:first-child {
  border-left: 0;
}

table tr td:last-child,
table tr th:last-child {
  border-right: 0;
}

.text-xl {
  display: block;
  color: $text-color;
}
.graydon-button {
  opacity: .5;
  transition: opacity $speed ease;
  &:hover {
    opacity: 1;
  }
}
.currency {
  transition: opacity ease-out $speed;
  opacity: .75;
  &.option {
    opacity: .5;
  }
  &:hover {
    opacity: 1;
  }
}
</style>
