<template>
  <div class="card h-100">
    <div class="card-head justify-between">
      <h2 class="h5">{{ $t(`report-statistic.dso-analysis.title`) }}</h2>
    </div>
    <div class="card-body justify-content-start">
      <date-filter v-bind="{ parameters }" @onUpdate="updateParameters"/>
      <div class="mt-2 row">
        <div class="col-12">
          <dso-line-chart v-if="dataSet" :data="dataSet" />
          <div v-else class="d-flex justify-content-center">
            <span v-text="$t('datatable.no-records-found')"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ReportStatisticComponent } from '@/mixins/ReportStatisticComponent';
import { AxiosResponse } from 'axios';

import DateFilter from './filters/DateFilter.vue';
import DsoLineChart from '@/components/report/statistic/charts/DsoLineChart';
import { DateTime } from '@/models';
import format from 'date-fns/format';
interface DsoDataObject {
  dso: DsoAnalysisData|null,
}

interface DsoAnalysisData {
      type: string,
      creditor: null|any,
      businessUnit: string,
      data: DsoData[]|null,
}
interface DsoData {
  timestamp: DateTime,
  value: number,
}
@Component({
  components: { DsoLineChart, DateFilter },
})
export default class DsoAnalysisReportStatistic extends mixins(ReportStatisticComponent) {

  protected readonly endpoint: string = 'report/statistics/dso-analysis';
  protected data: DsoAnalysisData | null = null;

  protected created() {
    this.parameters = {
      dateTo: new Date()
    }
  }
  private get dataSet(): Record<string, number>|null {
    if (!this.data?.data) {
      return null;
    }

    let data: Record<string, number> = {};

    this.data?.data?.sort(
      (a:DsoData,b:DsoData) => new Date(a.timestamp.date).getTime() - new Date(b.timestamp.date).getTime())
      .forEach((item: DsoData) => data[format(new Date(item.timestamp.date), 'dd/MM/yyyy')] = item.value
      );

    return data;
  }
  protected handleResponse({ data }: AxiosResponse<DsoDataObject>) {
    this.data = data.dso;
  }
}
</script>

<style lang="scss" scoped>

</style>
