<template>
  <div class="d-flex align-items-center">
    <div class="d-flex">
      <div @click="updateYear(year - 1)" class="text-secondary cursor-pointer">
        <icon name="minus" />
      </div>
      <div class="mx-2 select-none font-semibold text-primary">
        {{ year }}
      </div>
      <div @click="updateYear(year + 1)" class="text-secondary cursor-pointer">
        <icon name="plus" />
      </div>
    </div>

    <div class="mx-3">
      <input
        v-model="month"
        @blur="updateMonth($event.target.value)"
        maxlength="2"
        class="input-month font-semibold text-primary"
        type="text"
      >
    </div>

    <div class="cursor-pointer text-primary" @click="update">
      <icon name="redo" prefix="fas" />
    </div>
  </div>
</template>

<script lang='ts'>
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ReportStatisticParameters } from '@/models/ReportStatisticParameters';

@Component({})
export default class DateFilter extends Vue {

  @Prop({ required: true })
  private parameters!: ReportStatisticParameters;

  private month: number = 0;
  private year: number = 0;

  private mounted() {
    if (!this.parameters.dateTo || !this.parameters.dateTo) {
      return;
    }
    this.month = this.parameters.dateTo.getMonth() + 1;
    this.year = this.parameters.dateTo.getFullYear();

  }

  private updateYear(year: number): void {
    this.year = year;
  }

  private updateMonth(month: number): void {
    if (month > 12) {
      month = 12;
    }
    if (month < 1) {
      month = 1;
    }

    this.month = month;
  }

  private update() {
    const dateFrom = this.parameters.dateFrom;
    const dateTo = this.parameters.dateTo;

    dateFrom?.setFullYear(this.year);
    dateTo?.setFullYear(this.year);
    dateTo?.setMonth(this.month - 1);

    this.$emit('onUpdate', { ...this.parameters, dateFrom, dateTo });
  }
}
</script>

<style lang="scss" scoped>
.input-month {
  text-align: center;
  width: 2ch;
  border: none;
  box-sizing: content-box;
  padding-bottom: 0;
  border-bottom: 2px solid var(--primary-color);
}
</style>
