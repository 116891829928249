<template>
    <div class="position-absolute pin-b pin-x pin-t d-flex align-items-end align-items-lg-center">
      <div class="mx-auto">
        <card :disableHeader="true" class="shadow">
          <template v-if="allowTokenRegeneration" #body>
            <div class="mb-3 mt-2 text-muted">
              {{ $t('debtorportal.invalid-token.request.message') }}
              </div>
              <div class="d-flex justify-content-center">
                <json-form v-if="schema" :schema="schema" v-model="model" @submit="submit" :errors="errors" />
              </div>
              <div class="d-flex justify-content-end  mt-4 mb-2">
                <button class="btn btn-secondary btn-sm">Vraag aan</button>
              </div>

          </template>
          <template v-else #body>
            <div class="d-flex justify-content-center mt-2">
              {{ $t('debtorportal.invalid-token.message') }}
            </div>
            <div class="d-flex justify-content-center mt-4" v-if="redirectUrl">
            <a :href="redirectUrl" target="_blank" rel=noreferrer class="btn btn-primary text-white" v-text="$t('debtorportal.invalid-token.request-button')"/>
            </div>
          </template>
        </card>
      </div>
    </div>
</template>

<script lang='ts'>
import { Component } from 'vue-property-decorator';
import Card from '@/debtorportal/components/Card.vue';
import { mixins } from 'vue-class-component';
import { FormComponent } from '@/mixins/FormComponent';
@Component({
  components: {
    Card
  }
})
export default class DebtorPortalInvalidTokenHandler extends  mixins(FormComponent) {
  protected created() {
    const debtor = this.$route.query.debtor as string;
    this.authToken = this.$route.query.token as string;

    this.endpoint = `debtorportal/${debtor}/refresh-token`;

    this.loadData();
  }
  private get allowTokenRegeneration() {
    return this.$store.state.platform.debtorportal.allowTokenRegeneration;
  }
  private get redirectUrl() {
    return this.$store.state.platform.debtorportal.contactUrl;
  }
}
</script>
