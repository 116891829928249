import { Module } from 'vuex';
import { ReportStatisticState, RootState } from '@/models';
import { storage } from '@/services/LocalStorage';
import { Statistic } from '@/models/ReportStatistic';

export const reportStatistic: Module<ReportStatisticState, RootState> = {
  namespaced: true,
  state: {
    currency: 'EUR',
    available_currencies: storage.get<string[]>('report-statistic.available_currencies') ?? [],
    components: storage.get<Statistic[]>('report-statistic.components') ?? [],
  },
  actions: {
    storeData({ commit }, data: ReportStatisticState) {
      commit('setCurrency', data.currency);
      commit('setAvailableCurrencies', data.available_currencies);
      commit('persistAvailableCurrencies', data.available_currencies);
      commit('setComponents', data.components);
      commit('persistComponents', data.components);
    },
    storeComponents({commit}, data: Statistic[]) {
      commit('setComponents', data);
      commit('persistComponents', data);
    }
  },
  mutations: {
    setCurrency(state, data: string) {
      state.currency = data;
    },
    setAvailableCurrencies(state, data: string[]) {
      state.available_currencies = data;
    },
    persistAvailableCurrencies(state, data: string[]) {
      storage.save('report-statistic.available_currencies', data);
    },
    setComponents(state, data: Statistic[]) {
      state.components = data;
    },
    persistComponents(state, data: Statistic[]) {
      storage.save('report-statistic.components', data);
    }
  },
};
