import Vue from 'vue';
import Vuex from 'vuex';
import { RawLocation } from 'vue-router';

import { RootState } from '@/models';
import { i18n } from './i18n';
import { auth } from './auth';
import { dashboard } from './dashboard';
import { task } from './task';
import { debtor } from './debtor';
import bars from './bars';
import { modal } from './modal';
import { platform } from './platform';
import { datatable } from './datatable';
import { selection } from './selection';
import router from '@/router';
import { batch } from '@/store/batch';
import { action } from '@/store/action';
import { reportStatistic } from '@/store/report-statistic';
import { debtorGroup } from '@/store/debtorGroup';
Vue.use(Vuex);

export default new Vuex.Store<RootState>({
  modules: {
    auth,
    action,
    bars,
    batch,
    dashboard,
    task,
    debtor,
    debtorGroup,
    i18n,
    modal,
    datatable,
    selection,
    platform,
    reportStatistic,
  },
  actions: {
    throwError(_, { msg }) {
      Vue.toasted.error(msg);
    },
    throwSuccess(_, { msg }) {
      Vue.toasted.success(msg);
    },
    throwInfo(_, { msg }) {
      Vue.toasted.info(msg);
    },
    redirect(_, location: RawLocation) {
      router.push(location);
    },
  },
});
