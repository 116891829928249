<template>
  <div class="card h-100">
    <loader :loading="loading"/>
    <div class="card-head justify-between py-0 pr-0">
      <h2 class="h5">{{ $t('debtors.overview.creditor.title') }}</h2>
    </div>

    <div class="card-body" v-if="creditorInfo">
      <div class='d-flex justify-content-between center align-items-start'>
        <p class="text-lg">{{creditorInfo.display_name}}</p>
        <p class="text-lg font-semibold">{{creditorInfo.businessUnit.name}}</p>
      </div>
      <div v-if="defaultContact">
        <div class="mb-1">{{defaultContact.first_name}} {{defaultContact.middle_name}} {{defaultContact.last_name}}</div>
        <div v-if="defaultContact.telephone">
          <span>{{$t('debtors.overview.creditor-info.phone')}}: </span>
          <span>{{defaultContact.telephone}}</span>
        </div>
        <div v-if="defaultContact.email">
          <span>{{$t('debtors.overview.creditor-info.email')}}: </span>
          <span>{{defaultContact.email}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { LoadingState, CreditorInfo as CreditorInfoType, Contact, Address } from '@/models';
import api from '@/api';

@Component({})
export default class CreditorInfo extends Vue {
  private loading: LoadingState = false;
  private creditorInfo: CreditorInfoType | null = null;
  private async created() {
    this.loadData();
  }

  @Watch('$route.params.groupId')
  private async loadData() {
    this.loading = true;
    const res = await api.get(`/debtor/group/${this.$route.params.groupId}/creditor/info`);
    if (res.data) {
      this.creditorInfo = res.data;
      return (this.loading = false);
    }
    this.loading = 'error';
  }

  get defaultContact(): Contact | undefined {
    if (this.creditorInfo) {
      return this.creditorInfo.defaultContact || undefined;
    }
    return undefined;
  }
  get defaultAddress(): Address | undefined {
    if (this.creditorInfo) {
      return this.creditorInfo.defaultAddress || undefined;
    }
    return undefined;
  }
}
</script>
