<template>
  <span class="wrapper">
    <font-awesome-icon :icon="[prefix, iconName]" :size="size" :fixed-width="fw" />
    <span v-if="layer" :class="`badge badge-${layer.color || 'black'}`" class="layer">
      {{ layer.value }}
    </span>
  </span>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as icons  from './Icons';

@Component({
  components: {
    FontAwesomeIcon,
  },
})
export default class Icon extends Vue {
  @Prop({ default: 'fal' }) public prefix!: string;
  @Prop() public name!: string;
  @Prop() public layer!: { value: string | number; color: string };
  @Prop({ default: '1x' }) public size!: string;
  @Prop({ default: true }) public fw!: boolean;
  private created() {
    library.add(icons);
  }

  get iconName() {
    let iconName: any;

    switch (this.name) {
      case 'view':
        iconName = 'eye';
        break;
      case 'edit':
        iconName = 'pencil-alt';
        break;
      case 'delete':
        iconName = 'trash-alt';
        break;
      case 'importsettings':
        iconName = 'upload';
        break;
      case 'download_single_sided':
        iconName = 'file';
        break;
      case 'download_double_sided':
        iconName = 'copy';
        break;
      case 'retry_batch':
        iconName = 'repeat';
        break;
      case 'use_mapping':
        iconName = 'file';
        break;
      case 'reactivate':
        iconName = 'play';
        break;
      case 'acl':
        iconName = 'universal-access';
        break;
      case 'settings':
        iconName = 'cog';
        break;
      case 'accept':
        iconName = 'check';
        break;
      case 'decline':
      case 'cancel':
      case 'close':
        iconName = 'times';
        break;
      case 'process':
        iconName = 'play';
        break;
      case 'skip':
        iconName = 'step-forward';
        break;
      case 'claim':
        iconName = 'hand-holding-usd';
        break;
      case 'release':
        iconName = 'rocket';
        break;
      case 'impersonate':
        iconName = 'user-secret';
        break;
      case 'preview':
        iconName = 'search';
        break;
      case 'deactivate':
        iconName = 'user-slash';
        break;
      case 'activate':
        iconName = 'user';
        break;
      case 'reset_trial':
        iconName = 'history'
        break
      case 'link_dialog':
      case 'assign':
        iconName = 'link';
        break;
      case 'sso_signin':
        iconName = 'sign-in';
        break;
      case 'license_model':
        iconName = 'users-cog';
        break;
      case 'download_sepa':
        iconName = 'file-code';
        break;
      case 'download_xls':
        iconName = 'file-excel';
        break;
      default:
        if (this.name.indexOf('download') !== -1) {
          iconName = 'arrow-alt-to-bottom';
        } else {
          iconName = this.name;
        }
    }
    return iconName;
  }

  get label() {
    return this.name;
  }
}
</script>

<style lang="scss">
.wrapper {
  position: relative;
}
.layer {
  position: absolute;
  bottom: -5px;
  right: -15px;
}
</style>
