import axios from '@/api';

import { Store } from 'vuex';
import { AuthState, User } from '@/models';
import { AxiosResponse } from 'axios';

const MFA_IN_PROGRESS = 'MFA_IN_PROGRESS';

interface LoginResponse {
  status: 'MFA_IN_PROGRESS' | string;
  token?: string;
  data?: {
    user: User
  };
}

export class AuthService {
  constructor(protected $store: Store<{ auth: AuthState }>) {
    this.$store = $store;
  }

  public requiresMfa = (data: LoginResponse): boolean => data.status === MFA_IN_PROGRESS;

  public async login(token?: string, user?: User) {
    await this.$store.dispatch('auth/handleLogin', {token, user});
    const acl = await this.$store.dispatch('auth/loadAcl');

    // TODO: Don't set authorization token globally
    axios.defaults.headers.common.Authorization = `Bearer ${this.$store.state.auth.token}`;
  }
  public async checkCredentials(username: string, password: string) {
    const payload = {
      _username: username,
      _password: password,
    };
    return await axios.post<LoginResponse>('/auth/login/check', payload);
  }
  public checkMfa(authCode: string, trusted: boolean) {
    return axios.post<LoginResponse>('/auth/login/2fa/check', { _auth_code: authCode, _trusted: trusted });
  }
}
