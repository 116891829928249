import { Module } from 'vuex';

import { AuthState, RootState, User, UserEntity } from '@/models';
import api from '@/api';
import { storage } from '@/services/LocalStorage';

if (storage.get('auth.token')) {
  api.defaults.headers.common.Authorization = `Bearer ${storage.get('auth.token')}`;
}

export const auth: Module<AuthState, RootState> = {
  namespaced: true,
  state: {
    loading: false,
    schemas: { login: null, forgotPassword: null, password: null },
    acl: storage.get<object>('auth.acl'),
    token: storage.get<string>('auth.token'),
    mfa: storage.get<boolean>('auth.mfa') ?? false,
    user: storage.get<User>('auth.user'),
    impersonateUsername: null,
  },
  actions: {
    async loadUser({ commit, dispatch }) {
      const res = await api.get(`/user`);
      if (res.data) {
        commit('setUser', res.data);
      } else {
        dispatch('throwError', { msg: 'Could not load user' }, { root: true });
      }
    },
    logout({ commit, dispatch }) {
      api.get('auth/logout');

      commit('logout');
      commit('i18n/changeLocale', null, { root: true });
      dispatch('impersonate');
      dispatch('dashboard/unloadData', {}, { root: true });
      dispatch('redirect', { name: 'auth.login' }, { root: true });
    },
    async impersonate({ commit, dispatch, rootState }, username: string | null) {
      commit('impersonate', username);
      if (rootState.auth.token) {
        const user = await api.get(`/user`);
        dispatch('loadAcl');
        commit('setUser', user.data);
        dispatch('dashboard/loadData', {}, { root: true });
        dispatch('dashboard/loadMetrics', {}, { root: true });
      }
    },
    async handleEndTrialSubscription({ commit, state, getters, dispatch }) {
      const creditor = state.user?.active_entity;
      if (! creditor?.trial_ends_at) {
        return;
      }

      api.delete(`settings/creditor/${creditor.uuid}/delete`)
        .then(() =>{
          if (! state.user?.is_super_user) {
            commit('logout');
          }
        }).then(() => dispatch('loadUser'))
        .then(() => dispatch('loadAcl'));
    },
    handleLogin({ commit }, payload: { token: string, user: User }) {
      commit('setUser', payload.user);
      commit('setToken', payload.token);
      commit('setMfa', false);
    },
    beginMfa({commit}) {
      commit('setMfa', true);
    },
    async loadAcl({ commit, state }) {
      return api
        .get('/user/acl', {
          headers: {
            common: {
              Authorization: `Bearer ${state.token}`,
            },
          },
        })
        .then(({ data }) => {
          commit('setAcl', data);
          return data;
        })
        .catch(() => {
          commit('logout');
        });
    },
  },
  mutations: {
    setToken(state, token: string) {
      state.token = token;
      storage.save<string>('auth.token', token);
    },
    setAcl(state, acl) {
      state.acl = acl;
      storage.save('auth.acl', acl);
    },
    logout(state) {
      state.token = null;
      state.user = null;
      state.acl = null;

      storage.remove('auth.token');
      storage.remove('auth.user');
      storage.remove('auth.acl');
      storage.remove('auth.impersonateUsername');

      api.defaults.headers.common.Authorization = '';
    },
    restoreUser(state) {
      state.user = storage.get<User>('auth.user');
    },
    setMfa(state: AuthState, mfa: boolean) {
      state.mfa = mfa;
      storage.save<boolean>('auth.mfa', mfa);
    },
    setUser(state, user) {
      state.user = user;
      storage.save<User>('auth.user', user);
    },
    setEntity(state, entity: UserEntity) {
      if (state.user?.active_entity) {
        state.user.active_entity = entity;
      }
    },
    impersonate(state, username) {
      state.impersonateUsername = username;
      if (username) {
        storage.save('auth.impersonateUsername', username);
      } else {
        storage.remove('auth.impersonateUsername');
      }
    },
  },
  getters: {
    aclHasKey: (state) => (key: string) => {
      return state.acl && state.acl.settings[key];
    },
    isSuperUser: ({user}) => !!user?.is_super_user,
    trialEndsAt: ({user}): Date|null => user?.active_entity?.trial_ends_at ? new Date(user.active_entity.trial_ends_at) : null,
    isInTrialPeriod: (state, getters) => getters.trialEndsAt > new Date(),
  },
};
