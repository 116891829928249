import { Prop, Watch, Component, Vue } from 'vue-property-decorator';

import {
  ReportStatisticParameters,
} from '@/models/ReportStatisticParameters';
import { AxiosError, AxiosResponse } from 'axios';

import api from '@/api';
import { appendQueryParams } from '@/utils/queryString';

@Component({})
export class ReportStatisticComponent extends Vue {
  protected readonly endpoint!: string;
  protected parameters: ReportStatisticParameters = {};

  @Prop({ required: true })
  private type!: string;

  @Prop({ required: true })
  private currency!: string;

  protected mounted() {
    this.loadData();
  }

  @Watch('currency')
  @Watch('parameters', { immediate: false })
  protected loadData() {
    api.get(appendQueryParams(this.endpoint, { currency: this.currency, ...this.parameters }))
      .then(res => this.handleResponse(res))
      .catch(err => this.handleErrorResponse(err));
  }

  protected handleResponse(res: AxiosResponse) {
    //
  }

  protected handleErrorResponse(error: AxiosError) {
    //
  }

  protected updateParameters(parameters: ReportStatisticParameters) {
    this.parameters = parameters;
  }
}
