<template>
  <div
    :id="'form-group-' + id"
    class="form-group row"
    :class="{ select: schema.items.enum, object: schema.items.type === 'object' }"
  >
    <label v-if="labels" class="col-sm-3 col-form-label d-flex flex-row" :for="id">
      <span>{{ schema.title }} {{ required ? '*' : '' }}</span>
      <div
        class="cursor-pointer col-sm-3 select-none"
        @click="addArrayItem"
        v-if="!disableForm && !schema.readOnly && schema.allow_add && schema.items.type === 'string'"
      >
        <icon name="plus" />
      </div>
    </label>

    <div :class="{ 'col-sm-9': labels }">
      <button
        v-if="
          !disableForm &&
            !schema.readOnly &&
            !schema.widget &&
            schema.allow_add !== false &&
            schema.items.type === 'object'
        "
        class="btn-add border p-1 mb-2"
        :disabled="schema.attr && schema.attr.limit && multiObjectValue.length >= schema.attr.limit"
        @click.prevent="addMultiObjectValue"
        :title="$t('form.array.add')"
      >
        <icon class="icon-plus" name="plus" />
      </button>

      <div v-if="schema.widget === 'choice-multiple-expanded'">
        <div v-for="item in items" :key="item.id" class="checkbox form-group">
          <label :for="item.value" class="checkbox-label">
            <input
              :id="item.value"
              :class="['checkbox-input', { 'is-invalid': hasError }]"
              :value="item.value"
              @change="handleCheckBoxChange($event, item.value)"
              :checked="checkBoxIsChecked(item.value)"
              type="checkbox"
            />
            <span class="checkmark"></span>
            {{ item.label }}
          </label>
        </div>
      </div>
      <div v-else-if="schema.widget === 'multi_column_select'">
        <list-box v-bind="{ schema, options: items, value: multiSelectValue }" @input="updateMultiSelectValue" />
      </div>
      <div v-else-if="schema.widget === 'tristate'">
        <div class="d-flex select-none mb-1">
          <div
            class="cursor-pointer mr-2 btn-underline border-success p-1"
            @click="setAllTristateStates(schema.items.title, 'allow')"
          >
            <Icon :fw="true" class="text-success mr-1" name="check" size="lg" />
            <span v-text="$t('form.tristate.allow-all')" />
          </div>
          <div
            class="cursor-pointer mr-2 btn-underline border-danger p-1"
            @click="setAllTristateStates(schema.items.title, 'deny')"
          >
            <Icon :fw="true" class="text-danger mr-1" name="times" size="lg" />
            <span v-text="$t('form.tristate.deny-all')" />
          </div>
          <div
            class="cursor-pointer btn-underline border-muted p-1"
            @click="setAllTristateStates(schema.items.title, 'empty')"
          >
            <Icon prefix="fas" name="circle" :fw="true" class="text-muted mr-1" size="xs" />
            <span v-text="$t('form.tristate.empty-all')" />
          </div>
        </div>
        <div class="ml-2">
          <tristate-group
            v-for="(item, key) in multiObjectValue"
            @input="updateMultiObjectValue($event, key)"
            :key="item.uuid"
            :value="item"
            :schema="schema.items"
          />
        </div>
      </div>
      <multiselect
        v-else-if="schema.items.enum && schema.widget !== 'choice-multiple-expanded'"
        :value="multiSelectValue"
        @input="updateMultiSelectValue"
        :options="items"
        :closeOnSelect="false"
        hide-selected
        multiple
        label="label"
        track-by="value"
        deselectLabel
        selectLabel
        selectedLabel
        :disabled="disableForm || schema.readOnly"
      />
      <div v-else-if="schema.items.type === 'object'">
        <div class="row">
          <div v-for="(value, key) in multiObjectValue" :key="key" class="flex" :class="{ 'ml-2': key > 0 }">
            <span
              class="cursor-pointer px-2 text-muted select-none"
              :class="{ 'text-primary font-semibold': key === selectedTab }"
              v-text="`${(schema.attr && $t(schema.attr.tab_title)) || schema.title} ${key + 1}`"
              @click="selectedTab = key"
            />
          </div>
        </div>
        <div class="row no-gutters">
          <div
            v-for="(value, key) in multiObjectValue"
            :key="key"
            class="flex flex-grow-1 mb-2"
            v-show="key === selectedTab"
          >
            <div class="card form-column p-2 mt-1">
              <json-form-object
                :schema="schema.items"
                :value="value"
                :errors="childError(key)"
                @input="updateMultiObjectValue($event, key)"
                :labels="labels"
                :disable-rich-text="disableRichText"
                :disable-form="disableForm || schema.readOnly"
              />
              <div class="d-flex justify-content-end">
                <button
                  class="btn-delete"
                  v-if="!disableForm && !schema.readOnly && isDeletable(key)"
                  @click.prevent="removeMultiObjectValue(key)"
                >
                  <icon name="trash-alt" />
                  {{ $t('form.array.remove') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="schema.items.type === 'string'">
        <div v-for="(item, key) in arrayValues" :key="key" class="d-flex align-items-baseline">
          <json-form-string
            class="w-100"
            :id="key"
            :title="schema.title"
            :errors="childError(key)"
            :labels="false"
            :schema="schema.items"
            :value="item"
            @input="updateArrayItem($event, key)"
          />
          <div
            class="cursor-pointer ml-1"
            @click="removeArrayItem(key)"
            v-if="!disableForm && !schema.readOnly && isDeletable(key)"
          >
            <icon name="minus" />
          </div>
        </div>
      </div>
    </div>
    <div class="invalid-feedback" v-if="hasError">
      <p v-for="error in hasError" :key="error">{{ error }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { JSONSchema4 } from 'json-schema';
import { SelectValue } from '@/models';
import { EventBus } from '@/services/EventBus';
import TristateGroup from '@/plugins/json-form/widget-types/TristateGroup.vue';
import ListBox from '@/plugins/json-form/widget-types/ListBox.vue';

@Component({
  components: {
    TristateGroup,
    ListBox,
  },
})
export default class JsonFormArray extends Vue {
  @Prop()
  private schema!: JSONSchema4;
  @Prop()
  private id!: string;
  @Prop({ default: () => [], type: [Array, Object] })
  private value!: any[];
  @Prop({ default: false })
  private required!: boolean;
  @Prop()
  private errors!: any;
  @Prop()
  private labels!: boolean;
  @Prop()
  private disableRichText!: boolean;
  @Prop()
  private disableForm!: boolean;
  private selectedTab: number = 0;
  private unEditableItems: number[] = [];
  private multiSelectValue: SelectValue[] = [];
  private multiObjectValue: any[] = [];
  private arrayValues: any[] = [];

  private created() {
    this.populateValues();
    this.addUndeletableItemsToArray();

    this.$emit('input', this.value);
  }

  private addUndeletableItemsToArray() {
    if (this.schema.allow_delete === false) {
      this.multiObjectValue.forEach((item, index) => this.unEditableItems.push(index));
      this.arrayValues.forEach((item, index) => this.unEditableItems.push(index));
    }
  }
  @Watch('value')
  private populateValues() {
    const schemaItems = (this.schema.items as JSONSchema4);

    if (schemaItems.enum) {
      return this.multiSelectValue = this.items.filter(item => this.value.indexOf(item.value) !== -1);
    }

    if (schemaItems.type === 'object') {
      return this.multiObjectValue = Object.values(this.value);
    }

    this.arrayValues = Object.values(this.value);
  }
  private addArrayItem() {
    this.arrayValues.push('');
  }
  private removeArrayItem(key: number) {
    this.arrayValues.splice(key, 1);
    this.$emit('input', this.arrayValues);
  }
  private updateArrayItem(value: any, key:number) {
      this.arrayValues[key] = value;

      this.$emit('input', this.arrayValues);
  }
  private updateMultiSelectValue(value: SelectValue[]) {
    this.multiSelectValue = value;
    this.$emit(
      'input',
      this.multiSelectValue.map(val => val.value)
    );
  }

  private updateMultiObjectValue(value: any, key: number) {
    this.$set(this.multiObjectValue, key, value);
    this.$emit('input', this.multiObjectValue);
  }
  private addMultiObjectValue() {
    const defaults = typeof this.schema.default === 'object' ? this.schema.default : {};

    this.multiObjectValue.push({ ...defaults });
    this.selectedTab = this.multiObjectValue.length - 1;
    this.$emit('input', this.multiObjectValue);
  }

  private removeMultiObjectValue(key: number) {
    this.multiObjectValue.splice(key, 1);
    if  (this.selectedTab) {
      this.selectedTab--
    }
    this.$emit('input', this.multiObjectValue);
  }
  private setAllTristateStates(identifier: string, state: string) {
    EventBus.$emit(`${identifier}.tristate.setState`, state);
  }
  private handleCheckBoxChange(evt: any, val: string) {
    if (evt.target.checked && !this.value.includes(val)) {
      this.value.push(val);
    } else {
      this.value.splice(this.value.indexOf(val), 1);
    }
  }
  private checkBoxIsChecked(val: string) {
    return this.value.includes(val);
  }

  private childError(key: string): object {
    return this.errors?.children?.[key];
  }
  private get multiple() {
    return this.schema.uniqueItems;
  }

  private get items(): SelectValue[] {
    const schemaItems = this.schema.items as JSONSchema4;
    if (! schemaItems?.hasOwnProperty('enum')) {
      return [];
    }
    const items = schemaItems.enum as unknown as SelectValue[];

    return items.map(item => ({ ...item, $isDisabled: item.value === null }));
  }
  private get hasError() {
    return this.errors?.errors;
  }
  private isDeletable(key: number): boolean {
    return this.schema.allow_delete || !this.unEditableItems.includes(key);
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/colors';

.form-group.object {
  & .card {
    border: 1px solid $mercury;
  }
}
.checkbox {
  margin-left: 0;
}

.btn-underline {
  border-bottom: solid 1px grey;
  margin-bottom: 1px;
  &:hover {
    border-bottom-width: 2px;
    margin-bottom: 0;
  }
  &:not(:hover) > span {
    color: hsl(0, 0, 40);
  }
}
</style>
