<template>
  <ul class="list-unstyled subnav-nav">
    <li class="subnav-nav--item">
      <router-link :to="{ name: 'dashboard.batch.list' }" class="py-1 overview">Overview</router-link>
    </li>
    <li class="subnav-nav--item" v-for="task in openTasks" :key="task.type">
      <router-link
        :to="{
            name: 'dashboard.batch.type',
            params: { type: task.type }
          }"
        class="py-1"
      >{{ $t(`batch.type.${task.type}`) + ` (${task.count})` }}</router-link>
    </li>
  </ul>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class SubnavBar extends Vue {
  get openTasks() {
    return this.$store.state.bars.subnavBarTree;
  }
}
</script>

