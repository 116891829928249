<template>
  <div class='card h-100' v-if='data'>
    <div class='card-head justify-between'>
      <h2 class='h5'>{{ $t(`report-statistic.missing-data-analysis.title`) }}</h2>
    </div>
    <div class='card-body justify-content-start'>
      <div class='mt-2 row'>
        <div class='col-12'>
          <h6>{{ $t('report-statistic.missing-data.total.title') }}</h6>
          <h3>{{ totalTasks }}</h3>
        </div>
      </div>
      <div class='mt-2 row'>
        <div class='col-12'>
          <div class='d-flex justify-content-between w-100'>
            <h6>{{ $t('report-statistic.missing-data.type.title') }}</h6>
            <h6>{{ $t('report-statistic.missing-data.value.title') }}</h6>
          </div>
          <div v-for='(value, key) in data' :key='key' class='d-flex justify-content-between w-100'>
            <router-link
              :to="{ name: 'report.statistic.missing-data.view', params: { dataType: key }}">
              {{ $t(`report-statistic.missing-data-analysis.${key}`) }}
            </router-link>
            <div>{{ value }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ReportStatisticComponent } from '@/mixins/ReportStatisticComponent';
import { AxiosResponse } from 'axios';

import DateFilter from './filters/DateFilter.vue';

interface MissingDataData {
  missing_email: string,
  missing_phone: string,
  missing_mobile: string,
  missing_address: string,
  negative_open_amount: string,
}

@Component({
  components: { DateFilter },
})
export default class MissingDataAnalysisReportStatistic extends mixins(ReportStatisticComponent) {

  protected readonly endpoint: string = 'report/statistics/missing-data-analysis';

  protected data: MissingDataData | null = null;

  protected handleResponse({ data }: AxiosResponse<MissingDataData>) {
    this.data = data;
  }
  private get totalTasks(): number {
    return Object.values({ ...this.data }).map((x) => Number(x)).reduce((a,b) => a + b);
  }
}
</script>

<style lang='scss' scoped>

</style>
