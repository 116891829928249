<template>
  <div class="d-flex timeline-item">
    <div class="pt-3 text-muted text-right timeline-item--time">
      {{ item.eventDate | datetime('dd MM ’yy') }}
    </div>
    <div class="py-2 timeline-item--icon">
      <icon name="file" size="lg" />
    </div>
    <div class="pt-2 timeline-item--info">
      <h3 class="h5 mb-0 cursor-pointer" v-text="$t(`graydon.monitor.event-code.${item.eventCode}`)" />
      <p v-if="isStringNumberEvent(item)">
        <span v-text="item.change.from" />
        <icon name="angle-right" />
        <span v-text="item.change.to" />
      </p>
    </div>
  </div>
</template>

<script lang='ts'>
import { Vue, Component, Prop } from 'vue-property-decorator';
import { MonitorEvent, StringNumberMonitorEvent } from '@/models';


@Component({})
export default class ActivityMonitorItem extends Vue {
  @Prop({ required: true }) private item!: MonitorEvent;

  private isStringNumberEvent(item: MonitorEvent): item is StringNumberMonitorEvent {
    const type = item?.change?.valueType ?? '';
    return ['string', 'number'].includes(type);
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/colors';
@import '~@/assets/scss/elements/timeline';

.text-muted {
  opacity: 0.6;
}
</style>
