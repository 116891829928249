<template>
  <div>
    <div class="card h-100 mb-4">
      <div class="card-head justify-between">
        <div>
          <button
            class="btn btn-sm mr-2"
            :class="this.filters.disableDashboardFilter ? 'btn-primary' : 'btn-light'"
            @click="toggle('disableDashboardFilter')"
          >
            {{ $t(`task.disableDashboardFilter`) }}
          </button>
          <button
            class="btn btn-sm mr-2"
            :class="this.filters.onlyClaimedTasks ? 'btn-primary' : 'btn-light'"
            @click="toggle('onlyClaimedTasks')"
          >
            {{ $t(`task.onlyClaimedTasks`) }}
          </button>
          <router-link class="btn btn-sm mr-2 btn-light" v-if="nextDebtor" :to="{ name: 'debtor.task', params: { debtorId: nextDebtor } }">{{
        $t('debtor.tasks-completed.next-debtor')
      }}</router-link>
        </div>
      </div>
      <div class="card-body">
        <data-table
          :model="model"
          :endpoint="endpointWithFilters"
          @action="doAction"
          @bulkAction="doBulkAction"
          @response="handleResponse"
          ref="taskList"
          disable-column-options
          disable-pagination
          disable-vertical-scroll
          :route-context="$route.meta?.context"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { TaskListComponent } from '@/mixins/TaskListComponent';
import { DebtorFilter, DebtorFilterOption } from '@/models';
import { appendQueryParams } from '@/utils/queryString';
import { pickBy } from 'lodash';

@Component({ components: {} })
export default class TaskList extends mixins(TaskListComponent) {}
</script>

<style scoped lang="scss"></style>
