import { LoadingState } from '@/models/LoadingState';
import { appendQueryParams } from '@/utils/queryString';
import { DebtorFilter, DebtorFilterOption } from '@/models';
// mixin.js
import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { EmitterAction, BulkAction } from '@/models';
import { AxiosResponse } from 'axios';
import { ListComponent } from '@/mixins/ListComponent';
import { parseEndpoint } from '@/router';
import WorkflowChange from '@/components/views/debtor/WorkflowChange.vue';
import { pickBy } from 'lodash';

type TaskBulkAction = 'process_all' | 'process' | 'workflow_change' | 'cancel' | 'release' | 'claim';

@Component
export class TaskListComponent extends mixins(ListComponent) {
  public loading: LoadingState = false;
  protected model: string = 'task';
  protected nextDebtor: string | null = null;
  protected filters: DebtorFilter = {
    onlyClaimedTasks: false,
    disableDashboardFilter: false,
  };

  protected async doAction(action: EmitterAction) {
    const context: string = `modal.${this.getActionContext(action)}`;
    this.setDataTable();

    // handle view/edit
    if (['view','edit'].includes(action.emit)) {
      this.pushNextRouteFromAction(action);
      return;
    }
    if (action.emit === 'preview') {
      const endpoint = `/task/${action.params.id}/preview`;

      await this.download(endpoint);
      return;
    }
    if (action.emit === 'cancel') {
      await this.showConfirmation(this.$t(`${context}.body`) as string)
      .then(() => this.$store.dispatch('bars/closeRightBar'))
      .then(() => {
        this.post({
          endpoint: `${this.model}/${action.id}/${action.emit}`,
          feedback: {
            success: this.$t(`${context}.success`),
            error: this.$t(`${context}.error`),
          },
        });
      })
      return;
    }
    // handle delete
    if (action.emit === 'delete') {
      this.handleDelete(action);
      return;
    }

    // handle download
    if (action.emit.indexOf('download') !== -1) {
      await this.handleDownload(action);
      return;
    }

    // post
    await this.handlePost(action);
  }

  protected async doBulkAction(action: BulkAction<TaskBulkAction>) {
    this.setDataTable();

    const data: any = {
      feedback: { success: this.$t(`${this.model}.${action.emit}.success`) },
    };

    if (action.emit === 'process_all') {
      data.endpoint = `/dashboard/batch/${this.$route.params.type || action.type}/process`;
      data.payload = { batch_process: 1 };
    }

    if (action.emit === 'process') {
      if (action.context.indexOf('batch') > 1) {
        data.endpoint = `/dashboard/batch/${this.$route.params.type}/${action.emit}`;
        data.payload = { batch_process: { domain: action.selectDomain, selection: 1 } };
      } else {
        data.endpoint = `${this.model}/${action.emit}`;
        data.payload = { process_task: { domain: action.selectDomain } };
      }
    }

    if (action.emit === 'cancel') {
      data.payload = { cancel_task: { domain: action.selectDomain } };
      data.endpoint = `${this.model}/${action.emit}`;
    }

    if (action.emit === 'workflow_change') {
      this.$store.dispatch('bars/openRightBar', {
        component: WorkflowChange,
        properties: {
          type: 'task',
        },
        title: this.$t('debtor.workflow.change.title'),
      });
      return;
    }

    if (action.emit === 'claim' || action.emit === 'release') {
      data.endpoint = `${this.model}/claim`;
      data.payload = {
        claim_task: { domain: action.selectDomain, taskClaimAction: action.emit },
      };
    }

    await this.post(data);
  }

  protected afterLoad(response: AxiosResponse) {
    this.$store.dispatch('dashboard/loadData');
    this.reload();
  }

  protected handleResponse(data: any) {
    this.nextDebtor = data.nextDebtor;
  }

  protected async toggle(filter: DebtorFilterOption) {
    this.filters[filter] = !this.filters[filter];
  }

  @Watch('$route.params.debtorId')
  protected resetNextDebtor() {
    this.nextDebtor = null;
  }

  protected get endpoint(): string {
    const { params, name, meta } = this.$route;
    if (! name) {
      throw new Error('Could not determine endpoint');
    }

    if (name.indexOf('debtor.view') !== -1 || name.indexOf('debtor.task') !== -1) {
      return `/debtor/${this.$route.params.debtorId}/task`;
    }

    return parseEndpoint(meta?.endpoint, params);
  }

  protected get endpointWithFilters() {
    return appendQueryParams(this.endpoint, { filters: pickBy(this.filters) });
  }

  protected get isDebtorView() {
    return this.$route.name && this.$route.name.indexOf('debtor.view') !== -1;
  }

  private async handlePost(action: EmitterAction) {
    await this.post({
      endpoint: `${this.model}/${action.id}/${action.emit}`,
      feedback: { success: this.$t(`${this.model}.${action.emit}.success`) },
    });
  }

  private handleDelete(action: EmitterAction) {
    let endpoint = `/${this.model}/${action.id}/delete`;
    if (action.context && action.context.indexOf('debtor') !== -1) {
      endpoint = `/debtor/${action.params.debtorId}/${action.params.type}/${action.id}/delete`;
    }
    this.delete(endpoint);
  }

  private async handleDownload(action: EmitterAction) {
    const single = action.emit.indexOf('single') !== -1;
    let endpoint = `/dashboard/batch/${action.id}/download/${single ? 'single' : 'double'}`;

    if (action.context && action.context.indexOf('debtor') !== -1) {
      endpoint = `/debtor/${action.params.debtorId}/${action.params.type}/${action.id}/download`;
    }
    await this.download(endpoint);
  }
}
