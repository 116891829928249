<template>
  <vue-topprogress ref="progress" :zIndex="0"/>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { vueTopprogress } from 'vue-top-progress';
import { LoadingState } from '@/models';

@Component({
  components: {
    vueTopprogress,
  },
})
export default class Loader extends Vue {
  @Prop([Boolean, String]) private loading!: LoadingState;

  public mounted() {
    this.setLoading(this.loading);
  }

  @Watch('loading')
  private loadingChanged(loading: LoadingState) {
    this.setLoading(loading);
  }

  private setLoading(loading: LoadingState = true) {
    if (this.$refs.progress) {
      if (loading === 'error') {
        (this.$refs.progress as any).fail();
      } else if (loading) {
        (this.$refs.progress as any).start();
      } else {
        (this.$refs.progress as any).done();
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
