<template>
    <Workflow :workflows="workflows" @change="updateWorkflow($event)" :value="value">
      <a @click.prevent="removeWorkflow()" slot="footer" v-if="this.$parent.filtergroup">
        <icon name="trash-alt"/>
        {{$t('workflow.delete')}}
      </a>
    </Workflow>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

import { WorkflowSelect, SegmentationWorkflow } from '@/models';
import Workflow from './Workflow.vue';

@Component({
  components: { Workflow },
})
export default class Workflows extends Vue {
  @Prop({ type: [Object], required: true })
  private workflows!: WorkflowSelect;
  @Prop({ type: [Object || null], required: false })
  private value!: SegmentationWorkflow | null;

  private updateWorkflow(value: SegmentationWorkflow | null) {
    if (!value) {
      value = {
        type: 'select_workflow',
        parameters: {},
      };
    }
    const workflow = Object.assign({}, value);
    this.$emit('input', workflow);
  }
  private removeWorkflow() {
   this.$emit('input', null);
  }
}
</script>

<style scoped lang="scss">
</style>
