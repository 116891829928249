<template>
  <icon :name="name" size="lg" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class HistoryIcon extends Vue {
  @Prop({ required: true, type: [String] })
  private type!: string;

  get name() {
    switch (this.type) {
      case 'OutgoingEmail':
      case 'DebtorEmail':
        return 'at';
      case 'Letter':
        return 'envelope-open';
      case 'Sms':
        return 'comment-alt';
      case 'DebtorAttachmentLink':
        return 'paperclip';
      case 'Call':
        return 'phone';
      default:
        return 'envelope';
    }
  }
}
</script>
