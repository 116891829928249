<template>
    <multiselect
      :value="newVal"
      @input="updateValue"
      :options="items"
      label="label"
      trackBy="value"
      hide-selected
      deselectLabel
      selectLabel
      selectedLabel
      @search-change="updateSearchDebounce"
      :class="{ 'is-invalid': hasError }"
      :allow-empty="!required"
      :disabled="disableForm || schema.readOnly || schema.disabled"
      @open="isOpen = true"
      @close="isOpen = false">
      <span slot="noResult" v-text="$t('choicelist.no_matches')"/>
      <template slot="beforeList" v-if="!required && value !== ''" >
        <button class='clear_value' @click.prevent="clearValue()">
        </button>
      </template>
      <template slot="noOptions" >
        <span v-if="schema.queryFilterUrl && !queryFilter" v-text="$t('choicelist.search_matches')" />
        <span v-else v-text="$t('choicelist.no_matches')" />
      </template>
    </multiselect>
</template>

<script lang='ts'>
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { SelectValue } from '@/models';
import { JSONSchema4 } from 'json-schema';
import { appendQueryParams } from '@/utils/queryString';
import api from '@/api';
import { debounce } from 'lodash';
import { DEFAULT_DEBOUNCE_DELAY } from '@/plugins/data-table/constants';

@Component({})
export default class JsonFormStringEnumerable extends Vue {
  @Prop() private schema!: JSONSchema4;
  @Prop() private hasError!: boolean;
  @Prop() private disableForm!: boolean;
  @Prop({ default: false }) private required!: boolean;
  @Prop({ default: '' }) private value!: string;

  @Prop({ default: false }) private isOpen!: boolean;
  private queryFilter: string = '';
  private searchResults: SelectValue[] = [];
  private updateSearchDebounce = debounce(this.updateSearch, DEFAULT_DEBOUNCE_DELAY);
  private newVal: string | SelectValue | null;

    constructor() {
      super();
      this.newVal = this.items.find(item => this.value === item.value) || null;
    }
    private updateValue(value: SelectValue) {
    this.newVal = value;
    this.$emit('input', this.newVal?.value ?? null);
  }

  private clearValue() {
      let value = {  value: '', label: 'Select option', $isDisabled: false}
      this.updateValue(value)
  }

  private updateSearch(queryFilter: string) {
    this.queryFilter = queryFilter;
    if (this.schema.queryFilterUrl !== undefined) {
      api
        .get(appendQueryParams(this.schema.queryFilterUrl.replace(/^\/api/, ''), {queryFilter}))
        .then<SelectValue[]>(({ data }) => this.searchResults = data);
    }
  }
  private get items(): SelectValue[] {
    if (this.queryFilter && this.searchResults.length) {
      return this.searchResults;
    }
    return this.schema.enum as unknown as SelectValue[];
  }

  @Watch('items')
  private itemsChanged(items: SelectValue[]) {
    if (this.newVal && typeof this.newVal === 'object' && this.newVal.hasOwnProperty('key')) {
      const currentItem = items.find(item => item.value === (this.newVal as SelectValue).value);
      if (currentItem) {
        this.$set(this.newVal, 'title', currentItem.label);
      }
    }
  }
  @Watch('value')
  private valueChanged(val: string) {
      const currentItem = this.items.find(item => item.value === val);

      if (currentItem) {
        this.newVal = currentItem;
      }
  }
}
</script>

<style lang="scss" scoped>

.clear_value {
  display: block;
  padding: 13px;
  width: 100%;
  height: 100%;
  border: 0;
  background-color: #f8f9fa;
}
</style>
