<template>
  <div>
    <loader :loading="loading" />
    <div class="row mb-3">
      <div class="col-12 col-md-6 col-lg-4">
        <div class="card" v-if="importedPayment">
          <div class="card-head justify-between">
            <h2 class="h5" :class="{ 'text-success': importedPayment.status === 'assigned' }">{{ $t(importedPayment.status) }}</h2>
          </div>
          <div class="card-body overflow-auto">
            <div class="row row-cols-2">
              <div class="text-center">
                <h4 v-text="$t('importedpayment.amount')" />
                <h2 class="text-secondary">{{ importedPayment.transaction_amount | currency }}</h2>
              </div>
              <div class="text-center">
                <h4 v-text="$t('importedpayment.linked_amount')" />
                <h2 class="text-primary">{{ importedPayment.linked_amount | currency }}</h2>
              </div>
            </div>
            <hr>
            <field type="column" label="importedpayment.account_iban" :value="importedPayment.account_iban" />
            <field type="column" label="importedpayment.account_bic" :value="importedPayment.account_bic" />
            <field type="column" label="importedpayment.reference" :value="importedPayment.transaction_reference" />
            <h6  v-if='importedPayment.account_name || importedPayment.address' class='mb-1' v-text="$t('importedpayment.address')" />
            <div v-if='importedPayment.account_name'>{{ importedPayment.account_name }}</div>
            <div v-if='importedPayment.address'>{{ importedPayment.address }}</div>
            <div v-if='importedPayment.informational_message1'>{{ importedPayment.informational_message1 }}</div>
            <div v-if='importedPayment.informational_message2'>{{ importedPayment.informational_message2 }}</div>
            <div v-if='importedPayment.informational_message3'>{{ importedPayment.informational_message3 }}</div>
            <div v-if="importedPayment.imported_payment_receivable_links.length">
              <hr>
              <field
                v-for="link in importedPayment.imported_payment_receivable_links"
                :key="link.receivable.id"
                :translate-label="false"
                :label="link.receivable.receivable_reference"
                :value="link.amount | currency"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-8">
        <div class="card" v-if="!loading || schema">
          <div class="card-head justify-between">
            <h2 class="h5">{{ $t('importedPayment.assign.form.title') }}</h2>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" class="form" v-if="schema" :ref="schema.title">
              <json-form :schema="schema" v-model="model" :errors="errors" :labels="false"/>
              <button class="btn btn-primary">
                <icon name="search" class="mr-1" />
                {{ $t('importedPayment.assign.form.submit') }}
              </button>
              <button class="btn btn-light ml-2" type="reset" @click="model = {}">{{ $t('importedPayment.assign.form.reset') }}</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="card" v-if="table">
      <div class="card-head">
        <h2 class="h5">{{ $t('importedPayment.assign.suggestions.title') }}</h2>
      </div>
      <div class="card-body">
        <data-table v-bind="{ model, endpoint, data: table }" @action="doAction" @bulkAction="doBulkAction" :ref="`${model}List`" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { FormComponent } from '@/mixins/FormComponent';
import { AxiosResponse } from 'axios';
import { EmitterAction, Receivable, Table } from '@/models';
import { parseEndpoint } from '@/router';
import Field from '@/components/global/Field.vue';
import ReceivableView from '@/views/debtor/receivable/View.vue';
import ReceivableForm from '@/views/debtor/receivable/Edit.vue';
import { SettingsForm } from '@/views/settings';
import ImportedPaymentAssign from '@/views/importedpayment/Assign/Form.vue';
import { EventBus } from '@/services/EventBus';

@Component({
  components: { Field },
})
export default class ImportedPaymentView extends mixins(FormComponent) {
  protected model: any = {};
  protected table: null | Table = null;
  protected importedPayment: null|any = null;

  private created() {
    this.setEndpoint();
    this.loadData();
    EventBus.$on('imported_payment.assign.reload', this.loadData);
  }

  private beforeDestroy() {
    EventBus.$off('imported_payment.assign.reload', this.loadData);
  }

  protected afterLoad(res: AxiosResponse) {
    if (res.data.suggestions) {
      this.table = res.data.suggestions.table;
    }
    if(res.data.importedPayment) {
      this.importedPayment = res.data.importedPayment;
    }
  }

  protected afterSubmit(success: boolean, response: AxiosResponse) {
    this.afterLoad(response);
  }

  private async doAction(action: EmitterAction) {
    if (action.emit === 'assign') {
      this.$store.dispatch('bars/openRightBar', {
        component: ImportedPaymentAssign,
        title: this.$t(`imported_payment.${action.emit}`),
        properties: {
          importedPayment: {
            uuid: this.importedPayment.uuid,
          },
          receivable: {
            uuid: action.id,
          }
        }
      });
    }
  }


  private doBulkAction() {

  }

  private setEndpoint() {
    const { params, meta } = this.$route;
    this.endpoint = parseEndpoint(meta?.endpoint, params);
  }
}
</script>
