<template>
  <div>
    <div class="card mb-3">
      <div class="card-head justify-between py-0 pr-0">
        <h2 class="h5">{{ $t('settings.my.user.info') }}</h2>
        <div class="d-flex">
          <router-link
            class="nav-link nav-link-right"
            :title="$t('user.info.edit')"
            :to="{ name: 'settings.user.edit', params: { id: user.uuid } }"
          >
            <icon name="edit" />
          </router-link>
        </div>
      </div>

      <div class="card-body">
        <p>
          <strong>Naam:</strong>
          {{ user.first_name }} {{ user.last_name }}
          <br />
          <strong>E-mail:</strong>
          {{ user.email }}
        </p>
        <div>
          <locale-picker />
          <entity-switcher />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { LocaleModel } from '@/models';
import EntitySwitcher from '@/components/settings/EntitySwitcher.vue';

interface User {
  uuid: string;
  first_name: string;
  last_name: string;
  email: string;
}

@Component({
  components: {
    EntitySwitcher,
  },
})
export default class UserBlock extends Vue {
  @Prop() private user!: User;
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/colors';
.card {
  border-bottom-color: $block-settings;
  & > .card-body {
    min-height: 213px; // exact size of 3 setting blocks (with padding)
  }
}
</style>
