// mixin.js
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { EmitterAction } from '@/models';
import DataTable from '@/plugins/data-table/DataTable.vue';
import { ListComponent } from '@/mixins/ListComponent';
import { parseEndpoint, hasRoute } from '@/router';

@Component
export class SettingsListComponent extends mixins(ListComponent) {
  protected model: string = '';

  protected async doAction(action: EmitterAction) {
    const { params, name, meta } = this.$route;
    if (typeof action.params.id === 'object') {
      Object.assign(action.params, action.params.id);
    }
    this.dataTable = this.$refs[this.model + 'List'] as DataTable;

    if (action.emit === 'delete') {
      let endpoint = '';
      if (name && name.indexOf('settings.template') !== -1) {
        if (name === 'settings.template.edit') {
          endpoint = `settings/templates/${params.template}/${action.params.id}/translation/${
            action.id
          }/delete`;
        } else {
          endpoint = `settings/templates/${params.template}/${action.id}/delete`;
        }
      } else if (name === 'settings.dispute') {
        endpoint = `settings/${meta?.model}/type/${action.id}/delete`;
      } else if (name === 'settings.workflow.edit') {
        endpoint = `settings/${meta?.model}/${action.params.id}/step/${action.id}/delete`;
      } else if (name === 'settings.businessunit.acl') {
        endpoint = `settings/${meta?.model}/${params.id}/entitylink/${action.id}/delete`;
      } else if (name === 'settings.permissions.business_unit_entity_link_permission_group_link') {
        endpoint = `settings/entityuserlinkpermissiongrouplink/${
          action.params.entityUserLinkUuid
        }/businessunitentitylink/${action.params.businessUnitEntityLinkUuid}/delete`;
      } else {
        endpoint = `settings/${meta?.model}/${action.id}/delete`;
      }
      await this.delete(endpoint);
    }
    else if (action.emit === 'download' || action.emit === 'preview') {
      let endpoint = '';
      if (name === 'settings.template.edit') {
        endpoint = `settings/templates/${params.template}/${action.params.id}/translation/${
          action.params.subId
        }/preview`;
      } else {
        endpoint = `settings/${meta?.model}/${action.id}/download`;
      }
      await this.download(endpoint);
    }
    else {
      const newRoute = {
        name: `${action.context}.${action.emit}`,
        params: {
          ...params,
          ...action.params,
          model: meta?.model,
        },
      };
      if (action.emit === 'edit' && !hasRoute(newRoute.name)) {
        if (name && name.indexOf('settings.template') !== -1) {
          newRoute.name = 'settings.template.edit';
          newRoute.params.template = meta?.model;
        } else {
          newRoute.name = 'settings.model.edit';
        }
      } else if (action.emit === 'impersonate') {
        this.loading = true;
        const res = await this.get(`/settings/user/${action.id}/edit`);
        const username = res.form.values.email;
        await this.$store.dispatch('auth/impersonate', username);
        newRoute.name = 'dashboard.index';
        this.loading = false;
      }

      this.$router.push(newRoute);
    }
  }

  protected get endpoint() {
    const { params, name, meta } = this.$route;
    switch (name) {
      case 'settings.workflow.edit':
        return `settings/workflow/${params.id}/steps`;
      default:
        return parseEndpoint(meta?.endpoint, params);
    }
  }

  protected get routeParams() {
    if (this.$route.params.subId) {
      return { ...this.$route.params, subId: { key: 'uuid' } };
    } else {
      return { ...this.$route.params, id: { key: 'uuid' } };
    }
  }

  protected get routeModel() {
    if (this.$route.name === 'settings.template') {
      return this.$route.params.template;
    }
    return this.$route.meta?.model;
  }
}
