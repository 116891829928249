<script lang="ts">
import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ReportFormComponent } from '@/mixins/ReportFormComponent';
import { appendQueryParams } from '@/utils/queryString';

@Component({
  components: {},
})
export default class ReportForm extends mixins(ReportFormComponent) {
  private report: Report = { name: '', type: '', reportType: '' };

  protected created() {
    this.setEndpointAndLoad();
  }
  protected afterLoad() {
    this.updateValues();
  }
  @Watch('model')
  protected formhasChanged() {
    if (this.report.type !== this.model.type || this.report.reportType !== this.model.reportType) {
      this.updateValues();

      this.addParamsToEndpoint();

      this.loadData();
    }
  }

  private updateValues() {
    this.report.name = this.model.name;
    this.report.type = this.model.type;
    this.report.reportType = this.model.reportType;
  }
  private addParamsToEndpoint() {
    this.endpoint = appendQueryParams('report/create', { report: this.report });
  }
}
interface Report {
  name: string;
  type: string;
  reportType: string;
}
</script>
