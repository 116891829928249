<template>
  <div class="h-100">
    <loader :loading="loading" />
    <div>
      <div>
        <div class="row">
          <div class="col-6">{{ $t('payment_file.date') }}</div>
          <div class="col-6 text-right">{{ paymentFile.date | date }}</div>
        </div>
      </div>
      <div class="pl-0 pr-0">
        <div class="mt-3">
          <div class="row">
            <div class="col-6">{{ $t('payment_file.billing_amount') }}</div>
            <div class="col-6 text-right">{{ paymentFile.billing_amount | currency(paymentFile.currency) }}</div>
          </div>
        </div>
        <hr/>
        <div v-if='!isEmpty(paymentFile.description)'>
          <div class="row">
            <div class="col-6">{{ $t('payment_file.description') }}</div>
            <div class="col-6 text-right">{{ paymentFile.description }}</div>
          </div>
          <hr />
        </div>
        <div v-for="payment in paymentFile.payments" :key="payment.id">
          <div class="row">
            <div class="col-6">{{ $t('payment.amount') }}</div>
            <div class="col-6 text-right">{{ payment.transaction_amount - payment.linked_amount | currency(paymentFile.currency) }}</div>
          </div>
          <div class="row">
            <div class="col-6">{{ $t('payment.transaction_date') }}</div>
            <div class="col-6 text-right">{{ payment.transaction_date | date }}</div>
          </div>
          <div class="row">
            <div class="col-6">{{ $t('payment.status') }}</div>
            <div class="col-6 text-right">{{ payment.status }}</div>
          </div>
          <div class="row">
            <div class="col-6">{{ $t('payment.transaction_reference') }}</div>
            <div class="col-6 text-right">{{ payment.transaction_reference }}</div>
          </div>
          <hr />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { LoadingState } from '@/models';
import api from '@/api';
import { PaymentFile } from '@/models';
import isEmpty from 'lodash/isEmpty';

@Component({
  methods: { isEmpty }
})
export default class PaymentFileView extends Vue {
  private paymentFile: PaymentFile | null = null;
  private endpoint: string = '';
  private loading: LoadingState = false;

  private async created() {
    await this.loadData();
  }

  private async loadData() {
    this.loading = true;
    const paymentFileId = this.$store.state.bars.rightBarProperties.paymentFile.uuid;
    this.endpoint = `/payment-file/${paymentFileId}/view`;
    await api
      .get(this.endpoint)
      .then((res) => (this.paymentFile = res.data))
      .catch((err) => this.handleFailure(err));

    this.loading = false;
  }

  private handleFailure(err: any) {
    this.$router.back();
    throw new Error(err);
  }
}
</script>

<style scoped lang="scss">
dt {
  font-weight: 400;
}
</style>
