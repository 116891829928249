<template>
  <data-table :model="model" :endpoint="endpoint" @action="doAction" ref="reportList"/>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ReportListComponent } from '@/mixins/ReportListComponent';

@Component
export default class ReportList extends mixins(ReportListComponent) {}
</script>

<style scoped lang="scss">
</style>
