// mixin.js
import Component, { mixins } from 'vue-class-component';
import { EmitterAction, BulkAction } from '@/models';
import { ListComponent } from '@/mixins/ListComponent';
import WorkflowChange from '@/components/views/debtor/WorkflowChange.vue';

type DebtorDataTableBulkAction = WorkflowBulkActionType | 'workflow_change';
type WorkflowBulkActionType = 'workflow_interrupt' | 'workflow_resume';
type WorkflowStatus = 'on_hold' | 'in_progress';

const STATUS_BY_ACTION = {
  workflow_interrupt: 'on_hold' as const,
  workflow_resume: 'in_progress' as const,
};

@Component
export class DebtorListComponent extends mixins(ListComponent) {
  protected model: string = 'debtor';

  protected async doAction(action: EmitterAction) {
    if (action.emit === 'view' || action.emit === 'edit') {
      if (action.params?.newWindow) {
        const route = this.$router.resolve({
          name: `${this.model}.${action.emit}`,
          params: { ...this.$route.params, [this.model + 'Id']: action.id as string },
        });
        return window.open(route.href, '_blank');
      }
      this.$router.push({
        name: `${this.model}.${action.emit}`,
        params: { ...this.$route.params, [this.model + 'Id']: action.id as string },
      });
    } else if (action.emit === 'delete') {
      this.delete(`/${this.model}/${action.id}/delete`);
    } else if (action.emit === 'download_attachment') {
      this.download(`/dashboard/batch/${action.id}/download/single`);
    } else {
      this.post({ endpoint: `${this.model}/${action.id}/${action.emit}` });
    }
  }

  protected async doBulkAction(action: BulkAction<DebtorDataTableBulkAction>): Promise<void> {
    this.setDataTable();

    const actionType = action.emit;
    switch (actionType) {
      case 'workflow_interrupt':
      case 'workflow_resume':
        await this.handleWorkflowBulkAction(actionType);
        break;
      case 'workflow_change':
        this.$store.dispatch('bars/openRightBar', {
          component: WorkflowChange,
          title: this.$t('debtor.workflow.change.title'),
        });
        break;
    }
  }

  private async handleWorkflowBulkAction(actionType: WorkflowBulkActionType) {
    const newStatus = STATUS_BY_ACTION[actionType];

    await this.showConfirmation(this.$t(`debtor.workflow.interruptresume_${newStatus}.confirmation`) as string)
      .then(() => this.$store.dispatch('bars/closeRightBar'))
      .then(() => this.bulkChangeWorkflow(newStatus))
      .then(() => {
        this.dataTable.clearSelection();
        this.$toasted.success(this.$t(`debtor.workflow.interruptresume_${newStatus}.success`) as string);
        this.dataTable.reload();
      })
      .catch(() => {
        this.$toasted.error(this.$t(`debtor.workflow.interruptresume_${newStatus}.error`) as string);
      });
  }

  private async bulkChangeWorkflow(newStatus: WorkflowStatus) {
    const batch = this.dataTable.selectedItems.values.map((uuid: string) => {
      return {
        debtor: uuid,
        workflow: {
          status: newStatus,
        },
      };
    });
    const payload = { batch };
    return this.post({
      endpoint: '/debtor/workflow/status',
      payload,
    });
  }

  protected get endpoint() {
    return `/${this.model}`;
  }
}
