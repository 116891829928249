<template>
  <div>
    <loader :loading="loading" />
    <form @submit.prevent="submit" class="form" :ref="schema.title">
      <div v-if="data.success">
        <json-form :schema="schema" v-model="model" :errors="errors" v-if="schema" />
        <button type="submit" class="btn btn-primary btn-sm w-100">
          <Icon prefix="fas" name="save" class="mr-2" />
          {{ $t('save') }}
        </button>
      </div>
      <div v-else-if="data.status">
        <p class="invalid-feedback">
          {{ $t(`debtor.workflow.change.error.${data.status}`) }}
        </p>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import api from '@/api';
import { Component, Watch } from 'vue-property-decorator';
import { Selection } from '@/Selection';
import { LoadingState } from '@/models';
import { JSONSchema4 } from 'json-schema';
import { EventBus } from '@/services/EventBus';

type WorkflowChangeResponse = {
      options: Array<{ uuid: string; name: string }>;
      forceResumeWorkflow: boolean;
      updateDebtorOnHold: boolean;
      success: true;
    }
  | {
      message?: string;
      success: false;
    };

@Component
export default class WorkflowChange extends Vue {
  protected static readonly ENDPOINT_OPTIONS = '/debtor/workflow/options';
  protected static readonly ENDPOINT_SUBMIT = '/debtor/workflow';

  private model: any = {};
  private schema: JSONSchema4 = {};
  private errors: any = {};
  private loading: LoadingState = true;
  private data: WorkflowChangeResponse = { success: false };

  private get selection(): string[] {
    return this.$store.getters['selection/selection'](this.type).values;
  }
  protected created() {
    this.reload();
  }
  private get type(): string {
    return this.$attrs.type ?? 'debtor';
  }
  @Watch('selection')
  protected reload() {
    const payload = {
      [`${this.type}s`]: this.selection,
    };
    this.loading = true;
    api
      .post(WorkflowChange.ENDPOINT_OPTIONS, payload)
      .then(({data}) => {
        this.data = data;
        this.updateSchema();
      })
      .catch(error => {
        if (! error.response?.data) {
          throw error;
        }
        this.data = error.response.data;
      })
      .finally(() => this.loading = false);
  }

  protected submit() {
    const workflow = this.model.workflow;
    const forceResumeWorkflow = this.model.forceResumeWorkflow;
    const updateDebtorOnHold = this.model.updateDebtorOnHold;

    if (workflow == null) {
      this.errors = {
        errors: {
          children: {
            workflow: {
              errors: [this.$t('form.error.required') as string],
            },
          },
        },
      };
      return;
    }
    const payload = {
      batch: this.selection.map(selection => ({ [this.type]: selection, workflow })),
      ...forceResumeWorkflow && { forceResumeWorkflow },
      ...updateDebtorOnHold && { updateDebtorOnHold },
    };

    api.post(WorkflowChange.ENDPOINT_SUBMIT, payload).then(({data}) => {
      if (data.success === true) {
        this.$toasted.success(this.$t('debtor.workflow.change.success') as string);
        this.$store.commit('selection/clear', {
          type: this.type,
        });
      } else {
        this.$toasted.error(this.$t('debtor.workflow.change.error') as string);
      }

      EventBus.$emit('form.submit.successful');
    });
  }
  private updateSchema() {
    if (this.data.success === false) {
      return;
    }

    this.schema = {
      title: 'change_workflow',
      type: 'object',
      properties: {
        workflow: {
          type: 'string',
          title: this.$t('debtor.workflow.select.label') as string,
          enum: this.data.options.map(option => ({value: option.uuid, label: option.name})),
        },
        forceResumeWorkflow: {
          type: 'boolean',
          title: this.$t('debtor.force_resume_workflow.label') as string,
          value: this.data.forceResumeWorkflow,
        },
        updateDebtorOnHold: {
          type: 'boolean',
          title: this.$t('debtor.update_debtor_on_hold.label') as string,
          value: this.data.updateDebtorOnHold,
        },
      },
      required: ['workflow'],
    };
  }
}
</script>

<style scoped lang="scss">
</style>
