<template>
<div>
    <SwaggerUI />
</div>
</template>

<script lang='ts'>
import { Vue, Component } from 'vue-property-decorator';
import SwaggerUI from '@/views/support/SwaggerUI.vue';
@Component({
  components: { SwaggerUI },
})
export default class Main extends Vue {
}
</script>

<style lang="scss" scoped>

</style>
