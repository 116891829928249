<template>
  <div>
    <div class="card h-100 mb-3">
      <loader :loading="loading"/>
      <div class="card-head justify-content-between">
        <h2 class="h5">{{ $t('creditor.info.title') }}</h2>
      </div>
      <div class="card-body">
        <div v-if="creditor">
          <field
            v-for="(value, key) in computedFields"
            :key="key"
            :label="`creditor.${key}`"
            :value-type="['created_at', 'updated_at'].includes(key) ? 'datetime' : 'text'"
            :value="value" />
        </div>
      </div>
    </div>
    <entity-property-list entity="creditor" property="contact"/>
    <entity-property-list entity="creditor" property="address"/>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { CreditorInfo } from '@/models';
import EntityPropertyList from '@/components/entity/PropertyList.vue';
import { API } from '@/mixins/API';
import Field from '@/components/global/Field.vue';
import { pickBy } from 'lodash';
@Component({
  components: { EntityPropertyList, Field },
})
export default class SettingsCreditorInfo extends mixins(API) {
  private creditor: CreditorInfo | null = null;
  private ignoredFields = ['defaultContact', 'defaultAddress', 'uuid'];
  private created() {
    this.loadData();
  }
  private get computedFields() {
    if (!this.creditor) {
      return {};
    }
    return pickBy(this.creditor, (value, key) => !this.ignoredFields.includes(key));
  }
  private async loadData() {
    this.creditor = await this.get(`/settings/creditor/${this.$route.params.id}`);
  }
}
</script>
