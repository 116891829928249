import './assets/scss/website.scss';
import 'vue-multiselect/dist/vue-multiselect.min.css';

import Vue from 'vue';
import Toasted from 'vue-toasted';
import Multiselect from 'vue-multiselect';
import VueChartkick from 'vue-chartkick';
import Chart from 'chart.js';
import Modal from 'bootstrap-vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n, { loadLanguages } from './i18n';

import { JsonFormPlugin } from './plugins/json-form';
import { DataTablePlugin } from './plugins/data-table';
import { ColorPickerPlugin } from './plugins/color-picker';
import { WorkflowPlugin } from './plugins/workflow';
import { SegmentationPlugin } from './plugins/segmentation';
import { tinymceInitFull } from './plugins/wysiwyg';

import './filters';
import './components/global';
import './registerServiceWorker';

Vue.config.productionTip = false;

Vue.use(Toasted, {
  duration: 3500,
  singleton: true,
});

Vue.use(VueChartkick, { adapter: Chart });
Vue.use(Modal);

Vue.component('multiselect', Multiselect);

Vue.use(JsonFormPlugin);
Vue.use(DataTablePlugin);
Vue.use(WorkflowPlugin);
Vue.use(SegmentationPlugin);
Vue.use(ColorPickerPlugin);

(async () => {
  await loadLanguages(i18n);
  new Vue({
    router,
    store,
    i18n,
    data: {
      tinymceInitFull,
      refreshKey: 0,
      VUE_APP_API_HOST: process.env.VUE_APP_API_HOST,
    },
    render: h => h(App),
  }).$mount('#app');
})();
