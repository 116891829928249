<template>
  <div class="conditions-and-actions-wrapper my-3">
    <div class="title">Filters</div>
    <switcher :options="['AND', 'OR']" v-model="value.logical_operator"/>
    <div class="card" v-for="(rule, index) in value.rules" :key="rule.uniq">
      <div class="condition-type" v-if="index">{{ value.logical_operator }}</div>
      <div class="card-body form-column">
        <segmentation-filter
          :filter-types="filterTypes"
          @change="ruleChanged(index, $event)"
          :value="rule"
        >
          <a @click.prevent="removeRule(index)" slot="footer">
            <icon name="trash-alt"/>
            {{$t('workflow.delete')}}
          </a>
        </segmentation-filter>
      </div>
    </div>
    <button
      :class="[
        'btn',
        'btn-dark',
        'mt-2',
        'btn-block'
      ]"
      @click.prevent="addRule"
    >
      <span v-if="value.rules.length">+{{value.logical_operator}} FILTER</span>
      <span v-else>+ FILTER</span>
    </button>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import randomize from 'randomatic';

import { Filters as FilterInterface, Rule, ConditionalAction, FilterTypes } from '@/models';
import SegmentationFilter from './Filter.vue';
import Switcher from '../workflow/Switcher.vue';
import clone from '@/utils/clone';

@Component({
  components: { SegmentationFilter, Switcher },
})
export default class Filters extends Vue {
  @Prop({ type: [Object], required: true })
  private filterTypes!: FilterTypes;
  @Prop({ type: [Object], required: true })
  private value!: FilterInterface;

  private addRule() {
    const filters = clone(this.value);
    filters.rules.push({
      type: '',
      parameters: {},
      uniq: randomize('Aa0', 10),
    });
    this.$emit('input', filters);
  }

  private ruleChanged(index: number, value: Rule | null) {
    const filters = clone(this.value);
    if (!value) {
      value = {
        type: '',
        parameters: {},
      };
    }
    filters.rules[index] = Object.assign({}, filters.rules[index], value);
    this.$emit('input', filters);
  }

  private removeRule(index: number) {
    this.value.rules.splice(index, 1);
    if (!this.value.rules.length) {
      this.$emit('onEmptyRuleSet', this.value);
    }
  }
}
</script>

<style scoped lang="scss">
</style>
