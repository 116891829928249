<template>
  <div class="position-absolute pin-y pin-x mt-heading d-flex justify-content-center align-items-end mb-4 mb-lg-0 align-items-lg-center">
    <loader v-bind="{ loading }" />
    <div v-if="! paymentStatus" class="text-center">
      <div v-if="! paymentPopup">
        <button
          class="btn btn-primary text-nowrap py-3 px-4 text-lg"
          v-text="$t('debtorportal.payment.button')"
          @click="openPaymentPopup()"
        />
      </div>
      <div v-else>
        <!-- progress-loader -->
        <div class="loader-container">
          <div class="dot dot-1" />
          <div class="dot dot-2" />
        </div>

        <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
          <defs>
            <filter id="loader-goo">
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
              <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7" />
            </filter>
          </defs>
        </svg>
        <div class="mt-5 text-instruction">
          {{ $t('payment.instruction.message') }}
        </div>
      </div>
    </div>
    <div v-else-if="paymentStatus === 'paid'" class="text-center">
      <!-- check icon -->
      <svg class="status" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
        <circle
          class="path circle"
          fill="none"
          stroke="#73AF55"
          stroke-width="6"
          stroke-miterlimit="10"
          cx="65.1"
          cy="65.1"
          r="62.1"
        />
        <polyline
          class="path check"
          fill="none"
          stroke="#73AF55"
          stroke-width="6"
          stroke-linecap="round"
          stroke-miterlimit="10"
          points="100.2,40.2 51.5,88.8 29.8,67.5 "
        />
      </svg>

      <div class="text-payment">{{ $t('payment.handler.message.success') }}</div>
    </div>
    <div v-else class="text-center">
      <!-- times icon -->
      <svg class="status mb-5" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
        <circle
          class="path circle"
          fill="none"
          stroke="#D06079"
          stroke-width="6"
          stroke-miterlimit="10"
          cx="65.1"
          cy="65.1"
          r="62.1"
        />
        <line
          class="path line"
          fill="none"
          stroke="#D06079"
          stroke-width="6"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="34.4"
          y1="37.9"
          x2="95.8"
          y2="92.3"
        />
        <line
          class="path line"
          fill="none"
          stroke="#D06079"
          stroke-width="6"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="95.8"
          y1="38"
          x2="34.4"
          y2="92.2"
        />
      </svg>
      <div class="text-payment">{{ $t('payment.handler.message.failed') }}</div>
      <div @click="retryPayment()" class="mt-2 button-retry d-inline-block">
        {{ $t('payment.handler.button.retry') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { LoadingState } from '@/models';

@Component({})
export default class PaymentHandler extends Vue {
  protected paymentPopup: Window | null = null;
  protected paymentStatus: string = '';
  private loading: LoadingState = true;

  private created() {
    const paymentUuid = this.$route.params.payment;

    if (!this.paymentUuid) {
      this.$router.replace({ name: 'auth.login' });
    }

    this.loading = false;
  }
  private openPaymentPopup() {
    this.paymentPopup = window.open(
      `${process.env.VUE_APP_API_HOST}/psp/payment/${this.paymentUuid}`,
      '_blank',
      'toolbar=no,location=no,status=no,menubar=no,width=400,height=700,top=100,left=100'
    );
    window.addEventListener('message', this.handlePayment);
  }

  private handlePayment(event: MessageEvent) {
    if (this.paymentPopup && event.data && event.data.status) {
      window.removeEventListener('message', this.handlePayment);
      this.paymentPopup.close();
      this.paymentStatus = event.data.status;
    }
  }
  private paymentIsPaid() {
    //
  }

  private retryPayment(): void {
    this.paymentStatus = '';
    this.openPaymentPopup();
  }
  public get paymentUuid(): string {
    return this.$route.params.payment;
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/colors';
.mt-heading {
  margin-top: 250px;
}
.text-instruction {
  font-size: 4rem;
  color: $text-muted;
}
.text-payment {
  font-size: 4rem;
  color: $text-muted;
}
.button-retry {
  user-select: none;
  cursor: pointer;
  font-size: 2.5rem;
  color: $text-muted;
  border-bottom: 1px solid $text-muted;
}

.loader-container {
  width: 200px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  filter: url('#loader-goo');
  animation: rotate-move 3s ease-in-out infinite;
  & .dot {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #000;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    &.dot-1 {
      background-color: var(--primary-color);
      animation: dot-1-move 3s ease infinite, index 9s -3s ease infinite;
    }
    &.dot-2 {
      background-color: var(--secondary-color);
      animation: dot-2-move 3s ease infinite, index 9s -6s ease infinite;
    }
  }
}

@keyframes dot-1-move {
  20% {
    transform: scale(1);
  }
  45% {
    transform: translateX(50px) scale(0.75);
  }
  80% {
    transform: translate(50px) scale(0.75);
  }
  100% {
    transform: translateY(0px) scale(1);
  }
}

@keyframes dot-2-move {
  20% {
    transform: scale(1);
  }
  45% {
    transform: translateX(-50px) scale(0.75);
  }
  80% {
    transform: translateX(-50px) scale(0.75);
  }
  100% {
    transform: translateY(0px) scale(1);
  }
}

@keyframes rotate-move {
  55% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  80% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes index {
  0%,
  100% {
    z-index: 3;
  }
  33.3% {
    z-index: 2;
  }
  66.6% {
    z-index: 1;
  }
}

svg.status {
  width: 100px;
  display: block;
  margin: auto;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  &.circle {
    -webkit-animation: dash 0.9s ease-in-out;
    animation: dash 0.9s ease-in-out;
  }
  &.line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
    animation: dash 0.9s 0.35s ease-in-out forwards;
  }
  &.check {
    stroke-dashoffset: -100;
    -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
    animation: dash-check 0.9s 0.35s ease-in-out forwards;
  }
}

p {
  text-align: center;
  margin: 20px 0 60px;
  font-size: 1.25em;
  &.success {
    color: #73af55;
  }
  &.error {
    color: #d06079;
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
</style>
