<template>
  <div class="pb-3">
    <div class="card h-100 mb-3">
      <loader :loading="loading" />
      <div class="card-head">
        <h2 class="h5">{{ $t('debtors.info.title') }}</h2>
      </div>
      <div class="card-body" v-if="debtor">
        <p v-if="debtor.reference">
          <span class="text-lg font-weight-bold">{{ debtor.reference }}</span>
        </p>
        <p>{{ debtor.display_name }}</p>
        <p v-if="debtor.defaultContact.telephone">
          {{ $t('debtors.overview.info.phone') }}:
          {{ debtor.defaultContact.telephone }}
        </p>
        <p v-if="debtor.defaultContact.email">
          {{ $t('debtors.overview.info.email') }}:
          {{ debtor.defaultContact.email }}
        </p>
        <br>
        <p v-if="debtor.account_manager">
          {{ $t('debtors.overview.info.account_manager') }}: {{ debtor.account_manager.first_name }} {{ debtor.account_manager.last_name}} &lt;{{debtor.account_manager.email}}&gt;
        </p>
      </div>
    </div>
    <EntityPropertyList entity="debtor" property="contact" />
    <EntityPropertyList entity="debtor" property="address" />
    <EntityPropertyList entity="debtor" property="thirdparty" />
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { Debtor } from '@/models';
import EntityPropertyList from '@/components/entity/PropertyList.vue';
import { API } from '@/mixins/API';

@Component({ components: { EntityPropertyList } })
export default class DebtorInfo extends mixins(API) {
  private debtor: Debtor | null = null;

  private created() {
    this.loadData();
  }

  private reload() {
    this.loadData();
  }

  private async loadData() {
    this.debtor = await this.get(`/debtor/${this.$route.params.debtorId}/contact_info`);
  }
}
</script>

<style lang="scss" scoped>
</style>
