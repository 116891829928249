<template>
  <card v-bind="{title: $t('debtorportal.administrative_info.title'), loading}">
    <template slot="body">
      <placeholder v-if="loading" :widths="['200px', '180px', '80px', '120px']" />
      <div v-else class="pl-0">
        <div v-if="data.receiver" class="row ">
          <p class="col-3 text-capitalize">{{ $t('debtorportal.administrative_info.creditor') }}</p>
          <p class="col-9"><span class="mr-2">:</span>{{ data.receiver }}</p>
        </div>
        <div v-if="data.iban" class="row">
          <p class="col-3 text-uppercase">{{$t('debtorportal.administrative_info.iban')}}</p>
          <p class="col-9"><span class="mr-2">:</span>{{ data.iban }}</p>
        </div>
        <div v-if="data.bic" class="row">
          <p class="col-3 text-uppercase">{{ $t('debtorportal.administrative_info.bic') }}</p>
          <p class="col-9"><span class="mr-2">:</span>{{ data.bic }}</p>
        </div>
        <div v-if="data.reference" class="row">
          <p class="col-3 text-capitalize">{{ $t('debtorportal.administrative_info.reference') }}</p>
          <p class="col-9"><span class="mr-2">:</span>{{ data.reference }}</p>
        </div>
      </div>
    </template>
  </card>
</template>

<script lang='ts'>
import { Vue, Component } from 'vue-property-decorator';
import Card from '@/debtorportal/components/Card.vue';
import { CardComponent } from '@/debtorportal/models/CardComponent';
@Component({
  components: {
    Card,
  },
})
export default class AdministrativeInfo extends CardComponent {
}
</script>
<style>
</style>
