<template>
  <div>
    <loader :loading="loading"/>
    <form @submit.prevent="submit" class="form" v-if="schema" :ref="schema.title">
      <json-form :schema="schema" v-model="model" :errors="errors"/>
      <button type="submit" class="btn btn-primary btn-sm w-100">
        <Icon prefix="fas" name="save" class="mr-2"/>
        {{ $t('save') }}
      </button>
    </form>
  </div>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import api from '@/api';
import clone from '@/utils/clone';
import { AxiosResponse } from 'axios';
import { FormComponent } from '@/mixins/FormComponent';
import { Dictionary } from '@/models';
import DebtorSettlementPlanView from '@/views/debtor/settlementplan/View.vue';

@Component({})
export default class DebtorSettlementPlanInstallmentEdit extends mixins(FormComponent) {
  protected model: any = {};
  protected endpoint!: string;
  protected settlementplan!: string;
  protected installment!: string;

  protected async created() {
    this.$store.commit('bars/setRightBarTitle', 'loading')

    this.settlementplan = this.$store.state.bars.rightBarProperties?.settlementplan;
    this.installment = this.$store.state.bars.rightBarProperties?.installment;

    this.endpoint = `paymentplan/${this.settlementplan}/installment/${this.installment}/edit`;

    await this.loadData();

    if (this.loading === 'error') {
      this.$store.commit('bars/closeRightBar');
    }
  }
  protected afterLoad(response: AxiosResponse) {
    const title: string = this.schema?.title ?? 'debtor.settlement_plan.installment';

    this.$store.commit('bars/setRightBarTitle', this.$t(title + '.header'));

    this.$store.commit('bars/setRightBarFormSubmit', this.submit);
  }

  protected afterSubmit(success: boolean, response: AxiosResponse) {
    if (success && this.schema) {
      this.$store.dispatch('bars/openRightBar', {
        component: DebtorSettlementPlanView,
        properties: {
          settlementplan: { uuid: this.settlementplan },
        },
      });
    }
  }
}
</script>

<style scoped lang="scss">
</style>
