<template>
  <div>
    <div
      class="alert alert-danger mb-3"
      v-if="$store.state.debtor.currentDebtor && $store.state.debtor.currentDebtor.is_syncing">
      <Icon name="exclamation-circle" class="mr-2" />
      <span>{{ $t('debtor.business_unit.is_syncing') }}</span>
    </div>
    <div class="row">
      <div class="col-md-6 col-xl-4 mb-3">
        <debtor-info />
      </div>
      <div class="col-md-6 col-xl-4 mb-3">
        <creditor-info />
      </div>
      <div class="col-xl-4 mb-3">
        <financial-info @onEnableGraydon="setGraydonMode"  v-if="!graydonEnabled" :key="'financial' + $root.$data.refreshKey" />
        <graydon-info @onEnableGraydon="setGraydonMode" v-else />
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-3">
        <div class="card">
          <div class="card-head select-none justify-between py-0 pl-0 pr-0">
            <div class="d-flex">
              <a
                v-for="type in types"
                :key="type"
                class="nav-link h5"
                :class="{ active: selectedType === type }"
                @click="selectType(type)"
                >{{ $t(`debtors.overview.${type}s.title`) }}</a
              >
            </div>
            <router-link
              class="nav-link nav-link-right text-nowrap"
              :to="{ name: `debtor.${selectedType}` }"
            >
              <icon name="th-list" class="pr-1" />
            </router-link>
          </div>
        </div>
        <receivable-list v-if="selectedType === 'receivable'" :key="'receivable' + $root.$data.refreshKey" ref="receivableList"/>
        <settlement-plan-list v-else :key="'spl' + $root.$data.refreshKey" ref="settlementPlanList"/>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <task-list :key="'task' + $root.$data.refreshKey" ref="taskList"/>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <correspondence-list :key="'corr' + $root.$data.refreshKey" ref="correspondenceList" />
      </div>
    </div>
    <div class="row">
      <div class="col-xl-8 mb-3 mb-xl-0">
        <current-process :key="$root.$data.refreshKey" />
        <graph :key="'graph' + $root.$data.refreshKey" class="mt-3" />
      </div>
      <div class="col-xl-4">
        <history :key="'history' + $root.$data.refreshKey" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import DebtorInfo from './DebtorInfo.vue';
import CreditorInfo from './CreditorInfo.vue';
import FinancialInfo from './FinancialInfo.vue';
import ReceivableList from '../receivable/List.vue';
import SettlementPlanList from '../settlementplan/List.vue';
import CurrentProcess from './CurrentProcess.vue';
import CorrespondenceList from './CorrespondenceList.vue';
import History from './History.vue';
import Graph from './Graph.vue';
import TaskList from './TaskList.vue';
import GraydonInfo from '@/views/debtor/dashboard/GraydonInfo.vue';

@Component({
  components: {
    GraydonInfo,
    DebtorInfo,
    CreditorInfo,
    FinancialInfo,
    ReceivableList,
    SettlementPlanList,
    CurrentProcess,
    History,
    Graph,
    TaskList,
    CorrespondenceList,
  },
})
export default class DebtorDashboard extends Vue {
  private types: string[] = ['receivable', 'settlementplan'];
  private selectedType: string = 'receivable';
  private graydonEnabled: boolean = false;

  protected async selectType(type: string) {
    this.selectedType = type;
  }
  protected setGraydonMode(mode: boolean) {
    this.graydonEnabled = mode;
  }
}
</script>

<style lang="scss" scoped>
</style>
