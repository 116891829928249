<template xmlns="http://www.w3.org/1999/html">
  <div
    class="form-group"
    :id="'form-group-' + id"
    :class="[{ select: schema.enum, row: labels && schema.widget !== 'wysiwyg' }, schema.widget]"
    v-if="schema.widget !== 'hidden'"
  >
    <label
      :class="[{ 'col-sm-3': schema.widget !== 'wysiwyg' }, { 'mb-1': schema.widget === 'wysiwyg' }, 'col-form-label']"
      v-if="labels"
      :for="id"
    >
      {{ title || schema.title }} {{ required ? '*' : '' }}
      <icon
        :title="$t(schema.description)"
        name="question-circle"
        prefix="fas"
        v-if="schema.description"
        style="cursor:pointer"
      />
    </label>

    <div class="position-relative" :class="{ 'col-sm-9': labels && schema.widget !== 'wysiwyg' }">
      <json-form-string-enumerable
        v-if="schema.enum"
        v-bind="{ schema, value: newVal, hasError, required }"
        @input="updateValue"
      />

      <Tiles v-else-if="schema.widget === 'tiles'" :schema="schema" :value="newVal" :id="id" @input="updateValue" />

      <div class="date position-relative" v-else-if="schema.widget === 'date'">
        <flatpickr
          :input-class="{ 'is-invalid': hasError, 'form-control': true }"
          :static="true"
          :placeholder="placeholder"
          :value="jsDateVal"
          @change="updateDateValue"
          @clear="updateDateValue"
          :disabled="disableForm || schema.readOnly"
        />
      </div>
      <json-editor v-else-if="schema.attr && schema.attr.widget === 'json'" :value="newVal" @onChange="updateValue" />
      <div class="date position-relative" v-else-if="schema.widget === 'time'">
        <flatpickr
          :input-class="{ 'is-invalid': hasError, 'form-control': true }"
          :placeholder="placeholder"
          :value="newVal"
          :static="true"
          :noCalendar="true"
          :enableTime="true"
          altFormat="H:i"
          :allow-input="false"
          dateFormat="H:i"
          @change="updateValue"
          @clear="updateValue"
          :disabled="disableForm || schema.readOnly"
        />
      </div>
      <wysiwyg
        :id="id"
        :title="title || schema.title"
        :init="wysiwygConfig"
        v-model="newVal"
        :class="['form-control', { 'is-invalid': hasError }]"
        v-else-if="schema.widget === 'wysiwyg' && !disableRichText"
        :disabled="disableForm || schema.readOnly"
      />
      <textarea
        :id="id"
        :title="title || schema.title"
        :class="['form-control', { 'is-invalid': hasError }]"
        v-model="newVal"
        :placeholder="placeholder"
        @input="updateValue($event.target.value)"
        :required="required"
        v-else-if="schema.widget === 'textarea'"
        :disabled="disableForm || schema.readOnly"
      />
      <file-upload
        v-else-if="schema.widget === 'file'"
        :class="{ 'is-invalid': hasError }"
        @onFileChanged="updateValue"
        v-model="newVal"
        :multiple="schema.attr && schema.attr.multiple"
        :accept="schema.attr && schema.attr.accept"
        :disabled="disableForm || schema.readOnly"
        :required="required"
        :title="title || schema.title"
      />

      <currency
        :id="id"
        :initVal="newVal"
        :title="title || schema.title"
        :required="required"
        @onUpdate="updateValue($event)"
        :class="['form-control', { 'is-invalid': hasError }]"
        v-else-if="schema.widget === 'money'"
        :disabled="disableForm || schema.readOnly"
      />
      <color-picker
        :color="newVal"
        :id="id"
        :title="title || schema.title"
        :class="{ 'is-invalid': hasError }"
        :required="required"
        @input="updateValue"
        v-else-if="schema.widget === 'color'"
        :disabled="disableForm || schema.readOnly"
      />
      <input
        v-else
        :id="id"
        :title="title || schema.title"
        :type="schema.widget === 'password' ? (passwordVisible ? 'text' : 'password') : schema.widget || 'text'"
        :class="['form-control', { 'is-invalid': hasError }]"
        :placeholder="placeholder"
        :value="schema.widget === 'number' && typeof newVal === 'string' ? floatParser(newVal) : newVal"
        @input="updateValue($event.target.value)"
        :required="required"
        :step="schema.widget === 'number' ? 'any' : ''"
        :disabled="disableForm || schema.readOnly || schema.disabled"
      />
      <span
        class="position-absolute pin-t pin-r mt-1 mr-3 text-muted cursor-pointer"
        v-if="schema.widget === 'password'"
        @click="passwordVisible = !passwordVisible"
      >
        <icon :name="passwordVisible ? 'eye-slash' : 'eye'" />
      </span>
      <div class="invalid-feedback" v-if="hasError">
        <p v-for="error in hasError" :key="error">{{ error }}</p>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { JSONSchema4 } from 'json-schema';
import templates from '@/plugins/wysiwyg/temporarilyTemplateDefinitions.json';
import { SelectValue } from '@/models';
import JsonEditor from '@/plugins/json-form/widget-types/JsonEditor.vue';
import JsonFormStringEnumerable from '@/plugins/json-form/JsonFormStringEnumerable.vue';
import Flatpickr from '@/plugins/flatpickr/Flatpickr.vue';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import Currency from '@/plugins/json-form/widget-types/Currency.vue';
import FileUpload from '@/plugins/json-form/widget-types/FileUpload.vue';
import Tiles from '@/plugins/json-form/widget-types/Tiles.vue';
import { uploadImageHandler } from '@/plugins/wysiwyg';
import { ImageListEntry, TinyMceTemplate } from '@/plugins/wysiwyg/typings';

type StringType = string | File | File[];
@Component({
  components: {
    JsonFormStringEnumerable,
    JsonEditor,
    FileUpload,
    Flatpickr,
    Currency,
    Tiles,
  },
})
export default class JsonFormString extends Vue {
  @Prop() private schema!: JSONSchema4;
  @Prop() private id!: string;
  @Prop({ required: false, default: null })
  private title!: null|string;
  @Prop({ default: '' })
  private value!: StringType;
  @Prop({ default: false })
  private required!: boolean;
  @Prop() private errors!: any;
  @Prop() private labels!: boolean;
  @Prop() private disableRichText!: boolean;
  @Prop() private disableForm!: boolean;

  private newVal: StringType | SelectValue | null;
  private passwordVisible: boolean = false;

  constructor() {
    super();
    this.newVal = null;
  }
  private created() {
    this.getValueFromInput();

    if (!this.newVal) {
      this.$emit('input', this.newVal);
    }
  }
  private floatParser(value: string) {
    return parseFloat(value.replace(',', '.').replace(' ', ''));
  }
  private updateValue(value: StringType) {
    this.getValueFromInput();
    this.$emit('input', value);
  }
  private updateDateValue(value: Date) {
    const date = value ? format(new Date(value), 'dd-MM-yyyy') : '';

    this.updateValue(date);
  }

  private getValueFromInput(): void {
    if (typeof this.value !== undefined) {
      this.newVal = this.value;
    }
  }
  private updateWysiwygValue(editor: any) {
    editor.on('blur', (e: any) => {
      this.$emit('input', this.newVal);
    });
  }

  @Watch('value')
  private valueChanged(val: string) {
    this.newVal = val;
  }

  private get jsDateVal() {
    const date = parse(this.newVal as string, 'dd-MM-yyyy', new Date());
    return !isNaN(date.valueOf()) ? date : '';
  }

  private get hasError() {
    if (this.errors?.errors?.length) {
      return this.errors.errors;
    }
    return false;
  }

  private get placeholder(): string {
    const placeholder = this.schema?.attr?.placeholder;
    if (placeholder) {
      return this.schema.widget === 'textarea' ? this.$t(placeholder) as string : placeholder;
    }
    if (!this.labels) {
      return this.title ?? this.schema.title ?? '';
    }
    return '';
  }

  private get wysiwygConfig() {
    return Object.assign({}, this.$root.$data.tinymceInitFull, {
      templates,
      init_instance_callback: this.updateWysiwygValue,
      image_list: this.schema.image_list,
      images_upload_handler: uploadImageHandler(this.schema.images_upload_url, (newImage: ImageListEntry) => {
        this.schema.image_list.push(newImage);
      }),
    });
  }
}
</script>
<style lang="scss" scoped></style>
