<template>
  <div class="card-body">
    <loader :loading="loading" />
    <form @submit.prevent="submit" class="form settings-form mb-3" v-if="schema">
      <div class="mb-3 alert alert-danger" v-if="message">{{ $t(message) }}</div>
      <json-form :schema="schema" v-model="model" :errors="errors" />
      <div class="offset-sm-2 pl-1">
        <button type="submit" class="btn btn-primary btn-sm w-100">
          <Icon prefix="fas" name="save" class="mr-2" />
          {{ $t('settings.save') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { FormComponent } from '@/mixins/FormComponent';
import { AxiosResponse } from 'axios';

@Component({})
export default class FunctionalityManagerForm extends mixins(FormComponent) {
  protected message: string = '';
  protected model: any = {};
  protected reloadAfterSubmit: boolean = false;

  @Prop()
  protected endpoint!: string;

  protected created() {
    this.setEndpointAndLoad();
  }

  protected afterSubmit(success: boolean, response: AxiosResponse) {
      this.$store.dispatch('auth/loadAcl');
  }

  @Watch('endpoint')
  protected async setEndpointAndLoad() {
    await this.loadData();
  }
}
</script>
