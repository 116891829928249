<template>
  <div>
    <loader :loading="loading"></loader>
    <form class="row" @submit.prevent="submit" v-if="schema">
      <div class="col-sm-8">
        <json-form
          v-if="schema"
          :disableForm="!allowed"
          :schema="schema"
          v-model="model"
          :errors="errors"
          :labels="false"
        />
      </div>
      <div class="col-sm-4">
        <button type="submit" class="btn btn-primary btn-block mb-0" :disabled="!allowed">Select</button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch } from 'vue-property-decorator';
import { FormComponent } from '@/mixins/FormComponent';
import { mixins } from 'vue-class-component';

@Component({
  components: {},
})
export default class SelectWorkflow extends mixins(FormComponent) {

  protected model = {};
  protected endpoint = '';
  @Prop({default: false}) private allowed!: boolean;

  protected created() {
    this.endpoint = `/debtor/${this.$route.params.debtorId}/workflow/set`;
    this.getDataIfAllowed();
  }

  protected async afterSubmit() {
    this.$emit('reload');
  }

  @Watch('allowed')
  private getDataIfAllowed() {
    if (this.allowed) {
      this.loadData();
    }
  }
}
</script>

<style scoped>
</style>
