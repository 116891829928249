import { Module } from 'vuex';

import { RootState, I18NState, Locale, LocaleDTO } from '@/models';
import { getFromStorage, setLanguage } from '@/i18n';
import api from '@/api';

export const i18n: Module<I18NState, RootState> = {
  namespaced: true,
  state: {
    locale: getFromStorage(),
    token: null,
    schema: null,
    errors: null,
  },
  actions: {
    async getLocaleSchema({ commit, rootState }, authenticated: boolean) {
      const context = rootState.auth.token && authenticated ? 'user' : 'app';
      const res = await api.get(`/${context}/locale`);
      if (res.data.locales && res.data.locales.form) {
        commit('setLocaleSchema', res.data.locales.form);
      }
      if (res.data.form) {
        commit('setLocaleSchema', res.data.form);
      }
    },
    async changeLocale({ commit, rootState, state }, {locale, authenticated}) {
      if (state.locale === locale) {
        return;
      }
      if (rootState.auth.token && authenticated) {
        const dto: LocaleDTO = {
          user_locale: {
            locale,
          },
        };
        const res = await api.post(`/user/locale`, dto);
        if (!res.data.locale) {
          return;
        }
      }
      commit('changeLocale', locale);
    },
  },
  mutations: {
    changeLocale(state, locale: Locale) {
      state.locale = setLanguage(locale) as Locale;
    },
    setLocaleSchema(state, { schema, values, errors }) {
      state.schema = schema;
      if (values.locale) {
        setLanguage(values.locale);
      }
      state.locale = values.locale;
      state.errors = errors;
    },
  },
};
