import { Module } from 'vuex';
import { ActionState, Task, RootState } from '@/models';

export const action: Module<ActionState, RootState> = {
  namespaced: true,
  state: {
    openActions: [],
  },
  getters: {
    open: state => state.openActions,
  },
  mutations: {
    setOpenActions(state, payload: any[]) {
      state.openActions = payload;
    }
  }
};
