import { Module } from 'vuex';

import { RootState, DebtorState, Debtor } from '@/models';
import { isEqual } from 'lodash';
import api from '@/api';

export const debtor: Module<DebtorState, RootState> = {
  namespaced: true,
  state: {
    loading: false,
    currentDebtor: null,
  },
  actions: {
    async loadCurrentDebtor({ state, commit, dispatch }, debtorId) {
      commit('setLoading', true);
      try {
        const res = await api.get('/debtor/' + debtorId + '/contact_info');

        if (!res?.data) {
          dispatch('throwError', { msg: 'Did not receive the correct parameters' }, { root: true });
          return;
        }

        if (isEqual(state.currentDebtor, res.data)) {
          return commit('setLoading', false);
        }

        commit('setCurrentDebtor', res.data);
      } catch (_) {
        commit('setLoading', 'error');
      }
    },
    unloadData({ commit }) {
      commit('unsetData');
    },
  },
  mutations: {
    setLoading(state, loading: boolean) {
      state.loading = loading;
    },
    setCurrentDebtor(state, currentDebtor: Debtor) {
      state.currentDebtor = currentDebtor;
    },
  },
};
