<template>
  <div class="card h-100">
    <loader :loading="loading" />
    <div class="card-head py-0 pr-0 justify-content-between">
      <div class="d-flex align-items-center">
        <router-link :to="{name: 'graydon.view'}">
          <img src="@/assets/img/graydon.svg" alt="graydon" height="18px">
        </router-link>
        <icon name="bookmark" prefix="fas" class="entity-status ml-1" :title="$t(`debtors.overview.graydon.code`)" :class="entityColor" />
        <span v-if="data" class="text-muted text-sm ml-2">{{ data.timestamp.date | datetime }}</span>
      </div>
      <div class="d-flex">
        <div class="nav-link nav-link-right" @click="sync">
          <icon name="sync" />
        </div>
        <div
          @click="downloadReport"
          class="nav-link nav-link-right"
          :title="$t('debtors.overview.graydon.download-report')">
          <icon name="file-chart-line" />
        </div>
        <div class="nav-link nav-link-right" @click="disableGraydon">
          <icon name="close" />
        </div>
      </div>
    </div>
    <div class="card-body p-0">
      <table v-if="data" class="table mb-0 h-100">
        <tbody>
        <tr>
          <td class="p-3">
            <span class="text-xl">{{ data.credit_limit.value | currency }}</span>
            <span>{{ $t('debtors.overview.graydon.credit_limit') }}</span>
          </td>
          <td class="p-3">
            <span class="text-xl">{{ data.payment_score }}<small>/10</small></span>
            <span>{{ $t('debtors.overview.graydon.payment_score') }}</span>
          </td>
        </tr>
        <tr>
          <td class="p-3 text-nowrap">
            <div class="d-flex align-items-center">
            <div class="inline mr-1 rounded" :style="ratingStyle" />
            <span class="text-xl font-semibold">{{ data.rating.value }}</span>
            </div>
            <span>{{ $t('debtors.overview.graydon.rating') }}</span>
          </td>
          <td class="p-3">
            <span class="text-xl">{{ data.pd }}%</span>
            <span>{{ $t('debtors.overview.graydon.pd') }}</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang='ts'>
import Component from 'vue-class-component';
import AbstractGraydonComponent from '@/mixins/AbstractGraydonComponent';

@Component({})
export default class GraydonInfo extends AbstractGraydonComponent {}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/colors';
@import '~@/assets/scss/_variables';

table {
  border-collapse: collapse;
  table-layout: fixed;
  color: $dark;
}

table td,
table th {
  border: 1px solid $medium;
}

table tr:first-child td {
  border-top: 0;
}

table tr:last-child td {
  border-bottom: 0;
}

table tr td:first-child,
table tr th:first-child {
  border-left: 0;
}

table tr td:last-child,
table tr th:last-child {
  border-right: 0;
}

.text-xl {
  display: block;
  color: $text-color;
}
.entity-status {
  transition: background-color 0.2s ease-in;
}
</style>
