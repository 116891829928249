<template>
  <div class="card" v-if="metric">
    <loader :loading="loading" />
    <div class="card-head border-0">
      <h2 class="h5 d-flex flex-row">
        {{ $t('dashboard.graph.title') }}
      </h2>
    </div>
    <div class="card-body border-0">
      <line-chart
        v-if="metric.type == 'line'"
        :data="[
          {
            name: ' ',
            data: lineGraphData,
          },
        ]"
        :colors="['#4074AF', '#DDDB00']"
        :legend="false"
      ></line-chart>
      <div v-else-if="metric.type === 'gauge' && metric.data.dataSet && metric.data.dataSet.length > 1">
        <gauge-chart
          :data="metric.data"
          :height="175"
          :width="350"
          :styles="{ height: '175px', width: '350px', margin: 'auto' }"
        />
        <div class="align-self-center text-center d-flex flex-column">
          <span v-text="$tc('days', Number(value.toFixed(1)))" />
          <div>
            <icon
              prefix="fas"
              :name="difference >= 0 ? 'caret-up' : 'caret-down'"
              :class="difference >= 0 ? 'text-success' : 'text-warning'"
            />
            <span v-text="Number(difference.toFixed(2).replace('-', '')) + '%'" />
          </div>
        </div>
      </div>
      <div class="text-center" v-else>
        <span>{{ $t('debtor.dashboard.chart.insufficient_data') }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { LoadingState } from '@/models';
import api from '@/api';
import Icon from '@/components/global/Icon.vue';
import format from 'date-fns/format';
import { Metric } from '@/models';
import GaugeChart from '@/components/charts/GaugeChart.vue';

@Component({
  components: {
    GaugeChart: GaugeChart as any,
    Icon,
  },
})
export default class Graph extends Vue {
  private loading: LoadingState = false;
  private metric: Metric | any = null;

  private async created() {
    await this.loadData();


  }

  private async loadData() {
    this.loading = true;
    await api
      .get(`/debtor/${this.$route.params.debtorId}/metrics`)
      .then(({ data }) => (this.metric = data.dsoMetrics));

    this.loading = false;
  }
  private get difference(): number {
    const lastMonth = this.prevValue;
    const thisMonth = this.value;
    return ((thisMonth - lastMonth) / lastMonth) * 100;
  }
  private get prevValue(): number {
   return this.metric.data.dataSet[this.metric.data.dataSet.length - 2].value; 
  }
  private get value(): number {
    return this.metric.data.dataSet[this.metric.data.dataSet.length - 1].value;
  }
  private get lineGraphData() {
    const data: any = {};
    if (this.metric && this.metric.data.dataSet) {
      this.metric.data.dataSet.forEach((item: any) => {
        data[format(new Date(item.timestamp.date), 'dd/MM/yyyy')] = item.value;
      });
    }
    return data;
  }
}
</script>
