<template>
  <div class="card action-card form-column mb-2">
    <loader :loading="loading" />
    <div class="title" v-if="title">{{ title }}</div>
    <div class="card-body border">
      <multiselect
        :options="types"
        label="label"
        v-model="selectModel"
        deselectLabel
        hide-selected
        selectLabel
        selectedLabel
        @input="onChange"
      />
      <json-form v-if="schema" :schema="schema" v-model="model" :errors="errors" disable-rich-text />
      <div class="text-right text-muted mt-1">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch } from 'vue-property-decorator';
import { ActionTypes, Action as ActionType } from '@/models';
import { FormComponent } from '@/mixins/FormComponent';
import { mixins } from 'vue-class-component';

@Component({})
export default class Action extends mixins(FormComponent) {
  protected model: any = {};
  protected endpoint = '';

  @Prop({ required: true, type: [Object] })
  private actionTypes!: ActionTypes;
  @Prop({ required: false })
  private title?: string;
  @Prop()
  private value!: any;

  private initialModelValue: any = false;

  private selectModel: SelectModel | null = null;

  protected created() {
    if (!this.value?.type) {
      return;
    }

    this.selectModel = this.types.find((type) => type.key === this.value.type) ?? null;

    if (this.selectModel) {
      this.initialModelValue = Object.assign({}, this.value.parameters);
      this.onChange(this.selectModel);
    }
  }

  private async onChange(e: SelectModel) {
    if (!e.key) {
      return this.clearData();
    }

    this.endpoint = this.actionTypes[e.key].url.replace(/^\/api/, '');
    await this.loadData();

    if (this.initialModelValue) {
      this.model = { ...this.model, ...this.initialModelValue };
      this.initialModelValue = false;
    }
  }

  @Watch('model')
  private onModelChange() {
    let value: ActionType | null = null;

    if (this.selectModel) {
      value = { type: this.selectModel.key, parameters: this.model };
    }
    this.$emit('change', value);
  }

  get types(): SelectModel[] {
    return Object.keys(this.actionTypes).map((key) => ({ key, label: this.actionTypes[key].label }));
  }
}

interface SelectModel {
  key: string;
  label: string;
}
</script>

<style scoped lang="scss"></style>
