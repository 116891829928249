import { EmitterAction } from '@/models/EmitterAction';
import { Warnings } from '@/models/Warnings';
import Component, { mixins } from 'vue-class-component';
import DataTable from '@/plugins/data-table/DataTable.vue';
import { API } from '@/mixins/API';
import { AxiosResponse } from 'axios';

@Component
export class ListComponent extends mixins(API) {
  protected model: string = '';
  protected warnings: Warnings | null = null;
  protected dataTable!: DataTable;

  protected mounted() {
    this.setDataTable();
  }

  protected setDataTable() {
    this.dataTable = this.$refs[this.model + 'List'] as DataTable;
  }

  protected reload() {
    this.setDataTable();

    this.dataTable.reload();
  }

  protected afterLoad(response: AxiosResponse) {
    this.reload();

    this.warnings = response.data?.warnings
  }

  protected async showConfirmation(message: string) {
    return new Promise<any>((resolve, reject) => {
      this.$store.dispatch('modal/open', {
        body: message,
        actions: [
          {
            label: this.$t('modal.record.delete.cancel') as string,
            class: 'btn-dark',
            action: async () => this.$store.dispatch('modal/close'),
          },
          {
            label: this.$t('modal.record.delete.confirm') as string,
            class: 'btn-primary',
            action: async () => {
              this.$store.dispatch('modal/close').then(resolve);
            },
          },
        ],
      });
    });
  }

  protected pushNextRouteFromAction(action: EmitterAction) {
    const name: string = this.getActionContext(action);

    this.$router.push({
      name,
      params: {
        ...this.$route.params,
        ...action.params,
        [this.model + 'Id']: action.id as string,
      },
    });
  }

  protected getActionContext(action: EmitterAction): string {
    const IS_DEBTOR_ROUTE = this.$route.name?.indexOf('debtor') !== -1;
    const DEBTOR_ROUTE = `${action.context}.${this.model}.${action.emit}`;
    const DEFAULT_ROUTE = `${action.context}.${action.emit}`;

    return IS_DEBTOR_ROUTE ? DEBTOR_ROUTE : DEFAULT_ROUTE;
  }
}
