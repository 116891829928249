import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { FormComponent } from '@/mixins/FormComponent';
import { parseEndpoint, getRoute } from '@/router';

@Component
export class SettingsFormComponent extends mixins(FormComponent) {
  protected model: any = {};
  protected endpoint!: string;
  protected reloadAfterSubmit: boolean = true;

  protected created() {
    this.setEndpointAndLoad();
  }

  @Watch('$route.name') //endpoint needs to be reloaded (ie. worfklow create to workflow edit)
  protected async setEndpointAndLoad() {
    const { params, meta } = this.$route;
    this.endpoint = parseEndpoint(meta?.endpoint, { ...params });

    await this.loadData();

    const { platformCustomStyle } = this.model;
    if (platformCustomStyle) {
      const { primaryColor, secondaryColor } = platformCustomStyle;
      this.model.platformCustomStyle = Object.assign({}, platformCustomStyle, {
        primaryColor: primaryColor || this.$store.state.platform.primaryColor,
        secondaryColor: secondaryColor || this.$store.state.platform.secondaryColor,
      });
    }
  }

  protected afterSubmit(success: boolean, response: any) {
    if (success) {
      this.$toasted.success(this.$t(this.$route.name + '.success') as string);

      if (this.$route.meta?.gotoAfterSubmit) {
        let params = this.$route.params;
        if (!params.id) {
          params = { ...params, id: response.data.uuid };
        }
        this.$router.push({
          name: this.$route.meta?.gotoAfterSubmit,
          params,
        });
      } else if (
        this.$route.name === 'settings.template.create' ||
        this.$route.name === 'settings.template.edit'
      ) {
        this.$router.push({ name: 'settings.template' });
      } else {
        this.$router.push({ name: 'settings.' + this.routeModel });
      }
      if (this.$route.name === 'settings.index') {
        this.$store.dispatch('platform/loadData');
      }
    } else {
      this.$toasted.error(this.$t('settings.' + this.routeModel + '.error') as string);
    }
  }

  protected get routeModel() {
    return this.$route.meta?.model || this.$route.params.model;
  }
}
