import { AxiosRequestConfig, AxiosResponse } from 'axios';
// mixin.js
import Vue from 'vue';
import Component from 'vue-class-component';
import { LoadingState, Download } from '@/models';
import api from '@/api';
import axios from 'axios';

@Component
export class API extends Vue {
  public loading: LoadingState = true;
  protected async get(endpoint: string, params?: AxiosRequestConfig) {
    this.loading = true;
    const res = await api.get(endpoint, params);
    if (res.data) {
      this.loading = false;
      return res.data;
    } else {
      this.loading = 'error';
    }
  }
  protected async put({ endpoint, payload, feedback }: any) {
    try {
      this.loading = true;
      const res = await api.put(endpoint, payload);
      if (res) {
        this.$toasted.success(feedback.success as string);
        this.afterLoad(res);
      } else {
        this.$toasted.error(feedback.error as string);
      }
      this.loading = false;
    } catch (_) {
      this.loading = 'error';
      return false;
    }
  }
  protected async post({ endpoint, payload, feedback }: any) {
    try {
      this.loading = true;
      const res = await api.post(endpoint, payload);
      if (res) {
        this.$toasted.success(feedback.success as string);
        this.afterLoad(res);
      } else {
        this.$toasted.error(feedback.error as string);
      }
      this.loading = false;
    } catch (_) {
      this.loading = 'error';
      return false;
    }
  }
  protected async delete(endpoint: string ) {
    this.$store.dispatch('modal/open', {
      body: this.$t('modal.record.delete.body') as string,
      actions: [
        {
          label: this.$t('modal.record.delete.cancel') as string,
          class: 'btn-dark',
          action: async () => this.$store.dispatch('modal/close'),
        },
        {
          label: this.$t('modal.record.delete.confirm') as string,
          class: 'btn-primary',
          action: async () => {
            try {
              const res = await api.delete(endpoint);
              this.$store.dispatch('modal/close');
              this.loading = false;
              this.$toasted.success(this.$t(`delete.success`) as string);
              this.afterLoad(res);
              return;
            } catch (_) {
              this.loading = 'error';
              return;
            }
          },
        },
      ],
    });
  }
  protected async download(endpoint: string): Promise<boolean> {
    try {
      await api.get(endpoint)
      .then((res) => this.createDownloadLink(res))
      .then(({data, filename}) => this.handleDownloadLink(data, filename))

      return true;
  } catch (_) {
    this.loading = 'error';
    }
    return false;
  }

  private async createDownloadLink(res: AxiosResponse) {
    const host = process.env.VUE_APP_API_HOST;

    const {data} = await axios
    .get(`${host}/download/${res.data.attachment}`, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${res.data.token}`,
      },
    });

    return { data, filename: res.data?.filename};
  }

  private handleDownloadLink(data: Blob, filename: string) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', filename ?? 'file');
    document.body.appendChild(link);
    link.click();

    link.remove();
  }

  /* tslint:disable */
  protected afterLoad(response: AxiosResponse) {}
  /* tslint:enable */
}
