import Vue from 'vue';

import 'tinymce/tinymce';
import 'tinymce/themes/silver';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/plugins/paste/plugin';
import 'tinymce/plugins/link/plugin';
import './TemplatePlugin';
import 'tinymce/plugins/textpattern/plugin';
import 'tinymce/plugins/table/plugin';
import 'tinymce/plugins/lists/plugin';
import 'tinymce/plugins/noneditable/plugin';
import 'tinymce/icons/default';
import 'tinymce/plugins/code/plugin';
import 'tinymce/plugins/autoresize/plugin';
import 'tinymce/plugins/image/plugin';
import 'tinymce/plugins/fullscreen/plugin';
import 'tinymce/plugins/pagebreak/plugin';
import 'tinymce/plugins/hr/plugin';
import 'tinymce/plugins/imagetools/plugin';
import 'tinymce/plugins/charmap/plugin';
import Editor from '@tinymce/tinymce-vue';
import api from '@/api';
import { AxiosResponse } from 'axios';
import { BlobInfo, ImageListEntry, TineMCEInit } from '@/plugins/wysiwyg/typings';
import { ImageUploadResponse } from '@/models/ImageUploadResponse';

Vue.component('wysiwyg', Editor);

export const tinymceLightToolbar = 'undo redo | styleselect | bold italic | link';
export const tinymceFullPlugins = 'table link lists image code paste pagebreak hr charmap fullscreen template autoresize';
export const tinymceFullToolbar = [
  'fullscreen code | styleselect  fontsizeselect forecolor backcolor bold italic strikethrough underline removeformat | alignleft aligncenter alignright alignjustify',
  'bullist numlist | indent outdent | link unlink | table image charmap pastetext pagebreak | template',
];

export const tinymceInitFull: TineMCEInit = {
  selector: 'textarea',
  valid_children: '+body[style]',
  menubar: false,
  statusbar: false,
  min_height: 500,
  convert_urls: false,
  insert_toolbar: 'quickimage quicktable media codesample',
  plugins: tinymceFullPlugins,
  toolbar: tinymceFullToolbar,
  setup: (ed: any) => {
    ed.on('init', (e: any) => {
      ed.execCommand('fontName', false, 'Arial');
    });
  },
  content_style: 'body{font-size: 11pt;color: #0e1625;font-family:sans-serif}',
};

export async function uploadImage(endpoint: string, file: BlobInfo): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file.blob(), file.filename());
    api
      .post<ImageUploadResponse>(endpoint, formData, {
        headers: {
          'content-type': `multipart/form-data`,
        },
      })
      .then((result: AxiosResponse<ImageUploadResponse>) => {
        resolve(result.data.location);
      })
      .catch(reject);
  });
}

export function uploadImageHandler(endpoint: string, cb?: (image: ImageListEntry) => void) {
  return (blobInfo: BlobInfo, success: (location: string) => void, failure: (error: any) => void) => {
    uploadImage(endpoint, blobInfo)
      .then((location: string) => {
        if (null != cb) {
          cb({
            title: blobInfo.filename(),
            value: location,
          });
        }
        success(location);
      })
      .catch(failure);
  };
}
