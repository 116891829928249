<template>
  <div class="d-flex">
    <div class="listbox-wrapper">
      <div class="heading d-flex">
        <h5>{{ $t('listbox.available').toUpperCase() }}</h5>
        <div @click="selectAll" class="ml-2 cursor-pointer">
          <icon name="plus" />
        </div>
      </div>
      <ul>
        <li
          class="cursor-pointer"
          v-for="item in availableOptions"
          :key="item.value"
          v-text="item.label"
          @click="selectOption(item)"
        />
      </ul>
    </div>
    <div class="listbox-wrapper">
      <div class="heading d-flex">
        <h5>{{ $t('listbox.selected').toUpperCase() }}</h5>
        <div @click="deselectAll" class="ml-2 cursor-pointer">
          <icon name="minus" />
        </div>
      </div>
      <ul>
        <li
          class="cursor-pointer"
          v-for="(item, index) in newVal"
          :key="item.value"
          v-text="item.label"
          @click="deselectOption(index)"
        />
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { JSONSchema4 } from 'json-schema';
import { SelectValue } from '@/models';

@Component({})
export default class ListBox extends Vue {
  @Prop() private schema!: JSONSchema4;
  @Prop() private value!: SelectValue[];
  @Prop() private options!: SelectValue[];

  private newVal: SelectValue[];

  constructor() {
    super();
    this.newVal = this.value;
  }

  private updateValue() {
    this.$emit('input', this.newVal);
  }
  private selectAll() {
    this.newVal.push(...this.availableOptions);
    this.updateValue();
  }
  private deselectAll() {
    this.newVal = [];
    this.updateValue();
  }
  private selectOption(item: SelectValue) {
    this.newVal.push(item);
    this.updateValue();
  }
  private deselectOption(index: number) {
    this.newVal.splice(index, 1);
    this.updateValue();
  }
  private get availableOptions(): SelectValue[] {
    return this.options.filter(item => !this.value.some(value => value.value === item.value));
  }
}
</script>

<style lang="scss" scoped>

.listbox-wrapper {
  user-select: none;
  background-color: #f0f0f0;
  width: 50%;
  &:first-of-type {
    margin-right: 1em;
  }
  .heading {
    & > h5 {
      color: #333;
      margin: 0;
    }
    padding: 0.5em 2em;
    width: 100%;
    background-color: var(--secondary-color-lightest);
  }
  & > ul {
    height: 230px;
    overflow: auto;
    padding: 0.5em 2em 0.75em;
    margin: 0;
    list-style: none;
    li {
      padding: 0.35em 0;
    }
  }
}
</style>
