<template>
  <div class="card h-100" v-if="data">
    <div class="card-head justify-between">
      <h2 class="h5">{{ $t(`report-statistic.debtor-best-worst-analysis.title`) }}</h2>
    </div>
    <div class="card-body justify-content-start">
      <date-filter v-bind="{ parameters }" @onUpdate="updateParameters"/>
      <div class="mt-2 row">
        <div class="col-6">
          <h6>{{ $t(`report-statistic.debtor-best-worst-analysis.best-debtors.title`) }}</h6>
          <div v-for="debtor in data.best" :key="debtor.debtor_name" class="d-flex justify-content-between w-100">
            <router-link :to="{ name: 'debtor.view', params: { debtorId: debtor.uuid }}">{{ debtor.debtor_name }}</router-link>
            <div>{{ debtor.open_amount | currency(currency) }}</div>
          </div>
        </div>
        <div class="col-6">
          <h6>{{ $t(`report-statistic.debtor-best-worst-analysis.worst-debtors.title`) }}</h6>
          <div v-for="debtor in data.worst" :key="debtor.debtor_name" class="d-flex justify-content-between w-100">
            <router-link :to="{ name: 'debtor.view', params: { debtorId: debtor.uuid }}">{{ debtor.debtor_name }}</router-link>
            <div>{{ debtor.open_amount | currency(currency) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ReportStatisticComponent } from '@/mixins/ReportStatisticComponent';
import { AxiosResponse } from 'axios';

import DateFilter from './filters/DateFilter.vue';

interface BestWorstData {
  best: DebtorData[],
  worst: DebtorData[],
}
interface DebtorData {
      uuid: string,
      debtor_name: string,
      open_amount: string,
      overdue_days: string,
}
@Component({
  components: { DateFilter },
})
export default class DebtorBestWorstAnalysisStatistic extends mixins(ReportStatisticComponent) {

  protected readonly endpoint: string = 'report/statistics/debtor-best-worst-analysis';
  protected data: BestWorstData[] | null = null;

  protected created() {
    this.parameters = {
      dateFrom: new Date(new Date().getFullYear(), 0, 1),
      dateTo: new Date()
    }
  }

  protected handleResponse({ data }: AxiosResponse<BestWorstData[]>) {
    this.data = data;
  }
}
</script>

<style lang="scss" scoped>

</style>
