<template>
  <div class="row">
    <div class="col-12">
      <card v-bind="{ title: $t('debtorportal.verify.title') }">
        <template slot="body">
          <loader v-bind="{ loading }" />
          <form v-if="schema" @submit.prevent="submit" class="form" :ref="schema.title">
            <json-form :schema="schema" v-model="model" :errors="errors" :labels="false" />
            <div class="row">
              <div class="col-12 d-flex justify-content-sm-end">
                <button type="submit" class="btn btn-primary">
                  {{ $t('verify') }}
                </button>
              </div>
            </div>
          </form>
          <div v-else>
            <div class="invalid-feedback" v-if="message">
              <p>{{ message }}</p>
            </div>
          </div>
        </template>
      </card>
    </div>
  </div>
</template>

<script lang='ts'>
import { mixins } from 'vue-class-component';
import { FormComponent } from '@/mixins/FormComponent';
import {Component} from "vue-property-decorator";
import Card from "@/debtorportal/components/Card.vue";
import {LoadingState} from "@/models";
import { AxiosResponse } from 'axios';
import api from "@/api";

@Component({
  components: {
    Card
  }
})
export default class DebtorPortalVerify extends mixins(FormComponent) {
  protected loading: LoadingState = true;
  private message: string|null = null;
  private debtor: string|null = null;

  protected async created() {
    this.loading = true;

    this.debtor = this.$route.query.debtor as string;
    this.authToken = this.$route.query.token as string;

    this.endpoint = `debtorportal/${this.debtor}/verify`;

    await this.loadData();
  }

  protected afterLoad(response: AxiosResponse) {
    this.loading = false;

    this.message = response.data?.message;

    if (response.data?.token) {
      this.authToken = response.data.token;
      api.defaults.headers.common.Authorization = `Bearer ${this.authToken}`;
      this.redirectToPortal();
    }

    if (response.data?.creditorLogo) {
      this.$store.dispatch('platform/setLogo', response.data.creditorLogo);
    }
  }

  protected afterSubmit(success: boolean, response: any) {
    if (success && response.data?.token) {
      this.authToken = response.data.token;
      api.defaults.headers.common.Authorization = `Bearer ${this.authToken}`;
      this.redirectToPortal();
    }

    (FormComponent as any).options.methods.afterSubmit.call(this, success, response);
  }

  private redirectToPortal() {
    const debtor = this.debtor as string;
    const token = this.authToken as string;

    this.$router.push({ name: 'debtorportal', query: { debtor, token } });
  }
}
</script>
