<template>
  <div>
    <loader :loading="loading" />
    <div v-if="settingsEndpoint" class="card">
      <div class="card-body">
        <mailbox-settings :endpoint="settingsEndpoint" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { SettingsFormComponent } from '@/mixins/SettingsFormComponent';
import { AxiosResponse } from 'axios';
import { mixins } from 'vue-class-component';
import MailboxSettings from '@/components/settings/mailbox/MailboxSettings.vue';

@Component({
  components: { MailboxSettings },
})
export default class EditMailbox extends mixins(SettingsFormComponent) {
  protected model: any = {};

  protected settingsEndpoint: string | null = null;
  protected reloadAfterSubmit = false;

  protected afterLoad(response: AxiosResponse) {
    this.settingsEndpoint = response.data.settingsEndpoint;
  }
}
</script>
