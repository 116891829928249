import { Dictionary } from '@/models/Dictionary';
// mixin.js
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { EmitterAction } from '@/models';
import { ListComponent } from '@/mixins/ListComponent';
import ReceivableView from '@/views/debtor/receivable/View.vue';
import ReceivableForm from '@/views/debtor/receivable/Edit.vue';
import SettlementPlanForm from '@/views/debtor/settlementplan/Edit.vue';

@Component
export class ReceivableListComponent extends mixins(ListComponent) {
  protected model: string = 'receivable';

  protected async doAction(action: EmitterAction) {
    const debtorId = this.$route.params.debtorId;
    let type: string = action.params?.type?.toLowerCase() ?? this.$route.params.type.toLowerCase();
    if (! ['invoice', 'creditnote', 'payment', 'imported-payment'].includes(type)) {
      if (['admin', 'penalty', 'interest'].includes(type)) {
        type = 'cost';
      } else {
        type = 'invoice';
      }
    }
    this.setDataTable();
    if (action.emit === 'view' || action.emit === 'edit') {
        this.$store.dispatch('bars/openRightBar', {
          component: action.emit === 'view' ? ReceivableView : ReceivableForm,
          title: this.$t(`debtor.receivable.${action.emit}`),
          properties: {
            receivable: {
              uuid: action.id,
              type,
            },
          }
        });
        return;
    }
    if (action.emit === 'delete') {
      this.delete(`/debtor/${debtorId}/${type}/${action.id}/delete`);
    } else if (action.emit.indexOf('download') !== -1) {
      this.download(`/debtor/${debtorId}/${type}/${action.id}/download`);
    } else {
      this.post({
        endpoint: `${this.model}/${action.id}/${action.emit}`,
        feedback: { success: this.$t(`${this.model}.${action.emit}.success`) },
      });
    }
  }
  protected async doBulkAction(action: any) {
    this.setDataTable();
    const createActions = ['dispute', 'promise_to_pay', 'payment_plan','export'];
    const changeActions = ['on_hold','legal_on_hold', 'archived','open'];

    const settlementplan: Dictionary<string> = {
        uuid: action.id,
        type: action.emit,
      };

    if (createActions.includes(action.emit)) {
      this.$store.dispatch('bars/openRightBar', {
        component: SettlementPlanForm,
        title: this.$t(`settlementplan.${action.emit}.create`),
        properties:{
          settlementplan,
        }
      });
      return;
    }
    if (changeActions.includes(action.emit)) {
      const payload = {
        receivable_status: {
          domain: action.selectDomain,
          status_action: action.emit.toUpperCase(),
        },
      };
      this.post({
        endpoint: `/debtor/${this.$route.params.debtorId}/${this.model}/status`,
        payload,
        feedback: { success: this.$t(`${this.model}.${action.emit}.success`) },
      });
      return;
    }
  }

  protected get endpoint() {
    // if type is set and is valid option then return the type endpoint else return model (invoice)
    const type = ['payment', 'cost', 'creditnote', 'invoice', 'imported-payment'].includes(this.type) ? this.type : this.model;
    return `/debtor/${this.$route.params.debtorId}/${this.isDebtorView ? 'receivable' : type}`;
  }

  protected get isDebtorView() {
    return this.$route.name?.indexOf('debtor.view') !== -1;
  }

  protected get type() {
    return this.$route.params.type?.toLowerCase();
  }
}
