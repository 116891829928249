<template>
  <div>
    <loader :loading="loading" />
    <form @submit.prevent="submit" v-if="schema">
      <div class="card h-100 mb-3 border-0">
        <div class="card-head justify-content-between py-0">
          <h2 class="h5">{{ $t('settings.segmentation.add.header') }}</h2>
          <button type="submit" class="nav-link nav-link-right">
            <Icon prefix="fas" name="save" class="mr-2" />
            {{ $t('save') }}
          </button>
        </div>
        <div class="card-body">
          <json-form :schema="schema" v-model="model" :errors="errors" :extra="extra" />
          <button type="submit" class="btn btn-primary btn-sm">{{ $t('save') }}</button>
        </div>
      </div>
    </form>
  </div>
</template>
<script lang="ts">
import { Component, Watch } from 'vue-property-decorator';
import { SettingsFormComponent } from '@/mixins/SettingsFormComponent';
import { mixins } from 'vue-class-component';

@Component({})
export default class SettingsSegmentationForm extends mixins(SettingsFormComponent) {
  private extra: any = {
    segmentationData: {},
  };

  protected afterLoad(response: any) {
    const extra = Object.assign({}, this.extra);
    if (response.data && response.data.workflowSelect) {
      extra.segmentationData.workflowSelect = response.data.workflowSelect;
    }
    if (response.data && response.data.filterTypes) {
      extra.segmentationData.filterTypes = response.data.filterTypes;
    }
    this.extra = extra;
  }
}
</script>

<style lang="scss" scoped>
</style>