<template>
  <div class="card mb-4">
    <div class="card-head justify-content-between py-0 px-0">
      <div class="d-flex">
        <router-link
          v-for="type in ['email', 'letter', 'sms', 'call', 'note']"
          :key="type"
          :to="{
            name: 'debtor.communication.type',
            params: { type, debtorId: $route.params.debtorId },
            query: {},
          }"
          class="nav-link"
          :class="{ active: $route.params.type === type }"
          @click.native='loadData'
        >

          <span class=" h5 d-none d-lg-block">{{ $t(`debtor.communication.${type}`) }}</span>
          <span class='badge badge-communication ml-1'>{{ counts[type] }}</span>
          <icon class="d-lg-none" :name="getIconFromType(type)" />
        </router-link>
      </div>
      <router-link
        :to="{ name: 'debtor.communication.type.task.create' }"
        class="nav-link nav-link-right"
      >
        <icon
          class="icon-plus"
          name="plus"
          :title="$t('debtor.communication.' + $route.params.type + '.create')"
        />
      </router-link>
    </div>
    <div class="card-body">
      <data-table
        :key="$route.params.type"
        :model="$route.params.type"
        :endpoint="endpoint"
        route-context="debtor.communication.type"
        :route-params="{
          debtorId: $route.params.debtorId,
          type: $route.params.type,
          typeId: { key: 'uuid' },
        }"
        @action="doAction"
        ref="taskList"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { TaskListComponent } from '@/mixins/TaskListComponent';
import DataTable from '@/plugins/data-table/DataTable.vue';
import { Dictionary } from '@/models';
import api from '@/api';

@Component({ components: { DataTable } })
export default class DebtorCommunication extends mixins(TaskListComponent) {
  private counts = [];
  private getIconFromType(type: string): string {
    const options: Dictionary<string> = {email: 'at', letter: 'envelope-open', sms: 'comment-alt', call:'phone', note: 'file'};

    return options[type] ?? 'letter';
  }


  protected created() {
    this.loadData();
  }

  protected async loadData() {
    let endpoint = `/debtor/${this.$route.params.debtorId}/communication/counts`;
    this.loading = true;

    await api.get(endpoint)
      .then(res => {
        this.counts = res.data;
        this.loading = false;
        this.afterLoad(res);
      }).catch(() => {
        this.loading = 'error';
      });
  }
}
</script>

<style scoped lang="scss">

.badge-communication {
  background-color: var(--secondary-color);
  color: white;
  border-radius: 10px;
  height: 1.5em;
  align-items: center;
}

</style>
