<template>
  <div v-if="item" class="my-1">
    <div class="d-flex select-none cursor-pointer" @click="nextState()">
      <div class="icon-wrapper">
        <Icon v-if="item.value === 'allow'" :fw="true" class="text-success" name="check" size="lg" />
        <Icon v-else-if="item.value === 'deny'" :fw="true" class="text-danger" name="times" size="lg" />
        <Icon v-else prefix="fas" name="circle" :fw="true" class="text-muted" size="xs" />
      </div>
      <span v-text="item.label" class="font-light" />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { JSONSchema4 } from 'json-schema';
import TristateItem from '@/models/TristateItem';
import clone from '@/utils/clone';
import { EventBus } from '@/services/EventBus';
@Component({})
export default class TristateGroup extends Vue {
  @Prop() private value!: any;
  @Prop() private schema!: JSONSchema4;

  private mounted() {
    if (!this.valueIdentifier) {
      this.$toasted.error(this.$t('form.tristate.errors.no-identifier') as string);
    }
    EventBus.$on(`${this.schema.title}.tristate.setState`, (state: string) => this.setState(state));
  }
  private get item(): TristateItem {
    return new TristateItem(this.value.uuid, this.value.label, this.value[this.valueIdentifier]);
  }
  @Emit('input')
  private update() {
    const value = clone(this.value);
    value[this.valueIdentifier] = this.item.value;
    return value;
  }
  private nextState() {
    this.item.nextState();

    this.update();
  }
  private setState(state: string) {
    this.item.setState(state);

    this.update();
  }
  private get valueIdentifier(): string {
    let key: any = null;
    if (this.schema && this.schema.properties) {
      key = Object.values(this.schema.properties).find(val => {
        return JSON.stringify(val).indexOf('choice-expanded') !== -1;
      });
    }
    return key.title;
  }
}
</script>

<style lang="scss">
.icon-wrapper {
  width: 2rem;
  display: flex;
  justify-content: center;
  margin-right: 0.75rem;
}
</style>
