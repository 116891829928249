<template>
  <div class="card h-100" :class="{ 'import-failed': hasFailedImports }">
    <div class="card-head justify-content-start">
      <div @click="handleSearchToggle" class="text-primary mt-1 cursor-pointer">
        <icon name="filter"/>
      </div>
      <input
        class="form-control flex-grow-1 ml-2 mr-3"
        type="text"
        v-if="searchMode"
        v-model="search"
        autofocus
        @keydown.esc="handleSearchToggle"
        :placeholder="$t('dashboard.application-data.search')"
      />
    </div>
    <div class="card-body overflow-auto" :class="businessUnits.length ? 'justify-content-start' : 'justify-content-center'">
      <div v-if="businessUnits.length">
        <div v-for="bu in businessUnits" :key="bu.uuid" class="d-flex my-1">
          <div class="text-dark mr-2 cursor-pointer" v-if="bu.is_failed" @click="openFailedWarningDialog(bu)">
            <icon name="exclamation-circle" class="text-danger" />
          </div>
          <router-link v-else class="text-dark mr-2" :to="{ name: 'settings.businessunit.sync', params: { id: bu.uuid } }">
            <div :class="{ syncing: bu.is_syncing }" :title="getSyncTitle(bu)">
              <icon name="sync" />
            </div>
          </router-link>
          <span v-text="bu.displayName" />
        </div>
      </div>
      <div v-else class="d-flex justify-content-center">
        <span v-text="$t('datatable.no-records-found')"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Import, DateTime } from '@/models';
import format from 'date-fns/format';

@Component({})
export default class ApplicationData extends Vue {
  @Prop() private imports!: Import[];

  private searchMode: boolean = false;
  private search: string = '';

  private getDate(dateTime?: DateTime) {
    return dateTime ? format(new Date(dateTime.date), 'dd/MM/yy HH:mm') : '-';
  }
  private handleSearchToggle(): void {
    this.search = '';
    this.searchMode = !this.searchMode;
  }
  private openFailedWarningDialog(i: Import) {
      this.$store.dispatch('modal/open', {
        body: this.$t('dashboard.application-data.sync-failed.message'),
        actions: [
          {
            label: this.$t('dashboard.application-data.sync-failed.confirm'),
            class: 'btn-primary',
            action: async () => {
              this.$store.dispatch('modal/close')
                .then(() => this.$router.push({name: 'settings.businessunit.sync', params: {id: i.uuid}}));
            },
          },
        ],
      });
  }
  private getSyncTitle(bu: Import): string {
    const CONTEXT = '';

    if (bu.is_syncing) {
      return (this.$t(`dashboard.application-data.syncing`) as string);
    }

    const DATE = this.getDate(bu.importDateTime);
    return (`${this.$t('dashboard.application-data.last-sync-date')}: ${DATE}`);
  }
  private get businessUnits(): Import[] {
    if (!this.imports.length) {
      return [];
    }
    return this.imports.filter((bu: Import) => bu.displayName.toLowerCase().includes(this.search.toLowerCase()));
  }
  private get hasFailedImports(): boolean {
    return this.imports.some(i => i.is_failed);
  }
}

interface BusinessUnitImportData {
  uuid: string;
  name: string;
  syncing: boolean;
  date: string;
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";

.card {
  &.import-failed {
    border: solid 3px rgba(255,0,0,0.7);
  }
  .form-control:focus {
    box-shadow: none;
  }
  .card-body {
    max-height: 200px;
  }
}
.fade-enter-active,
.fade-leave-active {
  opacity: 0;
  transition: opacity $speed ease;
}
.form-control {
  background-color: hsl(0, 0%, 99%);
  border-color: transparent;
  border-bottom-color: var(--primary-color);
}
.syncing {
  animation-name: spin;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
}

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
