import { Module } from 'vuex';

import { RootState, DashboardState, DashboardData, FinancialInformation } from '@/models';
import api from '@/api';
import { AxiosResponse } from 'axios';

export const dashboard: Module<DashboardState, RootState> = {
  namespaced: true,
  state: {
    loading: false,
    data: null,
    financialInformation: null,
    metrics: null,
  },
  actions: {
    async loadData({ commit, dispatch }) {
      commit('setLoading', true);
      try {
        const res = await api.get('/dashboard');
        if (res.data) {
          commit('setData', res.data);
        } else {
          dispatch('throwError', { msg: 'Did not receive the correct parameters' }, { root: true });
        }
        commit('setLoading', false);
      } catch (_) {
        commit('setLoading', 'error');
      }
    },
    async loadFinancialInformation({commit, dispatch}, params) {
      commit('setLoading', true);

        await api.get('/dashboard/financial-information', { params })
          .then(({data}: AxiosResponse<FinancialInformation>) => commit('setFinancialInformation', data))
          .catch(() => dispatch('throwError', { msg: 'Did not receive the correct parameters' }, { root: true }));

        commit('setLoading', false);
    },
    async loadMetrics({ commit, dispatch }) {
      commit('setLoading', true);
      try {
        const res = await api.get('/dashboard/metrics');
        if (res.data) {
          commit('setMetrics', res.data);
        } else {
          dispatch('throwError', { msg: 'Did not receive the correct parameters' }, { root: true });
        }
        commit('setLoading', false);
      } catch (_) {
        commit('setLoading', 'error');
      }
    },
    unloadData({ commit }) {
      commit('unsetData');
    },
  },
  mutations: {
    setLoading(state, loading: boolean) {
      state.loading = loading;
    },
    setMetrics(state, data: any) {
      state.metrics = data;
    },
    setFinancialInformation(state, data: FinancialInformation) {
      state.financialInformation = data;
    },
    setData(state, data: DashboardData) {
      state.data = data;
    },
    unsetData(state) {
      state.loading = false;
      state.data = null;
    },
  },
};
