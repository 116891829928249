// mixin.js
import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { FormComponent } from '@/mixins/FormComponent';
import { parseEndpoint } from '@/router';
import { AxiosResponse } from 'axios';

@Component
export class DebtorFormComponent extends mixins(FormComponent) {
  protected model: any = {};
  protected endpoint!: string;
  protected reloadAfterSubmit: boolean = false;

  protected created() {
    this.setEndpointAndLoad();

    this.$store.commit('bars/setRightBarTitle', 'loading');
  }

  @Watch('$route.name')
  @Watch('$route.params.receivableId')
  @Watch('$route.params.settlementplanId')
  protected setEndpointAndLoad() {
    const { params, name } = this.$route;
    switch (name) {
      case 'debtor.edit':
        this.endpoint = `debtor/${params.debtorId}/edit`;
        break;
      case 'debtor.create':
        this.endpoint = 'debtor/create';
        break;
      case 'debtor.view.receivable.edit':
        this.endpoint = `/debtor/${params.debtorId}/${params.type.toLowerCase()}/${
          params.receivableId
        }/edit`;
        break;
      case 'debtor.view.settlementplan.edit':
        this.endpoint = `/settlementplan/${params.settlementplanId}/edit?domain=Debtor/${
          params.debtorId
        }/Invoices/Open`;
        break;
        case 'dashboard.dispute.edit':
          this.endpoint = `/settlementplan/${params.settlementplanId}/edit`;
          break
      case 'debtor.info.note.create':
        this.endpoint = `/debtor/${params.debtorId}/note/create`;
        break;
      case 'debtor.info.note.edit':
        this.endpoint = `/debtor/note/${params.noteId}/edit`;
        break;
      case 'debtor.receivable.create':
        this.endpoint = `/debtor/${params.debtorId}/${params.type.toLowerCase()}/create`;
        break;
      case 'debtor.receivable.edit':
        this.endpoint = `/debtor/${params.debtorId}/${params.type.toLowerCase()}/${
          params.receivableId
        }/edit`;
        break;
      case 'debtor.communication.type.task.create':
        this.endpoint = `/debtor/${params.debtorId}/${params.type}/create`;
        break;
      case 'debtor.communication.type.task.edit':
        this.endpoint = `/debtor/${params.debtorId}/${params.type}/${
          this.$route.params.typeId
        }/edit`;
        break;
      default:
        this.endpoint = parseEndpoint(this.$route.meta?.rightBarEndpoint ?? '', params);
        break;
    }
    this.loadData();
  }

  protected afterLoad(response: AxiosResponse) {
    const title: string = this.schema && this.schema.title ? this.schema.title : this.$route.meta?.title;

    this.$store.commit('bars/setRightBarTitle', this.$t(title + '.header'));

    this.$store.commit('bars/setRightBarFormSubmit', this.submit);
  }

  protected afterSubmit(success: boolean, response: AxiosResponse) {

    if (success && this.$route.name === 'debtor.edit') {
      this.$store.dispatch('debtor/loadCurrentDebtor', this.$route.params.debtorId);
    }

    (FormComponent as any).options.methods.afterSubmit.call(this, success, response);

    if (success && this.schema) {
      if (this.$route.name === 'debtor.create' && response.data.uuid) {
        this.redirectToUuid('debtor.view', { debtorId: response.data.uuid });
      }

    }
  }
}
