<template>
  <div :class="`field-${type}`" v-if="value">
    <div :class="{'font-weight-bold': translateLabel }" v-if="label" v-text="translateLabel ? $t(label): label" />
    <div v-if="'datetime' === valueType">{{ parsedValue | datetime }}</div>
    <div v-else-if="'date' === valueType">{{ parsedValue | date }}</div>
    <div v-else-if="asHtml" v-html="parsedValue" />
    <div v-else v-text="parsedValue" />
  </div>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Field extends Vue {
  @Prop({ default: 'row' }) private type!: 'row' | 'column';
  @Prop({ default: false, required: false }) private asHtml!: boolean;
  @Prop({ default: 'text' }) private valueType!: 'text' | 'date' | 'datetime';
  @Prop({default: true}) private translateLabel!: boolean;
  @Prop({default: null }) private label!: string|null;
  @Prop() private value!: string|boolean;


  private get parsedValue(): string {
    if (typeof this.value === 'boolean') {
      return '-';
    }

    return this.value;
  }
}
</script>
