<template>
  <div>
    <loader :loading="loading" />
    <ViewEmail v-if="!loading" :data="data" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { LoadingState, Download } from '@/models';
import ViewEmail from '@/components/views/Email.vue';
import api from '@/api';

@Component({
  components: { ViewEmail },
})
export default class EmailView extends Vue {
  @Prop({ required: false, default: null })
  protected email!: string;

  @Prop({ required: false, default: null })
  protected debtor!: string;

  protected loading: LoadingState = true;
  protected data: {} = {};

  protected async created() {
    await this.loadData();
  }

  @Watch('endpoint')
  protected async loadData() {
    this.loading = true;
    const res = await api.get(this.endpoint);
    this.loading = false;
    this.data = res.data;
  }

  private get endpoint()
  {
    if(this.email != null && this.debtor != null) {
      return `/debtor/${this.debtor}/email/${this.email}`;
    }
    else {
      return `/email/${this.$route.params.emailId}`;
    }
  }
}
</script>

<style scoped lang="scss">
dt {
  font-weight: 400;
}
</style>
