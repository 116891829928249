import { Component } from 'vue-property-decorator';
import { TaskListComponent } from '@/mixins/TaskListComponent';
import { BatchTask, EmitterAction } from '@/models';

@Component
export class BatchListMixin extends TaskListComponent {
  private get openTasks(): BatchTask[] {
    return this.$store.getters['batch/open'];
  }

  protected async afterAction() {
    return this.loadData();
  }

  protected async loadData() {
    //Empty so we can prep for the load
    this.$store.commit('bars/setTree', []);
    const data = await this.get(`/dashboard/batch`);
    this.$store.commit('batch/setOpenTasks', data.openTasks);
  }

  private async created() {
    await this.loadData();
    this.$store.commit('bars/setTree', this.openTasks);
  }

  private async doActionAndUpdate(action: EmitterAction) {
    await this.doAction(action);
    await this.afterAction();
  }

  private async doBulkActionAndUpdate(action: any) {
    await this.doBulkAction(action);
    await this.afterAction();
  }
}
