export class CollectionData {
  public receivable: CollectionReceivable | null = null;
  public set(data: CollectionData) {
    this.receivable = data.receivable;
  }
}

interface CollectionReceivable {
  uuid: string;
  dossier: CollectionDossier;
  reference: string;
  date: string;
  dueDate: string;
  amount: number;
  hasAttachment: boolean;
  meta: {
    street: string;
    houseNr: string;
    boxNr: string;
    zipCode: string;
  };
}
interface CollectionDossier {
  reference: string,
  status: string;
  substatus: string|null;
}
