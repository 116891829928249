<template>
  <div class="card-columns">
    <UserBlock :user="currentUser"/>
    <div v-for="(value, key) in $store.state.auth.acl.settings" :key="key">
      <Block
        v-if="value && getRouteByAcl(key)"
        :title="$t(getModel(key))"
        :link="{ name: getRouteByAcl(key).name, params:{model:getModel(key), template:getModel(key)} }"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import UserBlock from '@/components/settings/UserBlock.vue';
import { User } from '@/models';
import { getRouteByAcl } from '@/router';

@Component({
  components: {
    UserBlock,
  },
})
export default class SettingsIndex extends Vue {
  get currentUser(): User {
    return this.$store.state.auth.user;
  }

  protected getRouteByAcl(name: string) {
    return getRouteByAcl(name);
  }

  protected getModel(key: string = '') {
    return (
      getRouteByAcl(key).meta.model ||
      (key.split('_') && key.split('_').length && key.split('_')[2])
    );
  }
}
</script>

<style lang="scss" scoped>
</style>
