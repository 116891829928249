<template>
  <nav class="sidebar" :style="styles" :class="{ open }">
    <ul class="list-unstyled main-nav">
      <router-link
        tag="li"
        class="main-nav--item pl-3 pr-3"
        :class="{ open: $route.name && $route.name.indexOf('dashboard') !== -1 }"
        :style="[$route.name && $route.name.indexOf('dashboard') !== -1 ? styles.active : '']"
        :to="{ name: 'dashboard.index' }"
      >
        <a class="py-2">
          <icon name="chart-bar"></icon>
          {{ $t('sidebar.dashboard') }}
        </a>
        <button class="sub-nav--toggle btn btn-link">
          <icon prefix="fas" name="angle-down"></icon>
        </button>
        <ul class="list-unstyled sub-nav">
          <li class="sub-nav--item pl-4">
            <router-link :to="{ name: 'dashboard.action.list' }" :active-class="activeClass" class="pb-2">
              {{ $t('sidebar.actionlist') }}
              <span class="badge badge-actions float-right" :style="badgeColor('dashboard.action.list')">{{
                actions
              }}</span>
            </router-link>
          </li>
          <li class="sub-nav--item pl-4">
            <router-link
              :to="{ name: 'dashboard.dispute.list' }"
              :active-class="activeClass"
              class="pb-2"
            >
              {{$t('sidebar.disputelist')}}
              <span
                class="badge badge-disputes float-right"
                :style="badgeColor('dashboard.dispute.list')"
              >{{ disputes }}</span>
            </router-link>
          </li>
          <li class="sub-nav--item pl-4">
            <router-link :to="{ name: 'dashboard.batch.list' }" :active-class="activeClass" class="pb-2">
              {{ $t('sidebar.batchlist') }}
              <span class="badge badge-batch float-right" :style="badgeColor('dashboard.batch.list')">{{ batch }}</span>
            </router-link>
          </li>
        </ul>
      </router-link>
      <router-link
        tag="li"
        class="main-nav--item pl-3 pr-3"
        :active-class="activeClass"
        :style="[$route.name && $route.name.indexOf('debtor') !== -1 ? styles.active : '']"
        :to="{ name: 'debtor.index' }"
      >
        <a class="py-2">
          <icon name="credit-card"></icon>
          {{ $t('sidebar.debtors') }}
        </a>
        <ul class="list-unstyled sub-nav" v-if="$route.params.debtorId">
          <li class="sub-nav--item pl-4">
            <router-link :to="{ name: 'debtor.view' }" :active-class="activeClass" class="pb-2">{{
              $t('sidebar.debtor.dashboard')
            }}</router-link>
          </li>
          <li class="sub-nav--item pl-4">
            <router-link :to="{ name: 'debtor.info' }" :active-class="activeClass" class="pb-2">{{
              $t('sidebar.debtor.info')
            }}</router-link>
          </li>
          <li class="sub-nav--item pl-4">
            <router-link :to="{ name: 'debtor.history' }" :active-class="activeClass" class="pb-2">{{
              $t('sidebar.debtor.history')
            }}</router-link>
          </li>
          <li class="sub-nav--item pl-4">
            <router-link
              :to="{ name: 'debtor.communication.type', params: { type: 'email' } }"
              :active-class="activeClass"
              class="pb-2"
              >{{ $t('sidebar.debtor.communication') }}</router-link
            >
          </li>
        </ul>
      </router-link>
      <router-link
        tag="li"
        class="main-nav--item pl-3 pr-3"
        :active-class="activeClass"
        :style="[$route.name === 'imported_payment.index' ? styles.active : '']"
        :to="{ name: 'imported_payment.index', params: { type: 'payments' } }"
      >
        <a class="py-2" v-if='$store.state.auth.acl.functionality_setting.cash_allocation_module'>
          <icon name="euro-sign"></icon>
          {{ $t('sidebar.importedpayment') }}
          <!--<span class="badge badge-mailbox float-right" :style="badgeColor('importedpayment.index')">{{ importedPayments }}</span>-->
        </a>
      </router-link>
      <router-link
        tag="li"
        class="main-nav--item pl-3 pr-3"
        :class="{ open: $route.name && $route.name.indexOf('report') !== -1 }"
        :style="[$route.name && $route.name.indexOf('report') !== -1 ? styles.active : '']"
        :to="{ name: 'report.statistic' }"
      >
        <a class="py-2">
          <icon name="clipboard"></icon>
          {{ $t('sidebar.reports') }}
        </a>
        <button class="sub-nav--toggle btn btn-link">
          <icon prefix="fas" name="angle-down"></icon>
        </button>

        <ul class="list-unstyled sub-nav">
          <li class="sub-nav--item pl-4">
            <router-link :to="{ name: 'report.statistic' }" :active-class="activeClass" class="pb-2">
              {{ $t('sidebar.reporting.statistic') }}
            </router-link>
          </li>
          <li class="sub-nav--item pl-4">
            <router-link
              :to="{ name: 'report.export' }"
              :active-class="activeClass"
              class="pb-2"
            >
              {{$t('sidebar.reporting.export')}}
            </router-link>
          </li>
        </ul>
      </router-link>

      <router-link
        tag="li"
        class="main-nav--item pl-3 pr-3"
        :active-class="activeClass"
        :style="[$route.name === 'mailbox' ? styles.active : '']"
        :to="{ name: 'mailbox' }"
      >
        <a class="py-2">
          <icon name="envelope"></icon>
          {{ $t('sidebar.mailbox') }}
          <span class="badge badge-mailbox float-right" :style="badgeColor('mailbox')">{{ mailbox }}</span>
        </a>
      </router-link>
    </ul>
  </nav>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class SideBar extends Vue {
  public activeClass: string = 'active';

  @Prop({ default: true })
  private open!: boolean;

  get taskCounts() {
      return this.$store.state.task.counts;
  }
  private badgeColor(routeName: string) {
    return this.$route.name === routeName ? this.styles.badge.active : this.styles.badge;
  }
  get disputes() {
    return this.taskCounts?.disputes ?? '-';
  }

  get batch() {
    return this.taskCounts?.batch ?? '-';
  }

  get actions() {
    return this.taskCounts?.actions ?? '-';
  }
  // get importedPayments() {
  //   return this.taskCounts?.importedPayments ?? '-';
  // }
  get mailbox() {
    return this.taskCounts?.mailbox ?? '-';
  }
  get styles() {
      return this.$store.state.platform.styling.sidebar;
  }
  get borderLeft() {
      return this.styles.active.borderLeft;
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';
@import '~@/assets/scss/colors';
.sidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  background: hsl(0, 0%, 90%);
  color: $text-sidebar;
  overflow: hidden;
  border-right: solid 1px $medium;
  width: $sidebar-small;
  transition: width ease $speed;
  .main-nav {
    width: $sidebar-large;

    .main-nav--item {
      height: 44px;
      transition: height ease $speed;
      position: relative;
      display: block;
      border-left: solid 3px;
      border-left-color: transparent;
      a {
        display: block;
        color: inherit;
        opacity: 0.6;
        white-space: nowrap;
        &:hover {
          text-decoration: none;
          opacity: 1;
        }
      }
      & > a {
        font-size: 1.6rem;
        .badge {
          position: absolute;
          top: 12px;
          right: 5px;
        }
        & > span.wrapper {
          margin-right: 2rem;
          transition: margin ease $speed;
        }
      }

      .sub-nav {
        display: none;
        margin-left: 2rem;
        visibility: hidden;
      }
      &.open,
      &.active {
        transition: border ease $speed;
        border-left-color: var(--secondary-color);
        > a {
          opacity: 0.85;
          font-weight: bold;
        }
        .sub-nav {
          display: block;
        }
        &:before {
          background: rgba(0, 0, 0, 0.1);
          height: 100%;
          content: '';
          left: 0;
          width: 100%;
          position: absolute;
          top: 0;
        }
      }
      &.disabled {
        pointer-events: none;
        > a {
          opacity: 0.3;
        }
      }
    }
  }
  .sub-nav {
    transition: margin ease $speed;
  }

  &.open {
    width: $sidebar-large;
    .main-nav {
      .main-nav--item {
        height: 100%;
        transition: height ease $speed;
        &.open,
        &.active {
          .sub-nav {
            margin-left: 0;
            visibility: visible;
            transition: margin ease $speed;
          }
        }
        > a > span.wrapper {
          margin-right: 0.25rem;
          transition: margin ease $speed;
        }
      }
    }
  }
}

// .main-nav--item {
// &:after {
//   position: absolute;
//   display: block;
//   content: '';
//   bottom: 0;
//   left: -3px;
//   right: 0;
//   height: 1px;
//   background-color: rgba(255, 255, 255, 0.2);
// }
// }

.sub-nav--toggle {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}
.sub-nav--item {
  a {
    position: relative;
    width: 100%;
    .badge {
      position: absolute;
      top: 2px;
      right: -15px;
    }
    &.active {
      font-weight: bold;
    }
  }
  a::before {
    content: '- ';
  }
}
</style>
