import axios from 'axios';
import Vue from 'vue';
import store from '@/store';
import { serialize, deserialize } from '@/utils/queryString';

export const instance = axios.create({
  baseURL: process.env.VUE_APP_API_HOST + '/api',
  headers: {
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: true,
});

instance.interceptors.request.use(value => {
  // no impersonation in progress or no url detected
  if (!store.state.auth.impersonateUsername || !value.url) {
    return value;
  }

  // declare the variables
  const [url, params] = value.url.split('?');

  // append the impersonated user to the url when there are no params
  if (!params) {
    value.url = url + '?_switch_user=' + encodeURIComponent(store.state.auth.impersonateUsername);
    return value;
  }

  // when there are params, decode the params string, set or update the impersonated user and decode again.
  const decodedParams = deserialize(params);
  decodedParams._switch_user = store.state.auth.impersonateUsername;
  value.url = url + '?' + serialize(decodedParams);

  return value;
});

instance.interceptors.response.use(
  res => res,
  error => {
    if (error.response) {
      if ([498, 401].includes(error.response.status) && store.state.auth.token) {
        Vue.toasted.error('Session expired, please re-login');
        store.dispatch('auth/logout');
      } else if (error.response.status === 409) {
        Vue.toasted.info(error.response.data.message);
      } else if (error.response.data.message && !error.response.data.message.includes('JWT')) {
        Vue.toasted.error(error.response.data.message);
      }
    } else {
      Vue.toasted.error(error);
    }
    return Promise.reject(error);
  },
);

export default instance;
