<template>
  <div>
    <loader :loading="loading" />
    <form @submit.prevent="submit" class="form" v-if="schema" :ref="schema.title">
      <h2 class="h4 text-highlight">{{ $t(routeName + '.title') }}</h2>
      <div class="description-form">{{ $t(routeName + '.description') }}</div>
      <div class="d-flex justify-content-end add-debtor">
        <router-link
          :to="{ name: 'wizard.debtor.create' }"
          class="mb-2 select-none"
          v-if="routeName === 'claim'"
        >
          <icon name="plus-square" size="lg" />
          <span v-text="$t('debtor.create.title')" />
        </router-link>
      </div>

      <json-form :schema="schema" v-model="model" :errors="errors" />
      <div class="row">
        <div class="col-sm-8 offset-sm-4">
          <button
            type="submit"
            class="btn btn-primary w-100 h-100"
            v-if="!claimData.hasActiveClaim"
            v-text="$t(submitButtonText)"
          />

          <div v-else class="mt-2">
            <button
              v-if="claimData.paymentUrl"
              class="btn btn-primary w-100 h-100"
              @click.prevent="openPaymentPopup"
            >
              {{ $t('wizard.payment') }} - {{ claimData.claimPrice | currency }}
            </button>
            <button
              class="btn text-black-50 offset-sm-4 col-sm-4 mt-2 btn-sm bg-transparent"
              @click.prevent="handleCancelClaim"
            >
              {{ $t('claim.cancel') }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { Component, Watch, Vue } from 'vue-property-decorator';
import { FormComponent } from '@/mixins/FormComponent';
import { mixins } from 'vue-class-component';
import api from '@/api';
import clone from '@/utils/clone';
import { AxiosResponse } from 'axios';
import Icon from '@/components/global/Icon.vue';
import { parseEndpoint, getRouteChildren, currentRouteIndex } from '@/router';
import { routes } from '@/router';

@Component({
  components: {
    Icon,
  },
})
export default class SubscriptionForm extends mixins(FormComponent) {
  protected model: any = {};
  protected endpoint!: string;
  protected claimData: any = {};
  protected paymentPopup: Window | null = null;
  protected paymentStatus: string = '';

  protected created() {
    this.setEndpointAndLoad();
  }

  @Watch('$store.state.auth.acl')
  @Watch('model.debtor')
  protected async setEndpointAndLoad() {
    if (this.model.debtor) {
      this.endpoint = this.$route.meta?.endpoint + '?debtor=' + this.model.debtor;
    } else {
      this.endpoint = parseEndpoint(this.$route.meta?.endpoint, this.$route.params);
    }
    await this.loadData();
  }

  @Watch('model.subscriptionType')
  protected toggleCreditorSubscriptionSetting() {
    if (this.schema && this.schema.properties && this.model.subscriptionType) {
      if (this.selectedSubscription) {
        if (this.selectedSubscription.advanced_options) {
          const cloned = clone(this.schema.properties.creditorSubscriptionSetting);
          delete cloned.widget;
          this.schema.properties.creditorSubscriptionSetting = cloned;
        } else {
          this.$set(this.schema.properties.creditorSubscriptionSetting, 'widget', 'hidden');
        }
      }
    }
  }

  protected alterFormSchema(response: AxiosResponse) {
    if (this.schema && this.schema.properties) {
      const invoices = this.schema.properties.invoices;
      const subscriptionType = this.schema.properties.subscriptionType;
      if (subscriptionType) {
        const creditorSubscriptionSetting = this.schema.properties.creditorSubscriptionSetting;
        subscriptionType.widget = 'tiles';
        subscriptionType.enum_meta = Object.values(response.data.subscriptionType);
        delete subscriptionType.enum_meta.advanced_options;
        if (creditorSubscriptionSetting && creditorSubscriptionSetting.properties) {
          creditorSubscriptionSetting.widget = 'hidden';
          Object.values(creditorSubscriptionSetting.properties).forEach(
            prop => (prop.description = prop.title + '.description'),
          );
        }
      }
      if (invoices && this.claimData.hasActiveClaim) {
        invoices.readOnly = true;
      }
    }
    this.toggleCreditorSubscriptionSetting();
  }

  protected afterLoad(response: AxiosResponse) {
    this.claimData = response.data;
    delete this.claimData.form;
    this.alterFormSchema(response);
  }

  protected afterSubmit(success: boolean, response: AxiosResponse) {
    if (success) {
      this.$store.dispatch('auth/loadAcl');
    }
    this.alterFormSchema(response);
  }

  protected async handleCancelClaim() {
    if (this.debtor) {
      await api.post('subscription/claim/' + this.debtor + '/cancel');

      this.setEndpointAndLoad();
    }
  }

  protected openPaymentPopup() {
    this.paymentPopup = window.open(
      this.claimData.paymentUrl,
      this.$t('exact_online.auth') as string,
      'toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=400,height=700,top=100,left=100',
    );
    window.addEventListener('message', this.handlePayment);
  }

  protected handlePayment(event: MessageEvent) {
    if (this.paymentPopup && event.data && event.data.status) {
      window.removeEventListener('message', this.handlePayment);
      this.paymentPopup.close();
      this.paymentStatus = event.data.status;

      if (this.paymentStatus === 'paid') {
        return this.$router.push({
          name: 'wizard.claim.confirm',
          params: { claimData: this.claimData, debtor: this.debtor },
        });
      }
      this.$toasted.error(this.paymentStatus);
    }
  }
  protected get routeName(): string {
    return (this.$route.name && this.$route.name.split('.')[1]) || '';
  }
  protected get submitButtonText(): string {
    if (this.routeName !== 'claim') {
      return this.routeName + '.next.btn';
    }
    return this.routeName + '.confirm.process.claim.btn';
  }
  protected get selectedSubscription(): any {
    if (!this.schema || !this.schema.properties || !this.schema.properties.subscriptionType) {
      return;
    }
    const subscriptionType = this.schema.properties.subscriptionType;
    const match =
      subscriptionType.enum_meta &&
      subscriptionType.enum_meta.find((meta: any) => meta.uuid === this.model.subscriptionType);

    return match;
  }

  protected get debtor() {
    return this.model.debtor;
  }
}
</script>

<style lang="scss" scope >
.add-debtor {
  margin-top: -2rem;
}
</style>
