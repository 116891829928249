<template>
  <div class="d-flex flex-wrap h-100 align-items-center justify-content-center login-container">
    <div class="col-sm-10 col-md-6 col-lg-4">
      <div class="clearfix">
        <div class="float-right">
          <locale-picker/>
        </div>
      </div>
      <div class="card p-5 clearfix">
        <img
          :src="this.$store.getters['platform/logo']"
          class="mb-5 img-fluid mx-auto"
          :style="{ maxHeight: '300px' }"
        >
        <ForgotPasswordForm/>
      </div>
      <p class="text-right mt-2">
        <router-link :to="{ name: 'auth.login' }">
          <small class="font-italic text-muted">{{ $t('form.login_type.submit.label') }}</small>
        </router-link>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import ForgotPasswordForm from '@/components/auth/ForgotPassword.vue';

@Component({
  components: {
    ForgotPasswordForm,
  },
})
export default class ForgotPassword extends Vue {}
</script>
