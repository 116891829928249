<template>
  <div class="card table-responsive">
    <div class="card-body">
      <data-table
        ref="settlementplanList"
        :endpoint="endpoint"
        model="settlementplan"
        :disable-pagination="isDebtorView"
        :disable-bulk-actions="isDebtorView"
        :disable-search-fields="isDebtorView"
        :disable-sort="isDebtorView"
        :disable-column-options="isDebtorView"
        :route-params="{ type: {key:'type'} }"
        :route-context="$route.meta?.context"
        @action="doAction"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { SettlementPlanListComponent } from '@/mixins/SettlementPlanListComponent';
import { LoadingState } from '@/models';
import api from '@/api';

@Component({
  components: {},
})
export default class DebtorSettlementPlanList extends mixins(SettlementPlanListComponent) {}
</script>

<style scoped lang="scss">
.nav-link {
  padding: 1rem 1rem;
}
</style>
