import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { FormComponent } from '@/mixins/FormComponent';
import { parseEndpoint } from '@/router';

@Component
export class ReportFormComponent extends mixins(FormComponent) {
  protected model: any = {};
  protected endpoint!: string;
  protected reloadAfterSubmit: boolean = false;

  protected created() {
    this.setEndpointAndLoad();
  }

  @Watch('$route.name') //endpoint needs to be reloaded (ie. worfklow create to workflow edit)
  protected async setEndpointAndLoad() {
    const { params, name, meta } = this.$route;
    this.endpoint = parseEndpoint(meta?.endpoint, { ...params });

    await this.loadData();

    if (this.schema && this.schema.title) {
      this.$store.commit('bars/setRightBarTitle', this.$t(this.schema.title + '.header'));
    }
  }

  protected afterSubmit(success: boolean, response: any) {
    if (success) {
      this.$toasted.success(this.$t(this.$route.name + '.success') as string);

      if (this.$route.meta?.gotoAfterSubmit) {
        let params = this.$route.params;
        if (!params.id) {
          params = { ...params, id: response.data.uuid };
        }
        this.$router.push({
          name: this.$route.meta?.gotoAfterSubmit,
          params,
        });
      }
    }
  }

  protected get routeModel() {
    return this.$route.meta?.model || this.$route.params.model;
  }
}
