<script lang="ts">
  import History from '@/views/debtor/dashboard/History.vue';
  import { Component } from 'vue-property-decorator';

  @Component({})
  export default class CommunicationHistory extends History {

    protected get endpoint(): string {
      const debtor = this.$route.query.debtor as string;

      return `/debtorportal/${debtor}/communication/history`;
    }
  }
</script>
