<template>
  <div class="row tiles">
    <div class="col-4 select-none" v-for="(item,i) in schema.enum" :key="item">
      <div
        class="card border p-2"
        :class="[item === selected && 'active']"
        @click="handleClick(item)"
        style="cursor:pointer"
      >
        <div class="title">{{getEnumTitle(i)}}</div>
        <div v-for="(meta,key) in getEnumMeta(item)" :key="key" :class="key">
            <div v-if="key === 'description'" v-html="meta"/>
            <div v-else-if="key !== 'uuid'" v-text="meta"/>
        </div>
        <input type="radio" :name="id" :checked="item === selected" :value="item" :ref="'tile-'+i">
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { JSONSchema4 } from 'json-schema';

@Component
export default class Tiles extends Vue {
  @Prop() protected schema!: JSONSchema4;
  @Prop() private value!: string;
  @Prop() private id!: string;
  private selected: string = '';

  private handleClick(id: string) {
    this.selected = id;
    this.$emit('input', id);
  }

  private getEnumTitle(i: number) {
    return this.schema.enum_titles && this.schema.enum_titles[i];
  }

  private getEnumMeta(id: string) {
    return this.schema.enum_meta && this.schema.enum_meta.find((meta: any) => meta.uuid === id);
  }
}
</script>

<style lang="scss" scoped>
input {
  display: none;
}
</style>