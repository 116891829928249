import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Line } from 'vue-chartjs';
import { ChartOptions } from 'chart.js';

@Component({
  extends: Line,
})
export default class DsoLineChart extends Mixins(Line) {

  @Prop({required: true})
  private data!: Record<string, number>;

  private mounted() {
    this.draw();
  }
  @Watch('data', {immediate:false})
  private draw() {
    this.renderChart({
      labels: Object.keys(this.data),
      datasets: [
        {
          borderColor: '#3cba9f',
          backgroundColor: 'transparent',
          data: Object.values(this.data),
        },
      ],
    }, {
      responsive: true,
      maintainAspectRatio: false,
      legend: {display:false},
      tooltips: {enabled: false}
    });
  }
}
