<template>
  <ul class="list-unstyled subnav-nav">
    <li class="subnav-nav--item">
      <router-link :to="{ name: 'settings' }" class="py-1 overview">Overview</router-link>
    </li>
    <li class="subnav-nav--item" v-for="(value, key) in $store.state.auth.acl.settings" :key="key">
      <router-link
        v-if="value && getRouteByAcl(key)"
        :to="{
          name: getRouteByAcl(key).name,
          params: { model: getModel(key), template: getModel(key) },
        }"
        exact
        class="py-1"
        >{{ $t(getModel(key)) }}</router-link
      >
    </li>
  </ul>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { getRouteByAcl } from '@/router';

@Component({})
export default class SubnavBar extends Vue {
  protected getRouteByAcl(name: string) {
    return getRouteByAcl(name);
  }

  protected getModel(key: string = '') {
    return (
      getRouteByAcl(key).meta.model ||
      (key.split('_') && key.split('_').length && key.split('_')[2])
    );
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';
@import '~@/assets/scss/colors';
</style>
