<template>
  <div class="h-100">
    <loader :loading="loading" />
    <div class="border-0" v-if="receivable">
      <div class="pl-0 pr-0 pt-0 mb-2">
        <h2 class="h4">
          <small class="text-muted">{{ $t('receivable.status') }}: {{ receivable.status }}</small>
        </h2>
        <div class="row" v-if="receivable.collection_partner_name">
          <div class="col-6">{{ $t('receivable.collection.partner')}}</div>
          <div class="col-6 text-right">{{receivable.collection_partner_name}}</div>
        </div>
      </div>

      <div class="pl-0 pr-0">
        <div class="mt-3">
          <div class="row" v-if="isCreditNoteOrPayment(receivable) && receivable.invoice_reference">
            <div class="col-6">{{ $t('invoice.reference') }}</div>
            <div class="col-6 text-right">{{ receivable.invoice_reference }}</div>
          </div>
          <div class="row">
            <div class="col-6">{{ $t('date') }}</div>
            <div class="col-6 text-right">{{ receivable.receivable_date | date }}</div>
          </div>
          <div class="row" v-if="isInvoice(receivable) && receivable.invoice_due_date">
            <div class="col-6">{{ $t('invoice.expires') }}</div>
            <div class="col-6 text-right">{{ receivable.invoice_due_date | date }}</div>
          </div>
          <div class="row" v-if="receivable.archived_at">
            <div class="col-6">{{ $t('invoice.archived_at') }}</div>
            <div class="col-6 text-right">{{ receivable.archived_at | date }}</div>
          </div>
          <small
            v-if="isInvoice(receivable) && receivable.invoice_due_date && isPast(receivable.invoice_due_date)"
            class="text-danger mt-1"
            :title="receivable.invoice_due_date | date"
          >
            <icon name="exclamation-circle" />
            {{ $t('invoice.time-expired', [isPast(receivable.invoice_due_date)]) }}
          </small>
          <div  v-if="isInvoice(receivable) && receivable.payment_reference" class="row mt-2">
            <div class="col-6">{{ $t('invoice.payment_reference') }}</div>
            <div class="col-6 text-right">{{ receivable.payment_reference }}</div>
          </div>
          <div v-if="receivable.description" class="mt-2">
            <h3 class="h6 mb-0">{{ $t('invoice.description') }}</h3>
            {{ receivable.description }}
          </div>
          <div v-if="receivable.note" class="mt-2">
            <div class='row'>
              <h3 class="h6 mb-0 ml-2">{{ $t('invoice.note') }}</h3>
              <small class='ml-1 text-italic' v-if="receivable.note_created_at">{{ $t('invoice.note_created_at', { date: this.formattedDate(receivable.note_created_at) }) }}</small>
            </div>
            <div v-text="receivable.note" />
          </div>
          <div v-if="receivable.comment" class="mt-2">
            <h3 class="h6 mb-0">{{ $t('invoice.comment') }}</h3>
            <div v-text="receivable.comment" />
          </div>
          <div v-if="receivable.meta.length" class="mt-2">
            <hr />
            <h3 class="h6">{{ $t('receivable.meta.title') }}</h3>
            <div class="row" v-for="(meta, index) in receivable.meta" :key="index">
              <div class="col-6">{{ meta.name }}</div>
              <div class="col-6 text-right">{{ meta.value }}</div>
            </div>
          </div>
        </div>
        <hr />
        <div>
          <div class="row">
            <div class="col-6 font-semibold">{{ $t('invoice.amount-with-taxes') }}</div>
            <div class="col-6 text-right">{{ receivable.receivable_amount | currency(receivable.currency) }}</div>
          </div>
        </div>
        <hr />
      </div>

      <div v-if="hasTransactions">
        <div class="mb-1">
          <span class="font-semibold">{{ $t('invoice.linked_settlement_plans') }}</span>
        </div>
        <div v-for="transaction in transactions" :key="transaction.uuid" class="field-column mb-1">
          <div class="field-row d-flex justify-content-between">
            <div class="font-semibold text-primary" v-text="transaction.transaction_reference" />
            <div class="align-items-end">{{ transaction.transaction_date | date }}</div>
            <div>{{ $t(`invoice.` +  transaction.transaction_type) }}</div>
            <div>{{ transaction.transaction_amount | currency(transaction.currency_code) }}</div>
          </div>
          <div v-if="transaction.description" class="field-row d-flex justify-content-between">
            <div class="font-weight-bold">{{ $t('transaction.description') }}:</div>
            <div>{{ transaction.description }}</div>
          </div>
        </div>
      </div>

      <div v-if="hasSettlementPlan">
        <div class="mb-1">
          <span class="font-semibold">{{ $t('invoice.linked_settlement_plans') }}</span>
        </div>
        <div v-if="activeSettlementPlan" class="mb-2">
          <span
            @click="showSettlementPlan(activeSettlementPlan.settlement_plan)"
            class="font-semibold text-primary cursor-pointer">{{ $t('invoice.linked_settlement_plans.active') }}</span>
        </div>
        <div v-if="closedSettlementPlans && closedSettlementPlans.length">
          <div class="font-semibold">{{ $t('invoice.linked_settlement_plans.closed') }}</div>
          <div v-for="link in closedSettlementPlans" :key="link.settlement_plan.uuid">
            <span @click="showSettlementPlan(link.settlement_plan)" class="font-semibold text-primary cursor-pointer">{{ link.settlement_plan.created_at | date }}</span>
          </div>
        </div>
      </div>

      <div class="mt-4" v-if="collectionData.receivable">
        <div class="mb-1">
          <span class="font-semibold">{{ $t('invoice.collection_data') }}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span>{{ $t('collection.dossier.reference') }}</span>
          <span>{{ collectionData.receivable.dossier.reference }}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span>{{ $t('invoice.expires') }}</span>
          <span>{{ collectionData.receivable.dueDate | date }}</span>
        </div>
        <!-- render dossierStatus info -->
        <div class="d-flex justify-content-between">
          <span>{{ $t('collection.dossier.status') }}</span>
          <span>{{ collectionData.receivable.dossier.status }}</span>
        </div>
        <div class="d-flex justify-content-between" v-if="collectionData.receivable.dossier.substatus">
          <span>{{ $t('collection.dossier.subStatus') }}</span>
          <span>{{ collectionData.receivable.dossier.substatus }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import isBefore from 'date-fns/isBefore';
import formatDistance from 'date-fns/formatDistance';
import { mixins } from 'vue-class-component';
import { LoadingState, Download, CollectionData, Invoice, CreditNote, Payment, Dictionary } from '@/models';

import api from '@/api';
import { getDateFnsLocale } from '@/i18n';
import { Receivable } from '@/models';
import DebtorSettlementPlanView from '../settlementplan/View.vue';
import { format } from 'date-fns';

type ReceivableTypes = 'cost' | 'invoice' | 'payment' | 'creditnote';

@Component
export default class DebtorReceivableView extends Vue {
  private receivable: Receivable | null = null;
  private collectionData: CollectionData = new CollectionData();
  private endpoint: string = '';
  private loading: LoadingState = false;

  private async created() {
    await this.loadData();
  }

  private formattedDate(dateString: any) {
    let date = new Date(dateString);
    return format(date, 'dd-MM-yyyy');
  }

  public get receivableContext(): { uuid: string; type: string } {
    return this.$store.state.bars.rightBarProperties.receivable;
  }
  private showSettlementPlan(settlementplan: any) {
    const params: Dictionary<string> = {
      uuid: settlementplan.uuid,
    };
    this.$store.dispatch('bars/openRightBar', {
      component: DebtorSettlementPlanView,
      title: this.$t(`debtor.settlementplan.title`),
      properties: {
        settlementplan: params,
      },
    });
    return;
  }
  @Watch('receivableContext')
  private async loadData() {
    this.loading = true;
    const debtorId = this.$store.state.bars.rightBarProperties.debtorId ?? this.$route.params.debtorId;
    this.endpoint = `/debtor/${debtorId}/${this.receivableContext.type}/${this.receivableContext.uuid}`;
    await api
      .get(this.endpoint)
      .then((res) => (this.receivable = res.data))
      .catch((err) => this.handleFailure(err));

    this.$store.commit('bars/setRightBarTitle', this.receivable?.receivable_reference);

    if (this.receivable?.status === 'IN_COLLECTION') {
      this.fetchCollectionData();
    }
    this.loading = false;
  }
  private isInvoice(r: Receivable): r is Invoice {
    return r.type.toLowerCase() === 'invoice';
  }
  private isCreditNoteOrPayment(r: Receivable): r is CreditNote | Payment {
    return !this.isInvoice(r);
  }
  private isPast(date: string) {
    if (isBefore(new Date(date), new Date())) {
      return formatDistance(new Date(date), new Date(), { locale: getDateFnsLocale() });
    }
    return null;
  }
  private async fetchCollectionData() {
    const debtorId = this.$store.state.bars.rightBarProperties.debtorId ?? this.$route.params.debtorId;
    const endpoint = `/debtor/${debtorId}/receivable/${this.receivableContext.uuid}`;
    await api.get(`${endpoint}/get-collection-data`).then((res) => this.collectionData.set(res.data));
  }
  private get hasSettlementPlan() {
    return (this.receivable as Invoice)?.linked_settlement_plans?.length > 0;
  }
  private get activeSettlementPlan() {
    const activeplan = (this.receivable as Invoice).linked_settlement_plans.filter((link) => {
      return !link.settlement_plan.is_closed;
    });
    return activeplan[0];
  }

  private get closedSettlementPlans() {
    const plans = (this.receivable as Invoice).linked_settlement_plans.filter((link) => {
      return link.settlement_plan.is_closed;
    });
    return plans;
  }
  private handleFailure(err: any) {
    this.$router.back();
    throw new Error(err);
  }
  private get settlementplanRouteName(): string {
    if (this.$route.name?.indexOf('debtor.view') !== -1) {
      return 'debtor.view.settlementplan.view';
    }
    return 'debtor.settlementplan.view';
  }

  private get hasTransactions() {
    return (this.receivable as Invoice)?.transactions?.length > 0;
  }

  private get transactions() {
    return (this.receivable as Invoice).transactions.sort((a, b) => {
      return new Date(b.transaction_date).getTime() - new Date(a.transaction_date).getTime();
    });
  }
}
</script>

<style scoped lang="scss">
dt {
  font-weight: 400;
}
</style>
