<template>
  <card v-bind="{ title: $t('debtorportal.debtor_note.title') }">
    <template slot="body">
      <placeholder v-if="! schema">
        <div class="placeholder" style="height: 200px"/>
        <div class="placeholder" :style="{ height: '41px', width: '160px' }"/>
        <div class="row justify-content-sm-end">
          <div class="placeholder" :style="{ height: '39px', width: '108px' }"/>
        </div>
      </placeholder>

      <form v-else @submit.prevent="submit" class="form" :ref="schema.title">
        <json-form :schema="schema" v-model="model" :errors="errors" :labels="false" />
        <div class="row">
          <div class="col-12 d-flex justify-content-sm-end">
            <button type="submit" class="btn btn-primary">
              {{ $t('create') }}
            </button>
          </div>
        </div>
      </form>
    </template>
  </card>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import Card from '@/debtorportal/components/Card.vue';
import { mixins } from 'vue-class-component';
import { FormComponent } from '@/mixins/FormComponent';

@Component({
  components: {
    Card,
  },
})
export default class CreditorInfo extends mixins(FormComponent) {
  protected model: any = {};
  private title: string = '';
  protected created() {
    this.setEndpointAndLoad();
  }

  protected async setEndpointAndLoad() {
    this.endpoint = `debtorportal/${this.$route.query.debtor}/note`;
    await this.loadData();
  }
}
</script>

<style lang="scss" scoped>
.mt-n8 {
  margin-top: -5.5rem;
}
</style>
