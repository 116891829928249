<script lang="ts">
import { Watch } from 'vue-property-decorator';
import Component, { mixins } from 'vue-class-component';
import { SettingsFormComponent } from '@/mixins/SettingsFormComponent';
import { AxiosResponse } from 'axios';
import { parseEndpoint } from '@/router';
import { appendQueryParams } from '@/utils/queryString';
import api from '@/api';

@Component({
  components: {},
})
export default class MappingCreate extends mixins(SettingsFormComponent) {
  private manualBulk: manualBulkType = { name: '', type: '', subtype: ''};
  private file: File| any;
  protected created() {
    this.setEndpointAndLoad();
  }
  @Watch('$route.name') //endpoint needs to be reloaded
  protected async setEndpointAndLoad() {
    const { params, name, meta } = this.$route;
    this.endpoint = parseEndpoint(meta?.endpoint, { ...params });
    await this.loadData();
  }

  protected afterLoad(response: AxiosResponse) {
    if (response?.data?.owner) {
      this.$store.commit('auth/setEntity', response.data.owner)
    }
    this.updateValues();
  }
  @Watch('model')
  protected formhasChanged() {
    if (this.manualBulk.type !== this.model.type || this.manualBulk.subtype !== this.model.subtype) {
      this.updateValues();

      this.addParamsToEndpoint();

      this.loadData();
    }

    if (this.model.importFile) {
      this.file = this.model.importFile;
      this.putData();
    }
  }

  protected async putData() {
    if (!this.endpoint) {
      return;
    }
    if (this.authToken) {
      api.defaults.headers.common.Authorization = `Bearer ${this.authToken}`;
    }
    this.loading = true;

    const reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onload = async () => {
      if (reader.result) {
        const encodedFile = (<string>reader.result).split(",")[1];
        const data = {
          file: encodedFile,
          fileName: this.file.name,
          fileMimeType: this.file.type,
        };
        await api.put(this.endpoint, data, {})
          .then(res => {
            if (res.data?.form?.schema) {
              this.setData(res.data.form);
              this.loading = false;
            }
            this.afterLoad(res);
          }).catch(() => {
            this.loading = 'error';
          });
      }
      }
  }

  private updateValues() {
    this.manualBulk.name = this.model.name;
    this.manualBulk.type = this.model.type;
    this.manualBulk.subtype = this.model.subtype;
  }
  private addParamsToEndpoint() {
    let endpoint = `settings/businessunit/${this.$route.params.id}/sync/mapping/create`;
    this.endpoint = appendQueryParams(endpoint, { mappingDataType: this.manualBulk });
  }

  protected beforeDestroy() {
    this.$store.commit('auth/restoreUser');
  }
}
interface manualBulkType {
  name: string;
  type: string;
  subtype: string;
}
</script>
