<template>
  <div>
    <loader :loading="loading" />
    <template v-if="isLoaded && typeof data !== 'boolean'">
      <small class="text-muted mb2">
        {{ $t('task.processed_at') }}: {{ data.processed_at ? $t('task.processed') : '' }}
      </small>
      <dl class="row">
        <dt class="col-6">{{ $t('task.created_at') }}</dt>
        <dd class="col-6 text-right">{{ data.created_at | date }}</dd>
        <dt class="col-6">{{ $t('task.updated_at') }}</dt>
        <dd class="col-6 text-right">{{ data.updated_at | date }}</dd>
        <dt class="col-6">{{ $t('task.debtor.display_name') }}</dt>
        <dd class="col-6 text-right" v-if="data.debtor">
          <router-link
            :to="{
            name: 'debtor.view',
            params: {
              debtorId: data.debtor.uuid,
            },
            query: {
              onlyClaimedTasks: true
            },
          }"
          >{{ data.debtor.display_name }}</router-link
          >
        </dd>
        <dt class="col-6">{{ $t('task.debtor.reference') }}</dt>
        <dd class="col-6 text-right" v-if="data.debtor">{{ data.debtor.reference }}</dd>
      </dl>
      <hr />
      <dl class="row">
        <h3 class="col-6 h6">{{ $t('task.task_type') }}</h3>
        <dd class="col-6 text-right">{{ $t(data.task_type) }}</dd>
      </dl>
      <hr />
      <dl class="row">
        <h3 class="col-6 h6">{{ $t('task.priority') }}</h3>
        <dd class="col-6 text-right">{{ data.priority }}</dd>
      </dl>
      <hr />
      <dl>
        <h3 class="h6">{{ $t('task.message') }}</h3>
        <div>{{ data.message }}</div>
      </dl>
      <hr />
      <dl class="row" v-if="data.custom_notice_type">
        <h3 class="col-6 h6">{{ $t('task.custom_notice_type.label') }}</h3>
        <dd class="col-6 text-right">{{ data.custom_notice_type.label }}</dd>
      </dl>
      <div v-if="data.custom_notice_type">
        <hr />
        <dl class="row" >
          <h3 class="col-6 h6">{{ $t('task.urgent') }}</h3>
          <dd class="col-6 text-right">{{ $t(`${data.urgent}`) }}</dd>
        </dl>
      </div>
      <div v-if="data.receivables && data.receivables.length > 0">
        <hr />
        <dl class="row">
          <h3 class="h6">{{ $t('task.receivables') }}</h3>
          <dd class="col-12 text-right" v-for="receivable in data.receivables" :key="receivable.uuid">
            <a @click="showReceivable(receivable, data.debtor.uuid)" class="text-primary">
              {{ receivable.receivable_reference }} - ({{
              receivable.receivable_date | datetime('d/MM/y')
              }})
            </a>
          </dd>
        </dl>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import isBefore from 'date-fns/isBefore';
import formatDistance from 'date-fns/formatDistance';

import api from '@/api';
import { LoadingState, Task, Receivable } from '@/models';
import { getDateFnsLocale } from '@/i18n';
import ReceivableView from '@/views/debtor/receivable/View.vue';

@Component({
  components: {},
})
export default class TaskView extends Vue {
  private loading: LoadingState = false;
  private data: Task | boolean = false;

  private get isLoaded(): boolean {
    return this.data != null && this.data !== false;
  }

  private async created() {
    await this.loadData();
  }
  private showReceivable(receivable: Receivable, debtorId: string) {
    this.$store.dispatch('bars/openRightBar', {
      component: ReceivableView,
      title: this.$t(`debtor.receivable.view`),
      properties: {
        debtorId,
        receivable: {
          uuid: receivable.uuid,
          type: 'invoice',
        },
      },
    });
  }
  @Watch('$route.params.taskId')
  private async loadData() {
    this.loading = true;
    const res = await api.get(`/task/${this.$route.params.taskId}`);
    this.loading = false;

    this.data = res.data as Task;
    this.$store.commit('bars/setRightBarTitle', this.$t(this.data.task_type));
  }
  private loadingChanged(loading: LoadingState) {
    this.loading = loading;
  }

  private reload() {
    this.loadData();
  }
}
</script>

<style scoped lang="scss">
dt {
  font-weight: 400;
}
</style>
