import Vue from 'vue';
import VueI18n from 'vue-i18n';
import nl from 'date-fns/esm/locale/nl';
import enUS from 'date-fns/esm/locale/en-US';
import fr from 'date-fns/esm/locale/fr';

import { Locale } from '@/models';
import api from '@/api';
import { storage } from '@/services/LocalStorage';

Vue.use(VueI18n);

(window as any).MissingTranslations = {};

const i18n = new VueI18n({
  messages: {},
  missing: (locale, key, vm) => {
    (window as any).MissingTranslations[key] = '';
    return `[${key}:${locale}]`;
  },
  locale: 'nl_BE',
});

const loadedLanguages: Locale[] = [];

export async function loadLanguages(i18n: VueI18n) {
  return await new Promise(async (resolve, reject) => {
    // TODO
    // const res = await api.get(`/translations/messages.json?locales=${i18n.locale}`);
    const res = await api.get('/translations/messages.json?locales=nl_NL,nl_BE,en,fr,de');
    Object.keys(res.data.translations).forEach((lang) => {
      const { messages } = res.data.translations[lang];
      i18n.setLocaleMessage(lang, messages);
      loadedLanguages.push(lang as Locale);
    });
    resolve(setLanguage(i18n.locale as Locale, true));
  });
}

export function setLanguage(lang: Locale, force = false) {
  if (i18n.locale === lang && !force) {
    return lang;
  }
  if (force) {
    // We're emptying the Locale first, and wait for next tick so when we set the lang, it will reload the messages.
    i18n.locale = '';
    setTimeout(() => internalSetLanguage(lang));
  }
  return internalSetLanguage(lang);
}

function internalSetLanguage(lang: Locale) {
  i18n.locale = lang;
  api.defaults.headers.common['Accept-Language'] = lang;
  if (document && document.documentElement) {
    document.documentElement.setAttribute('lang', lang);
  }
  storage.save('locale', lang);
  return lang;
}

export function getFromStorage() {
  const locale = storage.get('locale');
  if (locale) {
    setLanguage(locale);
  }
  return locale;
}

export function getDateFnsLocale() {
  const currentLocale = i18n.locale as Locale;
  switch (currentLocale) {
    case 'nl_NL':
    case 'nl_BE':
      return nl;
    case 'fr':
      return fr;
    case 'en':
    default:
      return enUS;
  }
}

export default i18n;
