<template>
  <div class="search-field">
    <label v-if='label === true'>{{ $t(this.placeholder) }}</label>
    <input
      :value="value"
      @input="setValue($event.target.value)"
      class="form-control"
      :placeholder="$t(this.placeholder)"
    />
    <icon name="search" class="text-muted" size="lg" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DEFAULT_DEBOUNCE_DELAY } from '@/plugins/data-table/constants';
import { debounce } from 'lodash';

@Component({})
export default class StringFilter extends Vue {
  @Prop({required: false, default: ''})
  private initialValue!: string;
  @Prop({required: false, default: 'datatable.search.string.placeholder'})
  private placeholder!: string;
  @Prop({required: false, default: false})
  private label!: boolean;


  private currentValue: string | null = null;

  private update = debounce(() => this.emitUpdate(), DEFAULT_DEBOUNCE_DELAY);

  private created() {
    this.currentValue = this.initialValue;
  }
  private get value() {
    return this.currentValue;
  }

  private setValue(input: string) {
    this.currentValue = input;
    this.update();
  }

  private emitUpdate() {
    this.$emit('update', this.currentValue);
  }
}
</script>

<style scoped lang="scss">

</style>
