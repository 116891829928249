<template>
  <div
    id="subscription-wizard"
    class="d-flex flex-column h-100 align-items-center container justify-items-center content-container"
  >
    <div class="col-sm-10 mt-5">
      <div class="d-flex justify-content-between">
        <router-link
          :to="{
            name: 'dashboard.index',
          }"
          class="py-1 font-semibold"
          v-text="$t('dashboard')"
        >
        </router-link>
        <div class="d-flex">
          <entity-switcher />
          <locale-picker />
        </div>
      </div>
      <div class="card p-5 mb-4">
        <img :src="this.$store.getters['platform/logo']" class="logo-wizard mb-2" />

        <subscription-actions :actions="actions" :activeAction="activeAction" />

        <router-view :key="$route.fullPath" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import EntitySwitcher from '@/components/settings/EntitySwitcher.vue';
import { routes } from '@/router';
import SubscriptionActions from '@/components/subscription/Actions.vue';
import { SubscriptionAction as Action } from '@/models';
@Component({
  components: {
    EntitySwitcher,
    SubscriptionActions,
  },
})
export default class Subscription extends Vue {
  protected actions: Action[] = [];

  protected created() {
    if (!this.$store.state.auth.acl.subscription_wizard) {
      this.$router.replace({ name: 'auth.login' });
    }

    this.createActions();

    this.navigateToRoute();
  }

  @Watch('$store.state.auth.token')
  protected tokenChanged(token: string) {
    return !token && this.$router.replace({ name: 'auth.login' });
  }

  @Watch('$store.state.auth.acl.subscription_wizard')
  private createActions() {
    this.actions = [
      {
        name: 'info',
        route: 'wizard.info',
        icon: 'user-alt',
        allowed: this.$store.state.auth.acl.subscription_wizard.api_subscription_wizard_info,
      },
      {
        name: 'subscription',
        route: 'wizard.subscription',
        icon: 'briefcase',
        allowed: this.$store.state.auth.acl.subscription_wizard.api_subscription_wizard_subscription,
      },
      {
        name: 'claim',
        route: 'wizard.claim',
        icon: 'plus',
        allowed: this.$store.state.auth.acl.subscription_wizard.api_subscription_wizard_claim,
        hidden: !this.$store.state.auth.user.is_super_user,
      },
    ];
  }

  @Watch('$store.state.auth.acl.subscription_wizard')
  private navigateToRoute() {
    if (this.activeAction === this.nextAction) {
      return;
    }

    if (this.nextAction) {
      return this.$router.replace({ name: this.nextAction.route });
    }

    this.$router.replace({ name: 'dashboard.index' });
  }

  private get activeAction(): Action | undefined {
    return this.actions.find(action => action.route === this.$route.name);
  }

  @Watch('$route.name')
  private validateAction(): void {
    if (this.$store.getters['auth/isSuperUser']) {
      return;
    }

    // No action? Must be subroute ...
    if (!this.activeAction) {
      return;
    }

    if (this.activeAction.allowed) {
      return;
    }

    this.navigateToRoute();
  }

  private get nextAction(): Action | undefined {
    return this.actions.find(action => action.allowed);
  }
}
</script>

<style lang="scss">
.logo-wizard {
  width: 33%;
}

#entity {
  margin-right: 2rem;
}

#subscription-wizard #form-group-entity {
  margin-right: 1rem;
}

.wysiwyg {
  label + div {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

.form-group {
  label {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  label + div {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }
}

#subscription-wizard .object .col-xl-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.description-form {
  margin-bottom: 4rem;
}

.checkbox {
  margin-left: calc(16% + 15px);
}

.select.tiles {
  margin-bottom: 4rem;
  label {
    display: none;
  }

  label + div {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .card {
    .title {
      font-size: 2.4rem;
      line-height: 3rem;
    }
    .name {
      display: none;
    }
    .advanced_options {
      display: none;
    }
    .price div {
      text-align: right;
      font-weight: bold;
      font-size: 2rem;
      color: var(--primary-color);
      &:before {
        content: '€ ';
        width: 100px;
        height: 10px;
      }
    }
  }
}
</style>
