<template>
  <div class="card mb-3">
    <div class="card-body success d-flex flex-row timeline">
      <div class="text-center text-lg timeline-item" v-for="(phase, i) in sortedPhases" :key="i">
        <div>
          <div class="timeline-item--icon mb-2">
            <div
              class="wrapper cursor-pointer"
              :style="{ borderColor: phase.color }"
              @click="gotoToDebtorOverview(phase)"
            >
              <span class="h3 m-0">{{ phase.count }}</span>
              <span class="h5 m-0 mb-2">{{ phase.amount | currency('EUR', { precision: 0 }) }}</span>
            </div>
          </div>
          {{ phase.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import hash from '@/utils/hash';

@Component({})
export default class InvoiceSummary extends Vue {
  @Prop() private invoiceSummary!: InvoiceSummaryData;

  private get sortedPhases(): GroupedByPhase[] {
    return this.invoiceSummary.groupedByPhase.sort((a: GroupedByPhase, b: GroupedByPhase) => a.sort_order - b.sort_order);
  }

  private async gotoToDebtorOverview(phase: GroupedByPhase) {
    this.$store.commit('datatable/setFilter', {
      filter: {subscriptionLogType: phase.uuid},
      identifier: await hash('debtor.index./debtor'),
    });
    this.$router.push({ name: 'debtor.index' });
  }
}

export interface InvoiceSummaryData {
  groupedByPhase: GroupedByPhase[];
}

export interface GroupedByPhase {
  uuid: string;
  amount: string;
  color: string;
  count: string;
  name: string;
  sort_order: number;
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/colors';
@import '~@/assets/scss/elements/timeline';

.timeline-item--icon {
  position: relative;
  display: flex;
  padding: 0 2rem;
  &:before {
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $medium;
    content: '';
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    border: 5px solid black;
    width: 120px;
    height: 120px;
    color: $text-color;
    text-align: center;
    margin: auto;
  }
}
.text-muted {
  opacity: 0.6;
}
</style>
