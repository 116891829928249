<template>
  <div>
    <loader :loading="loading" />
    <div class="h-100 mb-3 border-0">
      <div class="pl-0 pr-0 pt-0 mb-3">
        <small class="text-muted d-block" v-if="data.status">
          {{ $t('debtor.settlementplan.status') }}:
          <span class="text-uppercase">{{ data.status }}</span>
        </small>
      </div>
      <div class="pl-0 pr-0">
        <div class="border-bottom pb-3 mb-3">
          <div class="field-row" v-if="data.debtor">
            <div class="font-weight-bold">{{ $t('debtor') }}</div>
            <router-link :to="{ name: 'debtor.view', params: { debtorId: data.debtor.uuid } }">{{
              data.debtor.display_name
            }}</router-link>
          </div>
          <field v-if="data.dispute_amount" label="dispute_amount" :value="data.dispute_amount | currency" />
          <div v-if="data.promised_amount" class="field-row d-flex justify-content-between">
            <div class="font-weight-bold" v-text="$t('promised_amount')" />
            <div>{{ data.promised_amount | currency }}</div>
          </div>
          <field label="created_at" :value="data.created_at" valueType="date" />
          <field label="due_date" :value="data.due_date" valueType="date" />
          <field label="owner" v-if="data.owner" :value="data.owner.first_name" />
          <field
            label="responsible"
            v-if="data.responsible"
            :value="`${data.responsible.first_name} ${data.responsible.last_name}`"
          />
          <field
            label="settlement_plan.reference"
            v-if="data.settlement_plan_reference"
            :value="`${data.settlement_plan_reference}`"
          />
        </div>
        <div class="field-column" v-if="attachments && attachments.length">
          <attachment-download-list v-bind:attachments="attachments">
            {{ $t('attachment_links') }}
          </attachment-download-list>
        </div>
        <div v-if="data.dispute_type">
          <field
            class="border-bottom pb-3 mb-3"
            :label="data.dispute_type.code"
            :value="data.dispute_type.name"
            :translate-label="false"
          />
        </div>
        <div v-if="data.linked_invoices && data.linked_invoices.length" class="border-bottom pb-3 mb-3">
          <div v-for="{ invoice } in data.linked_invoices" :key="invoice.id" class="field-column mb-1">
            <div class="field-row d-flex justify-content-between">
              <div class="font-weight-bold" v-text="invoice.receivable_reference" />
              <div>{{ invoice.receivable_amount | currency }}</div>
            </div>
          </div>
        </div>
        <div v-if="data.workflow_link" class="border-bottom pb-3 mb-3">
          <field label="workflow_link.workflow.name" :value="data.workflow_link.workflow.name" />
          <field label="workflow_link.current_workflow_step" v-if="data.workflow_link.current_workflow_step" :value="data.workflow_link.current_workflow_step.name" />
          <field label="workflow_link.next_workflow_step" v-if="data.workflow_link.next_workflow_step" :value="data.workflow_link.next_workflow_step.name" />
          <field label="workflow_link.next_workflow_step_due_date" :value="data.workflow_link.next_workflow_step_due_date" value-type="date" />
        </div>
        <div v-if="data.payment_plan_payments && data.payment_plan_payments.length" class="border-bottom pb-3 mb-3">
          <label class="font-weight-bold">{{ $t('payment_plan_payments') }}</label>
          <ul class="field-column list-unstyled mb-0">
            <li
              v-for="payment in data.payment_plan_payments"
              :key="payment.id"
              class="d-flex justify-content-between"
              :class="{
                'text-success': payment.paid_at,
                'text-danger': !payment.paid_at && isPast(payment.deadline_at),
              }"
            >
              <span>{{ payment.deadline_at | date }}</span>
              <div>
                <span v-if='payment.edited'>* </span><b>{{ payment.amount | currency }}</b>
                <span class="text-muted ml-2 cursor-pointer" @click="editPayment(payment)" :class="{'invisible': payment.paid_at}">
                    <icon  name="edit" />
                  </span>
              </div>
            </li>
          </ul>
        </div>
        <div v-if="data.notes && data.notes.length" class="border-bottom pb-3 mb-3">
          <label class="font-weight-bold">{{ $t('notes') }}</label>
          <div class='dispute-notes' v-for="note in data.notes">
            <field type="column" :key="note.created_at" :label="note.created_at | datetime" :translate-label="false" :value="note.note"/>
            <button class='btn btn-sm btn-primary btn-notes' @click.prevent='deleteNote(note.uuid)'><icon name="times" /></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import AttachmentDownloadList from '@/components/views/partial/AttachmentDownloadList.vue';
import { AttachmentDownload } from '@/models/AttachmentDownload';
import { AttachmentLink } from '@/models/AttachmentLink';
import isBefore from 'date-fns/isBefore';
import formatDistance from 'date-fns/formatDistance';
import { LoadingState } from '@/models';
import Field from '@/components/global/Field.vue';

import api from '@/api';
import { getDateFnsLocale } from '@/i18n';
import DebtorSettlementPlanInstallmentEdit from '@/views/debtor/settlementplan/InstallmentEdit.vue';
@Component({
  components: {
    'attachment-download-list': AttachmentDownloadList,
    Field,
  },
})
export default class DebtorSettlementPlanView extends Vue {
  protected endpoint: string = '';
  private data: any | {} = {};

  private loading: LoadingState = true;

  private async created() {
    await this.loadData();
  }

  @Watch('$route.params.debtorId')
  @Watch('settlementplan')
  private async loadData() {
    this.loading = true;
    const { settlementplanId } = this.$route.params;

    this.endpoint = `/settlementplan/${this.settlementplan?.uuid ?? settlementplanId}`;

    const res = await api.get(this.endpoint);
    this.data = res.data;

    this.$store.commit(
      'bars/setRightBarTitle',
      this.$t(this.data.settlement_plan_type || 'debtor.settlement_plan.title')
    );

    this.loading = false;
  }
  private editPayment(payment: any) {
    this.$store.dispatch('bars/openRightBar', {
      component: DebtorSettlementPlanInstallmentEdit,
      properties: {
        settlementplan: this.settlementplan.uuid,
        installment: payment.uuid,
      }
    });
  }
  private isPast(date: string) {
    if (isBefore(new Date(date), new Date())) {
      return formatDistance(new Date(date), new Date(), { locale: getDateFnsLocale() });
    }
    return null;
  }

  private get settlementplan(): { uuid: string; type: string } {
    return this.$store.state.bars.rightBarProperties?.settlementplan;
  }

  private get attachments(): AttachmentDownload[] {
    if(null == this.data.attachment_links) {
      return [];
    }

    return this.data.attachment_links.map((attachmentLink: AttachmentLink) => {
      return {
        original_name: attachmentLink.attachment.original_name,
        endpoint: `/settlementplan/${this.data.uuid}/attachment/${attachmentLink.uuid}/download`,
      };
    });
  }

  private async deleteNote(uuid: string) {
    await this.showConfirmation(this.$t(`settlementplan.note.delete.confirmation`) as string)
      .then(() => {
        let endpoint =`/debtor/${this.data.debtor.uuid}/${this.data.settlement_plan_type}/${this.settlementplan.uuid}/note/${uuid}/delete`;
        api.post(endpoint);
        this.$store.dispatch('bars/closeRightBar');
        this.loading = false;
        this.$toasted.success(this.$t(`delete.success`) as string);
        return;
      })
  }

  protected async showConfirmation(message: string) {
    return new Promise<any>((resolve, reject) => {
      this.$store.dispatch('modal/open', {
        body: message,
        actions: [
          {
            label: this.$t('modal.record.delete.cancel') as string,
            class: 'btn-dark',
            action: async () => this.$store.dispatch('modal/close'),
          },
          {
            label: this.$t('modal.record.delete.confirm') as string,
            class: 'btn-primary',
            action: async () => {
              this.$store.dispatch('modal/close').then(resolve);
            },
          },
        ],
      });
    });
  }
}
</script>

<style scoped lang="scss">
.card-body {
  border-bottom: none;
}
dt {
  font-weight: 400;
}

.dispute-notes {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.btn-notes {
  padding-left: 3px;
  padding-right: 3px;
}
</style>
