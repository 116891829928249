<template>
  <div>
    <h3 class="h4 text-highlight">
      {{ $t('workflow.direct-actions') }}
      <a @click.prevent="addAction">
        <icon class="icon-plus" name="plus" />
      </a>
    </h3>
    <div class="row mr-0">
      <div class="col-sm-6 col-lg-4" v-for="(action, index) in value" :key="action.uniq">
        <v-action :action-types="actionTypes" @change="actionChanged(index, $event)" :value="action">
          <a @click.prevent="removeAction(index)" slot="footer">
            <icon name="trash-alt" />
            {{ $t('workflow.delete') }}
          </a>
        </v-action>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import clone from '@/utils/clone';
import randomize from 'randomatic';

import { ActionTypes, Action } from '@/models';
import VAction from './Action.vue';

@Component({
  components: { VAction },
})
export default class DirectActions extends Vue {
  @Prop({ type: [Object], required: true })
  private actionTypes!: ActionTypes;
  @Prop({ type: [Array], required: true })
  private value!: Action[];

  private initializeActionObject() {
    return {
      type: '',
      parameters: {},
      uniq: randomize('Aa0', 10),
    };
  }
  private addAction() {
    const actions = clone(this.value);

    actions.push(this.initializeActionObject());

    this.$emit('input', actions);
  }

  private actionChanged(index: number, value: Action | null) {
    const actions = clone(this.value);
    value  = value ?? this.initializeActionObject();
    const isDifferentType = actions[index].type !== value.type;
    // only keep the action parameters if the type hasn't changed.
    actions[index] = !isDifferentType ? { ...actions[index], ...value } : { ...value };

    this.$emit('input', actions);
  }

  private removeAction(index: number) {
    const actions = clone(this.value);
    actions.splice(index, 1);

    this.$emit('input', actions);
  }
}
</script>

<style scoped lang="scss">
a {
  cursor: pointer;
}
</style>
