<template>
  <div>
    <loader :loading="loading"/>
    <form @submit.prevent="submit" class="form" v-if="schema" :ref="schema.title">
      <json-form :schema="schema" v-model="model" :errors="errors"/>
      <button type="submit" class="btn btn-primary btn-sm w-100">
        <Icon prefix="fas" name="save" class="mr-2"/>
        {{ $t('save') }}
      </button>
    </form>
  </div>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator';
import { FormComponent } from '@/mixins/FormComponent';
import { mixins } from 'vue-class-component';
import api from '@/api';
import clone from '@/utils/clone';
import { AxiosResponse } from 'axios';
import { parseEndpoint, getRoute } from '@/router';

@Component({})
export default class DebtorTaskForm extends mixins(FormComponent) {
  protected model: any = {};
  protected endpoint!: string;

  protected created() {
    this.setEndpointAndLoad();
  }

  @Watch('$route.name')
  protected async setEndpointAndLoad() {
    const { params, name, meta } = this.$route;
    this.endpoint = parseEndpoint(meta?.rightBarEndpoint, { ...params });
    await this.loadData();
  }

  protected afterLoad(response: AxiosResponse) {
    const title: string = this.schema && this.schema.title ? this.schema.title : '';
    if (title) {
      this.$store.commit('bars/setRightBarTitle', this.$t(title + '.header'));
    }
    this.$store.commit('bars/setRightBarFormSubmit', this.submit);
  }
}
</script>

<style scoped lang="scss">
</style>
