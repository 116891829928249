<template>
  <div class="card mb-3">
    <div class="card-head justify-between">
      <h2 class="h5">{{ $t(`batch.type.${$route.params.type}`)}}</h2>
    </div>
    <div class="card-body">
      <data-table
        :key="model"
        :model="model"
        :endpoint="endpoint"
        @action="doActionAndUpdate"
        @bulkAction="doBulkActionAndUpdate"
        ref="taskList"
        :route-context="$route.meta?.context"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { BatchListMixin } from '@/mixins/BatchListMixin';

@Component
export default class BatchListType extends mixins(BatchListMixin) {}
</script>

<style scoped lang="scss">
</style>
