<template>
  <div class="d-flex h-100 align-items-center justify-content-center login-container">
    <div class="col-sm-10 col-md-6 col-lg-4">
      <div class="d-flex justify-content-end">
        <locale-picker />
      </div>
      <div class="card p-5 clearfix">
        <img
          :src="this.$store.getters['platform/logo']"
          class="mb-5 img-fluid mx-auto"
          :style="{ maxHeight: '300px' }"
        >
        <p>{{ $t('login.mfa.title') }}</p>
        <form @submit.prevent="submit" class="form form-column">
          <loader :loading="loading" />
          <input class="form-control font-semibold text-lg text-center"
                 style="letter-spacing: 0.5em;" maxlength="6" v-model="authCode" type="text" autofocus @input="submit">
          <div class='checkbox mt-2'>
            <label for='trusted' class='checkbox-label'>
              <input
                id='trusted'
                class='checkbox-input'
                v-model='trusted'
                type='checkbox'
              />
              <span class='checkmark'></span>{{ $t('login.mfa.trust_device', {days: 60}) }}
            </label>
          </div>
          <button type="submit" class="mt-3 btn btn-primary btn-block" :disabled="authCode.length !== 6">
            <Icon name="angle-right" />
            <span>{{ $t('form.login_type.submit.label') }}</span>
          </button>
        </form>
      </div>
      <p class="text-right mt-2 cursor-pointer" @click="cancel">
        <small class="font-italic text-muted">{{ $t('login.mfa.cancel') }}</small>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { LoadingState } from '@/models';
import { AuthService } from '@/services/AuthService';
import axios from '@/api/axios';

@Component({})
export default class Mfa extends Vue {
  private loading: LoadingState = false;
  private authCode: string = '';
  private trusted: boolean = false;
  private authService!: AuthService;

  private async created() {
    this.authService = new AuthService(this.$store);
  }

  private async submit() {
    if (this.authCode.length !== 6) return;

    this.loading = true;
    this.authService.checkMfa(this.authCode, this.trusted)
      .then(({ data }) => this.authService.login(data.token, data.data?.user))
      .then(() => {
        const redirectRoute = this.$store.state.auth.acl?.subscription_wizard?.api_subscription_wizard_info ? 'wizard.info' : 'dashboard.index';
        return this.$store.dispatch('redirect', { name: redirectRoute }, { root: true });
      }).catch((res) => {
        if (res.status === 302) {
          this.$toasted.error(this.$t('login.mfa.session_expired') as string);
          this.cancel();
        }
    })
      .finally(() => this.loading = false);
  }

  private cancel() {
    this.$store.commit('auth/setMfa', false);
    this.$router.push({ name: 'auth.login' });
  }
}
</script>
