import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { API } from '@/mixins/API';
import { GraydonData, RecordChoices } from '@/models';
import { appendQueryParams } from '@/utils/queryString';

@Component
export default class AbstractGraydonComponent extends mixins(API) {
  protected data: GraydonData | null = null;
  protected recordColorMap: Record<RecordChoices, string> = {
    'AAA': '#61a853',
    'AA': '#95b75d',
    'A': '#d8c868',
    'BBB': '#f9ca6c',
    'BB': '#f1bb57',
    'B': '#ed9758',
    'CCC': '#e87f50',
    'CC': '#e54b47',
    'C': '#d60633',
    'D': '#aa001f',
  };

  protected created() {
    this.getData();
  }

  protected sync() {
    this.getData(true);
  }

  protected disableGraydon() {
    this.$emit('onEnableGraydon', false);
  }

  protected getData(refresh: boolean = false) {
    let endpoint = `/graydon/company_lookup/${this.debtorId}`;

    if (refresh) {
      endpoint = appendQueryParams(endpoint, { refresh: 1 });
    }

    this.get(endpoint)
      .then((res: GraydonData) => this.data = res)
      .catch(e => {
        this.loading = 'error';
        this.disableGraydon();
      });
  }

  protected downloadReport() {
    this.get(this.downloadReportEndpoint)
      .then(res => {
        const link = document.createElement('a');

        link.href = res.url;

        document.body.appendChild(link);
        link.click();

        link.remove();
      }).catch(e => this.loading = 'error');
  }

  protected get downloadReportEndpoint(): string {
    return `/graydon/company_report/${this.debtorId}/download`;
  }

  protected get debtorId(): string {
    return this.$route.params.debtorId;
  }

  protected get ratingStyle() {
    return { height: '2.5rem', width: '0.75rem', backgroundColor: this.ratingColor };
  }

  protected get ratingColor() {
    if (!this.data) {
      return '#333';
    }
    return this.recordColorMap[this.data.rating.value];
  }

  protected get entityColor() {
    switch (this.data?.credit_flag.value) {
      case 'R':
        return 'text-danger';
      case 'O':
        return 'text-warning';
      case 'G':
        return 'text-success';
      default:
        return 'text-muted';
    }
  }
}
