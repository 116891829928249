<template>
  <div>
    <div class="row">
      <div class="col-md-6 col-xl-4 mb-3">
        <debtor-group-info />
      </div>
      <div class="col-md-6 col-xl-4 mb-3">
        <creditor-info />
      </div>
      <div class="col-xl-4 mb-3">
        <financial-info :key="'financial' + $root.$data.refreshKey" />
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-3">
        <div class="card">
          <div class="card-head select-none justify-between py-0 pl-0 pr-0">
            <div class="d-flex">
              <a
                v-for="type in types"
                :key="type"
                class="nav-link h5"
                :class="{ active: selectedType === type }"
                @click="selectType(type)"
              >{{ $t(`debtors.group.overview.${type}s.title`) }}</a
              >
            </div>
          </div>
        </div>
        <receivable-list v-if="selectedType === 'receivable'" :key="'debtorGroupReceivable' + $root.$data.refreshKey" ref="debtorGroupReceivableList"/>
        <settlement-plan-list v-else :key="'spl' + $root.$data.refreshKey" ref="settlementPlanList"/>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <correspondence-list :key="'corr' + $root.$data.refreshKey" ref="correspondenceList" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import DebtorGroupInfo from '@/views/debtor-group/dashboard/DebtorGroupInfo.vue';
import CreditorInfo from '@/views/debtor-group/dashboard/CreditorInfo.vue';
import FinancialInfo from '@/views/debtor-group/dashboard/FinancialInfo.vue';
import ReceivableList from '@/views/debtor-group/receivable/List.vue';
import SettlementPlanList from '@/views/debtor-group/settlementplan/List.vue';
import CorrespondenceList from '@/views/debtor-group/dashboard/CorrespondenceList.vue';

import api from '@/api';

@Component({
  components: {
    DebtorGroupInfo,
    CreditorInfo,
    FinancialInfo,
    ReceivableList,
    SettlementPlanList,
    CorrespondenceList,
  },
})
export default class DebtorGroupDashboard extends Vue {
  private types: string[] = ['receivable', 'settlementplan'];
  private selectedType: string = 'receivable';

  protected async selectType(type: string) {
    this.selectedType = type;
  }
}
</script>

<style lang="scss" scoped>
</style>
