<template>
  <div>
    <!-- The Modal/Lightbox -->
    <div class="lightbox">
      <span class="close cursor" @click="$emit('closeLightBox')">&times;</span>
      <div class="lightbox-content">
        <div v-show='currentImage == image' v-for='(image, index) in images' class="lightbox-slides">
          <div class='number-text'>{{ index +1 }} / {{ images.length}}</div>
          <img :src="image" style="width:100%" alt=''>
        </div>

        <!-- Next/previous controls -->
        <a class="prev" @click="moveSlides(-1)">&#10094;</a>
        <a class="next" @click="moveSlides(1)">&#10095;</a>

        <!-- Thumbnail image controls -->
        <div v-for='(image, index) in images' class="thumbnail-list column">
            <img :class="[isActive(image)? 'active' : '' , 'thumbnail-image']" :src="image" @click="moveImage(index)" alt="">
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import { Component, Prop, Watch } from 'vue-property-decorator';
import { API } from '@/mixins/API';

@Component({
  components: {}
})
export default class LightBox extends API  {
  @Prop({ required: true, default: [] })
  protected images!: string[];
  @Prop({ required: true, default: '' })
  protected id!: string;

  private currentImage: string = '';
  private currentIndex: number = 0;

  @Watch(`id`)
  protected idChanged() {
    this.currentImage = this.id;
  }

  protected isActive(image: string) {
    return this.currentImage == image;
  }

  protected moveSlides(index: number) {
    this.currentIndex += index;

    if (this.currentIndex > this.images.length -1) {
      this.currentIndex = 0;
    }

    if (this.currentIndex < 0) {
      this.currentIndex = this.images.length - 1
    }

    this.currentImage = this.images[this.currentIndex];
  }

  protected moveImage(index: number) {
    this.currentImage = this.images[index];
  }
}
</script>>

<style scoped lang="scss">
@import '~@/assets/scss/colors';
/* The Modal (background) */
.lightbox {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}

/* Modal Content */
.lightbox-content {
  position: relative;
  background-color: black;
  margin: auto;
  padding: 0;
  width: 90%;
  max-width: 1200px;
}

/* The Close Button */
.close {
  color: white;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 35px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #999;
  text-decoration: none;
  cursor: pointer;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
  text-decoration: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Number text (1/3 etc) */
.number-text {
  color: $light;
  background-color: opacify(grey, 0.1);
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

.thumbnail-image {
  border: 1px solid #ddd; /* Gray border */
  border-radius: 4px;  /* Rounded border */
  padding: 2px; /* Some padding */
  width: 150px;
  height: 100px;
  contain: content;
  margin: 10px;
  opacity: 0.6;
  float: left;
}

.thumbnail-image:hover {
  border: 2px solid $dark !important;
  border-radius: 4px;
  opacity: 1;
}

.thumbnail-image.active {
  border: 2px solid #025fdfcc !important;
  border-radius: 4px;
  opacity: 1;
}

.thumbnail-list {
  padding-left: 0;
}
</style>
