<template>
  <data-table
    model="segmentation"
    @action="doAction"
    :endpoint="endpoint"
    :route-params="{ id: $route.params.id, stepid: { key: 'uuid' } }"
    route-context="settings.segmentation"
    ref="segmentationList"
  />
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { SettingsListComponent } from '@/mixins/SettingsListComponent';
import { EmitterAction } from '@/models';
import DataTable from '@/plugins/data-table/DataTable.vue';

@Component({})
export default class SettingsSegmentationList extends mixins(SettingsListComponent) {
  protected model = 'segmentation';
}
</script>

<style scoped lang="scss">
</style>
