<template>
  <div class="d-flex" ref="colorpicker">
    <div class="position-relative">
      <div class="current-color" :style="'background-color: ' + colorValue" @click="togglePicker()"></div>
      <picker :value="colors" @input="updateFromPicker" v-if="displayPicker"/>
    </div>
    <input
      type="text"
      class="form-control"
      v-model="colorValue"
      @focus="showPicker()"
      @input="updateFromInput"
      :id="id"
      :title="title"
      :required="required"
    >
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Chrome } from 'vue-color';

@Component({
  components: {
    picker: Chrome,
  },
})
export default class ColorPicker extends Vue {
  @Prop()
  private color!: string;
  @Prop()
  private title!: string;
  @Prop()
  private id!: string;
  @Prop()
  private required!: boolean;
  private colorValue: string = '';
  private displayPicker: boolean = false;
  private colors: Color = { hex: '#000', rgba: { r: 25, g: 77, b: 51, a: 1 }, a: 1 };

  protected mounted() {
    this.setColor(this.color || '#000000');
  }

  @Watch('colorValue')
  protected setColor(color: string) {
    if (color) {
      this.updateColors(color);
      this.colorValue = color;
      this.$emit('input', color);
    }
  }

  protected updateColors(color: string) {
    if (color.slice(0, 1) === '#') {
      this.colors = {
        hex: color,
      };
    } else if (color.slice(0, 4) === 'rgba') {
      const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
      const hex = `#${this.componentToHex(rgba[0])}${this.componentToHex(
        rgba[1],
      )}${this.componentToHex(rgba[2])}`.slice(1);
      this.colors = {
        hex,
        a: Number(rgba[3]),
      };
    }
  }

  protected showPicker() {
    document.addEventListener('click', this.documentClick);
    this.displayPicker = true;
  }

  protected hidePicker() {
    document.removeEventListener('click', this.documentClick);
    this.displayPicker = false;
  }

  protected togglePicker() {
    this.displayPicker ? this.hidePicker() : this.showPicker();
  }

  protected updateFromInput() {
    this.updateColors(this.colorValue);
  }

  protected updateFromPicker(color: Color) {
    this.colors = color;
    if (color.rgba.a === 1) {
      this.colorValue = color.hex || '';
    } else {
      this.colorValue =
        'rgba(' +
        color.rgba.r +
        ', ' +
        color.rgba.g +
        ', ' +
        color.rgba.b +
        ', ' +
        color.rgba.a +
        ')';
    }
  }

  protected documentClick(e: any) {
    const el: Element = this.$refs.colorpicker as Element;
    const target = e.target;

    if (el && (el !== target && !el.contains(target))) {
      this.hidePicker();
    }
  }

  protected componentToHex(c: any) {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }
}

export interface Color {
  rgba?: any;
  hex?: string;
  a?: number;
}
</script>

<style lang="scss" >
.vc-chrome {
  position: absolute;
  top: 35px;
  right: left;
  z-index: 30;
}

.current-color {
  display: block;
  width: 30px;
  height: 100%;
  background-color: #000;
  cursor: pointer;
}

.vc-chrome-alpha-wrap,
.vc-input__label,
.vc-chrome-toggle-btn {
  display: none !important;
}
</style>

