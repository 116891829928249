// mixin.js
import Component, { mixins } from 'vue-class-component';
import { EmitterAction, LoadingState } from '@/models';
import { ListComponent } from '@/mixins/ListComponent';

@Component
export class ReportListComponent extends mixins(ListComponent) {
  protected model: string = 'report';

  protected async doAction(action: EmitterAction) {
    this.setDataTable();
    if (action.emit === 'view') {
      this.$router.push({
        name: `${this.model}.${action.emit}`,
        params: { ...this.$route.params, [this.model + 'Id']: action.id as string },
      });
    } else if (action.emit === 'download') {
      this.download(`${this.model}/${action.id}/download`);
    } else if (action.emit === 'delete') {
      this.delete(`${this.model}/${action.id}/delete`);
    }
  }

  protected get endpoint() {
    return `/${this.model}`;
  }
}
