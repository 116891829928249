<template>
  <data-table :endpoint="endpoint"/>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { DebtorListComponent } from '@/mixins/DebtorListComponent';

@Component
export default class DebtorList extends mixins(DebtorListComponent) {
  protected get endpoint(): string {
    return '/debtor/' + this.$route.params.debtorId + '/history';
  }
}
</script>

<style lang="scss" scoped>
</style>
