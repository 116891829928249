<template>
  <div id="app" :style="this.$store.getters['platform/cssProps']">
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component
export default class App extends Vue {
  protected created() {
    this.$store.dispatch('platform/loadData');

    if (this.$store.state.auth.token) {
      this.$store.dispatch('auth/loadAcl');
    }
  }
  
  @Watch('$store.state.auth.acl')
  protected aclChanged() {
    this.$forceUpdate();
  }
  @Watch('$store.state.platform.name')
  private setTitle() {
    if (this.$store.state.platform.overrideTitle && this.$store.state.platform.name) {
      document.title = this.$store.state.platform.name;
    }
  }

  @Watch('$store.state.platform.favicon')
  private setFavicon() {
    if (this.$store.state.platform.favicon === null) {
      return;
    }

    const oldFavicon: HTMLElement | null = document.getElementById('platform-favicon');
    const newFavicon: HTMLLinkElement = this.createFavicon();

    if (oldFavicon) {
      document.head.removeChild(oldFavicon);
    }
    document.head.appendChild(newFavicon);
  }

  private createFavicon(): HTMLLinkElement {
    const favicon: HTMLLinkElement = document.createElement('link');
    favicon.id = 'platform-favicon';
    favicon.rel = 'shortcut icon';
    favicon.type = 'image/png';
    favicon.href = `data:image/png;base64, ${this.$store.state.platform.favicon}`;

    return favicon;
  }
}
</script>
