import { isFunction as lodashIsFunction } from 'lodash';

export function assertType<InputType, DesiredType extends InputType>(value: InputType, assertion: (value: InputType) => value is DesiredType): value is DesiredType {
  if(!assertion(value)) {
    throw new Error(`Failed to assert type: '${value}'`);
  }
  return true;
}

export function isNull(value: any): value is undefined|null {
  return null == value;
}

export function isBoolean(value: any): value is boolean {
  return value === true || value === false;
}

export function isNumber(value: any): value is number {
  return !isNull(value) && typeof value === 'number' && !isNaN(value);
}

export function isString(value: any): value is string {
  return !isNull(value) && typeof value === 'string';
}

export function isArray<T = any>(value: any): value is T[] {
  return !isNull(value) && Array.isArray(value);
}

// tslint:disable-next-line:ban-types
export function isObject<T = any>(value: any): value is Object {
  return !isNull(value) && typeof value === 'object' && !isArray(value);
}
export function isDate<T = any>(value: any): value is Date {
  return value instanceof Date;
}
export function isFunction(value: any): value is (...args: any) => any {
  return !isNull(value) && lodashIsFunction(value);
}
