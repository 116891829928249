<template>
  <div class="card h-100">
    <div class="card-head justify-between py-0 pr-0">
      <h2 class="h5">{{ $t('debtors.overview.tasks.title') }}</h2>
      <div class="d-flex">
        <router-link
          v-if="nextDebtor"
          class="nav-link nav-link-right"
          :to="{
            name: 'debtor.view',
            params: {
              debtorId: nextDebtor,
            },
          }"
        >
          <icon name="step-forward" :title="$t('debtor.tasks-completed.next-debtor')" />
        </router-link>
        <router-link
          class="nav-link nav-link-right"
          :to="{
            name: 'debtor.task',
          }"
        >
          <icon name="th-list" :title="$t('debtors.overview.tasks.btn.all')" />
        </router-link>
        <router-link
          class="nav-link nav-link-right"
          :to="{
            name: 'debtor.view.task.create',
          }"
        >
          <icon name="plus" :title="$t('debtors.overview.tasks.btn.create')" />
        </router-link>
      </div>
    </div>
    <div class="card-body">
      <div class="d-flex justify-content-end mb-3">
        <button
          class="btn btn-sm mr-2"
          :class="this.filters.disableDashboardFilter ? 'btn-primary' : 'btn-light'"
          @click="toggle('disableDashboardFilter')"
           v-text="$t(filters.disableDashboardFilter ? 'task.disableDashboardFilter.on': 'task.disableDashboardFilter.off')"
        >
          {{ $t(`task.disableDashboardFilter`) }}
        </button>
        <button
          class="btn btn-sm mr-2"
          :class="this.filters.onlyClaimedTasks ? 'btn-primary' : 'btn-light'"
          @click="toggle('onlyClaimedTasks')"
          v-text="$t(filters.onlyClaimedTasks ? 'task.onlyClaimedTasks.on': 'task.onlyClaimedTasks.off')"
        >
        </button>
      </div>

      <data-table
        :model="model"
        :endpoint="endpointWithFilters"
        @action="doAction"
        @bulkAction="doBulkAction"
        @response="handleResponse"
        ref="taskList"
        route-context="debtor.view"
        disable-column-options
        disable-pagination
        disable-vertical-scroll
        :disable-bulk-actions="isDebtorView"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { TaskListComponent } from '@/mixins/TaskListComponent';

@Component({})
export default class TaskList extends mixins(TaskListComponent) {
  protected created() {
    // temporarily catch filters from query
    // TODO: write DebtorDashboardState store to set filters etcetera.
    const {disableDashboardFilter, onlyClaimedTasks} = this.$route.query;
    // filter should be enabled when clicking through from TaskView...
    if (onlyClaimedTasks) {
      this.filters.onlyClaimedTasks = !!onlyClaimedTasks;
    }
    if (disableDashboardFilter) {
      this.filters.disableDashboardFilter = !!disableDashboardFilter;
    }
  }
}
</script>
