<template>
  <font-awesome-layers class="fa-lg" @click.prevent="onClick">
    <font-awesome-icon
      :icon="['fas', 'angle-up']"
      transform="shrink-6 up-1"
      :class="{active: direction === 'ASC'}"
    />
    <font-awesome-icon
      :icon="['fas', 'angle-down']"
      transform="shrink-6 down-5"
      :class="{active: direction === 'DESC'}"
    />
  </font-awesome-layers>
</template>

<script lang="ts">
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { SortDirection } from '@/models/SortDirection';
import { library } from '@fortawesome/fontawesome-svg-core';

@Component({
  components: {
    FontAwesomeLayers,
    FontAwesomeIcon,
  },
})
export default class SortIcon extends Vue {
  @Prop() private direction!: 'ASC' | 'DESC' | null;
  private created() {
    library.add(faAngleUp, faAngleDown);
  }
  private onClick() {
    let next: SortDirection | null;
    if (this.direction === 'ASC') {
      next = 'DESC';
    } else if (this.direction === 'DESC') {
      next = null;
    } else {
      next = 'ASC';
    }
    this.$emit('change', next);
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/colors';
svg {
  color: scale-color($color: $dark, $lightness: 50%);
  cursor: pointer;
  &.active {
    color: $text-color;
  }
}
</style>
