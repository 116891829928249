<template>
  <json-form
    v-if="model.entity"
    :schema="schema"
    v-model="model"
    :errors="errors"
    :labels="false"
  />
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator';
import { FormComponent } from '@/mixins/FormComponent';
import { mixins } from 'vue-class-component';

@Component({})
export default class EntitySwitcher extends mixins(FormComponent) {
  protected model: any = {};
  protected endpoint: string = `/settings/entity/change`;

  public created() {
    this.loadData();
  }

  @Watch('model.entity')
  private async changeEntity(_: string, oldEntity: string) {
    if (oldEntity) {
      await this.submit();
      this.$store.dispatch('auth/loadUser');
      this.$store.dispatch('auth/loadAcl');
    }
  }
}
</script>
<style lang="scss">
.dropdown-menu {
  min-width: inherit;
  position: absolute;
}
.btn-link {
  color: inherit;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}
.picker {
  position: relative;
}
label {
  font-weight: bold;
}
</style>
