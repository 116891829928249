<template>
  <div>
    <loader :loading="loading" />
    <entity-info v-bind="{entityColor, lastSyncDate }" @onSync="sync" @onDownload="downloadReport" />
    <div class="row">
      <div class="col-12 col-xl-7">
        <div class="row">
          <div class="col-12 col-md-6">
            <block
              v-if="data"
              :value="data.credit_limit.value | currency"
              identifier="credit_limit"
              icon="exclamation"
            />
          </div>
          <div class="col-12 col-md-6">
            <block
              v-if="data"
              :value="`${data.payment_score}<span class='text-sm'>/10</span>`"
              identifier="payment_score"
              icon="file-search"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <block
              v-if="data"
              :value="`${data.pd}%`"
              identifier="pd"
              icon="heart-rate"
            />
          </div>
          <div class="col-12 col-md-6">
            <block
              v-if="data"
              :value="data.rating.value"
              identifier="rating"
              icon="stamp"
            />
          </div>
        </div>
      </div>

      <div class="col-12 col-xl-5">
        <activity-monitor />
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { Component } from 'vue-property-decorator';
import Block from '@/components/graydon/Block.vue';
import AbstractGraydonComponent from '@/mixins/AbstractGraydonComponent';
import ActivityMonitor from '@/components/graydon/ActivityMonitor.vue';
import EntityInfo from '@/components/graydon/EntityInfo.vue';

@Component({
  components: {
    EntityInfo,
    Block,
    ActivityMonitor
  },
})
export default class GraydonView extends AbstractGraydonComponent {
  protected created() {
    this.getData();
  }
  private get lastSyncDate():string|undefined {
    return this.data?.timestamp?.date;
  }
}
</script>

<style lang="scss" scoped>

</style>
