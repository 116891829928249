<template>
  <div class="data-table">
    <loader :loading="loading" />

      <div v-if="!totalRecords && !(settings && settings.globalSearch) && !forceShowTable"
           class="d-flex flex-column align-items-center mt-4 mb-2">
        <span>{{ $t('datatable.no-records-found') }}</span>
        <span class="text-muted text-sm mt-1 cursor-pointer" @click.prevent="forceShowTable = true">{{ $t('datatable.show-datatable') }}</span>
    </div>
    <div v-else class="table-responsive mb-2" :style="{ overflowY: disableVerticalScroll ? 'hidden' : 'auto' }">
      <string-filter
        v-if="settings && settings.globalSearch"
        class="global-search-input d-inline-block"
        @update="setFilters({ globalSearch: $event })"
        :placeholder="`${tableName}.global-search.title`"
        :label='true'
      />
      <div v-if="columnSelectionMode" class="alert alert-info" v-text="$t('datatable.column-options.info')" />
      <div class="d-flex">
        <bulk-actions
          class='mt-2'
          v-if="
            !selectedItems.isEmpty &&
              !disableBulkActions &&
              mergedBulkActions.length
          "
          :select-domain="selectDomain"
          :options="mergedBulkActions"
          :route-context="routeContext"
          :route-params="routeParams"
          @handled="onBulkActionHandled"
        />
        <div class="ml-auto d-flex gap-1" v-if="showBulkSelection">
          <span v-if="isPageSelected" class="btn btn-light btn-sm" @click="selectAll(true)">{{$t('selection.select_all')}}</span>
          <span v-if="hasOffPageSelection" class="btn btn-light btn-sm" @click="selectAll(false)">{{$t('selection.deselect_all')}}</span>
          <span class="text-sm my-1">{{ $t('selection.item_count', [selectedItems.length]) }}</span>
        </div>
      </div>
      <table v-if="!blockData" class="table">
        <thead>
          <draggable v-model="columns" tag="tr" handle=".draggable-handle">
            <th v-for="column in filteredColumns" :key="column.name">
              <div v-if="column.is_key" class='bulk-selection-container position-relative'>
                <div class="checkbox">
                  <label class="checkbox-label">
                    <input
                      :disabled="loading || !mergedBulkActions.length"
                      type="checkbox"
                      :class="['checkbox-input']"
                      :checked="isPageSelected"
                      @click="onCheckboxChange($event, $event.target.checked, true)"
                    />
                    <span :class="['checkmark', { disabled: loading }]"></span>
                  </label>
                </div>
              </div>
              <div class="draggable-handle cursor-pointer select-none" v-else-if="columnSelectionMode">
                <span
                  @click="
                    column.hidden = !column.hidden;
                    $forceUpdate();
                  "
                  v-text="column.label"
                  :class="{ 'column-hidden': column.hidden }"
                  class="mr-1"
                />
                <icon name="arrows"></icon>
              </div>
              <div v-else-if="!column.is_key && !(column.type === 'nestedTableStringArray') && !(column.type === 'stringDetailLine')">
                <span v-text="column.label" />
                <sort-icon
                  v-if="column.sortable && !disableSort"
                  :direction="getColumnSortDirection(column.name)"
                  @change="changeSort($event, column.name)"
                >
                </sort-icon>
              </div>
            </th>
            <th class="d-flex justify-content-end p-0">
              <div class="position-relative d-flex">
                <button
                  class="btn btn-light btn-sm px-1 py-0"
                  :class="{
                    'text-primary': columnSelectionMode,
                    'modification-indicator': hasHiddenColumns && !columnSelectionMode,
                  }"
                  @click="toggleColumnSelectionMode(!columnSelectionMode)"
                >
                  <icon name="columns" :title="$t('datatable.column-options.btn')" />
                </button>
              </div>
              <button
                class="btn btn-light btn-sm btn-filter px-1 py-0"
                @click.prevent="toggleSearchFields"
                :class="{ active: !showSearchFields }"
              >
                <Icon name="filter" class="pointer" :title="$t('toggle_search')" />
              </button>
            </th>
          </draggable>
        </thead>
        <tbody>
          <data-table-filter
            v-if="!showSearchFields"
            class="filter"
            :routeContext="routeContext"
            :searchSettings="search"
            :columns="filteredColumns"
            @update="setSearch"
            @persist="persistSearch"
          ></data-table-filter>
          <template v-for="(record, index) in filteredSortedRecord">
            <tr
              :key="generateKeyFromUuids(record.uuid ? record.uuid : index)"
              :class="[(hasStringDetailLine)?'string-detail-tr':'', getRowColor(record), { pointer: hasRowClick }]"
            >
              <td v-for="column in filteredColumns" :key="column.name" @click="!column.is_key && !(column.type === 'nestedTableStringArray') && handleRowClick($event, record)">
                <div class="checkbox" v-if="column.is_key === true && !invoiceTypes.includes(record.type)">
                  <label class="checkbox-label">
                    <input
                      :disabled="loading || !mergedBulkActions.length"
                      type="checkbox"
                      :class="['checkbox-input']"
                      :checked="selectedItems.isSelected(record[column.name])"
                      @click="onCheckboxChange($event, $event.target.checked, record[column.name])"
                    />
                    <span :class="['checkmark', { disabled: loading }]"></span>
                  </label>
                </div>
                <div v-else-if="column.name === 'manualRegistered'">
                  <icon v-if='record[column.name] === true' name='envelope' style='color: red'></icon>
                  <icon v-if='record[column.name] === false' name='envelope'></icon>
                </div>
                <div v-else-if='column.is_key === true && invoiceTypes.includes(record.type)'>
                </div>
                <template v-else-if="column.type === 'router-link'">
                  <router-link :to="{ name: record[column.name].route, params: record[column.name].params }">
                    {{ record[column.name].label }}
                  </router-link>
                </template>
                <template v-else-if="column.type === 'nestedTableStringArray'">
                  <button
                    v-if="record.suggestions.length > 0 && getRowColor(record) !== 'grayRow'"
                    class="btn btn-light btn-sm"
                    :class="{ opened: opened.includes(record.uuid) }"
                    @click="toggleShowSection(record.uuid)"
                  >
                    <Icon name="arrow-down" prefix='fal' />
                  </button>
                </template>
                <template v-else-if="record[column.name]">
                  <template v-if="['date', 'datetime'].includes(column.type)">
                    <span v-if="column.type === 'date'">{{ record[column.name] | date }}</span>
                    <span v-else>{{ record[column.name] | datetime }}</span>
                  </template>
                  <template v-else-if="column.type === 'boolean'">
                    <icon name="check-circle" prefix="fas" v-if="record[column.name]" />
                    <icon name="times" v-else />
                  </template>
                  <template v-else-if="column.type === 'money'">
                    <span class="text-nowrap">{{ record[column.name] }}</span>
                  </template>
                  <template v-else-if="column.type === 'color'">
                    <div
                      class="color-sample"
                      :style="{ backgroundColor: record[column.name] }"
                    ></div>
                  </template>
                  <template v-else-if="column.type === 'stringArray'"
                  >{{ record[column.name].join(', ') }}
                  </template>
                  <template v-else-if="!(column.type === 'stringDetailLine')">
                    <span>{{ record[column.name] }}</span>
                  </template>
                </template>
              </td>
              <td class="table-actions" v-if="!disableActions">
                <actions
                  :ref="`actions-${record.uuid}`"
                  :actions="mergedActions"
                  :record="record"
                  :route-context="routeContext"
                  :route-params="routeParams"
                  :record-params="getRecordParams(record)"
                />
              </td>
            </tr>
            <tr class='string-detail-line-wrapper' v-if='hasStringDetailLine'  @click="handleRowClick($event, record)">
              <td colspan='12' class='p-0 m-0 w-100'>
                <table class='table w-100'>
                  <tr :class='["string-detail-line", { pointer: hasRowClick }]'>
                    {{ record['stringDetailLine'] }}
                  </tr>
                </table>
              </td>
            </tr>
            <tr class='nested-table'>
              <slot name="nested-table" v-bind="{record, opened}"></slot>
            </tr>
          </template>
        </tbody>
      </table>
      <div v-else class="process d-flex">
        <div class="col-sm-6 col-md-4 col-xl-3 mb-2" v-for="record in filteredSortedRecord" :key="record.uuid">
          <div class="p-2 process-block mb-4">
            <h3 class="h5 text-highlight">{{ record[blockData.title] }}</h3>
            <span v-if="blockData.description">{{ record[blockData.description] }}</span>
            <div class="proces_s-actions">
              <actions
                :ref="`actions-${record.uuid}`"
                :actions="mergedActions"
                :record="record"
                :route-context="routeContext"
                :route-params="routeParams"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <bulk-actions
          v-if="!selectedItems.isEmpty && !disableBulkActions && mergedBulkActions.length"
          :select-domain="selectDomain"
          :options="mergedBulkActions"
          :route-context="routeContext"
          :route-params="routeParams"
          @handled="onBulkActionHandled"
          class="mt-2"
        />
        <pagination
          v-if="settings && settings.pagination && !disablePagination && totalRecords > settings.pageSize"
          :current-page="settings.currentPage"
          :page-size="settings.pageSize"
          :total-records="filteredRecords"
          @change="changePage"
          class="ml-auto"
        />
      </div>
      <div v-if="!allRecordsVisible" class="mt-2 d-flex justify-content-center">
        <span class="select-none cursor-pointer" @click="increaseLimit(10)">{{ $t('datatable.increase_limit') }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Flatpickr from '@/plugins/flatpickr/Flatpickr.vue';
import Draggable from 'vuedraggable';

import {
  SortBy,
  DataTableSettings,
  DataTableQueryStringModel,
  LoadingState,
  EmitterAction,
  Table,
} from '@/models';
import {
  ChoiceColumnInterface,
  ColumnContext,
  CustomizableColumnContext, isColumnInterface,
} from '@/models/ColumnContext';
import { SortDirection } from '@/models/SortDirection';
import { appendQueryParams, removeUuidsFromEndpoint, serialize } from '@/utils/queryString';
import api from '@/api';
import Pagination from '../../components/global/Pagination.vue';
import Actions from './Actions.vue';
import ColumnOptions from './ColumnOptions.vue';
import BulkActions from './BulkActions.vue';
import BulkSelection from './components/BulkSelection.vue';
import { Selection } from '@/Selection';
import { mapGetters } from 'vuex';
import { EventBus } from '@/services/EventBus';
import { debounce, pickBy, isEqual } from 'lodash';
import { DEFAULT_DEBOUNCE_DELAY, types } from '@/plugins/data-table/constants';
import DataTableFilter from '@/plugins/data-table/DataTableFilter.vue';
import StringFilter from '@/plugins/data-table/filters/StringFilter.vue';
import hash from '@/utils/hash';
import clone from '@/utils/clone';
import ChoiceFilter from '@/plugins/data-table/filters/ChoiceFilter.vue';
import ImportedPaymentSuggestions from '@/components/views/ImportedPaymentSuggestions.vue';

@Component({
  components: {
    ImportedPaymentSuggestions,
    ChoiceFilter,
    DataTableFilter,
    StringFilter,
    Pagination,
    Actions,
    ColumnOptions,
    Flatpickr,
    BulkActions,
    BulkSelection,
    Draggable,
    nestedTableStringArray: ImportedPaymentSuggestions
  },
  computed: mapGetters('selection', {
    getSelection: 'selection',
  }),
})
export default class DataTable extends Vue {
  public uniqueIdentifier: string = '';
  private originalColumns: ColumnContext[] = [];
  private columns: ColumnContext[] = [];
  // TODO write record class/interface
  private records: any[] = [];
  private settings: DataTableSettings | null = null;
  private totalRecords: number = 0;
  private filteredRecords: number = 0;
  private queryStringData: DataTableQueryStringModel = {};
  private loading: LoadingState = false;
  private selectDomain: string | null = null;
  private tableName: string = '';
  private tableActions: string[] = [];
  private tableBulkActions: string[] = [];
  private limit: number = 0;
  private columnSelectionMode: boolean = false;
  private getSelection!: (model: string) => Selection;
  private invoiceTypes = ['interest', 'penalty', 'admin'];

  @Prop({ required: false })
  private endpoint!: string;

  private forceShowTable: boolean = false;

  @Prop({ required: false })
  private disableSearchFields?: boolean;

  @Prop({ required: false })
  private model!: string;

  @Prop({ required: false })
  private data!: Table;

  @Prop({ required: false, })
  private deleteEndpoint?: string;

  @Prop({ required: false, default: '' })
  private routeContext!: string;

  @Prop({ required: false })
  private routeParams!: any;

  @Prop({
    required: false,
    default: () => [],
    type: [Array],
  })
  private actions!: Array<string | EmitterAction>;
  private rowAction!: string;
  private opened: string[] = [];

  @Prop({ required: false, default: () => [] })
  private bulkActions!: Array<string | EmitterAction>;

  @Prop({ required: false, default: '' })
  private prefix!: string;

  @Prop({ type: Boolean, required: false, default: false })
  private disablePagination!: boolean;

  private showSearchFields: boolean = false;

  @Prop({ type: Boolean, required: false, default: false })
  private disableSort!: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  private disableColumnOptions!: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  private disableActions!: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  private disableBulkActions!: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  private disableRowClick!: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  private disableVerticalScroll!: boolean;

  @Prop({ required: false, default: undefined })
  private blockData?: {
    title: string;
    description?: string;
  };

  @Prop({ required: false })
  private initialLimit?: number;

  @Prop({ required: false })
  private useServerSideSelection?: boolean;

  @Prop({ required: false, default: false })
  private enableBulkSelection!: boolean;

  @Prop({ required: false, default: () => [] })
  private emitableRecordKeys!: string[];

  private sortBy: SortBy = { column: '', dir: 'ASC' };

  private search: Record<string, string> = {};

  private filters: Record<string, string> = {};

  private latestQueryHash?: string;

  private updateSearchDebounce = debounce(this.updateSearch, DEFAULT_DEBOUNCE_DELAY);
  private updateFiltersDebounce = debounce(this.updateFilters, DEFAULT_DEBOUNCE_DELAY);

  public reload() {
    this.getData();
  }

  public clearSelection(): void {
    if (this.isUsingServerSideSelection) {
      this.selectedItems.clear();
    } else {
      this.$store.commit('selection/clear', { type: this.model });
    }
  }

  private onFormSubmit = () => this.reload();

  private beforeDestroy() {
    EventBus.$off('form.submit.successful', this.onFormSubmit);
    EventBus.$off(`datatable.${this.routeContext}.filters.clear`);
  }

  private get types() {
    return types;
  }

  private async created() {
    EventBus.$on(`datatable.${this.routeContext}.filters.clear`, this.clearFilters);
    EventBus.$on('form.submit.successful', this.onFormSubmit);
    const unhashedUniqueIdentifier = `${this.$route.name}.${removeUuidsFromEndpoint(this.endpoint)}`;
    this.uniqueIdentifier = await hash(unhashedUniqueIdentifier);

    if (this.data) {
      this.setData(this.data);
      return;
    }
    if (this.disableSearchFields) {
      this.showSearchFields = this.disableSearchFields;
    }

    if (this.initialLimit) {
      this.limit = this.initialLimit;
    }

    if (this.stateSortBy) {
      this.sortBy = this.stateSortBy;
      this.queryStringData = { ...this.queryStringData, order: [this.sortBy] };
    }

    if (this.stateFilters) {
      await this.setSearch(this.stateFilters);
      this.updateSearch();
      return;
    }

    await this.getData();
  }

  private applyCustomColumnOptions() {
    this.columns.forEach((column, index) => {
      column.order = index;

      const columnOverrides = this.stateColumnOptions?.[column.name];
      if (columnOverrides) {
        this.columns[index] = { ...column, ...columnOverrides };
      }
    });

    this.columns.sort((a, b) => a.order - b.order);
  }
  @Watch('endpoint')
  @Watch('$route.params.tab')
  @Watch('$route.params.debtorId')
  @Watch('$store.state.i18n.locale')
  @Watch('$route.meta.model.slug')
  private getData() {
    if (this.endpoint) {
      this.loadData(this.endpointWithQuery);
    }
  }
  private async loadData(endpoint: string) {
    this.loading = true;
    const currentQueryHash = await hash(serialize(this.queryStringData));
    this.latestQueryHash = currentQueryHash;

    const res = await api.get(endpoint);
    const table = this.prefix ? res.data[this.prefix].table : res.data.table;

    if (!table) {
      this.loading = 'error';
      return;
    }

    if (this.latestQueryHash !== currentQueryHash) {
      return;
    }

    await this.setData(table);

    this.loading = false;

    if (res.data) {
      this.$emit('response', res.data);
    }
  }

  @Watch('data')
  private setData(data: Table) {
    if (typeof data.columns === 'string') {
      data.columns = JSON.parse(data.columns);
    }

    this.columns = data.columns;
    this.records = data.data.dataRecords;
    this.settings = data.settings;
    this.totalRecords = data.data.totalRecords;
    this.filteredRecords = data.data.filteredRecords;
    this.rowAction = data.rowAction;
    this.tableName = data.tableName.replace('.table_name', '');
    this.tableActions = data.actions;
    this.tableBulkActions = data.bulkActions;
    this.selectDomain = data.selectDomain;
    if (this.isUsingServerSideSelection) {
      this.$store.commit('selection/setSelection', {
        type: this.model,
        selection: data.selection ? Object.values(data.selection) : [],
        domain: this.selectDomain,
      });
    }

    this.applyCustomColumnOptions();
    this.originalColumns = clone(this.columns);
  }

  private changeSort(direction: SortDirection, name: string) {
    direction && name ? this.setSortBy(name, direction) : this.resetSortBy();

    this.getData();
  }
  private clearFilters() {
    this.queryStringData = { ...this.queryStringData, search: [] };
    this.$store.commit('datatable/clearFilters', this.uniqueIdentifier);
    this.getData();
  }

  private resetSortBy() {
    this.sortBy = { column: '', dir: 'ASC' };

    this.queryStringData = { ...this.queryStringData, order: [] };

    this.$store.commit('datatable/clearSortBy', this.uniqueIdentifier);
  }

  private setSortBy(name: string, direction: SortDirection) {
    this.sortBy = { column: name, dir: direction };

    this.queryStringData = { ...this.queryStringData, order: [this.sortBy] };

    this.$store.commit('datatable/setSortBy', {
      value: this.sortBy,
      identifier: this.uniqueIdentifier,
    });
  }

  private changePage(page: number) {
    if (page > 1) {
      this.queryStringData = { ...this.queryStringData, page };
    } else {
      const queryStringData = { ...this.queryStringData };
      delete queryStringData.page;
      this.queryStringData = queryStringData;
    }

    this.getData();
  }

  private async onCheckboxChange(event: Event, checked: boolean, uuid: string | boolean) {
    if (this.model) {
      let uuids: string[];
      if (typeof uuid === 'boolean' && uuid === true) {
        uuids = this.filteredSortedRecord.map((item) => item.uuid);
      } else if (typeof uuid === 'string') {
        uuids = [uuid as string];
      } else {
        uuids = [];
      }

      if (this.isUsingServerSideSelection) {
        event.preventDefault();
        event.stopPropagation();
        const payload = {
          select_selectable: {
            domain: this.selectDomain,
            selectables: uuids,
            isSelected: checked,
          },
        };
        this.loading = true;
        const res = await api.post(this.model + '/select', payload);
        if (res.data.selection) {
          uuids = Object.values(res.data.selection);
          this.$store.commit('selection/setSelection', { type: this.model, selection: uuids });
          this.loading = false;
        } else {
          this.loading = 'error';
        }
      } else {
        if (checked) {
          this.$store.commit('selection/select', { type: this.model, value: uuids });
        } else {
          if (uuid === true) {
            this.$store.commit('selection/clear', { type: this.model });
          } else {
            this.$store.commit('selection/deselect', { type: this.model, value: uuids });
          }
        }
      }
    } else {
      Vue.toasted.error('No model on data table');
    }
  }

  private toggleSearchFields() {
    this.showSearchFields = !this.showSearchFields;
  }

  private onBulkActionHandled() {
    this.clearSelection();
  }

  private getColumnSortDirection(name: string) {
    if (this.sortBy.column === name) {
      return this.sortBy.dir;
    }
    return null;
  }

  private setSearch(filters: Record<string, string>, shouldPersist: boolean = false) {
    this.search = filters;
    this.updateSearchDebounce();
  }
  private setFilters(filters: Record<string, string>) {
    this.filters = pickBy({...this.filters, ...filters});

    this.updateFiltersDebounce();
  }
  private persistSearch(key:string, value: string) {
    if (value != null && value !== '') {
      return this.$store.commit('datatable/setFilter', { identifier: this.uniqueIdentifier, filter: { [key]: value }} );
    }
    this.$store.commit('datatable/clearFilter', {identifier: this.uniqueIdentifier, key });
  }
  private updateSearch() {
    this.updateSearchDebounce.cancel();

    const queryStringData = {
      ...this.queryStringData,
      search: this.getMappedSearch()
    };

    delete queryStringData.page;
    this.queryStringData = queryStringData;

    this.getData();
  }
  private updateFilters() {
    this.updateFiltersDebounce.cancel();

    const queryStringData = {
      ...this.queryStringData,
      filters: this.filters,
    };

    delete queryStringData.page;
    this.queryStringData = queryStringData;

    this.getData();
  }
  private getMappedSearch() {
    return Object.entries(this.search).map(entry => {
      const [column, find] = entry;
      return { column, find };
    });
  }
  private get endpointWithQuery() {
    return appendQueryParams(this.endpoint, this.queryStringData);
  }

  private getRecordParams(record: object) {
    if (!this.emitableRecordKeys.length) {
      return {};
    }
    return pickBy(record, (value, key) => {
      return this.emitableRecordKeys.includes(key);
    });
  }
  private handleRowClick(event: MouseEvent, record: { [key: string]: string }) {
    const actions = this.$refs[`actions-${record.uuid}`] as Actions[];
    const newWindow = event.ctrlKey;

    if (this.disableRowClick) {
      return;
    }

    if (this.rowAction) {
      return actions[0].doAction(this.rowAction, newWindow);
    }
    const safeActions = ['view', 'edit'];
    const isSafeAction = safeActions.includes(this.mergedActions[0] as string);

    if (this.mergedActions.length && isSafeAction) {
      return actions[0].doAction(this.mergedActions[0], newWindow);
    }

    if (this.mergedActions.indexOf('view') !== -1) {
      return actions[0].doAction('view', newWindow);
    }
  }
  private generateKeyFromUuids(uuid: number|string|object) {
    return typeof uuid === 'object' ? Object.values(uuid).join('.') : uuid;
  }
  private get computedIdentifier(): string {
    return this.uniqueIdentifier;
  }

  private async selectAll(select: boolean): Promise<void> {
    await this.loadData(appendQueryParams(this.endpointWithQuery, { select: select ? 'all' : 'clear' }));
  }

  private get showBulkSelection() {
    return this.enableBulkSelection && this.mergedActions.length > 0;
  }

  get filteredSortedRecord(): any[] {
    // Records are already sorted by backend, yet backend has no clue whether frontend view want to limit resultset.
    if (this.limit) {
      return this.records.slice(0, this.limit);
    }
    return this.records;
  }

  private get filteredColumns(): ColumnContext[] {
    if (this.columnSelectionMode) {
      return this.columns;
    }

    return this.columns.filter((column: ColumnContext) => {
      return column.is_key ? !this.disableBulkActions && this.mergedBulkActions.length : !column.hidden;
    });
  }

  get hasRowClick(): boolean {
    return (
      (this.mergedActions.length === 1 ||
        (this.mergedActions.length > 1 && this.mergedActions.indexOf('delete') !== 0)) &&
      !this.disableRowClick &&
      !this.disableActions
    );
  }

  get isPageSelected(): boolean {
    return this.filteredSortedRecord.every((record) => this.selectedItems.isSelected(record.uuid));
  }
  get hasOffPageSelection(): boolean {
    return this.selectedItems.values.some((value) => !this.filteredSortedRecord.find((record) => record.uuid === value));
  }
  get mergedActions(): Array<string | EmitterAction> {
    return this.actions.concat(this.tableActions);
  }

  get mergedBulkActions(): Array<string | EmitterAction> {
    return this.bulkActions.concat(this.tableBulkActions);
  }
  public get selectedItems() {
    return this.getSelection(this.model);
  }

  public get isUsingServerSideSelection(): boolean {
    return this.useServerSideSelection || this.selectDomain != null;
  }

  public get getTableName(): string {
    return this.tableName || 'default';
  }

  private increaseLimit(count: number = 5) {
    this.limit += count;
  }
  private toggleColumnSelectionMode(selectionMode: boolean) {
    if (!selectionMode) {
      this.updateAllColumnOrders();

      this.commitCustomColumnOptionsToStore();
    }

    this.columnSelectionMode = selectionMode;
  }
  private updateAllColumnOrders() {
    this.columns.forEach((column, index) => column.order = index);
  }
  private commitCustomColumnOptionsToStore() {
    const value: Record<string, CustomizableColumnContext> = {};

    for (const column of this.modifiedColumns()) {
      value[column.name] = { order: column.order, hidden: !!column.hidden};
    }

    this.$store.commit('datatable/setColumnOptions', {
      value,
      identifier: this.uniqueIdentifier,
    });

    this.originalColumns = clone(this.columns);
  }
  private modifiedColumns(): ColumnContext[] {
    return this.columns.filter((column, index) => !isEqual(column, this.originalColumns[index]));
  }
  private get allRecordsVisible() {
    return !this.limit || this.limit >= this.filteredRecords;
  }
  private get hasHiddenColumns() {
    return this.originalColumns.some(column => column.hidden);
  }
  private get stateFilters() {
    return this.$store.state.datatable.filters[this.computedIdentifier] ?? null;
  }
  private get stateSortBy() :SortBy|null {
    return this.$store.state.datatable.sorts[this.computedIdentifier] ?? null;
  }
  private get stateColumnOptions(): Record<string, any>|null {
    return this.$store.state.datatable.columnOptions[this.computedIdentifier] ?? null;
  }

  private toggleShowSection(id: string) {
    const index = this.opened.indexOf(id);
    if (index > -1) {
      this.opened.splice(index, 1)
    } else {
      this.opened.push(id)
    }
  }

  private getRowColor(record: Record<string, string>): string {
    let color = '';
    this.originalColumns.forEach((column: ColumnContext) => {
      if (column.type === 'color') {
        switch (record[column.name]) {
          case '#00ff00':
            color = 'greenRow';
            break;
          case '#ff0000':
            color = 'redRow';
            break;
          case '#ff9900':
            color = 'orangeRow';
            break;
          case '#cccccc':
            color = 'grayRow';
            break;
          default:
            color = 'whiteRow';
        }
      }
    });
    return color;
  }

  private hasStringDetailLine(): boolean {
    return this.originalColumns.some(column => column.type === 'stringDetailLine');
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/colors';

.table-responsive {
  background-color: $white;
  padding: 1rem 2rem;
  border-bottom: 2px solid $medium;
  .form-control {
    background-color: $light;
    border-color: $medium;
  }
}

.card .table-responsive {
  padding: 0;
}

.table {
  margin-bottom: 0;
  th,
  td {
    padding: 1rem;
  }
  thead tr {
    border-bottom: 1px solid $dark;
  }
  thead th {
    padding-top: 0;
    text-transform: uppercase;
    font-size: 1.25rem;
    white-space: nowrap;
    border: 0;
    color: $text-muted;
    min-height: 40px;
    & .column-hidden {
      opacity: 0.5;
    }
  }
  thead .btn-filter {
    &.active {
      background: transparent;
      span {
        color: var(--primary-color);
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid $light;
      &.pointer {
        cursor: pointer;
      }
      &:hover:not(.filter) {
        box-shadow: none;
        transition: all 0.2s;
        .table-actions {
          opacity: 1;
        }
      }
      &.filter {
        border-bottom-color: transparent;
      }
      &:nth-child(even) {
        background: transparent;
      }
      td {
        border: 0;
        .checkbox {
          margin-left: 0;
        }
        &.table-actions {
          text-align: right;
          white-space: nowrap;
          opacity: 0.5;
          a {
            margin-left: 1rem;
          }
        }
        .color-sample {
          width: 6px;
          height: 20px;
          border-radius: 2px;
        }
      }
    }
  }
}

.checkbox {
  margin: 0 20px 0 0;
  padding: 0;
  height: 14px;

  .checkbox-label {
    display: block;
  }

  .checkbox-input {
    display: none;
    width: 14px;
    height: 14px;
  }

  .checkmark {
    margin-top: -1px;
  }
}

.bulk-selection-card {
  display: none;
  z-index: 1000;
  width: fit-content;
  bottom: -30px;
  left: -6px;
  background-color: #fafafa;
  border-radius: 5px;
  border: 1px solid #eee;
  padding: 7px 5px;
  .checkbox {
    margin:0;
  }
}
.bulk-selection-container:hover .bulk-selection-card {
  display: block;
}

.greenRow {
  background-color: lighten(mediumseagreen, 45%) !important;
}

.redRow {
  background-color: lighten(darkred, 68%) !important;
}

.orangeRow {
  background-color: lighten(orange, 45%) !important;
}

.grayRow {
  background-color: lighten(gray, 45%) !important;
}

.string-detail-tr:hover + .string-detail-line-wrapper{
  border-bottom: 1px solid var(--primary-color);
  box-shadow: none;
  transition: all 0.2s;
}

.nested-table,
.string-detail-line {
  border-bottom: 0 !important;
}

.string-detail-tr, .string-detail-line-wrapper{
  border: 0;
}

.string-detail-body:hover {
  box-shadow: none;
  transition: all 0.2s;
}

.string-detail-line {
  line-height: 2;
  color: #495057;
  display: block;
  padding-left: 5px;
}

</style>
<style lang="scss">
@import '~@/assets/scss/variables';

.card-body > .data-table {
  .table-responsive {
    padding: 0;
    border-bottom: 0;
  }
}
.global-search-input {
  width: 320px;
}

.pointer-false {
  pointer-events: none;
}

.data-table {
  transition: all ease $speed;
}
</style>
