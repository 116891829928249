<template>
  <div class="card">
    <loader :loading="loading"/>
    <div class="card-head">
      <h2 class="h5 d-flex flex-row align-items-center">{{ $t('dashboard.graph.title') }}</h2>
    </div>
    <div class="card-body" v-if="dsoMetric || successRateMetric">
      <div
        class="d-flex flex-column flex-xl-row justify-content-around"
        v-if="dsoMetric && dsoMetric.type === 'gauge'"
      >
        <!-- moved check here untill dsometric is empty when no data is available -->
        <dso-chart
          v-if="dsoMetric && dsoMetric.data.dataSet && (dsoMetric.businessUnit || dsoMetric.creditor)"
        />
        <div class="d-flex" v-if="successRateMetric">
          <success-chart
            v-for="(val, index) in successRateMetric.data"
            :value="val"
            :name="index"
            :key="index"
          />
        </div>
      </div>
      <div v-else>
        <dso-chart
          v-if="
            dsoMetric && dsoMetric.data.dataSet && (dsoMetric.businessUnit || dsoMetric.creditor)
          "
        />
        <div class="d-flex justify-content-around" v-if="successRateMetric">
          <success-chart
            v-for="(val, index) in successRateMetric.data"
            :value="val"
            :name="index"
            :key="index"
          />
        </div>
      </div>
    </div>
    <div class="card-body" v-else>
      <span class="text-center">{{ $t('datatable.no-records-found') }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Watch, Vue } from 'vue-property-decorator';
import { LoadingState, Metric } from '@/models';
import format from 'date-fns/format';
import DsoChart from '@/components/dashboard/Metrics/DsoChart.vue';
import SuccessChart from '@/components/dashboard/Metrics/SuccessChart.vue';
@Component({
  components: {
    DsoChart,
    SuccessChart,
  },
})
export default class Graphs extends Vue {
  private loading: LoadingState = true;

  private async created() {
    await this.loadData();
  }
  private async loadData() {
    await this.$store.dispatch('dashboard/loadMetrics');
    this.loading = false;
  }
  get dsoMetric() {
    if (this.$store.state.dashboard.metrics) {
      return this.$store.state.dashboard.metrics.dsoMetrics;
    }
  }
  get successRateMetric() {
    if (this.$store.state.dashboard.metrics) {
      return this.$store.state.dashboard.metrics.successRateMetrics;
    }
  }
}
</script>
