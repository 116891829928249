<template>
  <div class="card h-100">
    <slot name="header">
      <div class="card-head justify-between py-0 pr-0" v-if="!disableHeader">
        <slot name="title">
          <h2 class="h5">{{ shouldTranslateTitle ? $t(title) : title }}</h2>
        </slot>

        <div class="d-flex align-items-center">
          <slot name="subTitle">
            <span class="h5 mb-0 font-weight-bolder mr-3" v-if="subTitle" v-text="subTitle" />
          </slot>

          <slot name="actions">
            <div v-if="filteredActions">
              <router-link
                class="nav-link nav-link-right"
                v-for="action in filteredActions"
                :key="action.title"
                :title="$t(action.title)"
                :to="{
                  name: action.routeName,
                  params: action.routeParams,
                }"
              >
                <icon :name="action.icon" />
              </router-link>
            </div>
          </slot>
        </div>
      </div>
    </slot>
    <div class="card-body" v-if="!disableBody">
      <slot name="body"/>
    </div>
  </div>
</template>

<script lang='ts'>
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action } from '@/debtorportal/models/CardAction';
import { LoadingState } from '@/models';

@Component({})
export default class Card extends Vue {
  @Prop({ default: false }) private loading!: LoadingState;
  @Prop({ default: false }) private disableHeader!: boolean;
  @Prop({ default: false }) private disableBody!: boolean;
  @Prop({ default: '' }) private title!: string;
  @Prop({}) private subTitle?: string;
  @Prop({ default: false }) private shouldTranslateTitle!: boolean;
  @Prop() private actions?: Action[];

  private get filteredActions() {
    return this.actions ? this.actions.filter(action => action.condition !== false) : null;
  }
}
</script>
