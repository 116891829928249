<template>
  <div>
    <h1>Not Found</h1>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class NotFound extends Vue {
}
</script>

<style scoped lang="scss">

</style>
