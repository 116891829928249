<template>
  <div class="btn-group" role="group">
    <button
      v-for="option in options"
      :class="[
        'btn',
        'btn-sm',
        {
          'btn-primary': value === option,
          'btn-dark': value !== option,
        }
      ]"
      @click.prevent="$emit('input', option)"
    >
      {{ option }}
    </button>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class Switcher extends Vue {
  @Prop({type: [Array], required: true})
  private options!: string[];
  @Prop({type: [String], required: true})
  private value!: string;
}
</script>

<style scoped lang="scss">

</style>
