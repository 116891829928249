export default class TristateItem {
  public uuid: string;
  public label: string;
  public value: string = 'empty';

  private states = ['allow', 'deny', 'empty'];

  constructor(uuid: string, label: string, value: string) {
    this.uuid = uuid;
    this.label = label;
    this.value = value;
  }

  public nextState() {
    const currentState = this.states.findIndex(value => value === this.value);

    if (currentState < 0 || currentState + 1 >= this.states.length) {
      return (this.value = this.states[0]);
    }
    this.value = this.states[currentState + 1];
  }
  public setState(state: string) {
    if (!this.states.includes(state)) {
      return console.error(`State ${state} is not a valid option`);
    }

    this.value = state;
  }
}
