import { Vue } from 'vue-property-decorator';
import { JSONSchema4 } from 'json-schema';
import isEmpty from 'lodash/isEmpty';
import { AxiosResponse } from 'axios';
import { LoadingState } from '@/models';
import api from '@/api';
import WithRender from '@/templates/form.html';

import { Component } from 'vue-property-decorator';
import { EventBus } from '@/services/EventBus';

@WithRender
@Component
export class FormComponent extends Vue {
  protected schema: JSONSchema4 | null = null;
  protected formIdentifier: string | null = null;
  protected loading: LoadingState = false;
  protected errors: object | null = null;
  protected token: string | null = null;
  protected model: any; // TODO: init empty object = {}
  protected endpoint!: string;
  protected postEndpoint: string | null = null;
  protected authToken: string | null = null;
  protected reloadAfterSubmit: boolean = true;

  protected async loadData() {
    if (!this.endpoint) {
      return;
    }
    if (this.authToken) {
      api.defaults.headers.common.Authorization = `Bearer ${this.authToken}`;
    }
    this.loading = true;

    await api.get(this.endpoint)
      .then(res => {
        if (res.data?.form?.schema) {
          this.setData(res.data.form);
          this.loading = false;
        }
        this.afterLoad(res);
      }).catch(() => {
        this.loading = 'error';
      });
  }

  protected setData(form: any) {
    this.schema = form.schema;
    this.formIdentifier = form.schema.title;
    if (form.csrf_token) {
      this.token = form.csrf_token.value;
    }
    this.errors = form.errors;
    if (isEmpty(this.errors)) {
      this.model = form.values;
    }
  }
  protected redirectToUuid(view: string, params: any) {
    this.$router.push({
      name: view,
      params,
    });
  }
  protected clearData() {
    this.schema = null;
    this.formIdentifier = null;
    this.token = null;
    this.errors = null;
    this.model = {};
  }

  /* tslint:disable */
  protected afterLoad(response: AxiosResponse) {}
  /* tslint:enable */

  protected async submit() {
    if (this.loading) {
      return;
    }

    this.loading = true;
    this.model._token = this.token;
    let data: FormData;
    if (!this.formIdentifier) {
      return;
    }
    data = this.convertJSONToFormData(this.model, new FormData(), this.formIdentifier);

    // Add the formIdentifier if the formdata object is empty to pass symfony's isSubmitted check.
    if(data.entries().next().done) {
      data.append(this.formIdentifier, '');
    }

    try {
      const res = await api.post(this.postEndpoint || this.endpoint, data);

      if (this.submitSuccessful(res)) {
        await this.handleSubmitSuccessful(res);
      } else {
        this.handleSubmitFailed(res);
      }
    } catch (err) {
      console.error({ err });
      this.loading = 'error';
    } finally {
      this.loading = false;
    }
  }
  protected submitSuccessful(res: AxiosResponse<any>) {
    return res.data.success !== false && !this.formHasErrors(res);
  }

  protected formHasErrors(res: AxiosResponse<any>) {
    return res.data.form?.errors;
  }

  protected async handleSubmitSuccessful(res: AxiosResponse<any>) {
    EventBus.$emit('form.submit.successful');
    this.loading = false;
    if (this.reloadAfterSubmit) {
      await this.loadData();
    }
    this.afterSubmit(true, res);
  }

  protected handleSubmitFailed(res: AxiosResponse<any>) {
    if (this.formHasErrors(res)) {
      this.showToast(res.data.form.errors.message);
      this.setData(res.data.form);
    }
    this.loading = 'error';
    this.afterSubmit(false, res);
  }

  protected showToast(message?: string) {
      return message && this.$toasted.error(message);
  }

  protected afterSubmit(success: boolean, response: AxiosResponse) {
    if (success && this.schema) {
      this.$toasted.success(this.$t(this.schema.title + '.success') as string);
      const meta = this.$route.meta;
      if (meta?.rightBarCloseGoTo) {
        this.$router.push({
          name: meta?.rightBarCloseGoTo,
          params: this.$route.params,
        });
      }
      if (meta?.modal && meta?.modal.closeGoTo) {
        this.$router.push({
          name: meta?.rightBarCloseGoTo,
          params: this.$route.params,
        });
      }
      if (meta?.gotoAfterSubmit && this.$route.name !== meta?.gotoAfterSubmit) {
        this.$router.push({
          name: meta?.gotoAfterSubmit,
          params: this.$route.params,
        });
      }
    }
  }

  protected convertJSONToFormData(data: any, form: FormData, namespace = ''): FormData
  {
    if (data === false || data === null) {
      return form;
    }

    /**
     * if data is a File, we are done and it should just be appended.
     */
    if (data instanceof File) {
      form.append(namespace, data);
      return form;
    }

    if (data instanceof Date) {
      form.append(namespace, data.toISOString());
      return form;
    }

    if (data instanceof Array) {
      data.forEach((element: any, index: number) => {
        this.convertJSONToFormData(element, form, `${namespace}[${index}]`);
      });

      return form;
    }

    // if data is an object, we remove all falsely and nulled children.
    if (typeof data === 'object') {
      Object.keys(data).forEach(propertyName => {
        const formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
        return this.convertJSONToFormData(data[propertyName], form, formKey);
      });
      return form;
    }

    form.append(namespace, data);
    return form;
  }
}
