<template>
  <div class="card pb-0 mb-3" :style="{'paddingBottom':'0 !important'}">
    <div class="card-head justify-content-between py-0 pr-0">
      <h2 class="h5">{{ $t(this.entity+'.'+this.property+".header") }}</h2>
      <router-link
        :to="{ name: `${routeContext}.property.create`, params:{property: this.property} }"
        class="nav-link nav-link-right"
      >
        <icon class="icon-plus" name="plus"/>
      </router-link>
    </div>
    <div class="card-body">
      <data-table
        :model="this.property"
        @action="doAction"
        :endpoint="endpoint"
        :ref="`${property}List`"
        :route-context="routeContext"
        :route-params="{propertyId:{key:'uuid'}, property}"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { EntityListComponent } from '@/mixins/EntityListComponent';

@Component({})
export default class EntityList extends mixins(EntityListComponent) {

  protected get routeContext() {
    return this.entity === 'creditor' ? 'settings.creditor.view' : 'debtor.info';
  }
}
</script>

<style scoped lang="scss">
</style>
