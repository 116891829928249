<template>
  <div class="d-flex align-items-center">
    <input
      :disabled="disabled"
      class="form-control"
      :value="value"
      ref="datePicker"
      type="date"
      :placeholder="placeholder"
    />
    <div v-if="!disabled" class="position-absolute pin-r mr-1">
      <a v-if="value" @click="onClear">
        <icon class="text-muted" name="times" />
      </a>
      <a @click="flatpickr.open()" v-else>
        <icon class="text-muted" name="calendar-alt" size="lg" />
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import flatpickr from 'flatpickr';
import 'flatpickr/dist/themes/airbnb.css';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import l10n from 'flatpickr/dist/l10n';
import { scrollPlugin } from './ScrollPlugin';
import Cleave from 'cleave.js';
import { Instance as FlatpickrInstance } from 'flatpickr/dist/types/instance';
import { EventBus } from '@/services/EventBus';
import { key as LocaleKey } from 'flatpickr/dist/types/locale';

@Component
export default class DateTimepicker extends Vue {
  @Prop()
  private value!: string;
  @Prop({ default: '' })
  private placeholder!: string;
  @Prop({ default: false })
  private disabled!: boolean;
  @Prop({ default: 'Y-m-d H:i:S' })
  private dateFormat!: string;
  @Prop({ default: false })
  private twelveHourTime!: boolean;
  @Prop({ default: false })
  private enableTime!: boolean;
  @Prop({ default: false })
  private enableSeconds!: boolean;
  @Prop({ default: 0 })
  private firstDayOfWeek!: number;
  @Prop({ default: false })
  private noCalendar!: boolean;
  @Prop({ default: 'd-m-Y' })
  private altFormat!: string;
  @Prop({ default: false })
  private static!: boolean;
  @Prop({ default: true })
  private allowInput!: boolean;
  private flatpickr!: FlatpickrInstance;
  private inputMask: Cleave|null = null;

  private mounted() {
    this.$nextTick(() => {
      this.flatpickr = flatpickr(this.$refs.datePicker, {
        defaultDate: this.value,
        allowInput: this.allowInput,
        enableTime: this.enableTime,
        disabled: this.disabled,
        enableSeconds: this.enableSeconds,
        onValueUpdate: this.onValueUpdate,
        noCalendar: this.noCalendar,
        dateFormat: this.dateFormat,
        locale: this.locale,
        static: this.static,
        altInput: true,
        altFormat: this.altFormat,
        time_24hr: !this.twelveHourTime,
        plugins: [scrollPlugin],
        onReady: (selectedDates: Date[], dateStr: string, instance: FlatpickrInstance) =>
          this.createInputMask(instance),
      });
      EventBus.$on('container-scrolled', () => this.flatpickr.close());
    });
  }

  private createInputMask(instance: FlatpickrInstance) {
    if (this.altFormat !== 'H:i') {
      this.inputMask= new Cleave((instance.altInput as HTMLElement), {
        date: true,
        datePattern: this.altFormat.split('-'),
        delimiter: '-',
      });
    }
  }

  private beforeDestroy() {
    this.inputMask?.destroy();
    this.flatpickr.destroy();
  }

  private onClear(event: Event) {
    this.flatpickr.clear();
    this.$emit('clear');
  }

  private onValueUpdate(event: Event) {
    this.$emit('change', this.flatpickr.input.value);
  }

  private get locale() {
    const lc: LocaleKey = this.$store.state.i18n.locale.split('_')[0];
    return l10n[lc];
  }
}
</script>

<style lang="scss" scoped>
.\!cursor-not-allowed {
  cursor: not-allowed !important;
}
</style>

<style>
.flatpickr-wrapper {
  width: 100%;
}

.flatpickr-calendar.open {
  z-index: 30 !important;
}
</style>
