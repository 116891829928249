<template>
  <div>
    <button class="btn btn-sm mr-2 my-1 btn-light" @click.prevent="updateSelection(true)">
      {{ $t('selection.select_all') }}
    </button>
    <button class="btn btn-sm mr-2 my-1 btn-light" @click.prevent="updateSelection(false)">
      {{ $t('selection.deselect_all') }}
    </button>
    <span class="text-sm my-1">{{ $t('selection.item_count', [count]) }}</span>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class BulkSelection extends Vue {
  @Prop({ required: true })
  private count!: number;

  private updateSelection(select: boolean) {
    this.$emit(select ? 'selectAll' : 'deselectAll');
  }
}
</script>

<style scoped lang="scss">
</style>
