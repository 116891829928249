import { AxiosResponse } from 'axios';
import { LoadingState } from '@/models/LoadingState';
import { Module } from 'vuex';
import { RootState } from '@/models';
import api from '@/api';
import defaultLogo from '@/assets/img/logo.jpg';
import { merge } from 'lodash';
import { storage } from '@/services/LocalStorage';

export const platform: Module<PlatformState, RootState> = {
  namespaced: true,
  state: {
    loading: false,
    defaultLogo: false,
    overrideTitle: false,
    name: '',
    logo: storage.get<string>('platform.logo') ?? false,
    favicon: storage.get<string>('platform.favicon') ?? null,
    primaryColor: storage.get<string>('platform.primary_color') ?? '#025fdf',
    secondaryColor: storage.get<string>('platform.secondary_color') ?? '#8ede02',
    debtorportal: {
      contactUrl: '',
      allowTokenRegeneration: true,
      allowRegistration: true,
    },
    styling: storage.get<CustomStyling>('platform.styling') ?? {
      app: {},
      debtorportal: {
        backdrop: {},
        payment: {
          button: {},
        },
      },
      dashboard: {
        actions: {
          button: {},
        },
        disputes: {
          button: {},
        },
        mailbox: {
          button: {},
        },
        batch: {
          button: {},
        },
      },
      topbar: {
        item: {},
        badge: {
          actions: {},
          disputes: {},
        },
      },
      sidebar: {
        active: {},
        badge: {
          active: {},
        },
      },
    },
  },
  actions: {
    async loadData({ commit }) {
      commit('setLoading', true);
      await api.get('/app/platform').then(({data}: AxiosResponse<PlatformDataModel>) => {
        commit('setValues', data);
        commit('storeValues', data);
      });

      commit('setLoading', false);
    },
    async setLogo({ commit, dispatch }, logo: string) {
      commit('setLoading', true);

      commit('setLogo', logo);

      commit('setLoading', false);
    },
  },
  mutations: {
    setLoading(state, loading: boolean) {
      state.loading = loading;
    },
    setLogo(state, logo: string) {
      state.logo = logo;
    },
    setValues(state: PlatformState, payload: PlatformDataModel) {
      state.name = payload.name;
      state.debtorportal.contactUrl = payload.settings.invalid_debtor_token_redirect;
      state.defaultLogo = payload.platform_style.defaultLogo;
      state.overrideTitle = payload.platform_style.override_title ?? false;
      state.logo = payload.platform_style.logo;
      state.favicon = payload.platform_style.favicon;
      state.primaryColor = payload.platform_style.primary_color;
      state.secondaryColor = payload.platform_style.secondary_color;
      state.styling = merge(state.styling, payload.platform_style.styling);
    },
    storeValues(state: PlatformState, data: PlatformDataModel) {
      storage.save('platform.name', data.name);
      storage.save('platform.defaultLogo', data.platform_style.defaultLogo);
      storage.save('platform.logo', data.platform_style.logo);
      storage.save('platform.favicon', data.platform_style.favicon);
      storage.save('platform.primary_color', data.platform_style.primary_color);
      storage.save('platform.secondary_color', data.platform_style.secondary_color);
      storage.save('platform.styling', merge(state.styling, data.platform_style.styling));
    },
  },
  getters: {
    debtorportal: state => state.styling.debtorportal,
    cssProps: state => {
      return {
        '--primary-color': state.primaryColor,
        '--secondary-color': state.secondaryColor,
        '--secondary-color-lightest': state.secondaryColor + '11',
        '--secondary-color-lighter': state.secondaryColor + '88',
        '--secondary-color-light': state.secondaryColor + 'CC',
        '--primary-color-lightest': state.primaryColor + '11',
        '--primary-color-lighter': state.primaryColor + '88',
        '--primary-color-light': state.primaryColor + 'CC',
      };
    },
    logo: state => {
      if (state.loading) {
        return;
      }

      if (state.logo && !state.defaultLogo) {
        return `${process.env.VUE_APP_API_HOST}${state.logo}`;
      }

      return defaultLogo;
    },
  },
};

export interface PlatformState {
  loading: LoadingState;
  name: string;
  primaryColor: string;
  secondaryColor: string;
  overrideTitle: boolean;
  defaultLogo: boolean;
  logo: string | boolean;
  favicon: string | null;
  styling: CustomStyling;
  debtorportal: {
    contactUrl: string|null;
    allowTokenRegeneration: boolean;
    allowRegistration: boolean;
  }
}

interface PlatformDataModel {
  name: string;
  settings: PlatformSettings;
  platform_style: PlatformStyles;
}

interface PlatformSettings {
  invalid_debtor_token_redirect: string | null;
}

interface PlatformStyles {
  primary_color: string;
  secondary_color: string;
  defaultLogo: boolean;
  override_title?: boolean;
  logo: string | boolean;
  favicon: string | null;
  styling: CustomStyling;
}

interface CustomStyling {
  [key: string]: CustomStyling | string;
}
