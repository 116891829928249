var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"btn-group",attrs:{"role":"group"}},_vm._l((_vm.options),function(option){return _c('button',{class:[
      'btn',
      'btn-sm',
      {
        'btn-primary': _vm.value === option,
        'btn-dark': _vm.value !== option,
      }
    ],on:{"click":function($event){$event.preventDefault();return _vm.$emit('input', option)}}},[_vm._v(" "+_vm._s(option)+" ")])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }