<template>
  <ChangePassword />
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import ChangePassword from '@/components/auth/ChangePassword.vue';

@Component({
  components: {
    ChangePassword,
  },
})
export default class SettingsPassword extends Vue {}
</script>

<style lang="scss" scoped>
</style>
