<template>
  <div>
    <warnings v-bind="{ warnings, model }" />
    <div class="card">
      <div class="card-body">
        <data-table
          :key="$route.params.type"
          ref="debtorGroupReceivableList"
          :endpoint="endpoint"
          :model="model"
          :route-context="'debtor.group'"
          :emitable-record-keys="['type']"
          :route-params="{ type: $route.params.type }"
          :disable-pagination="isDebtorView"
          :disable-search-fields="isDebtorView"
          :disable-sort="isDebtorView"
          :enableBulkSelection="true"
          :disable-column-options="isDebtorView"
          :initial-limit="isDebtorView ? limit : false"
        >
          <template #nested-table="{ record, opened}">
            <receivable-interest-cost
              v-if="opened.includes(record.uuid)"
              :stringArray='record.suggestions'
              :invoice-uuid='record.uuid'/>
          </template>
        </data-table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ReceivableListComponent } from '@/mixins/ReceivableListComponent';
import Warnings from '@/components/global/Warnings.vue';
import ReceivableInterestCost from '@/components/views/receivables/ReceivableInterestCost.vue';
import { Dictionary, EmitterAction } from '@/models';
import ReceivableView from '@/views/debtor/receivable/View.vue';
import ReceivableForm from '@/views/debtor/receivable/Edit.vue';
import SettlementPlanForm from '@/views/debtor/settlementplan/Edit.vue';
import { ListComponent } from '@/mixins/ListComponent';

@Component({
  components: {
    ReceivableInterestCost,
    Warnings
  },
})
export default class DebtorGroupReceivableList extends mixins(ListComponent) {
  private types: string[] = ['invoice','cost', 'payment', 'creditnote', 'imported-payment'];
  private limit: number = 5;
  protected mounted() {
    if(!this.isDebtorView && !this.types.includes(this.$route.params.type)) {
      this.setDefaultType();
    }
  }

  private setDefaultType() {
    const params = this.$route.params;
    const type = this.types[0];
    this.$router.replace({
      params: {...params, type},
    });
  }

  protected get endpoint() {
    // if type is set and is valid option then return the type endpoint else return model (invoice)
    const type = ['payment', 'cost', 'creditnote', 'invoice', 'imported-payment'].includes(this.type) ? this.type : this.model;
    return `/debtor/group/${this.$route.params.groupId}/${this.isDebtorView ? 'receivable' : type}`;
  }

  protected get isDebtorView() {
    return this.$route.name?.indexOf('debtor.group.view') !== -1;
  }

  protected get type() {
    return this.$route.params.type?.toLowerCase();
  }
}
</script>

<style scoped lang="scss">
.nav-link {
  padding: 1rem 2rem;
  font-size: 1.25em;
}
</style>
