<template>
  <div>
    <loader :loading="loading" />
    <template v-if="!loading || schema">
      <form @submit.prevent="submit" class="form" v-if="schema" :ref="schema.title">
        <json-form :schema="schema" v-model="model" :errors="errors" :labels="false" />
        <button class="btn btn-primary">
          <icon prefix='fas' name="save" class="mr-2" />
          {{ $t('assign') }}
        </button>
      </form>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { FormComponent } from '@/mixins/FormComponent';
import { AxiosResponse } from 'axios';
import api from '@/api';
import { EventBus } from '@/services/EventBus';

@Component({})
export default class ImportedPaymentAssign extends mixins(FormComponent) {
  protected model: any = {};

  private created() {
    this.setEndpoint();
    this.loadData();
  }

  protected afterSubmit(success: boolean, response: AxiosResponse) {
    EventBus.$emit('imported_payment.assign.reload');
  }

  @Watch('rightBarProperties')
  protected setEndpoint() {
    const receivableUuid = this.rightBarProperties.receivable?.uuid;
    const importedPaymentUuid = this.rightBarProperties.importedPayment?.uuid;
    const onHold = this.rightBarProperties.onHold;
    if (!receivableUuid || !importedPaymentUuid) {
      return;
    }
    if(onHold) {
      this.endpoint = `/imported_payment/on-hold/${importedPaymentUuid}/invoice/${receivableUuid}/assign`;
    } else {
      this.endpoint = `/imported_payment/payments/${importedPaymentUuid}/invoice/${receivableUuid}/assign`;
    }

  }

  public async loadData(){
    if (!this.endpoint) {
      return;
    }
    if (this.authToken) {
      api.defaults.headers.common.Authorization = `Bearer ${this.authToken}`;
    }
    this.loading = true;

    await api.get(this.endpoint)
      .then(res => {
        if (res.data?.form?.schema) {
          this.setData(res.data.form);
          this.loading = false;
        }
        this.afterLoad(res);
      }).catch(() => {
        this.loading = 'error';
      });
  }

  private get rightBarProperties() {
    return this.$store.state.bars.rightBarProperties;
  }
}
</script>
