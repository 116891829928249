<template>
  <div class="d-inline-flex align-items-center mb-4">
    <div class="entity-title d-flex align-items-center text-muted font-semibold text-xl rounded-pill py-2 px-3">
      <span v-text="debtorName" />
      <icon name="bookmark" prefix="fas" class="entity-status ml-1" :title="$t(`debtors.overview.graydon.code`)"
            :class="entityColor" />
    </div>
    <div
      @click="downloadReport"
      class="p-2 ml-1 text-muted entity-button cursor-pointer">
      <icon name="download" prefix="fas" size="lg" />
    </div>
    <div class="p-2 text-muted cursor-pointer entity-button" @click="sync">
      <icon name="redo" prefix="fas" size="lg" />
      <span v-if="lastSyncDate" class="text-muted hidden-md ml-2">last sync: {{ lastSyncDate | datetime }}</span>
    </div>
  </div>
</template>

<script lang='ts'>
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class EntityInfo extends Vue {
  @Prop({ required: true }) private entityColor!: string;
  @Prop() private lastSyncDate ?: string;

  private downloadReport() {
    this.$emit('onDownload');
  }

  private sync() {
    this.$emit('onSync');
  }

  private get debtorName(): string {
    return this.$store.state.debtor.currentDebtor?.display_name;
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.entity-title {
  background-color: rgba(#000000, .04);

  .entity-status {
    transition: background-color 0.2s ease-in;
  }
}

.entity-button {
  border-radius: 999px;
  transition: background-color 0.2s ease-in;

  &:hover {
    background-color: rgba(#000000, .04);
  }
}
</style>
