<template>
  <div class="search-field">
    <label v-if='label === true'>{{ $t(this.placeholder) }}</label>
    <input
      :value="value"
      @input="setValue($event.target.value)"
      class="form-control"
      :placeholder="$t(this.placeholder)"
    />
    <icon name="search" class="text-muted" size="lg" />
  </div>
</template>

<script lang="ts">

import { Component, Prop, Vue } from 'vue-property-decorator';
import { DEFAULT_DEBOUNCE_DELAY } from '@/plugins/data-table/constants';
import { debounce } from 'lodash';
@Component({})
export default class CurrencyFilter extends Vue {
  @Prop({required: false, default: ''})
  private initialValue!: string;
  @Prop({required: false, default: 'datatable.search.string.placeholder'})
  private placeholder!: string;
  @Prop({required: false, default: false})
  private label!: boolean;


  private currentValue: string | null = null;
  private formattedValue: string | null = null;

  private update = debounce(() => this.emitUpdate(), DEFAULT_DEBOUNCE_DELAY);

  private created() {
    this.currentValue = this.initialValue;
  }
  private get value() {
    return this.currentValue;
  }

  private setValue(input: string) {
    //format the currency here to normal numbers
    var number = this.priceToNumber(input);
    this.formattedValue = String(number);

    this.update();
  }

  private emitUpdate() {
    this.$emit('update', this.formattedValue);
  }

  private priceToNumber(v: string){
    if(!v){return 0;}
    v=v.split('.').join(',');
    v=v.split(',').join('.');
    return Number(v.replace(/[^0-9.]/g, ""));
  }
}
</script>

<style scoped lang="scss">

</style>
