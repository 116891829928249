<template>
  <div class="card h-100 mb-3">
    <loader :loading="loading" />
    <div class="card-head justify-between py-0 pr-0">
      <h2 class="h5">
        <span>
          {{ $t('debtor.group.overview.info.title') }}
        </span>
      </h2>
    </div>
    <div class="card-body" v-if="debtor">
      <div class="d-flex flex-row justify-content-between">
        <div class="d-flex">
          <span>{{ debtor.display_name }}</span>
        </div>
        <div>
          <p v-if="debtor.reference" class="text-lg font-weight-bold">
            {{ debtor.reference }}
          </p>
          <p v-if="debtor.segmentation_code" class="float-right">
            {{ debtor.segmentation_code }}
          </p>
        </div>
      </div>
      <p v-if="defaultAddress">
        <span v-if="defaultAddress.street">
          {{ defaultAddress.street }} {{ defaultAddress.house_number }} {{ defaultAddress.box_number }}
          <br />
          {{ defaultAddress.city }} {{ defaultAddress.postal_code }}
          <br />
          {{ defaultAddress.country }}
        </span>
      </p>
      <div v-if="debtor.ssn" class="d-flex flex-column">
        <span>{{ $t('debtors.ssn') }}: {{ debtor.ssn }}</span>
      </div>
      <div v-if="userOtherBusinessUnits.length" class="d-flex flex-column">
        <span>{{ $t('debtor_in_other_business_units') }}</span>
        <router-link
          v-for="item in userOtherBusinessUnits"
          :to="{ name: 'debtor.view', params: { debtorId: item.uuid } }"
          :key="item.uuid"
        >{{ item.business_unit.name }}</router-link
        >
      </div>
      <div v-if="defaultContact" class="d-flex flex-column">
        <span v-if="defaultContact.telephone">
          {{ $t('debtors.overview.info.phone') }}: {{ defaultContact.telephone }}
        </span>
        <span v-if="defaultContact.mobile">
          {{ $t('debtors.overview.info.mobile') }}: {{ defaultContact.mobile }}
        </span>
        <span v-if="defaultContact.email">
          {{ $t('debtors.overview.info.email') }}:
          <a v-bind:href="'mailto:' + defaultContact.email">{{ defaultContact.email }}</a>
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { Address, Contact, Debtor, DebtorUserInfoModel, UserOtherBusinessUnits } from '@/models';
import { API } from '@/mixins/API';
import { EventBus } from '@/services/EventBus';
import { Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class DebtorGroupInfo extends mixins(API) {
  private userInfo: DebtorUserInfoModel | null = null;
  private handlingUserEvent: boolean = false;
  private userOtherBusinessUnits: UserOtherBusinessUnits | [] = [];
  private debtor: Debtor | null = null;

  private async created() {
    await this.loadGroup();
  }

  private async loadGroup() {
    const res = await this.get(`/debtor/group/${this.$route.params.groupId}/contact_info`);
    this.userOtherBusinessUnits = res.otherBusinessUnits;
    this.debtor = res.group_master;
  }

  get defaultContact(): Contact | undefined {
    if (this.debtor) {
      return this.debtor.defaultContact || undefined;
    }
    return undefined;
  }

  get defaultAddress(): Address | undefined {
    if (this.debtor) {
      return this.debtor.defaultAddress || undefined;
    }
    return undefined;
  }

  private get canManageDebtor(): boolean {
    return !!this.debtor?.acl.manage_debtors;
  }
}
</script>

<style scoped lang="scss">
.verified {
  color: var(--primary-color);
}

.not-verified {
  opacity: 0.3;
}
</style>
