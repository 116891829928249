<template>
  <div>
    <select
      class="form-control"
      :value="value"
      @input="setValue($event.target.value)"
    >
      <option :value="null">{{ $t('datatable.search.enum.placeholder') }}</option>
      <option
        v-for="(value, key) in choices.enum"
        :value="value"
        :key="value"
      >{{ choices.enum_titles[key] || value }}</option
      >
    </select>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ColumnContext } from '@/models/ColumnContext';
import { EventBus } from '@/services/EventBus';

interface Choices {
  enum: {[id: string]: string};
  enum_titles: {[id: string]: string};
}

@Component({})
export default class ChoiceFilter extends Vue {
  @Prop({required: true})
  private choices!: Choices;

  @Prop({required: false, default: ''})
  private initialValue!: string;

  @Prop({required: false, default: 'default'})
  private routeContext!: string;

  @Prop({required: false})
  private column!: ColumnContext;

  private currentValue: string | null = null;


  public setValue(input: string) {
    this.currentValue = input;
    this.emitUpdate();
  }

  private beforeDestroy() {
    if(this.routeContext) {
      EventBus.$off(`datatable.${this.routeContext}.filter.set`);
      EventBus.$off(`datatable.${this.routeContext}.filter.clear`);
    }
  }

  private created() {
    this.currentValue = this.initialValue;
    if(this.routeContext) {
      EventBus.$on(`datatable.${this.routeContext}.filter.set`, this.updateFilter);
      EventBus.$on(`datatable.${this.routeContext}.filters.clear`, () => this.currentValue = '');
    }
  }

  private updateFilter(name: string, value: string, shouldPersist: boolean = false) {
    if(this.column && this.column.name === name) {
      this.currentValue = value;
    }
  }

  private get value() {
    return this.currentValue;
  }

  private emitUpdate() {
    this.$emit('update', this.currentValue);
  }
}
</script>

<style scoped lang="scss">

</style>
