<template>
  <nav class="titlebar navbar navbar-light">
    <div class="navbar-nav w-100 h-100">
      <div class="d-flex align-items-center justify-content-between h-100">
        <div class="d-flex align-items-center">
          <a
            class="nav-item nav-link position-relative"
            :class="{'modification-indicator': hasActiveFilters}"
            :href="$route.path"
            v-if="$route.meta?.filterBar"
            @click.prevent="toggleFilter"
          >
            <icon :name="$route.meta?.filterIcon" size="lg" />
          </a>
          <div
            v-for="(crumb, i) in crumbtrail"
            :key="i"
            class="mb-0 h3"
            :class="{ 'pl-3': i === 0, 'text-muted': i < crumbtrail.length - 1 }"
          >
            <router-link v-if="crumb.routeName" :to="{ name: crumb.routeName, params: {} }">{{
              crumb.title
            }}</router-link>
            <span v-else>{{ crumb.title }}</span>
            <span v-if="i < crumbtrail.length - 1" class="ml-2 mr-2 text-muted">&raquo;</span>
          </div>
        </div>
        <div class="d-flex h-100">
          <router-link
            :to="{
              name: action.name,
              params: Object.assign({}, $route.params, action && action.params),
            }"
            :class="{ 'nav-item nav-link nav-link-right': true, active: action.name === $route.name }"
            v-for="action in actions"
            :title="$t(action.name + '.title')"
            :key="action.name"
          >
            <icon v-if='action.icon' :name="action.icon" size="lg" />
            <span v-else>{{ $t(action.name + '.title') }}</span>
          </router-link>
          <a
            v-if="$store.state.auth.acl.functionality_setting.debtor_group && $route.name === 'debtor.view' && existsInOtherBusinessUnits.length"
            @click.prevent="fetchGroupUuid"
            :class="{ 'nav-item nav-link nav-link-right': true }"
            :title="$t('debtor.group.view.title')"
            :key="'debtor.group.view'"
          >
            <icon name="users" size="lg" />
          </a>
          <router-link
            v-if="$store.state.auth.acl.functionality_setting.debtor_group && $route.name === 'debtor.group.view'"
            :to="{
              name: 'debtor.view',
              params: {
                debtorId: debtorUuid
              },
            }"
            :class="{ 'nav-item nav-link nav-link-right': true }"
            :title="$t('debtor.view.title')"
            :key="'debtor.view'"
          >
            <icon name="user" size="lg" />
          </router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { hasRoute } from '@/router';
import { storage } from '@/services/LocalStorage';
import isEmpty from 'lodash/isEmpty';
import api from '@/api';

interface Action {
  name: string;
  icon?: string;
  params?: object;
}
@Component({})
export default class Title extends Vue {
  protected crumbs!: string[];
  protected prevCrumbs!: string[];
  protected groupUuid: string = '';
  get crumbtrail() {
    let crumbtrail = [];
    const { tab, type } = this.$route.params;
    if (this.$route.name && this.$route.name.indexOf('settings') !== -1) {
      this.crumbs = this.$route.name.split('.');
      this.crumbs = this.crumbs.map(crumb =>
        crumb !== 'template' && this.$route.params[crumb] ? this.$route.params[crumb] : crumb,
      );
      crumbtrail = this.crumbs.map((crumb, index) => {
        let title = this.$t(this.$route.meta?.title);
        if (!title) {
          const param = this.$route.params[crumb];
          title = param ? this.$t(param) : this.$t(crumb);
        }
        let routeName: string | boolean = false;
        if (this.crumbs.length > index + 1) {
          this.prevCrumbs = this.crumbs.slice(0, -(this.crumbs.length - (index + 1)));
          if (hasRoute(this.prevCrumbs.join('.'))) {
            routeName = this.prevCrumbs.join('.');
          }
        }
        this.prevCrumbs = this.crumbs;
        return {
          routeName,
          title,
        };
      });
      if (this.activeEntity) {
        crumbtrail[0] = { routeName: 'settings', title: this.activeEntity.display_name };
      }
    } else if (this.$store.state.debtor.currentDebtor && this.isDebtorView) {
      crumbtrail[0] = {
        routeName: 'debtor.view',
        routeParams: { debtorId: this.$store.state.debtor.uuid },
        title: this.$store.state.debtor.currentDebtor.display_name,
      };
      crumbtrail[1] = { routeName: false, title: this.$t(this.$route.meta?.title) };
    } else {
      crumbtrail[0] = { routeName: false, title: this.$t(this.$route.meta?.title) };
    }
    return crumbtrail;
  }
  get actions(): Action[] {
    return this.$route.meta?.actions;
  }
  get activeEntity() {
    return this.$store.state.auth.user?.active_entity;
  }
  get hasActiveFilters() {
    if (this.$route.meta?.filterBar?.name === 'DashboardFilterBar') {
      return this.$store.state.dashboard.data?.hasFilters;
    } else if(this.$route.meta?.filterBar?.name === 'ImportedPaymentFilterBar') {
      return !isEmpty(storage.get('imported_payment_filters'));
    }
  }
  private toggleFilter() {
    this.$emit('filter-toggle');
    this.$store.dispatch('bars/toggleBar', 'filterBar');
  }
  protected get routeModel() {
    return this.$route.meta?.model;
  }
  protected get isDebtorView() {
    const routeName = this.$route.name;
    return routeName?.indexOf('debtor') !== -1 && routeName !== 'debtor.index';
  }

  protected async fetchGroupUuid() {
    let debtor = this.$store.state.debtor.currentDebtor;
    let groupUuid = '';
    if (debtor) {
      groupUuid = debtor.group?.uuid;
      if (isEmpty(groupUuid)) {
        const res = await api.get(`debtor/group/${debtor.uuid}/dashboard`);
        if (res.data.uuid) {
          groupUuid = res.data.uuid;
        }
      }
    }
    if (groupUuid) {
      await this.$router.push({
        name: 'debtor.group.view',
        params: {
          groupId: groupUuid
        },
      });
    }
  }

  protected get existsInOtherBusinessUnits() {
    return this.$store.state.debtor?.currentDebtor?.otherBusinessUnits ? this.$store.state.debtor?.currentDebtor?.otherBusinessUnits : [];
  }
  protected get debtorUuid() {
    return this.$store.state.debtorGroup.currentDebtorGroup?.group_master.uuid;
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';
@import '~@/assets/scss/colors';

.modification-indicator:before {
  right: 20%;
}
.navbar-light {
  user-select: none;
  color: $text-color;
  background-color: $white;
  border-bottom: 1px solid $medium;
  min-height: $titlebar-height;
  max-height: $titlebar-height;
  a {
    color: inherit;
  }
}

.nav-item {
  border-left: 1px solid $medium;
}

.nav-item:hover {
  span {
    color: $vulcan !important;
  }
}

.nav-item {
  padding-left: 2rem;
  padding-right: 2rem;
}

h1 {
  color: $title-color;
}
</style>
