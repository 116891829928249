function tryParse(payLoad: string) {
  try {
    return JSON.parse(payLoad);
  }
  catch (e) {
    return payLoad;
  }
}

export interface ClientStorageInterface
{
  get<ReturnType = any>(key: string) : ReturnType | null;
  get<ReturnType = any>(key: string, defaultValue: ReturnType) : ReturnType;

  save<Type = any>(key: string, value: Type): void;

  remove(key: string): void;
}


export class LocalStorage implements ClientStorageInterface
{
  public get<ReturnType = any>(key: string) : ReturnType | null;
  public get<ReturnType = any>(key: string, defaultValue: ReturnType) : ReturnType;

  public get<ReturnType = any>(key: string, defaultValue?: ReturnType): ReturnType | null {
    const entry = window.localStorage.getItem(key);
    return entry ? tryParse(entry) : defaultValue;
  }

  public save<Type = any>(key: string, value: Type) {
    const data = typeof value === 'string' ? value : JSON.stringify(value);
    window.localStorage.setItem(key, data);
  }

  public remove(key: string) {
    window.localStorage.removeItem(key);
  }
}

export const storage = new LocalStorage();
