<template>
  <form @submit.prevent="submit" class="form-column">
    <loader :loading="loading" />
    <p>{{ $t('form.forgotpassword.intro') }}</p>
    <json-form v-if="schema" :schema="schema" :errors="errors" v-model="model" @submit="submit" />
    <button type="submit" class="mt-2 btn btn-primary btn-block">
      <Icon name="angle-right" />
      <span>{{ $t('form.forgotpassword.submit.label') }}</span>
    </button>
  </form>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { JSONSchema4 } from 'json-schema';
import { FormComponent } from '@/mixins/FormComponent';
import { mixins } from 'vue-class-component';

@Component({
  name: 'ForgotPasswordForm',
})
export default class Form extends mixins(FormComponent) {
  protected model: any = {
    first: '',
    second: '',
  };
  protected endpoint = '/user/forgotpassword';
  protected reloadAfterSubmit = false;

  protected created() {
    if (this.$route.query.token) {
      this.endpoint = '/user/password';
      this.authToken = this.$route.query.token as string;
    }
    this.loadData();
  }

  protected afterSubmit(success: boolean, response: any) {
    if (success && this.schema) {
      this.$toasted.success(this.$t(this.schema.title + '.success') as string);
      return this.$router.push({ name: 'auth.login' });
    }
    if (response.data.success === false) {
      const message = response.data.message || this.$t('login.unknown_error');
      this.$toasted.error(message);
    }
  }
}
</script>
