import { LoadingState } from '@/models/LoadingState';
import { Prop } from 'vue-property-decorator';
import { Action } from './CardAction';
import { Vue } from 'vue-property-decorator';
export class CardComponent extends Vue {
    protected shouldTranslateTitle: boolean = false;
    protected disableHeader: boolean = false;
    protected actions?: Action[];
    @Prop({default: () => new Object}) protected data: any;
    @Prop({default: false}) private loading!: LoadingState;
    protected get filteredActions() {
      return this.actions ? this.actions.filter(action => action.condition !== false) : null;
    }
}
