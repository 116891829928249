<template>
  <div class="card mb-3">
    <router-link class="card-head justify-between py-0 pr-0" :to="link || false">
      <h2 class="h5 card-title">{{title}}</h2>
      <span class="nav-link nav-link-right">
        <icon name="angle-right" size="lg"></icon>
      </span>
    </router-link>
    <div v-if="this.$slots.default" class="card-body">
      <slot/>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Location } from '@/models';

@Component({})
export default class Block extends Vue {
  @Prop() private title!: string;
  @Prop() private link!: Location;
  @Prop({
    required: false,
    default: '',
  })
  private text!: string;
}
</script>

<style lang="scss">
a.card-head > span.wrapper .svg-inline--fa.fa-fw {
  font-size: 4rem;
}
</style>
