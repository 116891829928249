<template>
  <div>
    <loader :loading="loading"></loader>
    <form @submit.prevent="setFilters" v-if="schema" class="form-column">
      <json-form :schema="schema" v-model="model" :errors="errors"/>
      <button type="submit" class="btn btn-primary btn-block">
        <icon name="filter"/>
        <span v-text="$t('filterbar.filter')"/>
      </button>
      <button type="reset" class="btn btn-dark btn-block mt-3" @click="handleReset">
        <icon name="times"/>
        <span v-text="$t('filterbar.reset')"/>
      </button>
    </form>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { FormComponent } from '@/mixins/FormComponent';
import { mixins } from 'vue-class-component';
import { ImportedPaymentFilterModel } from '@/models/ImportedPaymentFilterModel';
import { EventBus } from '@/services/EventBus';
import { storage } from '@/services/LocalStorage';
import isEmpty from 'lodash/isEmpty';

@Component({})
export default class ImportedPaymentFilterBar extends mixins(FormComponent)
{
  protected model: ImportedPaymentFilterModel | null = {};
  protected endpoint = '/imported_payment/filter';

  protected async created() {
      await this.loadAndSetFilter();
  }

  protected handleReset() {
    this.model = {
      businessUnits: undefined,
      fileUploads: undefined,
      certainty: undefined,
      startDate: undefined,
      endDate: undefined,
    }
    storage.save('imported_payment_filters', this.model);
    EventBus.$emit('imported_payment_filters', this.model);
    this.loadAndSetFilter();
  }

  protected async setFilters() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    if (!isEmpty(this.model)) {
      storage.save('imported_payment_filters', this.model);
      EventBus.$emit('imported_payment_filters', this.model);
    }

    this.loading = false;
    this.$store.dispatch('bars/closeBar', 'filterBar');
    await this.loadAndSetFilter();
  }

  protected async loadAndSetFilter() {
    await this.loadData()
    if (!isEmpty(storage.get('imported_payment_filters'))) {
      this.model = storage.get('imported_payment_filters')
    }
  }
}
</script>

<style scoped lang="scss">
.btn {
  font-size: 1.2rem;
}
.btn-light {
  color: #212529;
  &:hover {
    color: #212529;
  }
}
</style>
