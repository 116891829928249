<template>
  <div>
    <loader :loading="loading"/>
    <form @submit.prevent="submit" class="form" v-if="schema" :ref="schema.title">
      <json-form :schema="schema" v-model="model" :errors="errors"/>
      <button type="submit" class="btn btn-primary btn-sm w-100">
        <Icon prefix="fas" name="save" class="mr-2"/>
        {{ $t('save') }}
      </button>
    </form>
  </div>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator';
import { DebtorFormComponent } from '@/mixins/DebtorFormComponent';
import { mixins } from 'vue-class-component';
import api from '@/api';
import { AxiosResponse } from 'axios';
import { TemplateTranslation } from '@/models/TemplateTranslation';
import { EventBus } from '@/services/EventBus';

@Component({})
export default class DebtorCommunicationCreate extends mixins(DebtorFormComponent) {
  protected model: any = {};
  protected endpoint!: string;
  @Watch('model', { deep: true })
  protected async modelChanged(newModel: any, oldModel: any) {
    if (this.$route.params.type === 'letter') {
      return;
    }
    if (!this.$route.params.debtorId) {
      return;
    }
    const debtorId = this.$route.params.debtorId;

    let recipientName = 'recipient';
    if (newModel.contactTo) {
      recipientName = 'contactTo';
    }

    let newRecipient = newModel[recipientName];
    let oldRecipient = oldModel[recipientName];
    if (Array.isArray(newRecipient)) {
      if (!newRecipient.length) {
        newRecipient = undefined;
      } else {
        newRecipient = newRecipient[0];
      }
    }

    if (Array.isArray(oldRecipient)) {
      if (!oldRecipient.length) {
        oldRecipient = undefined;
      } else {
        oldRecipient = oldRecipient[0];
      }
    }

    if (!newRecipient || !newModel.template) {
      return;
    }

    if (newRecipient === oldRecipient && newModel.template === oldModel.template) {
      return;
    }

    const endpoint = `debtor/${debtorId}/template/${newModel.template}/contact/${newRecipient}/translation`;
    const result = await api.get<TemplateTranslation>(endpoint);
    if (result.data && result.status === 200) {
      this.updateTranslation(result.data);
    }
    if (result?.data.error) {
      this.$toasted.error(this.$t(result.data.error) as string);
    }
  }

  protected async handleSubmitSuccessful(res: AxiosResponse<any>) {
    EventBus.$emit('debtor.communication.reload');
    EventBus.$emit('form.submit.successful');
    this.loading = false;
    if (this.reloadAfterSubmit) {
      await this.loadData();
    }
    this.afterSubmit(true, res);
  }

  protected afterLoad(response: AxiosResponse) {
    const title: string = this.schema && this.schema.title ? this.schema.title : '';
    if (title) {
      this.$store.commit('bars/setRightBarTitle', this.$t(title + '.header'));
    }
    this.$store.commit('bars/setRightBarFormSubmit', this.submit);
  }

  private updateTranslation(templateTranslation: TemplateTranslation): void {
    this.model.content = templateTranslation.content;

    if (null != templateTranslation.subject && templateTranslation.subject.length > 0) {
      this.model.subject = templateTranslation.subject;
    }
  }
}
</script>

<style scoped lang="scss">
</style>
