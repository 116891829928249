<template>
  <div>
    <loader :loading="loading" />
    <form @submit.prevent="submit" class="form settings-form mb-3 h-100" v-if="schema">
      <div class="card">
        <div class="card-head justify-content-between py-0 pr-0">
          <h2 class="h5">{{ $t(`${schema.title}.header`) }}</h2>
          <button type="submit" class="nav-link nav-link-right">
            <Icon prefix="fas" name="save" class="mr-2" />
            {{ $t('save') }}
          </button>
        </div>
        <div class="card-body">
          <div class="mb-3 alert alert-danger" v-if="message">{{ $t(message) }}</div>
          <json-form :schema="schema" v-model="model" :errors="errors" />
          <div class="offset-sm-2 pl-1">
            <button  type="submit" class="btn btn-primary btn-sm w-100">
              <Icon prefix="fas" name="save" class="mr-2" />
              {{ $t('settings.save') }}
            </button>
          </div>
        </div>
      </div>
    </form>
    <Logos :logos="logos" @onLogoDeleted="removeLogo" />
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import Logos from './Logos.vue';
import { SettingsFormComponent } from '@/mixins/SettingsFormComponent';
import { mixins } from 'vue-class-component';
import { AxiosResponse } from 'axios';
import { Logo } from '@/models';

@Component({
  components: { Logos },
})
export default class BusinessUnitSettingsForm extends mixins(SettingsFormComponent) {
  protected message: string = '';
  protected logos: Logo[] = [];

  protected afterLoad(response: AxiosResponse) {
    this.logos = response.data?.logos ?? [];
    this.message = response.data?.message;
  }

  private removeLogo(uuid: string) {
    this.logos = this.logos.filter(logo => logo.uuid !== uuid);
  }
}
</script>
