var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('loader',{attrs:{"loading":_vm.loading}}),(_vm.isLoaded && typeof _vm.data !== 'boolean')?[_c('small',{staticClass:"text-muted mb2"},[_vm._v(" "+_vm._s(_vm.$t('task.processed_at'))+": "+_vm._s(_vm.data.processed_at ? _vm.$t('task.processed') : '')+" ")]),_c('dl',{staticClass:"row"},[_c('dt',{staticClass:"col-6"},[_vm._v(_vm._s(_vm.$t('task.created_at')))]),_c('dd',{staticClass:"col-6 text-right"},[_vm._v(_vm._s(_vm._f("date")(_vm.data.created_at)))]),_c('dt',{staticClass:"col-6"},[_vm._v(_vm._s(_vm.$t('task.updated_at')))]),_c('dd',{staticClass:"col-6 text-right"},[_vm._v(_vm._s(_vm._f("date")(_vm.data.updated_at)))]),_c('dt',{staticClass:"col-6"},[_vm._v(_vm._s(_vm.$t('task.debtor.display_name')))]),(_vm.data.debtor)?_c('dd',{staticClass:"col-6 text-right"},[_c('router-link',{attrs:{"to":{
          name: 'debtor.view',
          params: {
            debtorId: _vm.data.debtor.uuid,
          },
          query: {
            onlyClaimedTasks: true
          },
        }}},[_vm._v(_vm._s(_vm.data.debtor.display_name))])],1):_vm._e(),_c('dt',{staticClass:"col-6"},[_vm._v(_vm._s(_vm.$t('task.debtor.reference')))]),(_vm.data.debtor)?_c('dd',{staticClass:"col-6 text-right"},[_vm._v(_vm._s(_vm.data.debtor.reference))]):_vm._e()]),_c('hr'),_c('dl',{staticClass:"row"},[_c('h3',{staticClass:"col-6 h6"},[_vm._v(_vm._s(_vm.$t('task.task_type')))]),_c('dd',{staticClass:"col-6 text-right"},[_vm._v(_vm._s(_vm.$t(_vm.data.task_type)))])]),_c('hr'),_c('dl',{staticClass:"row"},[_c('h3',{staticClass:"col-6 h6"},[_vm._v(_vm._s(_vm.$t('task.priority')))]),_c('dd',{staticClass:"col-6 text-right"},[_vm._v(_vm._s(_vm.data.priority))])]),_c('hr'),_c('dl',[_c('h3',{staticClass:"h6"},[_vm._v(_vm._s(_vm.$t('task.message')))]),_c('div',[_vm._v(_vm._s(_vm.data.message))])]),_c('hr'),(_vm.data.custom_notice_type)?_c('dl',{staticClass:"row"},[_c('h3',{staticClass:"col-6 h6"},[_vm._v(_vm._s(_vm.$t('task.custom_notice_type.label')))]),_c('dd',{staticClass:"col-6 text-right"},[_vm._v(_vm._s(_vm.data.custom_notice_type.label))])]):_vm._e(),(_vm.data.custom_notice_type)?_c('div',[_c('hr'),_c('dl',{staticClass:"row"},[_c('h3',{staticClass:"col-6 h6"},[_vm._v(_vm._s(_vm.$t('task.urgent')))]),_c('dd',{staticClass:"col-6 text-right"},[_vm._v(_vm._s(_vm.$t(`${_vm.data.urgent}`)))])])]):_vm._e(),(_vm.data.receivables && _vm.data.receivables.length > 0)?_c('div',[_c('hr'),_c('dl',{staticClass:"row"},[_c('h3',{staticClass:"h6"},[_vm._v(_vm._s(_vm.$t('task.receivables')))]),_vm._l((_vm.data.receivables),function(receivable){return _c('dd',{key:receivable.uuid,staticClass:"col-12 text-right"},[_c('a',{staticClass:"text-primary",on:{"click":function($event){return _vm.showReceivable(receivable, _vm.data.debtor.uuid)}}},[_vm._v(" "+_vm._s(receivable.receivable_reference)+" - ("+_vm._s(_vm._f("datetime")(receivable.receivable_date,'d/MM/y'))+") ")])])})],2)]):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }