<template>
  <div class="d-flex justify-content-around mb-4" v-if="activeAction">
    <router-link
      class="action-tile"
      v-for="(action, index) in actions"
      :key="action.name"
      :to="{ name: action.route }"
      :class="['cursor-default', { completed: isCompleted(index), hidden: action.hidden }]"
    >
      <icon v-if="isCompleted(index)" prefix="fas" name="check-circle" size="2x" class="action-tile--checkmark" />
      <icon v-if="action.icon" :name="action.icon" size="6x" />
    </router-link>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { SubscriptionAction as Action } from '@/models';
import Icon from '@/components/global/Icon.vue';
@Component({
  components: {
    Icon,
  },
})
export default class SubscriptionActions extends Vue {
  @Prop() private actions!: Action[];
  @Prop() private activeAction!: Action;
  private isCompleted(actionIndex: number): boolean {
    return actionIndex < this.actions.indexOf(this.activeAction);
  }
}
</script>

<style lang="scss" scoped>
.action-tile {
  color: hsla(0, 0%, 50%, 0.1);
  position: relative;
  padding: 2em;
  transition: background 0.3s ease;
  &.router-link-active {
    color: var(--primary-color);
    background: rgba($color: #000, $alpha: 0.03);
    transition: background 0.3s ease;
  }
  &.completed {
    color: var(--primary-color);
    .action-tile--checkmark {
      position: absolute;
      right: 15px;
      top: 15px;
    }
    &:not(.router-link-active) {
      opacity: 0.2;
    }
  }
    &.hidden:not(.completed):not(.router-link-active) {
      visibility: hidden;
    }
}
</style>

