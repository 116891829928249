import { Module } from 'vuex';

import { RootState, ModalState } from '@/models';

export const modal: Module<ModalState, RootState> = {
  namespaced: true,
  state: {
    open: false,
    title: '',
    body: '',
    actions: [],
  },
  actions: {
    open({ commit }, modal) {
      document.body.classList.add('modal-open');
      commit('setModal', { open: true, body: modal.body, actions: modal.actions });
    },
    close({ commit }) {
      document.body.classList.remove('modal-open');
      commit('setModal', { open: false });
    },
    toggleModal({ commit }, modal) {
      commit('setModal', { modal });
    },
  },
  mutations: {
    setModal(state, payload) {
      state.open = payload.open;
      state.body = payload.body;
      state.actions = payload.actions;
    },
  },
};
