<template>
  <nav
    class="navbar navbar-light fixed-top flex-nowrap"
    :style="styles"
  >
    <div class="navbar-nav">
      <div class="d-flex">
        <button
          id="js-nav-toggle"
          class="nav-item nav-link"
          :style="styles.item"
          @click="toggleNav()"
        >
          <icon
            name="bars"
            size="lg"
          />
        </button>
        <router-link
          class="navbar-brand d-flex"
          :to="{ name: 'dashboard.index' }"
        >
          <img
            :src="this.$store.getters['platform/logo']"
            height="50"
            class="align-self-center brand-default"
          >
        </router-link>
      </div>
    </div>
    <div
      v-if="impersonateUsername"
      class="d-flex align-items-center"
    >
      <div class="mx-4">
        <small class="hidden-sm">
          {{ $t('settings.impersonated-as') }} {{ impersonateUsername }}
        </small>
        <button
          class="btn btn-block btn-primary"
          @click.prevent="endImpersonation"
        >
          {{ $t('settings.end-impersonation') }}
        </button>
      </div>
    </div>
    <div
      v-if="$store.getters['auth/isInTrialPeriod']"
      class="d-flex align-items-center flex-column"
    >
      <div class="text-muted">
        {{ $t('topbar.trial_ends_at', { date: formattedTrialEndDate } ) }}
      </div>
        <div
          class="text-danger font-weight-bold cursor-pointer"
          @click.prevent="endTrialSubscription"
        >
          {{ $t('topbar.deactivate-trial') }}
        </div>
    </div>
    <div class="navbar-nav">
      <div class="d-flex">
        <router-link
          v-if="canAccessWizard"
          class="nav-item nav-item--lg nav-link hidden-sm"
          :style="styles.item"
          :to="{ name: 'wizard' }"
        >
          <icon
            name="magic"
            size="lg"
          />
        </router-link>
        <router-link
          class="nav-item nav-item--lg nav-link hidden-sm"
          :style="styles.item"
          :to="{ name: 'dashboard.action.list' }"
        >
          <icon
            name="bell"
            size="lg"
          />
          <span
            class="badge badge-actions"
            :style="styles.badge.actions"
            v-text="actions"
          />
        </router-link>
        <router-link
          class="nav-item nav-item--lg nav-link hidden-sm"
          :style="styles.item"
          :to="{ name: 'dashboard.dispute.list' }"
        >
          <icon
            name="comment-alt-exclamation"
            size="lg"
          />
          <span
            class="badge badge-disputes"
            :style="styles.badge.disputes"
            v-text="disputes"
          />
        </router-link>
        <router-link
          class="nav-item nav-item--sub nav-link align-items-center d-none d-sm-flex"
          :style="styles.item"
          :to="{ name: 'settings' }"
        >
          <img
            src="@/assets/img/user.png"
            height="44"
            class="float-left"
          >
          <span
            class="d-none d-lg-block ml-2"
          >{{ currentUser.first_name }} {{ currentUser.last_name }}</span>
        </router-link>
        <router-link
          class="nav-item nav-link"
          :style="styles.item"
          :to="{ name: 'settings' }"
        >
          <icon
            name="cogs"
            size="lg"
          />
        </router-link>
        <div
          class="nav-item nav-item--lg nav-link cursor-pointer"
          @click="$store.dispatch('bars/toggleBar', 'supportBar')"
          :class="{'router-link-active': $store.state.bars.supportBarOpen }"
        >
          <icon
            name="question-circle"
            size="lg"
            prefix="fas"
          />
        </div>
        <a
          class="nav-item nav-link"
          :style="styles.item"
          href="#"
          @click.prevent="logout"
        >
          <icon
            name="power-off"
            size="lg"
          />
        </a>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { User, AccessControlList } from '@/models';
import { format } from 'date-fns';
import { TaskCounts } from '@/models/TaskState';

@Component({})
export default class TopBar extends Vue {
  private logout() {
    this.$store.dispatch('auth/logout');
  }

  private toggleNav() {
    this.$emit('toggleNav');
  }

  private async endImpersonation() {
    this.$store.dispatch('auth/impersonate', null);
    this.$router.push({ name: 'dashboard.index' });
  }
  private async endTrialSubscription() {
    this.$store.dispatch('modal/open', {
      body: this.$t('modal.deactivate-trial.body'),
      actions: [
        {
          label: this.$t('modal.deactivate-trial.cancel') as string,
          class: 'btn-light',
          action: async () => this.$store.dispatch('modal/close'),
        },
        {
          label: this.$t('modal.deactivate-trial.confirm') as string,
          class: 'btn-danger',
          action: async () => {
            this.$store.dispatch('modal/close')
              .then(() => this.$store.dispatch('auth/handleEndTrialSubscription')
              );
          },
        },
      ],
    });

  }
  private get canAccessWizard() {
    const wizardAcl: AccessControlList = this.$store.state.auth.acl.subscription_wizard;
    return Object.values(wizardAcl).includes(true);
  }
  private get formattedTrialEndDate() {
    return format(this.$store.getters['auth/trialEndsAt'], 'dd-MM-yyyy');
  }

  get currentUser(): User {
    return this.$store.state.auth.user;
  }

  get impersonateUsername(): string | null {
    return this.$store.state.auth.impersonateUsername;
  }

  get actions(): string {
    return this.taskCountData?.actions?.toString() ?? '0';
  }

  get disputes(): string {
    return this.taskCountData?.disputes.toString() ?? '0';
  }
  get taskCountData(): TaskCounts|null {
    return this.$store.state.task.taskCounts ?? null;
  }
  get logo() {
    return this.$store.state.platform.logo;
  }

  get styles() {
    return this.$store.state.platform.styling.topbar;
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';
@import '~@/assets/scss/colors';

.navbar-light {
  color: $text-color;
  background-color: $white;
  border-bottom: 1px solid $medium;
  height: $topbar-height;
  .picker {
    padding: 1.4rem;
  }
}

.nav-item {
  position: relative;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background: transparent;
  border: 0;
  &.nav-item--sub {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: 600;
    color: $text-color;
    small {
      display: block;
      color: $text-highlight;
      font-style: italic;
      font-weight: 400;
      line-height: inherit;
    }
  }
  &.nav-item--lg {
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
  }
  &.router-link-active {
    color: $text-highlight;
    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      display: block;
      height: 3px;
      content: '';
      background-color: $text-highlight;
    }
  }
  .badge {
    position: absolute;
    bottom: 10px;
    right: 5px;
  }
}

#js-nav-toggle {
  cursor: pointer;
  outline: none;
}

.navbar-brand {
  padding: 0 1rem;
}

.nav-item {
  border-left: 1px solid $light;
}

.brand-default {
  max-width: 150px;
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .brand-default {
  max-width: 200px;
}
}
@media screen and (min-width: 768px) {
  .brand-default {
    display: flex;
    max-width: 250px;
  }
  .brand-mobile {
    display: none;
  }
  .nav-item {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .fixed-top {
    z-index: 20;
  }
}
</style>

<style lang="scss">
.nav-item--lg .svg-inline--fa.fa-lg {
  height: 2.6rem;
}
</style>
