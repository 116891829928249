<template>
  <nav aria-label="navigation">
    <ul class="pagination justify-content-end mt-2">
      <li :class="{ 'page-item': true, disabled: currentPage === 1 }">
        <a class="page-link" @click.prevent="goToPage(1)" tabindex="-1">&LeftTriangleBar;</a>
      </li>
      <li :class="{ 'page-item': true, disabled: currentPage === 1 }">
        <a class="page-link" @click.prevent="goToPage(currentPage - 1)" tabindex="-1">&laquo;</a>
      </li>

      <li
        v-for="n in computedPages"
        :class="{ 'page-item': true, active: n === currentPage }"
        :key="n"
      >
        <a class="page-link" @click.prevent="goToPage(n)">{{ n }}</a>
      </li>
      <li :class="{ 'page-item': true, disabled: currentPage === totalPages }">
        <a class="page-link" @click.prevent="goToPage(currentPage + 1)">&raquo;</a>
      </li>
      <li :class="{ 'page-item': true, disabled: currentPage === totalPages }">
        <a class="page-link" @click.prevent="goToPage(totalPages)">&RightTriangleBar;</a>
      </li>
    </ul>
  </nav>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class Pagination extends Vue {
  @Prop()
  private currentPage!: number;

  @Prop()
  private pageSize!: number;

  @Prop()
  private totalRecords!: number;

  private goToPage(page: number) {
    if (page < 1 || page > this.totalPages || page === this.currentPage) {
      return;
    }
    this.$emit('change', page);
  }

  get totalPages() {
    return Math.ceil(this.totalRecords / this.pageSize);
  }
  get computedPages() {
    const pages = [];
    for (let i = 1; i <= this.totalPages; i++) {
      if (
        (i <= 10 && this.currentPage <= 10) ||
        (i <= this.currentPage && i > this.currentPage - 5) ||
        (i >= this.currentPage && i < this.currentPage + 5)
      ) {
        pages.push(i);
      }
    }
    return pages;
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/_colors';
.page-link {
  padding: 0.5rem;
  font-size: 1.2rem;
  color: $text-color;
  background-color: transparent;
  border: none;
  opacity: 0.6;
}
.page-item.active .page-link,
.page-item.disabled .page-link {
  color: $text-color;
  background-color: transparent;
}
.page-item .page-link:hover,
.page-item.active .page-link {
  opacity: 1;
}
.page-item.disabled .page-link {
  opacity: 0.3;
}
</style>
