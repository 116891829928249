<template>
  <div class="card">
    <loader :loading="loading" />
    <div class="card-head justify-between py-0 pr-0">
      <h2 class="h5">{{ workflow ? workflow.name : $t('debtors.overview.process.title') }}</h2>
      <a
        v-if="canManageWorkflow"
        @click.prevent="interruptResumeStep"
        class="nav-link nav-link-right"
        :title="$t(`debtors.overview.process.${this.workflowInProgress ? 'interrupt' : 'resume'}`)"
      >
        <icon :name="progressIcon" />
      </a>
    </div>
    <div class="card-body px-0" :class="{'justify-content-center align-items-center': !workflowStepLinks.length }">
      <div v-if="workflow && workflowStepLinks.length" class="process d-flex">
        <div
          v-for="step in workflowStepLinks"
          :key="step.uuid"
          :class="[
            'd-flex align-items-center process-block',
            {
              'process-done': step.processed_at,
              'process-active': step.is_current_workflow_step,
            },
          ]"
        >
          <div class="process-time p-2 pr-2 mr-3" v-if="step.due_date">
            <div class="day">{{ step.due_date | datetime('dd') }}</div>
            <div class="month-year">{{ step.due_date | datetime('MM ’yy') }}</div>
          </div>
          <div class="process-time p-2 pr-2 mr-3" v-else>
            <div class="day">!</div>
            <div class="month-year">{{ $t('form.setting.workflow.step.interval_days.label') }}</div>
          </div>
          <div class="process-title">
            <b>{{ step.workflow_step_name }}</b>
            <div v-if="!workflowInProgress">
              <Icon name="pause" />
              <i>{{ $t('workflow_hold') }}</i>
            </div>
            <div v-if="step.open_tasks">
              <i>{{ $t('open_tasks') }}: {{ step.open_tasks }}</i>
            </div>
          </div>
          <div class="process-actions" v-if="step.is_current_workflow_step && step.open_tasks">
            <a
              v-if="canProcessWorkflow"
              @click.prevent="processStep(step)"
              :class="['disabled', !workflowInProgress]"
              :title="$t('process')"
            >
              <icon name="play-circle" size="lg" />
            </a>
          </div>
        </div>
      </div>
      <span v-else>
        {{ $t(workflow ? 'no-workflow-steps' : 'no-workflow') }}
      </span>
    </div>
    <div class="card-footer p-2 px-3">
      <select-workflow @reload="loadData" :allowed="canManageWorkflow"></select-workflow>
    </div>
  </div>
</template>

<script lang="ts">
import api from '@/api';
import { EventBus } from '@/services/EventBus';
import SelectWorkflow from './SelectWorkflow.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { LoadingState, WorkflowStep, Workflow } from '@/models';

@Component({
  components: {
    SelectWorkflow,
  },
})
export default class CurrentProcess extends Vue {
  private loading: LoadingState = false;
  private workflowStepLinks: WorkflowStep[] = [];
  private workflowInProgress: boolean = true;
  private oldestOpenInvoiceDate!: string;
  private workflow: Workflow | null = null;

  private async created() {
    await this.loadData();
  }

  @Watch('$route.params.debtorId')
  private async loadData() {
    EventBus.$emit('workflow.status.changed');
    this.loading = true;
    const res = await api.get(`/debtor/${this.$route.params.debtorId}/workflow`);
    if (res.data) {
      this.workflowStepLinks = res.data.workflowStepLinks;
      this.workflowInProgress = res.data.workflowInProgress;
      this.oldestOpenInvoiceDate = res.data.oldestOpenInvoiceDate;
      this.workflow = res.data.workflow;
      return (this.loading = false);
    }
    this.loading = 'error';
  }

  private async interruptResumeStep() {
    const statusAction = this.workflowInProgress ? 'on_hold' : 'in_progress';
    const data = {
      workflow_status: {
        status_action: statusAction,
      },
    };
    const reply = await api.post(`debtor/${this.$route.params.debtorId}/workflow/status`, data);
    if (reply) {
      await this.loadData();
      this.$toasted.success(this.$t(
        'debtor.workflow.interruptresume_' + statusAction + '.success',
      ) as string);
    } else {
      this.$toasted.error(this.$t(
        'debtor.workflow.interruptresume_' + statusAction + '.error',
      ) as string);
    }
  }

  private async processStep(step: WorkflowStep) {
    const reply = await api.post(
      `debtor/${this.$route.params.debtorId}/workflow/${step.uuid}/process`,
    );
    if (reply.data.success) {
      this.$root.$data.refreshKey++;
      this.$toasted.success(this.$t('debtor.workflow.process.success') as string);
    } else {
      this.$toasted.error(this.$t('debtor.workflow.process.error') as string);
    }
  }

  private get progressIcon() {
    const icon = this.workflowInProgress ? 'pause' : 'play';
    return icon;
  }
  private get canManageWorkflow() {
    return this.currentDebtor?.acl.manage_debtor_workflow;
  }
  private get canProcessWorkflow() {
    return this.currentDebtor?.acl.process_workflow;
  }
  private get currentDebtor() {
    return this.$store.state.debtor.currentDebtor;
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/colors';
.card-body {
  position: relative;
  min-height: 16rem;
  &:before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    content: '';
  }
}
.process {
  top: 0;
  bottom: 0;
  left: 2rem;
  right: 2rem;
  position: absolute;
  overflow-x: auto;
}
.process-block {
  margin: 2rem 2rem 0 0;
  height: fit-content;
  min-width: 260px;
  position: relative;
  background-color: lighten($medium, 8%);
  border: 0;
  &.process-active {
    color: $text-highlight;
    background-color: lighten($medium, 0%);
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
  }
  &.process-done {
    color: $dark;
    background-color: lighten($medium, 30%);
  }
  &.process-upcoming {
    color: $dark;
    background-color: lighten($medium, 50%);
  }
  .day {
    line-height: 4.2rem;
  }
  .month-year {
    font-size: 1.4rem;
    line-height: 2rem;
  }
}

.card-footer {
  background-color: $medium;
  border: 0;
}
</style>
