<template>
  <div class="tag">
    <div v-if="showAdd" class="add-btn" @mouseup="$emit('add')">
      <icon name="plus"/>
    </div>
    <div v-if="showDelete" class="delete-btn" @mouseup="$emit('remove')">
      <icon name="trash-alt"/>
    </div>
    <div class="title">{{ value }}</div>
  </div>
</template>

<script lang='ts'>
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import { Rectangle } from 'vuedraggable';


@Component({
  components: {
   draggable,
  }
})
export default class MappingDiagram extends Vue {

  @Prop({ default: '' })
  private value!: string;

  @Prop({ default: false })
  private showDelete!: boolean;

  @Prop({ default: false })
  private showAdd!: boolean;

}
</script>

<style scoped lang="scss">
.tag {
  padding-right: .4rem;
  padding-bottom: .4rem;
  z-index: 1;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: nowrap;
  position: relative;
  .title {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px;
    line-height: 24px;
    min-height: 24px;
    background-color: white;
    border-top-right-radius: .4rem;
    border-bottom-right-radius: .4rem;
    flex-grow: 1;
    display: block;
    cursor: move;
    padding: 0 0.8rem;
  } 
  .add-btn {
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 8px;
    cursor: pointer;
    padding: 0 0.4rem;
    min-width: 24px;
    min-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: rgb(80, 160, 0);
    border-top-left-radius: .4rem;
    border-bottom-left-radius: .4rem;
    color: white;
    transition: background-color 0.1s ease;
    &:hover {
      color: black;
      background-color: greenyellow;
    }
  }
  .delete-btn {
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 8px;
    cursor: pointer;
    padding: 0 0.4rem;
    min-width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: darkred;
    color: white;
    border-top-left-radius: .4rem;
    border-bottom-left-radius: .4rem;
    transition: background-color 0.1s ease;
    &:hover {
      background-color: red;
    }
  }
}
</style>
