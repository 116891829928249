<template>
  <data-table
    :endpoint="endpoint"
    @action="doAction"
    prefix="translations"
    route-context="settings.template.edit.translation"
    :route-params="{ id: $route.params.id, template: $route.meta?.model, subId: { key: 'uuid' } }"
    ref="translationList"
  />
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { SettingsListComponent } from '@/mixins/SettingsListComponent';
import DataTable from '@/plugins/data-table/DataTable.vue';

@Component({})
export default class SettingsTranslationList extends mixins(SettingsListComponent) {
  protected model: string = 'translation';
}
</script>

<style scoped lang="scss">
</style>
