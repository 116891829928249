<template>
  <div>
    <h2 class="h4 text-highlight">Vordering toegevoegd</h2>
    <div class="description mb-2">
      Jouw vordering op
      <b>{{ claimData['displayName'] }}</b> met een totaalbedrag van
      <b>{{ claimData['claimAmount'] | currency }}</b> is succesvol toegevoegd.
      <br>We houden je per
      e-mail op de hoogte van de status.
      <br>Deze kan je ook altijd terugvinden in het
      <router-link :to="`/debtor/${debtor}/dashboard`">dashboard</router-link>
    </div>
    <router-link class="btn btn-primary" :to="{name: 'wizard.claim'}">{{ $t('create.claim')}}</router-link>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator';

@Component
export default class SubscriptionPayment extends Vue {
  protected get debtor() {
    return this.$route.params.debtor;
  }

  protected get claimData(): any {
    return this.$route.params.claimData;
  }
}
</script>
