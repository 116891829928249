<template>
  <div>
    <loader :loading="loading"/>
    <form @submit.prevent="submit" class="form settings-form mb-3" v-if="schema">
      <div class="card h-100">
        <div class="card-head justify-content-between py-0 pr-0">
          <h2 class="h5">{{ $t(`${schema.title}.header`) }}</h2>
          <div class='d-flex'>
            <button type="submit" class="nav-link nav-link-right">
              <Icon prefix="fas" name="save" class="mr-2"/>
              {{ $t('settings.start_manual_bulk_update') }}
            </button>
          </div>
        </div>
        <div class="card-body">
          <json-form :schema="schema" v-model="model" :errors="errors"/>
          <div class="offset-sm-2 pl-1">
            <button type="submit" class="btn btn-primary btn-sm w-100">
              <Icon prefix="fas" name="save" class="mr-2"/>
              {{ $t('settings.start_manual_bulk_update') }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { Watch } from 'vue-property-decorator';
import Component, { mixins } from 'vue-class-component';
import { appendQueryParams } from '@/utils/queryString';
import { SettingsFormComponent } from '@/mixins/SettingsFormComponent';
import { AxiosResponse } from 'axios';
import { parseEndpoint } from '@/router';

@Component({
  components: {},
})
export default class ManualBulkUpdate extends mixins(SettingsFormComponent) {
  protected afterLoad(response: AxiosResponse) {
    if (response?.data?.owner) {
      this.$store.commit('auth/setEntity', response.data.owner)
    }
  }

  protected beforeDestroy() {
    this.$store.commit('auth/restoreUser');
  }
}
interface mappingType {
  name: string;
  type: string;
  subtype: string;
}
</script>
