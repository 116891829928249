<template>
  <div>
    <div class="d-flex justify-content-end mb-3">
        <div class="btn btn-primary" @click="save">
          <icon prefix="fas" name="save" class="mr-1" />
          save
      </div>
    </div>

    <div
      v-for="(statisticChunk, index) in chunkedStatistics"
      :key="index"
      class="row mb-3"
    >
      <div
        v-for="statistic in statisticChunk"
        :key="statistic.type"
        @click="toggle(statistic)"
        class="col-6"
      >
        <statistic-component-wrapper
          style="cursor: pointer; user-select: none"
          :class="{'disabled': !statistic.enabled}"
          class="handle"
          :type="statistic.type"
          :currency="currency" />
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { Vue, Component } from 'vue-property-decorator';
import StatisticComponentWrapper from '@/components/report/statistic/StatisticComponentWrapper.vue';
import api from '@/api';
import { chunk } from 'lodash';
import { Statistic, ValidStatisticType } from '@/models/ReportStatistic';
import { reportStatisticComponentMap } from '@/utils/ReportStatisticComponentMap';
import { ReportStatisticState } from '@/models';

@Component({
  components: { StatisticComponentWrapper },
})
export default class ReportStatisticEdit extends Vue {
  protected statistics: Statistic[] = [];
  protected currency: string = 'EUR';

  private async created() {
    await this.loadComponents();

    const currentStatistics = this.storedComponents
      .filter(statistic => Object.keys(reportStatisticComponentMap).includes(statistic.type))
      .sort((a, b) => a.position - b.position)
      .map(s => ({ ...s, enabled: true }));


    const availableStatistics: Statistic[] = Object.keys(reportStatisticComponentMap)
      .filter(key => currentStatistics.every(s => s.type !== key))
      .map(key => ({
        type: key as ValidStatisticType,
        position: currentStatistics.length + 1,
        enabled: false,
      }));

    this.statistics = [...currentStatistics, ...availableStatistics];
  }

  private async loadComponents() {
    await api.get<ReportStatisticState>('report/statistics')
      .then(({ data }) => {
        data.components = data.components ?? this.getDefaultComponents();
        this.$store.dispatch('reportStatistic/storeData', data)
      })
      .catch(err => console.error(err));
  }

  private save() {
    const statistics: Statistic[] = [];
    const enabledStatistics: Statistic[] = this.statistics
      .filter(s => s.enabled)
      .map(s => ({ type: s.type, position: s.position }));

    enabledStatistics.forEach((statistic, index) => {
      statistic.position = index;
      statistics.push(statistic);
    });

    api.post('report/statistics/edit', { statistics })
      .then(() => this.$store.dispatch('reportStatistic/storeComponents', statistics))
      .then(() => this.$router.push({ name: 'report.statistic' }));
  }

  private toggle(statistic: Statistic) {
    statistic.enabled = !statistic.enabled;
  }
  private getDefaultComponents(): Statistic[] {
    //@ts-ignore
    return Object.keys(reportStatisticComponentMap).map<Statistic>(type => ({type, enabled:true, position: 0 }));
  }
  private get chunkedStatistics(): Statistic[][] {
    return chunk(this.statistics, 2);
  }

  private get storedComponents(): Statistic[] {
    return this.$store.state.reportStatistic.components;
  }
}
</script>

<style lang="scss" scoped>
.disabled {
  opacity: .5;
}
</style>
