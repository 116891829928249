<template>
  <div>
    <div class="card">
      <div class="card-head p-0 justify-content-between">
        <div class="d-flex">
          <a
            v-for="type in types"
            :key="type"
            class="nav-link h5"
            :class="{'active': selectedType === type}"
            @click="selectType(type)"
          >{{ $t(`settings.creditor.${type}`) }}</a>
        </div>
        <router-link
          class="nav-link nav-link-right"
          :to="{name: 'settings.creditor.settings.logo.create'}"
        >
          <icon class="icon-plus" name="plus"/>
        </router-link>
      </div>
      <div class="card-body table-responsive">
        <div class="row" v-if="logos">
          <div
            class="col-sm-6"
            v-for="(logo,i) in this.logos.filter(logo => logo.type === selectedType)"
            :key="i"
          >
            <div class="logo">
              <img :src="`${$root.VUE_APP_API_HOST}/api/app/logo/${logo.logo}`" class="img-fluid" :title="logo.name" :alt="logo.name">
              <div @click="deActivateLogo(logo.uuid)">
                <icon class="icon-trash pointer" name="delete"/>
              </div>
            </div>
          </div>
          <div class="col-sm-6" v-if="!(selectedType === 'corporate' && logos.some(l => l.type === selectedType))">
            <router-link
              class="logo h-100"
              :to="{name: 'settings.creditor.settings.logo.create', params:{type:selectedType}}"
            >
              <icon class="icon-plus" name="plus" size="lg"/>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

import api from '@/api';
import { Logo } from '@/models';

@Component({})
export default class Logos extends Vue {
  protected types: string[] = ['corporate', 'certificate', 'image'];
  protected selectedType: string = this.types[0];
  @Prop({ required: true })
  protected logos!: Logo[];

  protected async selectType(type: string) {
    this.selectedType = type;
  }

  protected async deActivateLogo(uuid: string) {
    if(!await this.showConfirmationModal() ) {
      return;
    }
    const res = await api.post(`/logo/${uuid}/deactivate`);
    if (res && res.data && res.data.success) {
      this.$emit('onLogoDeleted', uuid);
      this.$toasted.success(this.$t(`delete.success`) as string);
    }
  }

  private showConfirmationModal() {
    return new Promise<boolean>((resolve) => {
      this.$store.dispatch('modal/open', {
        body: 'modal.record.delete.body',
        actions: [
          {
            label: this.$t('modal.record.delete.cancel') as string,
            class: 'btn-dark',
            action: async () => this.$store.dispatch('modal/close').then(() => resolve(false)),
          },
          {
            label: this.$t('modal.record.delete.confirm') as string,
            class: 'btn-primary',
            action: async () => {
              this.$store.dispatch('modal/close').then(() => resolve(true));
            },
          },
        ],
      });
    });
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/colors';
.logo {
  border: 1px solid $medium;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: relative;

  .icon-trash {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    cursor: pointer;
  }

  &:hover .icon-plus {
    color: black;
  }
}
</style>
