<template>
  <div class="card pb-0 mb-3" :style="{'paddingBottom':'0 !important'}">
    <div class="card-body">
      <data-table
        :model="model"
        @action="doAction"
        :endpoint="endpoint"
        :route-params="routeParams"
        :ref="`${model}List`"
        :route-context="$route.name"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { SettingsListComponent } from '@/mixins/SettingsListComponent';

@Component({})
export default class SettingsList extends mixins(SettingsListComponent) {
  protected model: string = '';

  protected created() {
    this.model = this.routeModel;
  }

  @Watch('routeModel')
  private updateModel() {
    this.model = this.routeModel;
  }
}
</script>

<style scoped lang="scss">
</style>
