<template>
  <ul class="list-unstyled subnav-nav">
    <li class="subnav-nav--item">
      <a href="#" @click.prevent="clearActionDatatableFilter" class="py-1 overview">Overview</a>
    </li>
    <li class="subnav-nav--item" v-for="actionType in actionTypes" :key="actionType.name">
       <a href="#" @click.prevent="setActionDatatableFilter(actionType.name)">
        {{ $t(actionType.name) }} ({{ actionType.count }})
       </a>
    </li>
  </ul>
</template>

<script lang="ts">
import DataTable from '@/plugins/data-table/DataTable.vue';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { datatable } from '../../store/datatable';
import { EventBus } from '@/services/EventBus';

@Component({})
export default class SubnavBar extends Vue {

  get actionTypes() {
    return this.$store.state.bars.subnavBarTree;
  }
  private clearActionDatatableFilter() {
    EventBus.$emit('datatable.dashboard.action.filters.clear');
    
  }
  private setActionDatatableFilter(name: any) {
    EventBus.$emit('datatable.dashboard.action.filter.set', 'actionType', name);
    //this.$store.commit('datatable/setFilter', {filter: { actionType: name}, identifier: this.actionDatatable.uniqueIdentifier});
  }
}
</script>

