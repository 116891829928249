<template>
  <div v-if="dashboardData">
    <loader :loading="$store.state.dashboard.loading"></loader>
    <div
      class="alert alert-danger mb-3"
      v-if="$store.state.dashboard && $store.state.dashboard.data.is_syncing"
    >
      <Icon name="exclamation-circle" class="mr-2"/>
      <span>{{ $t('dashboard.business_unit.is_syncing') }}</span>
    </div>
    <div class="row">
      <div class="col-sm-6 col-lg-3 d-flex align-items-stretch">
        <Block
          :value="taskCounts.actions"
          identifier="actions"
          icon="bell"
          link="dashboard.action.list"
          :customStyling="styles.actions"
        />
      </div>
      <div class="col-sm-6 col-lg-3 d-flex align-items-stretch">
        <Block
          :value="taskCounts.disputes"
          identifier="disputes"
          icon="comment-alt-exclamation"
          link="dashboard.dispute.list"
          :customStyling="styles.disputes"
        />
      </div>
      <div class="col-sm-6 col-lg-3 d-flex align-items-stretch">
        <Block
          :value="taskCounts.mailbox"
          identifier="mailbox"
          icon="envelope"
          :customStyling="styles.mailbox"
          link="mailbox"
        />
      </div>
      <div class="col-sm-6 col-lg-3 d-flex align-items-stretch">
        <Block
          :value="taskCounts.batch"
          identifier="batch"
          icon="code-branch"
          link="dashboard.batch.list"
          :customStyling="styles.batch"
        />
      </div>
    </div>
    <div class="row" v-if="dashboardData.invoiceSummary && dashboardData.invoiceSummary.groupedByPhase">
      <div class="col-12">
        <InvoiceSummary :invoice-summary="dashboardData.invoiceSummary"/>
      </div>
    </div>
    <div class="row flex-column-reverse flex-xl-row mb-3">
      <div class="col-12 col-xl-3">
        <ApplicationData :imports="dashboardData.lastImport"/>
      </div>
      <div class="col-12 col-xl-9 mb-3 mb-xl-0">
        <FinancialInformation :financial-information="financialInformation"/>
      </div>
    </div>
    <graphs/>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Block from '@/components/dashboard/Block.vue';
import LoggedInAs from '@/components/dashboard/LoggedInAs.vue';
import ApplicationData from '@/components/dashboard/ApplicationData.vue';
import FinancialInformation from '@/components/dashboard/FinancialInformation.vue';
import Graphs from '@/components/dashboard/Graphs.vue';
import InvoiceSummary from '@/components/dashboard/InvoiceSummary.vue';
import { TaskCounts } from '@/models/TaskState';
import { dashboard } from '@/store/dashboard';

@Component({
  computed: {
    dashboard() {
      return dashboard
    }
  },
  components: {
    Block,
    LoggedInAs,
    ApplicationData,
    FinancialInformation,
    Graphs,
    InvoiceSummary,
  },
})
export default class DashboardIndex extends Vue {
  created() {
    this.$store.dispatch('dashboard/loadFinancialInformation');
    this.$store.dispatch('dashboard/loadData');
  }
  get taskCounts(): TaskCounts|null {
    return this.$store.state.task.counts;
  }
  get dashboardData() {
    return this.$store.state.dashboard.data;
  }
  get financialInformation() {
    return this.$store.state.dashboard.financialInformation;
  }
  get styles() {
    return this.$store.state.platform.styling.dashboard;
  }
}
</script>

<style lang="scss" scoped>
.second-row {
  max-height: 230px;
}
</style>
