<template>
  <div class="overflow-x-auto overflow-y-padding">
    <hr />
    <div class="invalid-feedback" v-if="hasError">
      <p v-for="error in hasError" :key="error">{{ error }}</p>
    </div>
    <direct-actions v-model="model.actions" :action-types="actionTypes" />
    <hr />
    <h3 class="h4 text-highlight">
      {{ $t('workflow.conditions-and-actions') }}
      <a @click.prevent="addConditionalAction">
        <icon class="icon-plus" name="plus" />
      </a>
    </h3>
    <div class="conditions-and-actions" v-for="(_, index) in model.conditionalActions" :key="index">
      <conditions-and-actions
        v-model="model.conditionalActions[index]"
        :condition-types="conditionTypes"
        :action-types="actionTypes"
        @onDestroy="model.conditionalActions.splice(index, 1)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { WorkflowData, WorkflowModel, ActionTypes, ConditionTypes } from '@/models';
import DirectActions from './DirectActions.vue';
import ConditionsAndActions from './ConditionsAndActions.vue';

@Component({
  components: {
    DirectActions,
    ConditionsAndActions,
  },
})
export default class Workflow extends Vue {
  @Prop({ type: [Object], required: true })
  private extra!: {
    workflowData: WorkflowData;
  };
  @Prop()
  private errors!: any;

  @Prop()
  private value!: any;

  private model: WorkflowModel = {
    actions: [],
    conditionalActions: [],
  };
  private created() {
    this.model = { ...this.value };
  }

  private addConditionalAction() {
    this.model.conditionalActions.push({
      conditions: {
        logical_operator: 'OR',
        rules: [],
      },
      true: {
        actions: [],
        conditionalActions: [],
      },
      false: {
        actions: [],
        conditionalActions: [],
      },
    });
  }

  @Watch('model', { deep: true })
  private modelChanged() {
    this.$emit('input', this.model);
  }

  get actionTypes(): ActionTypes {
    return this.extra.workflowData.actionTypes;
  }

  get conditionTypes(): ConditionTypes {
    return this.extra.workflowData.conditionTypes;
  }

  get hasError(): string[] {
    let errors: string[] = [];

    if (this.errors?.errors?.children?.actions?.errors?.length) {
      errors = [...this.errors.errors.children.actions.errors];
    }
    if (this.errors?.errors?.errors?.length) {
      errors = [...errors, ...this.errors.errors.errors];
    }
    return errors;
  }
}
</script>

<style lang="scss" scoped>
.conditions-and-actions {
  white-space: nowrap;
}

.conditions-and-actions .form-group {
  background: red;
}

.overflow-y-padding {
  // https://stackoverflow.com/questions/6421966/css-overflow-x-visible-and-overflow-y-hidden-causing-scrollbar-issue

  margin-top: -300px;
  padding-top: 300px;
   margin-bottom: -300px;
   padding-bottom: 300px;
}
</style>
