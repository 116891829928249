import { Module } from 'vuex';
import { storage } from '@/services/LocalStorage';
import { dictFilter, dictMap } from '@/utils/dictMap';
import { RootState } from '@/models';
import { Vue } from 'vue-property-decorator';
import { Selection } from '@/Selection';

function initSelection(): Record<string, Selection> {
  const mappedSelection = storage.get<Record<string, string[]>>('datatable_selection', {});
  return dictMap(mappedSelection, (selection) => new Selection(selection));
}

function getSelection(store: SelectionState, type: string): Selection {
  if(!store.selectionMapping.hasOwnProperty(type)) {
    Vue.set(store.selectionMapping, type, new Selection());
  }
  return store.selectionMapping[type];
}

function save(store: SelectionState) {
  const filteredSelection = dictFilter(store.selectionMapping, (selection: Selection) => !selection.isEmpty);
  const mappedSelection = dictMap(filteredSelection, (selection: Selection) => selection.values);
  storage.save('datatable_selection', mappedSelection);
}
interface SelectionState {
  selectionMapping: Record<string, Selection>;
}

export const selection: Module<SelectionState, RootState> = {
  namespaced: true,
  state: {
    selectionMapping: initSelection(),
  },
  mutations: {
    setSelection(state: SelectionState, params: { type: string, selection: string[], domain?: string }) {
      const {type, selection, domain} = params;
      getSelection(state, type).set(selection);

      if(null == domain) {
        save(state);
      }
    },
    select(state: SelectionState, params: {type: string, value: string|string[], domain?: string }) {
      const {type, value, domain} = params;
      getSelection(state, type).select(value);

      if(null == domain) {
        save(state);
      }
    },
    deselect(state: SelectionState, params: {type: string, value: string|string[], domain?: string }) {
      const {type, value, domain} = params;
      getSelection(state, type).deselect(value);

      if(null == domain) {
        save(state);
      }
    },
    clear(state: SelectionState, params: {type: string, domain?: string }) {
      const {type, domain} = params;
      getSelection(state, type).clear();

      if(null == domain) {
        save(state);
      }
    }
  },
  getters: {
    selection(state: SelectionState) {
      return (type: string) => {
        if(!state.selectionMapping.hasOwnProperty(type)) {
          return new Selection();
        }
        return new Selection(state.selectionMapping[type].values);
      };
    }
  }
};
