<template>
  <div v-if="allowedTabs.length">
    <loader :loading="loading" />
    <div class="card h-100">
      <div class="card-head justify-content-between">
        <div class="d-flex justify-start">
          <router-link
            v-for="tab in allowedTabs"
            :to="{ name: tab.name }"
            class="nav-link"
            :key="tab.name"
            :class="{ active: isActiveTab(tab.name) }"
          >
            {{ $t(`${tab.name}`) }}
          </router-link>
        </div>
        <router-link
        :to="{ name: action.name, params: Object.assign({},$route.params, action && action.params)}"
        class="nav-link nav-link nav-link-right"
        v-for="action in actions"
        :key="action.name"
      >
        <icon :name="action.icon" size="lg"/>
      </router-link>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { routes } from '@/router';
import { TabInterface } from '@/models';
import { AxiosResponse } from 'axios';
import api from '@/api';
import { parseEndpoint, getRoute } from '@/router';

@Component({})
export default class Tabbable extends Vue {
  @Prop() private tabs!: TabInterface[];
  private loading: boolean = false;

  protected async mounted() {
    if (this.allowedTabs.length) {
      this.$router.replace({ name: this.allowedTabs[0].name });
    }
  }

  @Watch('$route.name')
  private setTab() {
    this.loading = true;
    if (!this.allowedTabs.find(tab => this.isValidRouteTab(tab.name))) {
      this.$router.go(-1);
    }
    this.loading = false;
  }

  private get allowedTabs() {
    return this.tabs.filter((tab: TabInterface) => {
      if (! tab.acl) {
        return true;
      }
      return this.$store.state.auth.acl.settings[tab.acl];
    });
  }

  /*
   * check if current route is part of the current tab.
   */
  private isValidRouteTab(tab: string): boolean {
    if (!this.$route.name) {
      return false;
    }
    return this.$route.name.indexOf(tab) !== -1;
  }

  /*
   * Check if current tab is the current route, or the tab is a parent of the current route.
   */
  private isActiveTab(tab: string): boolean {
    if (!this.$route.name) {
      return false;
    }
    return this.$route.name === tab || this.$route.name.includes(tab + '.');
  }
  get actions() {
    return this.$route.meta?.actions || null;
  }
}
</script>


<style lang="scss" scoped>
.card-head {
  padding: 0 !important;
}
.nav-link.active {
  font-weight: 600;
}
</style>
