var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('loader',{attrs:{"loading":_vm.loading}}),(_vm.schema)?_c('form',{staticClass:"form settings-form mb-3 h-100",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-head justify-content-between py-0 pr-0"},[_c('h2',{staticClass:"h5"},[_vm._v(_vm._s(_vm.$t(`${_vm.schema.title}.header`)))]),_c('button',{staticClass:"nav-link nav-link-right",attrs:{"type":"submit"}},[_c('Icon',{staticClass:"mr-2",attrs:{"prefix":"fas","name":"save"}}),_vm._v(" "+_vm._s(_vm.$t('save'))+" ")],1)]),_c('div',{staticClass:"card-body"},[(_vm.message)?_c('div',{staticClass:"mb-3 alert alert-danger"},[_vm._v(_vm._s(_vm.$t(_vm.message)))]):_vm._e(),_c('json-form',{attrs:{"schema":_vm.schema,"errors":_vm.errors},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('div',{class:[
            { 'offset-sm-2 pl-1': !this.$route.meta?.buttonCentered },
            { 'd-flex align-items-center justify-content-center': this.$route.meta?.buttonCentered },
          ]},[_c('button',{class:[{ 'w-50 align-self-center':this.$route.meta?.buttonCentered}, {'w-100': !this.$route.meta?.buttonCentered}, 'btn btn-primary btn-sm '],attrs:{"type":"submit"}},[_c('Icon',{staticClass:"mr-2",attrs:{"prefix":"fas","name":"save"}}),_vm._v(" "+_vm._s(_vm.$t('settings.save'))+" ")],1)])],1)])]):_vm._e(),(this.$route.meta && this.$route.meta?.translation)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-head justify-content-between py-0 pr-0"},[_c('h2',{staticClass:"h5"},[_vm._v(_vm._s(_vm.$t('settings.translation.overview')))]),_c('router-link',{staticClass:"nav-link nav-link-right",attrs:{"to":{
          name: 'settings.template.edit.translation.create',
          params: Object.assign({}, _vm.$route.params, { template: this.$route.params.template }),
        }}},[_c('icon',{staticClass:"icon-plus",attrs:{"name":"plus"}})],1)],1),_c('div',{staticClass:"card-body table-responsive"},[_c('SettingsTranslationList')],1)]):_vm._e(),(this.$route.meta && this.$route.meta?.workflow)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-head justify-content-between py-0 pr-0"},[_c('h2',{staticClass:"h5"},[_vm._v(_vm._s(_vm.$t('settings.workflow.steps.overview')))]),_c('router-link',{staticClass:"nav-link nav-link-right",attrs:{"to":{ name: 'settings.workflow.edit.step.create', params: { model: _vm.$route.meta?.model } }}},[_c('icon',{staticClass:"icon-plus",attrs:{"name":"plus"}})],1)],1),_c('div',{staticClass:"card-body table-responsive"},[_c('SettingsWorkflowList')],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }