<template>
  <div class="alert alert-danger mb-3" v-if="hasWarnings && !dismissed">
    <div class="mb-1 d-flex justify-content-between">
      <div>
        <Icon name="exclamation-circle" class="mr-2" />
        <span>{{ $t(`${model}.warning.title`) }}</span>
      </div>
      <div class="cursor-pointer" @click="dismiss">
        <icon name="times" />
      </div>
    </div>

    <div v-for="(item, key) in warnings" :key="key">
      <div class="d-flex">
        <div class="mr-2">{{ $t(`invoice.warning.${key}`) }}:</div>
        <div v-if="Array.isArray(item)">
          <div v-for="i in item" :key="i" v-text="i" />
        </div>
        <div v-else>
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Warnings as WarningsModel } from '@/models';

@Component({})
export default class Warnings extends Vue {
  @Prop() private warnings?: WarningsModel;
  @Prop() private model!: string;
  private dismissed: boolean = false;

  private dismiss() {
    this.dismissed = true;
  }

  @Watch('warnings')
  private showWarning() {
    this.dismissed = false;
  }

  private get hasWarnings(): boolean {
    if (!this.warnings) {
      return false;
    }

    return Object.values(this.warnings).some(warning => warning !== null);
  }
}
</script>

<style></style>
