<template>
  <div>
    <loader :loading="isLoading"/>
    <template v-if="!isLoading">
      <form @submit.prevent="submit" class="form" v-if="schema" :ref="schema.title">
        <json-form :schema="schema" v-model="model" :errors="errors"/>
      </form>
      <view-email :data="emailData"></view-email>
    </template>
  </div>
</template>

<script lang="ts">
  import { Component } from 'vue-property-decorator';
import { EmailFormComponent } from '@/mixins/EmailFormComponent';
import { mixins } from 'vue-class-component';
import { AxiosResponse } from 'axios';
import ViewEmail from '@/components/views/Email.vue';
import api from '@/api';
import { EmailData } from '@/models/EmailData';

@Component({
  components: {
    ViewEmail,
  },
})
export default class EmailForm extends mixins(EmailFormComponent) {
  protected model: any = {};
  protected endpoint!: string;

  protected emailData: EmailData | null = {
    subject: '',
    uuid: '',
  };

  private dataLoading = true;

  protected afterLoad(response: AxiosResponse) {
    const title = this.schema?.title;
    if (title) {
      this.$store.commit('bars/setRightBarTitle', this.$t(title + '.header'));
    }
    this.$store.commit('bars/setRightBarFormSubmit', this.submit);

    this.getEmailData();
  }

  private async getEmailData() {
    this.dataLoading = true;
    const response = await api.get(`/email/${this.emailId}`);
    this.emailData = response.data;
    this.dataLoading = false;
  }

  private get isLoading() {
    return this.loading || this.dataLoading;
  }
}
</script>
