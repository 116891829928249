import Vue from 'vue';
import { format as formatter, isValid } from 'date-fns';
import accounting from '@/utils/accounting';
import { CurrencySettings } from 'accounting';
import currencyToSymbolMap from 'currency-symbol-map/currency-symbol-map';

Vue.filter('datetime', (value: string, format:string = 'dd-MM-yyyy HH:mm:ss') => formatDateTime(value, format));
Vue.filter('date', (value: string) => formatDateTime(value, 'dd-MM-yyyy'));
Vue.filter('currency', (value: number|string, currency: string = 'EUR', options?: CurrencySettings<string>) => {
  return accounting.formatMoney(typeof value === 'string' ? value.replace('.', ',') : value, {...options, symbol: currencyToSymbolMap(currency) ?? currency });
});

function formatDateTime(value: string, format: string) {
  if (!isValid(new Date(value))) {
    return '';
  }
  return formatter(new Date(value), format);
}
