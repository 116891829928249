<template>
  <div :class="['right-bar', { open, enlarged }]">
    <div class="card-head justify-content-between pl-3 pr-0 py-0">
      <div class="h5">{{ title }}</div>
      <div class="d-flex">
        <a @click="formSubmit" v-if="formSubmit" class="nav-link nav-link-right btn btn-sm">
          <Icon prefix="fas" name="save" class="mr-2" />
          {{ $t('save') }}
        </a>
        <a @click="enlarged = !enlarged" class="nav-link nav-link-right btn btn-sm">
          <Icon :name="enlarged ? 'compress-alt' : 'expand-alt'" size="lg" />
        </a>
        <a @click.prevent="handleClose" class="nav-link nav-link-right">
          <icon name="times" :fw="false" />
        </a>
      </div>
    </div>
    <div class="px-3 card-body">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { EventBus } from '@/services/EventBus';

@Component({})
export default class RightBar extends Vue {
  @Prop({ default: false })
  private open!: boolean;

  private enlarged: boolean = false;

  private created() {
    EventBus.$on('form.submit.successful', this.onFormSubmit);
    this.enlarged = this.$route.meta?.enlarge || this.enlarged;
  }
  private beforeDestroy() {
    EventBus.$off('form.submit.successful', this.onFormSubmit);
  }
  private handleClose() {
    this.$emit('close');
    if (this.$route.meta?.rightBarCloseGoTo) {
      this.$router.push({
        name: this.$route.meta?.rightBarCloseGoTo,
        params: this.$route.params,
      });
    } else {
      this.$store.dispatch('bars/closeBar', 'rightBar');
    }
  }

  private onFormSubmit() {
    this.$store.dispatch('bars/closeBar', 'rightBar');
  }

  private get title() {
    return this.$route.meta?.rightBarTitle || this.$store.state.bars.rightBarTitle;
  }
  private get formSubmit() {
    return this.$store.state.bars.rightBarFormSubmit;
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/variables';
@import '~@/assets/scss/colors';
.right-bar {
  position: fixed;
  top: $topbar-height + $titlebar-height;
  bottom: $footer-height;
  min-width: $rightbar-regular;
  right: -$rightbar-regular;
  background-color: $white;
  z-index: 30;
  border-left: solid 1px $medium;
  transition: ease $speed;
  &.open {
    right: 0;
    width: $rightbar-regular;
    &.enlarged {
      width: $rightbar-large;
      @media screen and (max-width: 576px) {
        min-width: 100%;
      }
    }
  }
  > div {
    max-width: 100%;
    overflow: auto;
  }

  .card-body {
    height: calc(100% - 50px);
  }
}
</style>
