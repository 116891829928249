<template>
  <div class="checkbox form-group">
    <label :for="identifier" class="checkbox-label">
      {{ schema.title }}
      <input
        :id="identifier"
        type="checkbox"
        :class="['checkbox-input', { 'is-invalid': hasError }]"
        :value="newVal"
        :checked="newVal"
        @change="updateValue($event.target.checked)"
        :disabled="disableForm"
      />
      <span class="checkmark"></span>
    </label>
    <div class="invalid-feedback" v-if="hasError">
      <p v-for="error in hasError" :key="error">{{ error }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { JSONSchema4 } from 'json-schema';
import { clone } from '@/utils/clone';
import randomize from 'randomatic';

@Component({})
export default class JsonFormBoolean extends Vue {
  @Prop()
  private schema!: JSONSchema4;
  @Prop()
  private id!: string;
  @Prop({ default: false })
  private value!: boolean;
  @Prop({ default: false })
  private required!: boolean;
  @Prop()
  private errors!: any;
  @Prop() private disableForm!: boolean;
  private newVal: boolean;

  private random: string;

  constructor() {
    super();
    this.newVal = clone(this.value);
    this.random = randomize('aA0', 10);
  }

  public created() {
    this.$emit('input', this.newVal);
  }

  public updateValue(value: string) {
    this.newVal = !!value;
    this.$emit('input', this.newVal);
  }

  @Watch('value')
  private valueChanged() {
    this.newVal = !!this.value;
  }

  get hasError() {
    if (
      this.errors &&
      this.errors.errors &&
      this.errors.errors.children &&
      this.errors.errors.children[this.id] &&
      this.errors.errors.children[this.id].errors &&
      this.errors.errors.children[this.id].errors.length
    ) {
      return this.errors.errors.children[this.id].errors;
    }
    return false;
  }

  get identifier() {
    return `${this.id}-${this.random}`;
  }
}
</script>

<style lang="scss" scoped>
.checkbox {
  margin-bottom: 1.5rem;
}
</style>
