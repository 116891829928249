<template>
  <div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import api from '@/api';

@Component({})
export default class Verify extends Vue {
  private async created() {
    const token = this.$route.query.token;
    if (!token) {
      this.$toasted.error(this.$t('auth.verify.no token provided') as string);
      this.$router.replace({ name: 'auth.login' });
      return;
    }
    const res = await api.get(`/user/verify`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    if (res.data.success) {
      this.$toasted.success('auth.verify.success');
      this.$router.replace('auth.login');
    }
  }
}
</script>
