<template>
  <div class="card pb-0 mb-3" :style="{'paddingBottom':'0 !important'}">
    <div class="card-body">
      <data-table
        :model="model"
        @action="doAction"
        :endpoint="endpoint"
        :route-params="routeParams"
        :route-context="$route.name"
        :ref="`${model}List`"
      />
    </div>
  </div>
</template>

<script lang='ts'>
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { SettingsListComponent } from '@/mixins/SettingsListComponent';
import { EmitterAction } from '@/models';

@Component({})
export default class TrialList extends mixins(SettingsListComponent) {
  protected async doAction(action: EmitterAction): Promise<void> {
    if (action.emit === 'reset_trial') {
      await this.showConfirmation(this.$t('creditor.reset_trial.confirmation') as string)
        .then(() => {
          this.post({
            endpoint: `settings/creditor/${action.id}/reset-trial`,
            feedback: { success: this.$t(`creditor.trial.restore.success`) },
          });
        });
    }
    if (action.emit === 'activate') {
      await this.showConfirmation(this.$t('creditor.activate.confirmation') as string)
        .then(() => {
          this.post({
            endpoint: `settings/creditor/${action.id}/restore`,
            feedback: { success: this.$t(`creditor.trial.restore.success`) }
          });
      });
    }

    if (action.emit === 'deactivate') {
      await this.delete(`settings/creditor/${action.id}/delete`);
    }

    this.reload();
  }
}
</script>

<style lang="scss" scoped>

</style>
