<template>
  <div class="d-flex flex-wrap h-100 align-items-center justify-content-center login-container container">
    <div class="col-sm-12 col-lg-8">
      <div class="clearfix">
        <div class="float-right">
          <locale-picker />
        </div>
      </div>
      <div class="card p-5 clearfix">
        <img
          :src="this.$store.getters['platform/logo']"
          class="mb-5 img-fluid mx-auto"
          :style="{ maxHeight: '300px' }"
        />
        <form @submit.prevent="submit" class="form-column">
          <loader :loading="loading"></loader>
          <p>{{ $t('form.forgotpassword.intro') }}</p>
          <json-form
            v-if="schema"
            :schema="schema"
            :errors="errors"
            v-model="model"
            @submit="submit"
          />
          <ul class="list-unstyled">
            <li v-for="(result, index) in rules" :key="index" class="rule" :class="first && result ? 'rule-valid' : 'rule-invalid'">
              <icon :class="{'invisible': !(first && result) }" name="check" />
              <span class="ml-1" v-text="$t(`form.forgotpassword.invalid_password.${index}`)"/>
            </li>
          </ul>
          <button type="submit" class="mt-2 btn btn-primary btn-block" :disabled="!Object.values(rules).every(i => i)">
            <Icon name="angle-right" />
            <span>{{ $t('form.forgotpassword.submit.label') }}</span>
          </button>
        </form>
      </div>
      <p class="text-right mt-2">
        <router-link :to="{ name: 'auth.login' }">
          <small class="font-italic text-muted">{{ $t('form.login_type.submit.label') }}</small>
        </router-link>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { JSONSchema4 } from 'json-schema';
import { FormComponent } from '@/mixins/FormComponent';
import { mixins } from 'vue-class-component';

@Component({
  name: 'SetPassword',
})
export default class SetPassword extends mixins(FormComponent) {
  protected model: any = {
    password: {
      first: '',
      second: '',
    },
  };
  protected endpoint = '/user/password';
  protected created() {
    this.authToken = this.$route.query.token as string;
    this.loadData();
  }

  protected afterSubmit(success: boolean, response: any) {
    if (success && this.schema) {
      this.$toasted.success(this.$t(this.schema.title + '.success') as string);
      return this.$router.push({ name: 'auth.login' });
    }
    if (response.data.success === false) {
      const message = response.data.message || this.$t('login.unknown_error');
      this.$toasted.error(message);
    }
  }
  private get rules(): { [key: string]: boolean } {
    return {
      uppercase: !!this.first?.match(/.*[A-Z]/),
      lowercase: !!this.first?.match(/.*[a-z]/),
      digit: !!this.first?.match(/.*\d/),
      length: this.first?.length >= 8,
      characters: !this.first?.match(/.*[^A-Za-z0-9!?@#$%^&*\s]/),
      match: this.first === this.second,
    };
  }
  private get first(): string {
    return this.model.password.first;
  }
  private get second(): string {
    return this.model.password.second;
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors";

.rule {
  font-weight: 600;
  white-space: nowrap;
  &.rule-invalid {
    color: scale-color($color: $text-muted, $lightness: 50%)
  }
  &.rule-valid {
    color: var(--primary-color);
  }
}
</style>