<template>
  <div>
    <loader :loading="loading" />
    <form v-show="false" @submit.prevent="submit" class="form settings-form">
      <div class="row mb-2">
        <div class="col-sm-3 order-sm-1 text-right">
          <button type="submit" class="btn btn-primary btn-sm">{{ $t('settings.save') }}</button>
        </div>
        <div class="col-sm-9">
          <h2 class="h4 text-highlight">{{ $t('form.changepassword.intro') }}</h2>
        </div>
      </div>
      <json-form v-if="schema" :schema="schema" v-model="model" @submit="submit" :errors="errors" />
    </form>
  </div>
</template>

<script lang="ts">
import { AxiosResponse } from 'axios';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { FormComponent } from '@/mixins/FormComponent';

@Component({
  name: 'ChangePassword',
})
export default class ChangePassword extends mixins(FormComponent) {
  protected model: any = {
    email: '',
  };
  protected endpoint = '/user/changepassword';
  protected reloadAfterSubmit = false;

  protected created() {
    this.loading = true;
    this.loadData();
  }

  protected afterLoad(response: AxiosResponse) {
    const user = this.$store.state.auth.user;
    this.$set(this.model, 'email', user.email);
    this.submit();
  }

  protected async afterSubmit(success: boolean, response: any) {
    if (success && response.data.success && response.data.resetPasswordToken && this.schema) {
      this.$toasted.success(this.$t(this.schema.title + '.success') as string);
      this.$store.commit('auth/logout');
      this.loading = false;
      return this.$router.push({
        name: 'auth.password',
        query: { token: response.data.resetPasswordToken },
      });
    }
    if (response.data.success === false) {
      const message = response.data.message || this.$t('login.unknown_error');
      this.loading = 'error';
      this.$toasted.error(message);
    }
  }
}
</script>
