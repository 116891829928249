<template>
  <div class="d-flex h-100 align-items-center justify-content-center login-container">
    <div class="col-sm-10 col-md-6 col-lg-4">
      <div class="d-flex justify-content-end">
        <locale-picker />
      </div>
      <div class="card p-5 clearfix">
        <img
          :src="this.$store.getters['platform/logo']"
          class="mb-5 img-fluid mx-auto"
          :style="{ maxHeight: '300px' }"
        >
        <LoginForm/>
      </div>
      <p class="text-right mt-2">
        <router-link :to="{ name: 'auth.forgotpassword' }">
          <small class="font-italic text-muted">{{ $t('login.forgot-password') }}</small>
        </router-link>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import LoginForm from '@/components/auth/Login.vue';

@Component({
  components: {
    LoginForm,
  },
})
export default class Login extends Vue {}
</script>
