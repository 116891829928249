<template>
  <div class="card">
    <div class="card-body">
      <data-table
        :model="model"
        :endpoint="$route.meta.endpoint"
        @action="doAction"
        ref="payment_fileList"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ListComponent } from '@/mixins/ListComponent';
import { EmitterAction } from '@/models';
import PaymentFileView from '@/views/importedpayment/payment-file/PaymentFileView.vue';

@Component
export default class PaymentFile extends mixins(ListComponent) {
  protected model: string = 'payment_file';

  protected async doAction(action: EmitterAction) {
    if (action.emit === 'download_sepa') {
      await this.download(`imported_payment/payment_file/${action.id}/sepa/download`)
    }
    if (action.emit === 'download_xls') {
      await this.download(`imported_payment/payment_file/${action.id}/xls/download`)
    }
    if (action.emit === 'delete') {
      return await this.delete(`imported_payment/payment_file/${action.id}/delete`)
    }

    if (action.emit === 'view') {
      return await this.$store.dispatch('bars/openRightBar', {
        component: PaymentFileView,
        title: this.$t(`payment-file.${action.emit}`),
        properties: {
          paymentFile: {
            uuid: action.id,
          },
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
</style>
