<template>
  <span>
    <button
      :class="['btn', {'btn-link p-0 pl-1': action.icon, 'btn-dark btn-sm': !action.icon}]"
      :title="$t(action.emit)"
      v-for="action in filteredEmitterActions"
      :key="action.emit"
      @click.prevent="doAction(action)"
      :disabled="action.status === false"
    >
      <icon v-if="action.icon" :name="action.icon" size="lg"/>
      <template v-else>{{ $t(action.emit) }}</template>
    </button>
  </span>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { EmitterAction, Dictionary } from '@/models';
import DataTable from './DataTable.vue';

@Component({})
export default class Actions extends Vue {
  @Prop({ required: true, type: [Array] })
  public actions!: string[];

  @Prop({ required: true })
  private record!: any;

  @Prop({ required: false })
  private routeContext!: string;

  @Prop({ required: false })
  private routeParams!: any;

  @Prop({ required: false, default: () => new Object() })
  private recordParams!: Dictionary<any>;

  public async doAction(action: string | EmitterAction, newWindow: boolean = false) {
    const parent = this.$parent instanceof DataTable ? this.$parent : this;
    const routerParams = this.getRouteParams(this.record);
    let emitterAction = typeof action === 'string' ? { emit: action } : action;

    if (this.record.tableRowActions[emitterAction.emit] == null) {
      return;
    }

    emitterAction = {
      ...emitterAction,
      params: {...routerParams, ...this.recordParams, newWindow},
      id: this.record.uuid,
      context: this.routeContext,
    };

    parent.$emit(emitterAction.emit, emitterAction);
    parent.$emit('action', emitterAction);
  }

  public get emitterActions(): any {
    return this.actions.map(action => ({
      status: this.record.tableRowActions[action],
      emit: action,
      icon: action,
    }));
  }

  public getRouteParams(record: any) {
    if (!this.routeParams && record) {
      return { id: record.uuid || record.id || '' };
    }
    if (typeof this.routeParams === 'object') {
      const routeParams: any = {};
      Object.keys(this.routeParams).forEach(key => {
        const val = this.routeParams[key];
        if (typeof val === 'string') {
          routeParams[key] = val;
        }
        if (typeof val === 'object' && val.key) {
          routeParams[key] = record[val.key];
        }
      });
      return routeParams;
    }
  }

  public get filteredEmitterActions() : EmitterAction[] {
    return this.emitterActions.filter((action:EmitterAction) => action.status !== null);
  }

}
</script>
