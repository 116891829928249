<template>
  <input
    v-model="value"
    class="form-control input-lg"
    @focus="$event.target.select()"
    @input="$emit('onUpdate', value)"
  >
</template>

<script>
export default {
  props: [
    'initVal'
  ],
  data() {
    return {
      value: 0,
    };
  },
  mounted() {
    this.value = this.initVal;
  }
};
</script>