import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { FormComponent } from '@/mixins/FormComponent';
import { parseEndpoint } from '@/router';

@Component
export class EmailFormComponent extends mixins(FormComponent) {
  @Prop({ required: true })
  protected emailId!: string;

  @Prop({ required: true })
  protected modelId!: string;

  protected endpoint: string = '';

  protected reloadAfterSubmit: boolean = false;

  protected created() {
    this.setEndpointAndLoad();
  }

  @Watch('emailId')
  protected async setEndpointAndLoad() {
    this.endpoint = parseEndpoint('email/:emailId/link', { emailId: this.emailId });
    await this.loadData();
  }

  protected get routeModel() {
    return this.modelId;
  }
}
