<template>
  <div :class="`w-100 mb-3 card card-${icon}`">
    <div class="card-body">
      <div class="h2 card-title text-nowrap" v-html="value" />
      <div
        class="card-subtitle mb-3 text-muted"
      >{{ $t(`graydon.${identifier}.title`) }}</div>
    </div>
    <icon :name="icon" size="lg"></icon>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class Block extends Vue {
  @Prop({ required: true }) private value!: number;
  @Prop({ required: true }) private icon!: string;
  @Prop() private identifier!: string;
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/colors';

.card {
  position: relative;
  > span {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: $medium;
  }
}

.card-body {
  position: relative;
  z-index: 1;
}
</style>

<style lang="scss">
.card > span.wrapper .svg-inline--fa.fa-fw {
  font-size: 5rem;
}
</style>
