<template>
  <div>
    <loader :loading="loading" />
    <form @submit.prevent="submit" v-if="schema">
      <div class="card h-100 mb-3 border-0">
        <div class="card-head justify-content-between py-0 pr-0">
          <h2 class="h5">{{ $t(`${schema.title}.header`) }}</h2>
          <button type="submit" class="nav-link nav-link-right">
            <Icon prefix="fas" name="save" class="mr-2" />
            {{ $t('save') }}
          </button>
        </div>
        <div class="card-body">
          <json-form :schema="schema" v-model="model" :errors="errors" :extra="extra" />
          <button type="submit" class="btn btn-primary btn-sm">{{ $t('save') }}</button>
        </div>
      </div>
    </form>
  </div>
</template>
<script lang="ts">
import { Component, Watch } from 'vue-property-decorator';
import { SettingsFormComponent } from '@/mixins/SettingsFormComponent';
import { mixins } from 'vue-class-component';
import { Action } from '@/models';
import randomize from 'randomatic';

@Component({})
export default class SettingsWorkflowStepEdit extends mixins(SettingsFormComponent) {
  private extra: any = {
    workflowData: {},
  };

  protected afterLoad(response: any) {
    this.model?.actions?.actions.forEach((action: Action) => action.uniq = randomize('Aa0', 10));

    const extra = {...this.extra };
    if (response.data && response.data.actionTypes) {
      extra.workflowData.actionTypes = response.data.actionTypes;
    }
    if (response.data && response.data.conditionTypes) {
      extra.workflowData.conditionTypes = response.data.conditionTypes;
    }
    this.extra = extra;
  }
}
</script>

<style scoped>
</style>
