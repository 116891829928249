<template>
  <div class="position-relative">
    <date-timepicker
       :enableTime="false"
       :value="value"
       @change="setValue"
       @clear="clearValue"
       :placeholder="$t('datatable.search.date.placeholder')"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import DateTimepicker from '@/plugins/flatpickr/Flatpickr.vue';
import format from 'date-fns/format';

const DATE_FORMAT = 'yyyy-MM-dd';

@Component({
  components: {
    DateTimepicker,
  },
})
export default class DateFilter extends Vue {
  @Prop({required: false, default: ''})
  private initialValue!: string;

  private currentValue: string | null = null;

  private created() {
    this.currentValue = this.initialValue;
  }
  private get value() {
    return this.currentValue;
  }

  private setValue(input: string | Date) {
    this.currentValue = format(new Date(input), DATE_FORMAT);
    this.emitUpdate();
  }

  private clearValue() {
    this.currentValue = '';
    this.emitUpdate();
  }

  private emitUpdate() {
    this.$emit('update', this.currentValue);
  }
}
</script>

<style scoped lang="scss">

</style>
