<template>
  <div>
    <div v-for="(property, key) in schema.properties" :key="key">
      <component
        v-if="property.type && schema.widget !== 'hidden' && !isHidden(key)"
        :is="name(property)"
        :schema="property"
        :id="key"
        :value="value[key]"
        @input="updateValue($event, key)"
        :required="isRequired(key)"
        :errors="errors && errors.children && errors.children[key]"
        :labels="labels"
        :disable-rich-text="disableRichText"
        :disable-form="disableForm || schema.readOnly"
        :extra="extra"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { JSONSchema4 } from 'json-schema';
import Ajv from 'ajv';
import MappingDiagram from '@/plugins/json-form/widget-types/MappingDiagram.vue';

@Component({
  components: {
    MappingDiagram
  }
})
export default class JsonFormObject extends Vue {
  @Prop()
  private schema!: JSONSchema4;
  @Prop({ required: true })
  private value!: Value;
  @Prop()
  private errors!: any;
  @Prop()
  private labels!: boolean;
  @Prop()
  private disableRichText!: boolean;
  @Prop()
  private disableForm!: boolean;
  @Prop()
  private extra!: any;

  protected name(property: any) {
    if (property.type === 'action') {
      return 'workflow';
    }
    if (property.type === 'filter') {
      return 'segmentation';
    }
    if (property.type === 'number' || property.type === 'integer') {
      property.widget = 'number';
      property.type = 'string';
    }
    if (property.type === 'object' && property.widget === 'mapping-diagram') {
      return 'mapping-diagram'
    }
    return `json-form-${property.type}`;
  }
  protected updateValue(value: any, key: string) {
    const newValue = { ...this.value };

    newValue[key] = value;
    this.$emit('input', newValue);
  }

  protected isRequired(key: string) {
    const checks = this.getAnyOfSchemasForChecks(key, 'required');

    if (checks.some(schema => (new Ajv({ strict: false })).validate(schema, this.value))) {
      return true;
    }

    if  (this.schema.required === undefined) {
      return false;
    }

    if (typeof this.schema.required === "boolean") {
      return this.schema.required;
    }

    return this.schema.required.indexOf(key) !== -1;
  }

  protected isHidden(key: string) {
    const checks = this.getAnyOfSchemasForChecks(key, 'hidden');

    return checks.some(schema => {
      const ajv = new Ajv({ strict: false });
      return ajv.validate(schema, this.value);
    });
  }

  protected getAnyOfSchemasForChecks(property: string, type: string): JSONSchema4[] {
    const anyOf = [...(this.schema.anyOf || []), ...(this.schema.oneOf || [])];

    return anyOf.filter(entry => {
      return entry.hasOwnProperty(type) && entry[type].indexOf(property) !== -1;
    });
  }
}

interface Value {
  [key: string]: string | string[] | Value;
}
interface XOfValue {
  [key: string]: string[];
}
</script>
