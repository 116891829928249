import { render, staticRenderFns } from "./MappingDiagramTag.vue?vue&type=template&id=0ef9e7a2&scoped=true"
import script from "./MappingDiagramTag.vue?vue&type=script&lang=ts"
export * from "./MappingDiagramTag.vue?vue&type=script&lang=ts"
import style0 from "./MappingDiagramTag.vue?vue&type=style&index=0&id=0ef9e7a2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ef9e7a2",
  null
  
)

export default component.exports