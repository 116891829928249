import Vue, { AsyncComponent, ComponentOptions } from 'vue';
import OutstandingReportStatistic from '@/components/report/statistic/OutstandingStatistic.vue';
import DebtorAnalysisReportStatistic from '@/components/report/statistic/DebtorAnalysisStatistic.vue';
import DebtorBestWorstAnalysisReportStatistic from '@/components/report/statistic/DebtorBestWorstAnalysisStatistic.vue';
import { ValidStatisticType } from '@/models/ReportStatistic';
import MissingDataAnalysisReportStatistic
  from '@/components/report/statistic/MissingDataAnalysisStatistic.vue';
import DsoAnalysisReportStatistic from '@/components/report/statistic/DsoAnalysisStatistic.vue';

export type VueComponent = ComponentOptions<Vue> | typeof Vue | AsyncComponent;

export const reportStatisticComponentMap: Record<ValidStatisticType, VueComponent|undefined> = {
  'outstanding': OutstandingReportStatistic,
  'debtor-analysis': DebtorAnalysisReportStatistic,
  'debtor-best-worst': DebtorBestWorstAnalysisReportStatistic,
  'missing-data': MissingDataAnalysisReportStatistic,
  'dso': DsoAnalysisReportStatistic
}
