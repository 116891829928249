<template>
  <div class="btn-group-sm">
    <span
      class="btn btn-primary btn-sm mr-2 px-2 py-1"
      :id="option"
      v-for="option in stringOptions"
      :key="option"
      @click="doBulkAction(option)"
      :title="customLabel(option)"
    >
      <div>{{ customLabel(option) }}</div>
    </span>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { EmitterAction } from '@/models';
import DataTable from './DataTable.vue';

@Component({})
export default class BulkActions extends Vue {
  @Prop({ required: false, default: [] })
  private options!: Array<string | EmitterAction>;
  @Prop({ required: false, default: null })
  private selectDomain!: string|null;
  @Prop({ required: false })
  private routeContext!: string;
  @Prop({ required: false })
  private routeParams!: any;

  private customLabel(label: string) {
    return this.$t(`datatable.bulk-actions.${label}`);
  }

  private async doBulkAction(selected: string) {
    const parent = this.$parent instanceof DataTable ? this.$parent : this;

    const option = this.options.find(
      option => (typeof option !== 'string' ? option.emit === selected : option === selected)
    );

    if (option == null) {
      return;
    }

    if (option === 'cancel') {
      await this.showConfirmation(this.$t(`delete`) as string)
        .then(() => {
          parent.$emit('bulkAction', {
            emit: option,
            selectDomain: this.selectDomain,
            context: this.routeContext,
          })
        });
    }

    parent.$emit('bulkAction', {
      emit: option,
      selectDomain: this.selectDomain,
      context: this.routeContext,
    });
  }

  get stringOptions() {
    return this.options.map(option => (typeof option !== 'string' ? option.emit : option));
  }

  protected async showConfirmation(message: string) {
    return new Promise<any>((resolve) => {
      this.$store.dispatch('modal/open', {
        body: message,
        actions: [
          {
            label: this.$t('modal.record.delete.cancel') as string,
            class: 'btn-dark',
            action: async () => this.$store.dispatch('modal/close'),
          },
          {
            label: this.$t('modal.record.delete.confirm') as string,
            class: 'btn-primary',
            action: async () => {
              this.$store.dispatch('modal/close').then(resolve);
            },
          },
        ],
      });
    });
  }
}
</script>

<style scoped lang="scss">
</style>
