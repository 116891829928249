<template>
  <footer class="footer px-3 py-1">
    &copy; {{ currentYear }} DunningOnline
    <div class="float-right success d-none d-lg-block">
      <icon prefix="fas" name="check-circle"></icon>
      {{ new Date() | datetime }}
    </div>
  </footer>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
})
export default class FooterBar extends Vue {}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';
@import '~@/assets/scss/colors';

.footer {
  transition: left ease $speed;
  height: $footer-height;
  font-size: 1.2rem;
  color: $dark;
  background-color: $white;
  border-top: solid $medium 1px;
  box-sizing: border-box;
}
</style>
