<template>
  <form @submit.prevent="submit" class="form form-column">
    <loader :loading="loading"></loader>
    <json-form
      v-if="schema"
      :schema="schema"
      v-model="model"
      @submit="submit"
    ></json-form>
    <button type="submit" class="mt-4 btn btn-primary btn-block">
      <Icon name="angle-right" />
      <span>{{ $t('form.login_type.submit.label') }}</span>
    </button>
  </form>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';

import axios from '@/api/axios';
import { AuthService } from '@/services/AuthService';

@Component({
  name: 'LoginForm',
})
export default class Form extends Vue {
  private loading = true;

  private model: {_username?: string, _password?: string} = {};

  private schema: any = null;

  private csrfToken?: string;

  private authService! : AuthService;

  private async created() {
    if (this.$store.state.auth.mfa) {
      return this.$router.push({ name: 'auth.mfa' });
    }
    this.authService = new AuthService(this.$store);

    this.loadSchema();
  }

  @Watch('$store.state.i18n.locale')
  private async loadSchema() {
    const locale = this.$store.state.i18n.locale;
    const result = await axios.get(`/auth/login?_locale=${locale}`);
    this.schema = result.data.form.schema;
    this.csrfToken = result.data.form.csrf_token.value;
    this.loading = false;
  }

  private async submit() {
    this.loading = true;

    await this.updateFormInput();

    await this.login();

    this.loading = false;
  }

  private async login () {
    const { data } = await this.authService.checkCredentials(this.model._username!, this.model._password!);

    if (this.authService.requiresMfa(data)) {
      await this.$store.dispatch('auth/beginMfa');
      return this.$store.dispatch('redirect', { name: 'auth.mfa' }, { root: true });
    }

    await this.authService.login(data.token, data.data?.user);

    const redirectRoute = this.$store.state.auth.acl?.subscription_wizard?.api_subscription_wizard_info ? 'wizard.info' : 'dashboard.index';

    return this.$store.dispatch('redirect', { name: redirectRoute }, { root: true });
  }

  private async updateFormInput() {
    //trigger change to register autofill values
    const elems = this.$el.querySelectorAll('input');
    for (const elem of elems) {
      if (elem.id === '_username') {
        elem.dispatchEvent(new Event('input'));
      }
    }
  }
}
</script>
