import { Module } from 'vuex';

import { RootState } from '@/models';
import { isEqual } from 'lodash';
import api from '@/api';
import { DebtorGroupState } from '@/models/DebtorGroupState';
import { DebtorGroup } from '@/models/DebtorGroup';

export const debtorGroup: Module<DebtorGroupState, RootState> = {
  namespaced: true,
  state: {
    loading: false,
    currentDebtorGroup: null,
  },
  actions: {
    async loadCurrentDebtorGroup({ state, commit, dispatch }, groupId) {
      commit('setLoading', true);
      try {
        const res = await api.get('/debtor/group/' + groupId + '/contact_info');

        if (!res?.data) {
          dispatch('throwError', { msg: 'Did not receive the correct parameters' }, { root: true });
          return;
        }

        if (isEqual(state.currentDebtorGroup, res.data)) {
          return commit('setLoading', false);
        }

        commit('setCurrentDebtorGroup', res.data);
      } catch (_) {
        commit('setLoading', 'error');
      }
    },
    unloadData({ commit }) {
      commit('unsetData');
    },
  },
  mutations: {
    setLoading(state, loading: boolean) {
      state.loading = loading;
    },
    setCurrentDebtorGroup(state, currentDebtorGroup: DebtorGroup) {
      state.currentDebtorGroup = currentDebtorGroup;
    },
  },
};
