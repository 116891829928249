<template>
  <div>
    <loader :loading="loading"/>
    <template v-if="!loading || schema">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="trySubmit" class="form" v-if="schema" :ref="schema.title">
            <json-form :schema="schema" v-model="model" :errors="errors" @input="formUpdated"/>
            <div class="offset-sm-2 pl-1">
              <div class="row row-cols-1 row-cols-md-2 row-cols-xl-4" v-if="validationInfo">
                <div class="text-center">
                  <p class="text-primary font-weight-bold">{{ $t('numberOfTransactions') }}</p>
                  <p>{{ validationInfo.numberOfTransactions }}</p>
                </div>
                <div class="text-center">
                  <p class="text-primary font-weight-bold">{{ $t('lastAccountBalance') }}</p>
                  <p>{{ validationInfo.lastAccountBalance | currency }}</p>
                </div>
                <div class="text-center">
                  <p class="text-primary font-weight-bold">{{ $t('statementStartBalance') }}</p>
                  <p :class="validated ? 'text-success' : 'text-danger'" class="font-weight-bold">{{ validationInfo.statementStartBalance | currency }}</p>
                </div>
                <div class="text-center">
                  <p class="text-primary font-weight-bold">{{ $t('statementAmount') }}</p>
                  <p>{{ validationInfo.statementAmount | currency }}</p>

                </div>
              </div>
                <button v-if="validated" type="submit" class="btn btn-primary btn-sm w-100">
                  <Icon prefix="fas" name="save" class="mr-2"/>
                  {{ $t('imported_payment.upload') }}
                </button>
              <button v-else type="button" @click.prevent="validate" class="btn btn-secondary btn-sm w-100">
                <Icon prefix="fas" name="save" class="mr-2"/>
                {{ $t('imported_payment.validate') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { FormComponent } from '@/mixins/FormComponent';
import { AxiosResponse } from 'axios';

interface ValidationResult {
  numberOfTransactions: number;
  statementAmount: number;
  statementStartBalance: number;
  lastAccountBalance: number;
}
@Component({})
export default class ImportedPaymentCreate extends mixins(FormComponent) {
  protected model: string = 'create';
  protected validated: boolean = false;
  protected validateEndpoint: string = '/imported_payment/upload/validate';
  protected endpoint: string = '/imported_payment/upload';
  protected validationInfo: ValidationResult|null = null;

  private mounted() {
    this.loadData();
  }
  protected formUpdated() {
    this.validated = false;
    this.validationInfo = null;
  }
  protected async validate() {
    this.endpoint = this.validateEndpoint;
    await this.submit();
  }
  protected async handleSubmitSuccessful(res: AxiosResponse) {
    this.loading = false;
    const { data } = res;
    if (data.numberOfTransactions) {
      this.validationInfo = res.data;
      this.validated = data.statementStartBalance === data.lastAccountBalance;
      this.endpoint = '/imported_payment/upload';
      return;
    }
    if (data.success) {
      this.afterSubmit(true, res);
    }
  }

  protected async handleSubmitFailed(res: AxiosResponse) {
    this.loading = false;
    const { data } = res;

    if (!data.success) {
      this.$toasted.error(this.$t(data.status) as string);
    }
  }

  protected async trySubmit() {
    if (!this.validated) {
      return this.$toasted.error(this.$t('imported_payment.validate_first') as string);
    }
    await this.submit();
  }
  }
</script>
