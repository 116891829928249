<template>
  <div>
    <loader :loading="loading" />
    <form @submit.prevent="submit" class="form settings-form mb-3 h-100" v-if="schema">
      <div class="card">
        <div class="card-head justify-content-between py-0 pr-0">
          <h2 class="h5">{{ $t(`${schema.title}.header`) }}</h2>
          <button type="submit" class="nav-link nav-link-right">
            <Icon prefix="fas" name="save" class="mr-2" />
            {{ $t('save') }}
          </button>
        </div>
        <div class="card-body">
          <div class="mb-3 alert alert-danger" v-if="message">{{ $t(message) }}</div>
          <json-form :schema="schema" v-model="model" :errors="errors" />
          <div
            :class="[
              { 'offset-sm-2 pl-1': !this.$route.meta?.buttonCentered },
              { 'd-flex align-items-center justify-content-center': this.$route.meta?.buttonCentered },
            ]"
          >
            <button
              type="submit"
              :class="[{ 'w-50 align-self-center':this.$route.meta?.buttonCentered}, {'w-100': !this.$route.meta?.buttonCentered}, 'btn btn-primary btn-sm ']"
            >
              <Icon prefix="fas" name="save" class="mr-2" />
              {{ $t('settings.save') }}
            </button>
          </div>
        </div>
      </div>
    </form>
    <div class="card" v-if="this.$route.meta && this.$route.meta?.translation">
      <div class="card-head justify-content-between py-0 pr-0">
        <h2 class="h5">{{ $t('settings.translation.overview') }}</h2>
        <router-link
          :to="{
            name: 'settings.template.edit.translation.create',
            params: Object.assign({}, $route.params, { template: this.$route.params.template }),
          }"
          class="nav-link nav-link-right"
        >
          <icon class="icon-plus" name="plus" />
        </router-link>
      </div>
      <div class="card-body table-responsive">
        <SettingsTranslationList />
      </div>
    </div>
    <div class="card" v-if="this.$route.meta && this.$route.meta?.workflow">
      <div class="card-head justify-content-between py-0 pr-0">
        <h2 class="h5">{{ $t('settings.workflow.steps.overview') }}</h2>
        <router-link
          :to="{ name: 'settings.workflow.edit.step.create', params: { model: $route.meta?.model } }"
          class="nav-link nav-link-right"
        >
          <icon class="icon-plus" name="plus" />
        </router-link>
      </div>
      <div class="card-body table-responsive">
        <SettingsWorkflowList />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import SettingsWorkflowList from './workflow/List.vue';
import SettingsTranslationList from './translation/List.vue';
import { SettingsFormComponent } from '@/mixins/SettingsFormComponent';
import { mixins } from 'vue-class-component';
import { AxiosResponse } from 'axios';

@Component({
  components: { SettingsWorkflowList, SettingsTranslationList },
})
export default class SettingsForm extends mixins(SettingsFormComponent) {
  protected message: string = '';

  protected paymentUrl: string = '';
  protected paymentPopup: Window | null = null;
  protected paymentStatus: string = '';

  protected afterLoad(response: AxiosResponse) {
    this.message = response.data && response.data.message;
  }

  protected openPaymentPopup() {
    this.paymentPopup = window.open(
      this.paymentUrl,
      this.$t('exact_online.auth') as string,
      'toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=400,height=700,top=100,left=100',
    );
    window.addEventListener('message', this.handlePayment);
  }

  protected handlePayment(event: MessageEvent) {
    if (this.paymentPopup && event.data && event.data.status) {
      window.removeEventListener('message', this.handlePayment);
      this.paymentPopup.close();
      this.paymentStatus = event.data.status;
    }
  }
}
</script>
