export function dictMap<ReturnValue, Value>(input: {[key: string]: Value}, cb: (value: Value, key: string) => ReturnValue): {[id: string]: ReturnValue} {
  const result: {[key: string]: ReturnValue} = {};
  for(const key in input) {
    if(!input.hasOwnProperty(key)) {
      continue;
    }
    result[key] = cb(input[key], key);
  }
  return result;
}

export function dictFilter<Value>(input: Record<string, Value>, cb: (value: Value, key: string) => boolean): Record<string, Value> {
  const result: Record<string, Value> = {};
  for(const key in input) {
    if(!input.hasOwnProperty(key)) {
      continue;
    }
    if(cb(input[key], key)) {
      result[key] = input[key];
    }
  }
  return result;
}
