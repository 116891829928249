<template>
  <div class="card">
    <div class="card-head justify-between py-0 pr-0">
      <h2 class="h5">{{ $t('manual-bulk.mapping.title') }}</h2>
      <router-link
        :to="{ name: 'settings.businessunit.sync.mapping.create', params: { businessUnit: $route.params.id } }"
        class="nav-link nav-link-right"
      >
        <icon class="icon-plus" name="plus" :title="$t('manual-bulk.mapping.create')" />
      </router-link>
    </div>
    <div class="card-body">
      <data-table
        :endpoint="endpoint"
        :model="model"
        @action="doAction"
        ref="mappingList"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { EmitterAction } from '@/models';
import { ListComponent } from '@/mixins/ListComponent';

@Component({
  components: {},
})
export default class Mapping extends mixins(ListComponent) {
  protected model: string = 'mapping';

  protected async doAction(action: EmitterAction) {
    if (action.emit === 'delete') {
      const params = this.$route.params;
      let endpoint =  `settings/businessunit/${params.id}/sync/mapping/${action.id}/delete`;
      this.delete(endpoint);
    } else if (action.emit === 'use_mapping') {
      const params = this.$route.params;
      this.$router.push({
        name: `settings.businessunit.sync.mapping.manual-bulk-update`,
        params: {
          id: params.id,
          mappingId: action.id,
        },
      });
    }
  }

  protected get endpoint(): string {
    const params = this.$route.params;
    return `settings/businessunit/${params.id}/manual-bulk-update/mapping`;
  }
}
</script>
