<template>
  <div class="card">
    <div class="card-head justify-content-between">
      <h2 class="h5">{{ $t('dashboard.financial-information.title') }}</h2>
      <div class="d-flex select-none" v-if="financialInformation && financialInformation.available_currencies.length > 1">
        <transition name="fade">
          <div v-if="showCurrencyDropdown" class="d-flex">
            <div
              v-for="option in availableCurrencySymbols"
              :key="option.currency"
              v-text="option.symbol"
              @click="onCurrencyChange(option.currency)"
              class="text-xl text-muted cursor-pointer mx-1 currency option"
            />
          </div>
        </transition>
        <div class="mx-3">
          <div class="text-xl text-muted cursor-pointer currency" v-text="activeCurrencySymbol" @click.self="toggleCurrencyChange"/>
        </div>
      </div>
    </div>

    <div class="card-body p-0 overflow-x-auto">
      <table class="table mb-0">
        <tbody>
          <tr v-for="(chunkedMetrics, i) in financialMetrics" :key="i">
            <td v-for="(metric, k) in chunkedMetrics" :key="k">
              <span v-if="metric.key.endsWith('_amount')" class="text-xl">{{ metric.value | currency(currency) }}</span>
              <span v-else class="text-xl">{{ metric.value }}</span>

              <span>{{ $t('dashboard.financial-information.' + metric.key) }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { FinancialInformation as FinancialInformationData } from '@/models';
import currencyToSymbolMap from 'currency-symbol-map/currency-symbol-map';
import { FinancialMetric } from '@/models/FinancialInformation';
import { chunk, forEach } from 'lodash';
@Component({})
export default class FinancialInformation extends Vue {

  private showCurrencyDropdown: boolean = false;
  @Prop()
  private financialInformation!: FinancialInformationData;

  private onCurrencyChange(currency: string) {
    this.showCurrencyDropdown = false;
    this.$store.dispatch('dashboard/loadFinancialInformation', { currency });
  }
  private get currency(): string {
    return this.financialInformation.currency;
  }
  private get activeCurrencySymbol(): string {
    return currencyToSymbolMap(this.currency) ?? this.currency;
  }

  private get availableCurrencySymbols(): Array<{ currency:string, symbol:string }> {
    return this.financialInformation.available_currencies
      .filter(item => item !== this.currency)
      .map((item) => ({ currency: item, symbol: currencyToSymbolMap(item) ?? item }));
  }

  private toggleCurrencyChange(): void {
    this.showCurrencyDropdown = !this.showCurrencyDropdown;
  }
  private get financialMetrics(): FinancialMetric[][] {
    const sortedMetrics = this.financialInformation?.data.sort((m1, m2) => m1.position - m2.position);

    return chunk(sortedMetrics, 5);
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/colors';
@import '~@/assets/scss/_variables';

table {
  border-collapse: collapse;
  color: $dark;
}
table td,
table th {
  border: 1px solid $medium;
  height: 100px;
  vertical-align : middle;
  padding-left: 20px;
}
table tr:first-child td {
  border-top: 0;
}
table tr:last-child td {
  border-bottom: 0;
}
table tr td:first-child,
table tr th:first-child {
  border-left: 0;
}
table tr td:last-child,
table tr th:last-child {
  border-right: 0;
}
.text-xl {
  display: block;
  color: $text-color;
}
.currency {
  transition: opacity ease-out $speed;
  opacity: .75;
  &.option {
    opacity: .5;
  }
  &:hover {
    opacity: 1;
  }
}

</style>
