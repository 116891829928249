<script lang="ts">
import { Doughnut } from 'vue-chartjs';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

//@ts-ignore
@Component({
  extends: Doughnut,
})
export default class GaugeChart extends Vue<Doughnut> {
  @Prop()
  protected data!: any;

  private mounted() {
    this.draw();
  }

  @Watch('data')
  private draw() {
    this.renderChart(this.renderedData(), this.options);
  }
  private renderedData() {
    return {
      datasets: [
        {
          data: [this.red, this.yellow, this.green],
          backgroundColor: ['hsl(5, 75%, 60%)', 'hsl(50, 75%, 60%)', 'hsl(110, 75%, 60%)'],
          hoverBackgroundColor: ['hsl(5, 75%, 60%)', 'hsl(50, 75%, 60%)', 'hsl(110, 75%, 60%)'],
          borderWidth: 0,
          hoverBorderWidth: 0,
        },
      ],
      maxDays: this.data.meta.maximumThreshold,
      value: this.value,
    };
  }
  get options() {
    return {
      cutoutPercentage: 50,
      rotation: -Math.PI,
      circumference: Math.PI,
      hover: {
        animationDuration: 0,
      },
      animation: {
        duration: 0,
        onComplete: (chartObject: any) => {
          const chart = chartObject.chart;
          const canvas = chart.canvas;
          const data = chart.config.data;
          const ctx = canvas.getContext('2d');
          const cw = chart.width;
          const ch = chart.height;

          const xPos = cw / 2;
          const yPos = ch / 2 + chart.chart.radiusLength;
          const val = data.value;
          let radian = 6.3 - (3.15 / data.maxDays) * val;
          radian = radian < 3.15 ? 3.15 : radian;
          radian = radian > 6.275 ? 6.275 : radian;
          ctx.lineWidth = 3;
          ctx.beginPath();
          ctx.moveTo(xPos, yPos);

          ctx.lineTo(xPos + xPos * Math.cos(radian), yPos + xPos * Math.sin(radian));
          ctx.strokeStyle = 'rgba(0,0,0,0.6)';
          ctx.stroke();
        },
      },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
    };
  }

  get value() {
    return Number(this.data.dataSet[this.data.dataSet.length - 1].value);
  }
  get green() {
    return Number(this.data.meta.successThreshold);
  }
  get yellow() {
    return Number(this.data.meta.warningThreshold - this.green);
  }
  get red() {
    return Number(this.data.meta.maximumThreshold - (this.yellow + this.green));
  }
}
</script>

<style>
</style>
