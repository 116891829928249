<template>
  <div>
    <div
      class="card pb-0 mb-3"
      :style="{ paddingBottom: '0 !important' }"
      v-if="$store.state.auth.acl.settings.api_setting_permission_group_index"
    >
      <div class="card-head justify-content-between py-0">
        <h2 class="h5">{{ $t('settings.permissions.group.header') }}</h2>
        <router-link
          :to="{
            name: action.name,
            params: Object.assign({}, $route.params, action && action.params),
          }"
          class="nav-link nav-link nav-link-right"
          v-for="action in actions"
          :key="action.name"
        >
          <icon :name="action.icon" size="lg" />
        </router-link>
      </div>
      <div class="card-body">
        <data-table
          :model="model"
          @action="doAction"
          :endpoint="endpoint"
          :ref="`${model}List`"
          :route-params="routeParams"
          :route-context="$route.name"
        />
      </div>
    </div>
    <Tabbable :tabs="tabs" />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Tabbable from '@/views/Tabbable.vue';
import { SettingsListComponent } from '@/mixins/SettingsListComponent';
import DataTable from '@/plugins/data-table/DataTable.vue';
import { EmitterAction, TabInterface } from '@/models';
import { parseEndpoint, hasRoute } from '@/router';

@Component({
  components: {
    Tabbable,
  },
})
export default class PermissionIndex extends mixins(SettingsListComponent) {
  protected model: string = 'permissiongroup';
  private tabs: TabInterface[] = [
    {
      name: 'settings.permissions.business_unit_entity_link',
      acl: 'api_setting_permission_business_unit_entity_link_overview_index',
    },
    {
      name: 'settings.permissions.business_unit_entity_link_permission_group_link',
      acl: 'api_setting_permission_entity_user_link_business_unit_entity_link_overview_index',
    },
    {
      name: 'settings.permissions.entity_user_link',
      acl: 'api_setting_permission_entity_user_link_overview_index',
    },
  ];

  protected async doAction(action: EmitterAction) {
    const { params, name, meta } = this.$route;
    this.dataTable = this.$refs[this.model + 'List'] as DataTable;
    const endpoint = `/settings/permission/group/${action.id}/${action.emit}`;

    if (action.emit === 'delete') {
      await this.delete(endpoint);
    } else {
      const newRoute = {
        name: `settings.permissions.${action.emit}`,
        params: {
          ...params,
          ...action.params,
          model: meta?.model,
        },
      };
      this.$router.push(newRoute);
    }
  }
  protected get endpoint() {
    return `settings/permission/group`;
  }
  get actions() {
    return [{ icon: 'plus', name: 'settings.permissions.create' }];
  }
}
</script>
