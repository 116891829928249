import { Component } from 'vue-property-decorator';
import { TaskListComponent } from '@/mixins/TaskListComponent';
import { Task, EmitterAction } from '@/models';

@Component
export class ActionListMixin extends TaskListComponent {
  private get openActions(): Task[] {
    return this.$store.getters['action/open'];
  }
  private get currentDatatableList(): Task[] {
    return this.$store.getters['datatable/open'];
  }

  protected async afterAction() {
    return this.loadData();
  }

  protected async loadData() {
    //Empty so we can prep for the load
    this.$store.commit('bars/setTree', []);
    const data = await this.get(`/dashboard/actions/filters`);
    this.$store.commit('action/setOpenActions', data);
  }

  private async created() {
    await this.loadData();
    this.$store.commit('bars/setTree', this.openActions);
  }

  private async doActionAndUpdate(action: EmitterAction) {
    await this.doAction(action);
    await this.afterAction();
  }

  private async doBulkActionAndUpdate(action: any) {
    await this.doBulkAction(action);
    await this.afterAction();
  }
}
