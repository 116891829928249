import Vue from 'vue';
import Icon from './Icon.vue';
import LocalePicker from './LocalePicker.vue';

import SortIcon from './SortIcon.vue';
import Loader from './Loader.vue';
import Block from './Block.vue';
import Modal from './Modal.vue';
import Placeholder from './Placeholder.vue';

Vue.component('LocalePicker', LocalePicker);
Vue.component('Placeholder', Placeholder);
Vue.component('SortIcon', SortIcon);
Vue.component('Loader', Loader);
Vue.component('Block', Block);
Vue.component('Modal', Modal);
Vue.component('Icon', Icon);
