<template>
  <div class="card pb-0 mb-3" :style="{'paddingBottom':'0 !important'}">
    <div class="card-head justify-content-between py-0" v-if="hasCreditorTrialModeEnabled">
      <h2 class="h5">{{ $t('settings.creditor.header') }}</h2>
      <div class="d-flex">
        <router-link
          :to="{ name: 'settings.creditor.trial' }"
          class="nav-link nav-link nav-link-right"
        >
          <icon name="user-graduate" size="lg" />
        </router-link>
      </div>
    </div>
    <div class="card-body">
      <data-table
        :model="model"
        @action="doAction"
        :endpoint="endpoint"
        :route-params="routeParams"
        :route-context="$route.name"
        :ref="`${model}List`"
      />
    </div>
  </div>
</template>

<script lang='ts'>
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { SettingsListComponent } from '@/mixins/SettingsListComponent';

@Component({})
export default class List extends mixins(SettingsListComponent) {

  private get hasCreditorTrialModeEnabled(): boolean {
    return this.$store.state.auth.acl.functionality_setting.creditor_trial_mode;
  }
}
</script>

<style lang="scss" scoped>

</style>
