// mixin.js
import Component, { mixins } from 'vue-class-component';
import { EmitterAction } from '@/models';
import DataTable from '@/plugins/data-table/DataTable.vue';
import { ListComponent } from '@/mixins/ListComponent';
import EmailForm from '@/views/email/Form.vue';
import { appendQueryParams } from '@/utils/queryString';

@Component
export class EmailListComponent extends mixins(ListComponent) {
  protected model: string = 'email';

  protected async doAction(action: EmitterAction) {
    this.dataTable = this.$refs[this.model + 'List'] as DataTable;

    switch(action.emit) {
      case 'link_dialog':
        return this.openLinkView(action.id!);

      case 'view':
        return this.$router.push({
          name: `${this.model}.${action.emit}`,
          params: { [this.model + 'Id']: action.id as string },
        });

      case 'delete':
        return this.delete(`${this.model}/${action.id}/delete`);
    }
  }

  protected async doBulkAction(action: any) {
    this.dataTable = this.$refs[this.model + 'List'] as DataTable;
    let payload = {};
    payload = {
        domain: action.selectDomain,
    };
    if (action.emit === 'link_selection') {
      return this.post({
        endpoint: `/email/link`,
        payload,
        feedback: { success: this.$t(`${this.model}.${action.emit}.success`) },
      });
    }
    if (action.emit === 'delete') {
      return this.delete(appendQueryParams(`/email/delete`, payload));
    }
  }

  protected get endpoint() {
    return `/${this.$route.meta?.endpoint}`;
  }

  private openLinkView(emailId: string) {
    return this.$store.dispatch('bars/openRightBar', {
      component: EmailForm,
      properties: {
        emailId,
        modelId: 'email',
      }
    });
  }
}
