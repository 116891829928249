<template>
  <div class="card h-100" v-if="data">
    <div class="card-head justify-between">
      <h2 class="h5">{{ $t(`report-statistic.outstanding.title`) }}</h2>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-6">
           <h6>{{ $t(`report-statistic.outstanding.totals.open_amount.title`)}}</h6>
          <h4 class="h3 text-primary">{{ data.totals.open_amount | currency(currency) }}</h4>
          <bar-chart :height="300" :data="data.groupedByPeriod" :colors="colors" />
        </div>
        <div class="col-6">
          <h6>{{ $t(`report-statistic.outstanding.totals.due_amount.title`)}}</h6>
          <h4 class="h3 text-primary">{{ data.totals.due_amount | currency(currency) }}</h4>
          <div v-for="(value, key) in data.groupedByPeriod" :key="key"
               class="d-flex align-items-center">
            <div
              class="mr-2"
              style="height: 10px; width: 10px; border-radius: 9999px;"
              :style="{'backgroundColor': getColorByKey(key)}" />
            <div class="d-flex justify-content-between w-100">
              <div>{{ $t(key) }}</div>
              <div>{{ value | currency(currency) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ReportStatisticComponent } from '@/mixins/ReportStatisticComponent';
import { AxiosResponse } from 'axios';
import BarChart from '@/components/report/statistic/charts/OutstandingBarChart';

interface OutstandingData {
  groupedByPeriod: Record<string, number>;
  totals: {
    open_amount: number,
    due_amount: number,
  };
}

@Component({
  components: { BarChart },
})
export default class OutstandingReportStatistic extends mixins(ReportStatisticComponent) {

  protected readonly endpoint: string = 'report/statistics/outstanding';
  protected data: OutstandingData | null = null;

  private colors: string[] =  ['#B3FF97','#9EDC9E','#5CB85C','#FFC584','#FFA14D','#FF8333','#FF8C8C','#FF5454', '#FF1A1A', '#3399FF', '#0EAE96FF', '#049A84FF', '#047867FF'];

  protected handleResponse({ data }: AxiosResponse<OutstandingData>) {
    this.data = data;
  }

  protected getColorByKey(key: string): string {
    if (!this.data?.groupedByPeriod) {
      return this.colors[0];
    }
    return this.colors[Object.keys(this.data.groupedByPeriod).indexOf(key)];
  }


}
</script>

<style lang="scss" scoped>

</style>
