<script lang="ts">
import { Doughnut } from 'vue-chartjs';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

//@ts-ignore
@Component({
  extends: Doughnut,
})
export default class DoughnutChart extends Vue<Doughnut> {
  @Prop()
  protected data: any;

  private mounted() {
    this.draw();
  }

  @Watch('data')
  private draw() {
    this.renderChart(this.renderedData(), this.options);
  }
  private renderedData() {
    return {
      datasets: [
        {
          data: [this.failure, this.success],
          backgroundColor: ['hsl(5, 75%, 60%)', 'hsl(110, 75%, 60%)'],
          hoverBackgroundColor: ['hsl(5, 75%, 60%)', 'hsl(110, 75%, 60%)'],
          borderWidth: 0,
          hoverBorderWidth: 0,
        },
      ],
    };
  }
  get options() {
    return {
      cutoutPercentage: 50,
      rotation: -Math.PI / 2,
      circumference: 2 * Math.PI,
      responsive: true,
      hover: {
        animationDuration: 0,
      },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      animation: {
        onComplete: (chartObject: any) => {
          const chart = chartObject.chart;
          const canvas = chart.canvas;
          const data = chart.config.data.datasets[0].data;
          const ctx = canvas.getContext('2d');
          const cw = chart.width;
          const ch = chart.height;

          ctx.font = (ch / 144).toFixed(2) + 'em Oxygen';
          ctx.textBaseline = 'middle';

          const text = Number(data[1]).toFixed(2) + '%';
          const textX = Math.round((cw - chart.ctx.measureText(text).width) / 2);
          const textY = ch / 2;

          ctx.fillText(text, textX, textY);
        },
      },
    };
  }
  get success() {
    return Number(this.data);
  }
  get failure() {
    return Number(100 - this.success);
  }
}
</script>

<style>
</style>