<template>
  <div>
    <label class="file-select d-flex" v-if="multiple || !value.length">
      <div class="select-button cursor-pointer text-nowrap" :class="{ disabled: disabled }">
        <div v-if="!disabled">
          <icon name="upload"></icon>
          <span class="ml-1" v-text="$t('file_upload.select_file')" />
        </div>
        <span v-else v-text="$t('file_upload.disabled')" />
      </div>
      <input type="file" @change="handleFileChange" :accept="accept" :multiple="multiple" :disabled="disabled" />
    </label>
    <ol class="file-list mt-2" v-if="value">
      <li v-for="(val, index) in value" :key="index" class="file-row d-flex relative">
        <div v-if="val.type.includes('image/')" class="file">
          <icon name="image" size="lg" class="text-dark" />
          <div class="file-image-container">
            <img :src="createUrl(val)" class="file-image" />
          </div>
        </div>
        <icon v-else :name="`file${getIconForFile(val)}`" class="text-dark" size="lg" />
        <span class="ml-2" v-text="val.name" :title="val.name" />
        <span
          v-if="value"
          @click="removeFile(index)"
          class="align-self-center ml-2 cursor-pointer delete"
        >
          <icon name="times" />
        </span>
      </li>
    </ol>
  </div>
</template>

<script lang='ts'>
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { EventBus } from '@/services/EventBus';

@Component
export default class FileUpload extends Vue {
  private value: File[] = [];
  @Prop({ default: false }) private multiple!: boolean;
  @Prop({ default: '' }) private accept!: string;
  @Prop({ default: false }) private disabled!: boolean;

  private onFormSubmit = () => this.clear();

  private created() {
    EventBus.$on('form.submit.successful', this.onFormSubmit);
  }
  private beforeDestroy() {
    EventBus.$off('form.submit.successful', this.onFormSubmit);
  }
  private handleFileChange(e: any) {
    this.value.push(...e.target.files);
  }
  private clear() {
    this.value = [];
  }
  private createUrl(file: File) {
    return URL.createObjectURL(file);
  }
  private removeFile(index: number) {
    if (this.value.length) {
      this.value.splice(index, 1);
    }
  }
  private getIconForFile(file: File) {
    const foundExtension = this.availableExtensions.filter(
      i => i.extension === this.getExtension(file.name),
    );
    return foundExtension.length ? `-${foundExtension[0].icon}` : '';
  }
  private getExtension(filename: string) {
    const regex = /(?:\.([^.]+))?$/;
    const res = regex.exec(filename);
    return res ? res[1] : null;
  }
  private get availableExtensions() {
    return [
      { extension: 'xls', icon: 'excel' },
      { extension: 'xlsx', icon: 'excel' },
      { extension: 'csv', icon: 'excel' },
      { extension: 'docx', icon: 'word' },
      { extension: 'doc', icon: 'word' },
      { extension: 'pdf', icon: 'pdf' },
    ];
  }
  @Watch('value')
  private emitValue() {
    this.$emit('onFileChanged', this.multiple || !this.value ? this.value : this.value[0]);
  }
}
</script>

<style scoped lang="scss">
.file-select {
  margin-bottom: 0;
  & > input[type='file'] {
    display: none;
  }
  & > .select-button {
    user-select: none;
    padding: 1rem;
    cursor: pointer;
    width: fit-content;
    color: var(--primary-color);
    border-left-width: 6px;
    background-color: transparentize($color: #000, $amount: 0.95);
    border-left-style: solid;
    border-color: var(--primary-color);

    border-radius: 0.3rem;
    opacity: 1;
    text-align: center;
    font-weight: bold;
    &:hover {
      background-color: transparentize($color: #000, $amount: 0.935);
    }
  }
}
.file-list {
  margin: 0;
  padding: 0;
  & > .file-row {
    & > span {
      user-select: none;
      overflow: hidden;
      color: gray;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .file {
      position: relative;
    }
    .file:hover > .file-image-container {
      display: initial;
    }
    .delete:hover {
      color: red;
    }
    .file-image-container {
      background-color: white;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
      display: none;
      bottom: 16px;
      left: 26px;
      position: absolute;
      & > .file-image {
        max-width: 300px;
      }
    }
  }
}
</style>
