<template>
  <div class="d-flex">
    <button class="btn btn-light btn-sm px-1 py-0" v-b-modal="`${identifier}Modal`">
      <icon name="columns" :title="$t('datatable.column-options.btn')" />
    </button>
    <b-modal
      :id="`${identifier}Modal`"
      :title="$t('datatable.column-options.title')"
      :cancel-title="$t('datatable.column-options.reset')"
      :ok-title="$t('datatable.column-options.ok')"
      @ok="onSubmit"
      @cancel="onCancel"
    >
      <draggable v-model="selectableColumns" tag="ul" class="list-group" :options="{handle: '.handle'}">
        <li
          v-for="element in selectableColumns"
          :key="element.name"
          class="list-group-item checkbox"
        >
          <label :for="element.name" class="checkbox-label">
            {{ element.label }}
            <input
              type="checkbox"
              class="checkbox-input"
              :checked="element.hidden !== true"
              @change="onCheckedChange($event.target.checked, element.name)"
              :id="element.name"
            />
            <span class="checkmark"></span>
          </label>
          <icon prefix="fas" name="sort" class="handle float-right" />
        </li>
        <template #footer>
          <li
            v-for="element in hiddenColumns"
            :class="['list-group-item checkbox']"
            :key="`disabled-${element.name}`"
          >
            <label :for="`disabled-${element.name}`" class="checkbox-label">
              {{ element.label }}
              <input
                type="checkbox"
                class="checkbox-input"
                :checked="element.hidden !== true"
                @change="onCheckedChange($event.target.checked, element.name)"
                :id="`disabled-${element.name}`"
              />
              <span class="checkmark"></span>
            </label>
          </li>
        </template>
      </draggable>
    </b-modal>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import clone from '@/utils/clone';

import { ColumnContext } from '@/models/ColumnContext';
import { storage } from '@/services/LocalStorage';

@Component({
  components: {
    draggable,
  },
})
export default class ColumnOptions extends Vue {
  @Prop({ required: true, type: [Array] })
  private columns!: ColumnContext[];
  @Prop({ required: true, type: [String] })
  private identifier!: string;
  private model: ColumnContext[] = [];

  private created() {
    this.init();
  }

  @Watch('identifier')
  @Watch('columns')
  private init() {
    // ! TODO: Rewrite so all column options are grouped in one key => value JSON object.
    let columns = clone(this.columns);
    if (storage.get(`column-options.${this.identifier}`)) {
      columns = storage.get(`column-options.${this.identifier}`, []);
    }
    this.model = columns;
  }

  private onCheckedChange(value: boolean, name: string) {
    this.model = this.model.map(element => {
      if (element.name === name) {
        element.hidden = !value;
      }
      return element;
    });
  }

  private onSubmit() {
    this.$emit('change', this.model);
    storage.save(`column-options.${this.identifier}`, this.model);
  }

  private onCancel() {
    this.$emit('change', []);
    storage.remove(`column-options.${this.identifier}`);
    this.init();
  }

  private get selectableColumns() {
    return this.model.filter(column => !(column.is_key || column.hidden));
  }
  private get hiddenColumns() {
    return this.model.filter(column => column.hidden === true);
  }
}
</script>

<style scoped lang="scss">
.handle {
  cursor: move;
}
.checkbox {
  padding-left: 30px;
}
.checkmark {
  top: 10px;
  left: 10px;
}
</style>
