<template>
  <div class="card mb-3">
    <div class="card-body">
      <span class="text-italic"  >{{ $t('dashboard.loggedin-as.title') }}</span>
      <img
        :src="creditor.logo"
        v-if="creditor.logo"
        class="img-responsive"
      >
      <div v-else class="h4 text-center">
        {{ creditor.displayName }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Creditor } from '@/models';

@Component({})
export default class LoggedInAs extends Vue {
  @Prop() private creditor!: Creditor;
}
</script>

<style scoped>
</style>
