<template>
  <transition name="modal">
    <div :class="['modal-mask', !$store.state.modal.open && 'd-none']">
      <div
        :class="['modal',$store.state.modal.open && 'd-block']"
        @click="close"
        style="cursor:pointer"
      >
        <div class="modal-dialog modal-dialog-centered" id="wrapper">
          <div class="modal-content" style="cursor:auto">
            <div class="modal-header" v-if="$store.state.modal.header">
              <slot name="header">{{$store.state.header}}</slot>
            </div>
            <div
              class="modal-body"
              v-if="$store.state.modal.body"
              v-html="$store.state.modal.body"
            />
            <div class="modal-footer" v-if="$store.state.modal.actions">
              <slot name="actions">
                <button
                  class="btn"
                  :class="action.class"
                  v-for="action in $store.state.modal.actions"
                  :key="action.label"
                  @click="action.action"
                >{{action.label}}</button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class Modal extends Vue {
  @Watch('$route')
  public routeChanged() {
    this.$store.dispatch('modal/close');
  }
  public close(e: Event): void {
    if ((e.target as HTMLInputElement).classList.contains('modal')) {
      this.$store.dispatch('modal/close');
    }
  }
}
</script>
<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-body,
.modal-footer {
  padding: 2rem;
}
</style>
