import Vue from 'vue';
import Router, { RouteConfig } from 'vue-router';

import store from '@/store';

import PaymentHandler from '@/views/PaymentHandler.vue';
import Verify from '@/views/auth/Verify.vue';
import Login from '@/views/auth/Login.vue';
import ForgotPassword from '@/views/auth/ForgotPassword.vue';
import SetPassword from '@/views/auth/SetPassword.vue';
import Main from '@/views/Main.vue';
import NotFound from '@/views/NotFound.vue';
import DashboardIndex from '@/views/dashboard/Index.vue';
import Filter from '@/components/dashboard/Filter.vue';
import DisputeList from '@/views/dashboard/DisputeList.vue';
import ActionList from '@/views/dashboard/ActionList.vue';
import ActionListNav from '@/components/dashboard/ActionListNav.vue';
import BatchList from '@/views/dashboard/BatchList.vue';
import BatchListNav from '@/components/dashboard/BatchListNav.vue';
import BatchListType from '@/views/dashboard/BatchListType.vue';
import SettingsNav from '@/components/settings/SettingsNav.vue';
import SettingsIndex from '@/views/settings/Index.vue';
import SettingsPassword from '@/views/settings/Password.vue';
import EntityCreate from '@/components/entity/Create.vue';
import EntityEdit from '@/components/entity/Edit.vue';
import {
  SettingsForm,
  SettingsList,
  SettingsUserList,
  SettingsCreditorList,
  SettingsModelImportSettings,
  SettingsWorkflowStepForm,
  SettingsCreditorView,
  SettingsSegmentationForm,
  SettingsSegmentationCreate,
  FunctionalityManagerList,
  BusinessUnitAclList,
  PermissionIndex,
  Sync, SettingsCreditorTrialList,
} from '@/views/settings';
import {
  DebtorPortal,
  DebtorPortalDashboard,
  DebtorPortalInvalidTokenHandler,
  DebtorPortalVerify,
} from '@/debtorportal';
import {
  DebtorCommunication,
  DebtorDashboard,
  DebtorForm,
  DebtorHistory,
  DebtorIndex,
  DebtorInfo,
} from '@/views/debtor';

import SupportMain from '@/views/support/Main.vue';

import DebtorCommunicationView from '@/views/debtor/communication/View.vue';
import DebtorCommunicationCreate from '@/views/debtor/communication/Create.vue';
import DebtorCommunicationEdit from '@/views/debtor/communication/Edit.vue';
import DebtorReceivableList from '@/views/debtor/receivable/List.vue';
import DebtorReceivableView from '@/views/debtor/receivable/View.vue';
import DebtorReceivableEdit from '@/views/debtor/receivable/Edit.vue';
import DebtorSettlementPlanList from '@/views/debtor/settlementplan/List.vue';
import DebtorSettlementPlanView from '@/views/debtor/settlementplan/View.vue';
import DebtorSettlementPlanEdit from '@/views/debtor/settlementplan/Edit.vue';
import DebtorTaskList from '@/views/debtor/task/List.vue';
import DebtorTaskForm from '@/views/debtor/task/Form.vue';
import TaskView from '@/components/task/View.vue';

import EmailList from '@/views/email/List.vue';
import ReportList from '@/views/report/List.vue';
import EmailView from '@/views/email/View.vue';
import ReportForm from '@/views/report/Form.vue';

import Subscription from '@/views/Subscription.vue';
import SubscriptionForm from '@/views/subscription/Form.vue';
import SubscriptionFormDebtor from '@/views/subscription/FormDebtor.vue';
import SubscriptionPayment from '@/views/subscription/Payment.vue';
import BusinessUnitSyncView from '@/components/settings/businessunit/sync/View.vue';

import GraydonView from '@/views/graydon/View.vue';

import ReportStatisticsView from '@/views/report/statistic/View.vue';
import ReportStatisticsEdit from '@/views/report/statistic/Edit.vue';
import EditMailbox from '@/views/settings/mailbox/Create.vue';
import MissingDataList from '@/views/missing-data/List.vue';
import ManualBulkUpdate from '@/views/settings/businessunit/ManualBulkUpdate.vue';
import FtpSettingsList from '@/views/settings/businessunit/FtpSettings.vue';
import SettingsUserEditForm from '@/views/settings/user/Edit.vue';
import Mfa from '@/views/auth/Mfa.vue';
import CreditorSettingsForm from '@/views/settings/creditor/Settings.vue';
import BusinessUnitSettingsForm from '@/views/settings/businessunit/Settings.vue';
import ImportedPaymentList from '@/views/importedpayment/importedpayment/List.vue';
import ImportedPaymentCreate from '@/views/importedpayment/importedpayment/Create.vue';
import ImportedPaymentHistory from '@/views/importedpayment/importedpayment/History.vue';
import ImportedPaymentView from '@/views/importedpayment/Assign/View.vue';
import Mapping from '@/views/settings/businessunit/ManualBulkMapping.vue';
import MappingCreate from '@/views/settings/businessunit/ManualBulkMappingCreate.vue';
import ImportedPaymentFilterBar from '@/components/importedpayments/Filter.vue';
import PaymentFile from '@/views/importedpayment/payment-file/PaymentFiles.vue';
import RecalculateWorkFlowTasksForm from '@/views/batch/Form.vue';
import DebtorGroupDashboard from '@/views/debtor-group/dashboard/Index.vue';

Vue.use(Router);

export const getRouteByAcl = (key: string): any | boolean => {
  const main = routes.find((route: any) => route.path === '/');
  const children: any[] | undefined = main && main.children;
  return children?.find((route: any) => {
      const acl = route.meta && route.meta.acl;
      return acl && acl.find((aclKey: string) => aclKey === key);
    });
};

export const getRoute = (name: string): any | boolean => {
  const props = router.resolve({
    name,
  });
  return props ? props.route : false;
};

export const getRouteChildren = (path: string): RouteConfig[]  => {
  const route = routes.find((route: RouteConfig) => route.path === path);

  return route?.children ?? [];
};

export const currentRouteIndex = (name: string): number => {
  const children: RouteConfig[] = getRouteChildren('/subscription');
  return children.findIndex(child => child.name === name);
};

export const hasRoute = (name: string): boolean => {
  const route = getRoute(name);
  return route && route.matched && route.matched.length;
};

export const parseEndpoint = (endpoint: string, params: any): string => {
  if (endpoint) {
    return endpoint.replace(/:[^\s/]+/g, (param: string) => params[param.substr(1)]);
  }
  return '';
};

const allRoutes: RouteConfig[] = [
  {
    path: '/',
    component: Main,
    children: [
      {
        path: '/',
        name: 'dashboard.index',
        component: DashboardIndex,
        meta: {
          title: 'dashboard.title',
          filterBar: Filter,
          filterIcon: 'sliders-h',
        },
      },
      {
        path: '/dashboard/action',
        name: 'dashboard.action.list',
        component: ActionList,
        meta: {
          endpoint: '/dashboard/actions',
          title: 'dashboard.action.list',
          context: 'dashboard.action',
          model: 'task',
          subnavBar: ActionListNav,
        },
      },
      {
        path: '/dashboard/action/:taskId',
        name: 'dashboard.action.view',
        component: ActionList,
        meta: {
          endpoint: '/dashboard/actions',
          title: 'dashboard.action.list',
          context: 'dashboard.action',
          model: 'task',
          rightBar: TaskView,
          rightBarCloseGoTo: 'dashboard.action.list',
        },
      },
      {
        path: '/dashboard/dispute',
        name: 'dashboard.dispute.list',
        component: DisputeList,
        meta: {
          endpoint: '/dashboard/disputes',
          title: 'dashboard.dispute.list',
          context: 'dashboard',
          model: 'dispute',
        },
      },
      {
        path: '/dashboard/dispute/:settlementplanId',
        name: 'dashboard.dispute.view',
        component: DisputeList,
        meta: {
          endpoint: '/dashboard/disputes',
          title: 'dashboard.dispute.list',
          context: 'dashboard',
          model: 'dispute',
          rightBar: DebtorSettlementPlanView,
          rightBarCloseGoTo: 'dashboard.dispute.list',
        },
      },
      {
        path: '/dashboard/dispute/:settlementplanId/edit',
        name: 'dashboard.dispute.edit',
        component: DisputeList,
        meta: {
          endpoint: '/dashboard/disputes',
          title: 'dashboard.dispute.list',
          context: 'dashboard',
          model: 'dispute',
          rightBar: DebtorSettlementPlanEdit,
          rightBarCloseGoTo: 'dashboard.dispute.list',
        },
      },
      {
        path: '/dashboard/batch',
        name: 'dashboard.batch.list',
        component: BatchList,
        meta: {
          endpoint: '/dashboard/batch/history',
          title: 'dashboard.batch.list',
          subnavBar: BatchListNav,
          actions: [
            {
              name: 'dashboard.batch.list.recalculate',
              icon: 'sync',
            }
          ],
        },
      },
      {
        path: '/dashboard/batch/recalculate',
        name: 'dashboard.batch.list.recalculate',
        component: BatchList,
        meta: {
          title: 'dashboard.batch.list.recalculate',
          subnavBar: BatchListNav,
          rightBar: RecalculateWorkFlowTasksForm,
          rightBarEndpoint: '/dashboard/batch/recalculate/tasks',
          rightBarCloseGoTo: 'dashboard.batch.list',
        },
      },
      {
        path: '/dashboard/batch/:type',
        name: 'dashboard.batch.type',
        component: BatchListType,
        meta: {
          endpoint: '/dashboard/batch/:type',
          title: 'dashboard.batch.list',
          context: 'dashboard.batch.type',
          subnavBar: BatchListNav,
        },
      },
      {
        path: '/dashboard/batch/:type/:taskId',
        name: 'dashboard.batch.type.view',
        component: BatchListType,
        meta: {
          title: 'dashboard.batch.list',
          endpoint: '/dashboard/batch/:type',
          context: 'dashboard.batch.type',
          subnavBar: BatchListNav,
          rightBar: TaskView,
          rightBarCloseGoTo: 'dashboard.batch.type',
        },
      },
      {
        path: '/settings',
        name: 'settings',
        component: SettingsIndex,
        meta: {
          subnavBar: SettingsNav,
        },
      },
      {
        path: '/settings/password',
        name: 'settings.password',
        component: SettingsPassword,
        meta: {
          subnavBar: SettingsNav,
        },
      },
      {
        path: '/settings/dispute',
        name: 'settings.dispute',
        component: SettingsList,
        meta: {
          acl: ['api_setting_creditor_dispute_type_index'],
          endpoint: 'settings/dispute/type',
          model: 'dispute',
          subnavBar: SettingsNav,
          actions: [
            {
              name: 'settings.dispute.create',
              icon: 'plus',
            },
          ],
        },
      },
      {
        path: '/settings/dispute/type/create',
        name: 'settings.dispute.create',
        component: SettingsForm,
        meta: {
          model: 'dispute',
          endpoint: '/settings/dispute/type/create',
          subnavBar: SettingsNav,
        },
      },
      {
        path: '/settings/dispute/edit/:id',
        name: 'settings.dispute.edit',
        component: SettingsForm,
        meta: {
          endpoint: 'settings/dispute/type/:id/edit',
          model: 'dispute',
          subnavBar: SettingsNav,
          gotoAfterSubmit: 'settings.dispute',
        },
      },
      {
        path: '/settings/creditor',
        name: 'settings.creditor',
        component: SettingsCreditorList,
        meta: {
          acl: ['api_setting_creditor_index'],
          endpoint: 'settings/creditor',
          model: 'creditor',
          subnavBar: SettingsNav,
          actions: [
            {
              name: 'settings.model.create',
              icon: 'plus',
              params: { model: 'creditor' },
            },
          ],
        },
      },
      {
        path: '/settings/creditor/trials',
        name: 'settings.creditor.trial',
        component: SettingsCreditorTrialList,
        meta: {
          endpoint: 'settings/creditor/trial',
          model: 'creditor',
          subnavBar: SettingsNav,
        },
      },
      {
        path: '/settings/creditor/:id/license-settings',
        name: 'settings.creditor.license_model',
        component: SettingsForm,
        meta: {
          endpoint: 'settings/creditor/:id/license_model/edit',
          model: 'creditor',
          subnavBar: SettingsNav,
        },
      },
      {
        path: '/settings/creditor/view/:id',
        name: 'settings.creditor.view',
        component: SettingsCreditorView,
        meta: {
          model: 'creditor',
          subnavBar: SettingsNav,
        },
      },
      {
        path: '/settings/creditor/settings/:id',
        name: 'settings.creditor.settings',
        component: CreditorSettingsForm,
        meta: {
          endpoint: 'settings/creditor/:id/setting/edit',
          model: 'creditor',
          subnavBar: SettingsNav,
        },
      },
      {
        path: '/settings/creditor/:id/settings/logo/create',
        name: 'settings.creditor.settings.logo.create',
        component: SettingsForm,
        meta: {
          endpoint: 'settings/creditor/:id/logo/create',
          model: 'creditor',
          subnavBar: SettingsNav,
          gotoAfterSubmit: 'settings.creditor.settings',
        },
      },
      {
        path: '/settings/creditor/:id/:property/create',
        name: 'settings.creditor.view.property.create',
        component: SettingsCreditorView,
        meta: {
          endpoint: 'entity/:id/:property/create',
          subnavBar: SettingsNav,
          rightBar: EntityCreate,
          rightBarCloseGoTo: 'settings.creditor.view',
        },
      },
      {
        path: '/settings/creditor/:id/:property/:propertyId/edit',
        name: 'settings.creditor.view.property.edit',
        component: SettingsCreditorView,
        meta: {
          endpoint: 'entity/:id/:property/:propertyId/edit',
          subnavBar: SettingsNav,
          rightBar: EntityEdit,
          rightBarCloseGoTo: 'settings.creditor.view',
        },
      },
      {
        path: '/settings/thirdparty',
        name: 'settings.thirdparty',
        component: SettingsList,
        meta: {
          acl: ['api_setting_thirdparty_index'],
          endpoint: 'settings/thirdparty',
          model: 'thirdparty',
          subnavBar: SettingsNav,
          actions: [
            {
              name: 'settings.model.create',
              icon: 'plus',
              params: { model: 'thirdparty' },
            },
          ],
        },
      },
      {
        path: '/settings/user',
        name: 'settings.user',
        component: SettingsUserList,
        meta: {
          acl: ['api_setting_user_index'],
          endpoint: 'settings/user',
          model: 'user',
          subnavBar: SettingsNav,
          actions: [
            {
              name: 'settings.model.create',
              icon: 'plus',
              params: { model: 'user' },
            },
          ],
        },
      },
      {
        path: '/settings/user/:id/edit',
        name: 'settings.user.edit',
        component: SettingsUserEditForm,
        meta: {
          endpoint: '/settings/user/:id/edit',
          subnavBar: SettingsNav,
        },
      },
      {
        path: '/settings/manage/:tab?',
        name: 'settings.functionalitymanager',
        component: FunctionalityManagerList,
        meta: {
          acl: ['api_setting_platform_functionality_manager_index'],
          model: 'functionalitymanager',
          subnavBar: SettingsNav,
        },
      },
      {
        path: '/settings/permissions',
        name: 'settings.permissions',
        component: PermissionIndex,
        meta: {
          model: 'permissions',
          acl: ['api_setting_permission_index'],
          subnavBar: SettingsNav,
          actions: [
            {
              name: 'settings.permissions.create',
              icon: 'plus',
            },
          ],
        },
        children: [
          {
            path: 'businessunitentitylink',
            name: 'settings.permissions.business_unit_entity_link',
            component: SettingsList,
            meta: {
              model: 'businessunitentitylinkpermissiongrouplink',
              endpoint: '/settings/businessunitentitylinkpermissiongrouplink',
              subnavBar: SettingsNav,
              actions: [
                {
                  name: 'settings.permissions.business_unit_entity_link.create',
                  icon: 'plus',
                },
              ],
              titlebar: false,
            },
          },
          {
            path: 'businessunitentitylink/create',
            name: 'settings.permissions.business_unit_entity_link.create',
            component: SettingsForm,
            meta: {
              model: 'businessunitentitylinkpermissiongrouplink',
              endpoint: '/settings/businessunitentitylinkpermissiongrouplink/create',
              subnavBar: SettingsNav,
              gotoAfterSubmit: 'settings.permissions.business_unit_entity_link',
            },
          },
          {
            path: 'businessunitentitylink/:id/edit',
            name: 'settings.permissions.business_unit_entity_link.edit',
            component: SettingsForm,
            meta: {
              model: 'businessunitentitylinkpermissiongrouplink',
              endpoint: '/settings/businessunitentitylinkpermissiongrouplink/:id/edit',
              subnavBar: SettingsNav,
              gotoAfterSubmit: 'settings.permissions.business_unit_entity_link',
            },
          },

          {
            path: 'entityuserlink/businessunitentitylink',
            name: 'settings.permissions.business_unit_entity_link_permission_group_link',
            component: SettingsList,
            meta: {
              model: 'entityuserlinkbusinessunitentitylinkpermissiongrouplink',
              endpoint: '/settings/entityuserlinkbusinessunitentitylinkpermissiongrouplink',
              subnavBar: SettingsNav,
              actions: [
                {
                  name: 'settings.permissions.business_unit_entity_link_permission_group_link.create',
                  icon: 'plus',
                },
              ],
              titlebar: false,
            },
          },
          {
            path: 'entityuserlinkbusinessunitentitylink/create',
            name: 'settings.permissions.business_unit_entity_link_permission_group_link.create',
            component: SettingsForm,
            meta: {
              model: 'businessunitentitylink',
              endpoint: '/settings/entityuserlinkbusinessunitentitylinkpermissiongrouplink/create',
              subnavBar: SettingsNav,
              gotoAfterSubmit: 'settings.permissions.business_unit_entity_link_permission_group_link',
            },
          },
          {
            path: 'entityuserlink/:entityUserLinkUuid/businessunitentitylink/:businessUnitEntityLinkUuid/edit',
            name: 'settings.permissions.business_unit_entity_link_permission_group_link.edit',
            component: SettingsForm,
            meta: {
              model: 'entityuserlinkbusinessunitentitylinkpermissiongrouplink',

              endpoint:
                'settings/entityuserlinkpermissiongrouplink/:entityUserLinkUuid/businessunitentitylink/:businessUnitEntityLinkUuid/edit',
              subnavBar: SettingsNav,
              gotoAfterSubmit: 'settings.permissions.business_unit_entity_link_permission_group_link',
            },
          },
          {
            path: 'entityuserlink',
            name: 'settings.permissions.entity_user_link',
            component: SettingsList,
            meta: {
              model: 'entityuserlinkpermissiongrouplink',
              endpoint: 'settings/entityuserlinkpermissiongrouplink',
              subnavBar: SettingsNav,
              actions: [
                {
                  name: 'settings.permissions.entity_user_link.create',
                  icon: 'plus',
                },
              ],
              titlebar: false,
            },
          },
          {
            path: 'entityuserlink/create',
            name: 'settings.permissions.entity_user_link.create',
            component: SettingsForm,
            meta: {
              model: 'entityuserlinkpermissiongrouplink',
              endpoint: '/settings/entityuserlinkpermissiongrouplink/create',
              subnavBar: SettingsNav,
              gotoAfterSubmit: 'settings.permissions.entity_user_link',
            },
          },
          {
            path: 'entityuserlink/:id/edit',
            name: 'settings.permissions.entity_user_link.edit',
            component: SettingsForm,
            meta: {
              model: 'entityuserlinkpermissiongrouplink',
              endpoint: '/settings/entityuserlinkpermissiongrouplink/:id/edit',
              subnavBar: SettingsNav,
              gotoAfterSubmit: 'settings.permissions.entity_user_link',
            },
          },
        ],
      },
      {
        path: 'settings/permissions/group/create',
        name: 'settings.permissions.create',
        component: SettingsForm,
        meta: {
          subnavBar: SettingsNav,
          model: 'permissions',
          endpoint: 'settings/permission/group/create',
          gotoAfterSubmit: 'settings.permissions',
        },
      },
      {
        path: 'settings/permissions/group/:id/edit',
        name: 'settings.permissions.edit',
        component: SettingsForm,
        meta: {
          subnavBar: SettingsNav,
          model: 'permissions',
          endpoint: 'settings/permission/group/:id/edit',
          gotoAfterSubmit: 'settings.permissions',
        },
      },
      {
        path: '/settings/subscriptionlogtype',
        name: 'settings.subscriptionlogtype',
        component: SettingsList,
        meta: {
          acl: ['api_setting_subscription_log_type_index'],
          endpoint: 'settings/subscriptionlogtype',
          model: 'subscriptionlogtype',
          subnavBar: SettingsNav,
          actions: [
            {
              name: 'settings.model.create',
              icon: 'plus',
              params: { model: 'subscriptionlogtype' },
            },
          ],
        },
      },
      {
        path: '/settings/webhook',
        name: 'settings.webhook',
        component: SettingsList,
        meta: {
          acl: ['api_setting_webhook_index'],
          endpoint: 'settings/webhook',
          model: 'webhook',
          subnavBar: SettingsNav,
          actions: [
            {
              name: 'settings.model.create',
              icon: 'plus',
              params: { model: 'webhook' },
            },
          ],
        },
      },
      {
        path: '/settings/template/:template',
        name: 'settings.template',
        component: SettingsList,
        meta: {
          acl: [
            'api_setting_email_template_index',
            'api_setting_letter_template_index',
            'api_setting_call_template_index',
            'api_setting_sms_template_index',
            'api_setting_system_template_index',
          ],
          endpoint: 'settings/templates/:template',
          subnavBar: SettingsNav,
          actions: [
            {
              name: 'settings.template.create',
              icon: 'plus',
            },
          ],
        },
      },
      {
        path: '/settings/template/:template/create',
        name: 'settings.template.create',
        component: SettingsForm,
        meta: {
          endpoint: 'settings/templates/:template/create',
          subnavBar: SettingsNav,
        },
      },
      {
        path: '/settings/template/:template/edit/:id',
        name: 'settings.template.edit',
        component: SettingsForm,
        meta: {
          endpoint: 'settings/templates/:template/:id/edit',
          subnavBar: SettingsNav,
          translation: true,
        },
      },
      {
        path: '/settings/template/:template/:id/translation/create',
        name: 'settings.template.edit.translation.create',
        component: SettingsForm,
        meta: {
          endpoint: 'settings/templates/:template/:id/translation/create',
          model: 'translation',
          subnavBar: SettingsNav,
          gotoAfterSubmit: 'settings.template.edit',
          buttonCentered: true
        },
      },
      {
        path: '/settings/template/:template/:id/translation/:subId',
        name: 'settings.template.edit.translation.edit',
        component: SettingsForm,
        meta: {
          endpoint: 'settings/templates/:template/:id/translation/:subId/edit',
          model: 'translation',
          subnavBar: SettingsNav,
          gotoAfterSubmit: 'settings.template.edit',
          buttonCentered: true
        },
      },
      {
        path: '/settings/interest-rates',
        name: 'settings.interest-rates',
        component: SettingsList,
        meta: {
          acl: ['api_setting_interest_rates_index'],
          endpoint: 'settings/interest-rates',
          model: 'interest-rates',
          subnavBar: SettingsNav,
        },
      },
      {
        path: '/settings/platform-setting',
        name: 'settings.platform-setting',
        component: SettingsForm,
        meta: {
          acl: ['api_setting_platform_setting_edit'],
          endpoint: 'settings/platform/setting/edit',
          model: 'platform-setting',
          subnavBar: SettingsNav,
          gotoAfterSubmit: 'settings',
        },
      },
      {
        path: '/settings/calendar',
        name: 'settings.calendar',
        component: SettingsList,
        meta: {
          acl: ['api_setting_custom_notice_type_index'],
          endpoint: 'settings/calendar',
          model: 'calendar',
          subnavBar: SettingsNav,
          actions: [
            {
              name: 'settings.model.create',
              icon: 'plus',
              params: { model: 'calendar' },
            },
          ],
        },
      },
      {
        path: '/settings/mailbox',
        name: 'settings.mailbox',
        component: SettingsList,
        meta: {
          acl: ['api_setting_mailbox_index'],
          endpoint: 'settings/mailbox',
          model: 'mailbox',
          subnavBar: SettingsNav,
          actions: [
            {
              name: 'settings.mailbox.create',
              icon: 'plus',
              params: { model: 'mailbox' },
            },
          ],
        },
      },
      {
        path: '/settings/mailbox/create',
        name: 'settings.mailbox.create',
        component: SettingsForm,
        meta: {
          model: 'mailbox',
          endpoint: 'settings/mailbox/settings',
          subnavBar: SettingsNav,
          gotoAfterSubmit: 'settings.mailbox.edit',
        },
      },
      {
        path: '/settings/mailbox/:id/edit',
        name: 'settings.mailbox.edit',
        component: EditMailbox,
        meta: {
          model: 'mailbox',
          endpoint: 'settings/mailbox/settings/:id',
          subnavBar: SettingsNav,
          gotoAfterSubmit: 'settings.mailbox',
        },
      },
      {
        path: '/settings/segmentation',
        name: 'settings.segmentation',
        component: SettingsList,
        meta: {
          acl: ['api_setting_segmentation_index'],
          endpoint: 'settings/segmentation',
          model: 'segmentation',
          subnavBar: SettingsNav,
          actions: [
            {
              name: 'settings.segmentation.create',
              icon: 'plus',
              params: { model: 'segmentation' },
            },
          ],
        },
      },
      {
        path: '/settings/segmentation/create',
        name: 'settings.segmentation.create',
        component: SettingsSegmentationCreate,
        meta: {
          endpoint: 'settings/segmentation/create',
          model: 'segmentation',
          subnavBar: SettingsNav,
          gotoAfterSubmit: 'settings.segmentation',
        },
      },
      {
        path: '/settings/segmentation/edit/:id',
        name: 'settings.segmentation.edit',
        component: SettingsSegmentationForm,
        meta: {
          endpoint: 'settings/segmentation/:id/edit',
          model: 'segmentation',
          subnavBar: SettingsNav,
        },
      },
      {
        path: '/settings/workflow',
        name: 'settings.workflow',
        component: SettingsList,
        meta: {
          acl: ['api_setting_workflow_index'],
          endpoint: 'settings/workflow',
          model: 'workflow',
          subnavBar: SettingsNav,
          actions: [
            {
              name: 'settings.workflow.create',
              icon: 'plus',
              params: { model: 'workflow' },
            },
          ],
        },
      },
      {
        path: '/settings/workflow/create',
        name: 'settings.workflow.create',
        component: SettingsForm,
        meta: {
          endpoint: 'settings/workflow/create',
          model: 'workflow',
          subnavBar: SettingsNav,
          gotoAfterSubmit: 'settings.workflow.edit',
        },
      },
      {
        path: '/settings/workflow/edit/:id',
        name: 'settings.workflow.edit',
        component: SettingsForm,
        meta: {
          endpoint: 'settings/workflow/:id/edit',
          model: 'workflow',
          subnavBar: SettingsNav,
          workflow: true,
        },
      },
      {
        path: '/settings/:model/edit/:id/step/create',
        name: 'settings.workflow.edit.step.create',
        component: SettingsWorkflowStepForm,
        meta: {
          endpoint: 'settings/workflow/:id/step/create',
          subnavBar: SettingsNav,
          model: 'workflow',
          gotoAfterSubmit: 'settings.workflow.edit',
        },
      },
      {
        path: '/settings/:model/edit/:id/step/:stepid/edit',
        name: 'settings.workflow.edit.step.edit',
        component: SettingsWorkflowStepForm,
        meta: {
          endpoint: 'settings/workflow/:id/step/:stepid/edit',
          subnavBar: SettingsNav,
          model: 'workflow',
          gotoAfterSubmit: 'settings.workflow.edit',
        },
      },
      {
        path: '/settings/businessunit',
        name: 'settings.businessunit',
        component: SettingsList,
        meta: {
          acl: ['api_setting_business_unit_index'],
          endpoint: 'settings/businessunit',
          model: 'businessunit',
          subnavBar: SettingsNav,
          actions: [
            {
              name: 'settings.model.create',
              icon: 'plus',
              params: { model: 'businessunit' },
            },
          ],
        },
      },
      {
        path: '/settings/businessunit/:id/settings',
        name: 'settings.businessunit.settings',
        component: BusinessUnitSettingsForm,
        meta: {
          model: 'businessunit',
          endpoint: 'settings/businessunit/:id/setting/edit',
          subnavBar: SettingsNav,
          gotoAfterSubmit: 'settings.businessunit',
        },
      },
      {
        path: '/settings/businessunit/:id/settings/logo/create',
        name: 'settings.businessunit.settings.logo.create',
        component: SettingsForm,
        meta: {
          endpoint: 'settings/businessunit/:id/logo/create',
          model: 'businessunit',
          subnavBar: SettingsNav,
          gotoAfterSubmit: 'settings.businessunit.settings',
        },
      },
      {
        path: '/settings/businessunit/:id/ftpsettings',
        component: FtpSettingsList,
        name: 'settings.businessunit.ftpsettings',
        meta: {
          model: 'businessunit',
          endpoint: 'settings/businessunit/:id/ftpsettings',
          subnavBar: SettingsNav,
          gotoAfterSubmit: 'settings.businessunit.importsettings',
        },
      },
      {
        path: '/settings/businessunit/:id/ftpsettings/edit',
        component: SettingsForm,
        name: 'settings.businessunit.ftpsettings.edit',
        meta: {
          model: 'businessunit',
          endpoint: 'settings/businessunit/:id/ftpsettings/edit',
          subnavBar: SettingsNav,
          gotoAfterSubmit: 'settings.businessunit.ftpsettings',
        },
      },
      {
        path: '/settings/businessunit/:id/sync/mapping/manual-bulk-update/:mappingId',
        name: 'settings.businessunit.sync.mapping.manual-bulk-update',
        component: ManualBulkUpdate,
        meta: {
          model: 'sync',
          acl: ['manual_bulk_update'],
          endpoint: 'settings/businessunit/:id/sync/mapping/manual-bulk-update/:mappingId',
          subnavBar: SettingsNav,
          gotoAfterSubmit: 'settings.businessunit.sync',
        },
      },
      {
        path: '/settings/businessunit/:id/sync/mapping',
        name: 'settings.businessunit.sync.mapping',
        component: Mapping,
        meta: {
          model: 'sync',
          acl: ['manual_bulk_update'],
          endpoint: 'settings/businessunit/:id/sync/mapping',
          subnavBar: SettingsNav,
        },
      },
      {
        path: '/settings/businessunit/:id/sync/mapping/create',
        name: 'settings.businessunit.sync.mapping.create',
        component: MappingCreate,
        meta: {
          model: 'sync',
          acl: ['manual_bulk_update'],
          endpoint: 'settings/businessunit/:id/sync/mapping/create',
          subnavBar: SettingsNav,
          gotoAfterSubmit: 'settings.businessunit.sync.mapping',
        },
      },
      {
        path: '/settings/businessunit/:id/sync',
        name: 'settings.businessunit.sync',
        component: Sync,
        meta: {
          model: 'sync',
          endpoint: 'settings/businessunit/:id/sync',
          subnavBar: SettingsNav,
          gotoAfterSubmit: 'settings.businessunit.sync',
        },
      },

      {
        path: '/settings/businessunit/sync/:id/view/:businessUnitImport',
        name: 'settings.businessunit.sync.view',
        component: Sync,
        meta: {
          model: 'sync',
          endpoint: 'settings/businessunit/:id/sync',
          subnavBar: SettingsNav,
          rightBar: BusinessUnitSyncView,
          rightBarCloseGoTo: 'settings.businessunit.sync',
        },
      },
      {
        path: '/settings/businessunit/:id/importsettings',
        name: 'settings.businessunit.importsettings',
        component: SettingsModelImportSettings,
        meta: {
          model: 'businessunit',
          endpoint: 'settings/businessunit/:id/importsettings',
          subnavBar: SettingsNav,
          gotoAfterSubmit: 'settings.businessunit.importsettings',
        },
      },
      {
        path: '/settings/businessunit/:id/entitylink',
        name: 'settings.businessunit.acl',
        component: BusinessUnitAclList,
        meta: {
          model: 'businessunit',
          endpoint: 'settings/businessunit/:id/entitylink',
          subnavBar: SettingsNav,
          actions: [
            {
              name: 'settings.businessunit.acl.create',
              icon: 'plus',
            },
          ],
        },
      },
      {
        path: '/settings/businessunit/:id/entitylink/create',
        name: 'settings.businessunit.acl.create',
        component: SettingsForm,
        meta: {
          endpoint: '/settings/businessunit/:id/entitylink/create',
          subnavBar: SettingsNav,
          gotoAfterSubmit: 'settings.businessunit.acl',
        },
      },
      {
        path: '/settings/businessunit/:id/entitylink/:subId/edit',
        name: 'settings.businessunit.acl.edit',
        component: SettingsForm,
        meta: {
          model: 'businessunit',
          endpoint: '/settings/businessunit/:id/entitylink/:subId/edit',
          subnavBar: SettingsNav,
          gotoAfterSubmit: 'settings.businessunit.acl',
        },
      },
      {
        path: '/settings/:model/create',
        name: 'settings.model.create',
        component: SettingsForm,
        meta: {
          endpoint: '/settings/:model/create',
          subnavBar: SettingsNav,
        },
      },
      {
        path: '/settings/:model/:id/edit',
        name: 'settings.model.edit',
        component: SettingsForm,
        meta: {
          endpoint: '/settings/:model/:id/edit',
          subnavBar: SettingsNav,
        },
      },
      {
        path: '/graydon/entity/:debtorId',
        name: 'graydon.view',
        component: GraydonView,
        meta: {
          title: 'graydon.view.title',
        }
      },
      {
        path: '/debtor',
        name: 'debtor.index',
        component: DebtorIndex,
        meta: {
          title: 'debtor.list.title',
          actions: [
            {
              name: 'debtor.create',
              icon: 'plus',
            },
          ],
        },
      },
      {
        path: '/debtor/create',
        name: 'debtor.create',
        component: DebtorForm,
        meta: {
          title: 'debtor.create.title',
        },
      },
      {
        path: '/debtor/:debtorId/dashboard',
        name: 'debtor.view',
        component: DebtorDashboard,
        meta: {
          title: 'debtor.dashboard.title',
          context: 'debtor.view',
        },
      },
      {
        path: '/debtor/:debtorId/dashboard/task/create',
        name: 'debtor.view.task.create',
        component: DebtorDashboard,
        meta: {
          title: 'debtor.dashboard.title',
          context: 'debtor.view',
          rightBar: DebtorTaskForm,
          rightBarCloseGoTo: 'debtor.view',
          rightBarEndpoint: 'debtor/:debtorId/notice/create',
        },
      },
      {
        path: '/debtor/:debtorId/dashboard/task/:taskId',
        name: 'debtor.view.task.view',
        component: DebtorDashboard,
        meta: {
          title: 'debtor.dashboard.title',
          context: 'debtor.view',
          rightBarCloseGoTo: 'debtor.view',
          rightBar: TaskView,
        },
      },
      {
        path: '/debtor/:debtorId/dashboard/receivable/:type/:receivableId',
        name: 'debtor.view.receivable.view',
        component: DebtorDashboard,
        meta: {
          title: 'debtor.dashboard.title',
          context: 'debtor.view',
          rightBarCloseGoTo: 'debtor.view',
          rightBar: DebtorReceivableView,
        },
      },
      {
        path: '/debtor/:debtorId/dashboard/receivable/:type/:receivableId/edit',
        name: 'debtor.view.receivable.edit',
        component: DebtorDashboard,
        meta: {
          title: 'debtor.dashboard.title',
          context: 'debtor.view',
          rightBarCloseGoTo: 'debtor.view',
          rightBar: DebtorReceivableEdit,
        },
      },
      {
        path: '/debtor/:debtorId/dashboard/settlementplan/:settlementplanId',
        name: 'debtor.view.settlementplan.view',
        component: DebtorDashboard,
        meta: {
          title: 'debtor.dashboard.title',
          context: 'debtor.view',
          rightBarCloseGoTo: 'debtor.view',
          rightBar: DebtorSettlementPlanView,
        },
      },
      {
        path: '/debtor/:debtorId/dashboard/settlementplan/:settlementplanId/edit',
        name: 'debtor.view.settlementplan.edit',
        component: DebtorDashboard,
        meta: {
          title: 'debtor.dashboard.title',
          context: 'debtor.view',
          rightBarCloseGoTo: 'debtor.view',
          rightBar: DebtorSettlementPlanEdit,
        },
      },
      {
        path: '/debtor/:debtorId/dashboard/communication/:type/create',
        name: 'debtor.view.communication.type.task.create',
        component: DebtorDashboard,
        meta: {
          title: 'debtor.communication.type.title',
          rightBar: DebtorCommunicationCreate,
          rightBarCloseGoTo: 'debtor.view',
          rightBarEndpoint: '/debtor/:debtorId/:type/create',
          enlarge: true
        },
      },
      {
        path: '/debtor/:debtorId/dashboard/communication/:type/:typeId',
        name: 'debtor.view.communication.type.task.view',
        component: DebtorDashboard,
        meta: {
          title: 'debtor.communication.type.title',
          rightBar: DebtorCommunicationView,
          rightBarCloseGoTo: 'debtor.view',
        },
      },
      {
        path: '/debtor/:debtorId/dashboard/communication/:type/:typeId/edit',
        name: 'debtor.view.communication.type.task.edit',
        component: DebtorDashboard,
        meta: {
          title: 'debtor.communication.type.title',
          rightBar: DebtorCommunicationEdit,
          rightBarCloseGoTo: 'debtor.view',
          rightBarEndpoint: '/debtor/:debtorId/:type/:typeId/edit',
        },
      },
      {
        path: '/debtor/group/:groupId?/dashboard',
        name: 'debtor.group.view',
        component: DebtorGroupDashboard,
        meta: {
          title: 'debtor.group.dashboard.title',
          context: 'debtor.group.view',
        },
      },
      {
        path: '/debtor/:debtorId/edit',
        name: 'debtor.edit',
        component: DebtorForm,
        meta: {
          gotoAfterSubmit: 'debtor.view',
          title: 'debtor.edit.title',
        },
      },
      {
        path: '/debtor/:debtorId/receivable/:type?',
        name: 'debtor.receivable',
        component: DebtorReceivableList,
        meta: {
          title: 'debtor.receivable.title',
          context: 'debtor',
        },
      },
      {
        path: '/debtor/:debtorId/receivable/:type?/create',
        name: 'debtor.receivable.create',
        component: DebtorReceivableList,
        meta: {
          title: 'debtor.receivable.title',
          context: 'debtor',
          rightBar: DebtorForm,
          rightBarCloseGoTo: 'debtor.receivable',
        },
      },
      {
        path: '/debtor/:debtorId/receivable/:type/:receivableId',
        name: 'debtor.receivable.view',
        component: DebtorReceivableList,
        meta: {
          title: 'debtor.receivable.title',
          context: 'debtor',
          rightBar: DebtorReceivableView,
          rightBarCloseGoTo: 'debtor.receivable',
        },
      },
      {
        path: '/debtor/:debtorId/receivable/:type/:receivableId/edit',
        name: 'debtor.receivable.edit',
        component: DebtorReceivableList,
        meta: {
          title: 'debtor.receivable.title',
          context: 'debtor',
          rightBar: DebtorForm,
          rightBarCloseGoTo: 'debtor.receivable',
        },
      },
      {
        path: '/debtor/:debtorId/settlementplan',
        name: 'debtor.settlementplan',
        component: DebtorSettlementPlanList,
        meta: {
          title: 'debtor.settlementplan.title',
          context: 'debtor',
        },
      },
      {
        path: '/debtor/:debtorId/settlementplan/:settlementplanId',
        name: 'debtor.settlementplan.view',
        component: DebtorSettlementPlanList,
        meta: {
          title: 'debtor.settlementplan.title',
          context: 'debtor',
          rightBarCloseGoTo: 'debtor.settlementplan',
          rightBar: DebtorSettlementPlanView,
        },
      },
      {
        path: '/debtor/:debtorId/settlementplan/:settlementplanId/edit',
        name: 'debtor.settlementplan.edit',
        component: DebtorSettlementPlanList,
        meta: {
          title: 'debtor.settlementplan.title',
          context: 'debtor',
          rightBarCloseGoTo: 'debtor.settlementplan',
          rightBar: DebtorForm,
        },
      },
      {
        path: '/debtor/:debtorId/dispute/create',
        name: 'debtor.receivable.dispute.create',
        component: DebtorReceivableList,
        meta: {
          title: 'debtor.receivable.title',
          context: 'debtor.receivable',
          rightBar: DebtorSettlementPlanEdit,
          rightBarCloseGoTo: 'debtor.receivable',
        },
      },
      {
        path: '/debtor/:debtorId/promise_to_pay/create',
        name: 'debtor.receivable.promise_to_pay.create',
        component: DebtorReceivableList,
        meta: {
          title: 'debtor.receivable.title',
          context: 'debtor.receivable',
          rightBar: DebtorSettlementPlanEdit,
          rightBarCloseGoTo: 'debtor.receivable',
        },
      },
      {
        path: '/debtor/:debtorId/payment_plan/create',
        name: 'debtor.receivable.payment_plan.create',
        component: DebtorReceivableList,
        meta: {
          title: 'debtor.receivable.title',
          context: 'debtor.receivable',
          rightBar: DebtorSettlementPlanEdit,
          rightBarCloseGoTo: 'debtor.receivable',
        },
      },
      {
        path: '/debtor/:debtorId/payment_plan/create',
        name: 'debtor.receivable.export.create',
        component: DebtorReceivableList,
        meta: {
          title: 'debtor.receivable.title',
          context: 'debtor.receivable',
          rightBar: DebtorSettlementPlanEdit,
          rightBarCloseGoTo: 'debtor.receivable',
        },
      },
      {
        path: '/debtor/:debtorId/task',
        name: 'debtor.task',
        component: DebtorTaskList,
        meta: {
          title: 'debtor.task.title',
          context: 'debtor',
          actions: [
            {
              name: 'debtor.task.create',
              icon: 'plus',
            },
          ],
        },
      },
      {
        path: '/debtor/:debtorId/task/:taskId/view',
        name: 'debtor.task.view',
        component: DebtorTaskList,
        meta: {
          title: 'debtor.task.title',
          context: 'debtor',
          rightBar: TaskView,
          rightBarCloseGoTo: 'debtor.task',
        },
      },
      {
        path: '/debtor/:debtorId/task/create',
        name: 'debtor.task.create',
        component: DebtorTaskList,
        meta: {
          title: 'debtor.task.title.create',
          context: 'debtor',
          rightBar: DebtorTaskForm,
          rightBarCloseGoTo: 'debtor.task',
          rightBarEndpoint: 'debtor/:debtorId/notice/create',
        },
      },
      {
        path: '/debtor/:debtorId/info',
        name: 'debtor.info',
        component: DebtorInfo,
        meta: {
          title: 'debtor.info.title',
          context: 'debtor.info.address',
        },
      },
      {
        path: '/debtor/:debtorId/info/:property/create',
        name: 'debtor.info.property.create',
        component: DebtorInfo,
        meta: {
          endpoint: 'entity/:debtorId/:property/create',
          title: 'settings.title',
          rightBar: EntityCreate,
          rightBarCloseGoTo: 'debtor.info',
        },
      },
      {
        path: '/debtor/:debtorId/info/:property/:propertyId/edit',
        name: 'debtor.info.property.edit',
        component: DebtorInfo,
        meta: {
          endpoint: 'entity/:debtorId/:property/:propertyId/edit',
          title: 'settings.title',
          rightBar: EntityEdit,
          rightBarCloseGoTo: 'debtor.info',
        },
      },
      {
        path: '/debtor/:debtorId/note/create', //avoid using info in path otherwise conflict with entity route
        name: 'debtor.info.note.create',
        component: DebtorInfo,
        meta: {
          endpoint: 'debtor/:debtorId/note/create',
          title: 'debtor.info.title',
          rightBar: DebtorCommunicationCreate,
          rightBarCloseGoTo: 'debtor.info',
        },
      },
      {
        path: '/debtor/:debtorId/info/note/:noteId/edit',
        name: 'debtor.info.note.edit',
        component: DebtorInfo,
        meta: {
          title: 'debtor.info.title',
          rightBar: DebtorCommunicationEdit,
          rightBarCloseGoTo: 'debtor.info',
        },
      },
      {
        path: '/debtor/:debtorId/history',
        name: 'debtor.history',
        component: DebtorHistory,
        meta: {
          title: 'debtor.history.title',
        },
      },
      {
        path: '/debtor/:debtorId/communication/:type',
        name: 'debtor.communication.type',
        component: DebtorCommunication,
        meta: {
          title: 'debtor.communication.type.title',
          context: 'debtor.communication.type.task',
          endpoint: '/debtor/:debtorId/:type',
        },
      },
      {
        path: '/debtor/:debtorId/communication/:type/create',
        name: 'debtor.communication.type.task.create',
        component: DebtorCommunication,
        meta: {
          title: 'debtor.communication.type.title',
          context: 'debtor.communication.type.task',
          endpoint: '/debtor/:debtorId/:type',
          rightBar: DebtorCommunicationCreate,
          rightBarCloseGoTo: 'debtor.communication.type',
        },
      },
      {
        path: '/debtor/:debtorId/communication/:type/:typeId',
        name: 'debtor.communication.type.task.view',
        component: DebtorCommunication,
        meta: {
          title: 'debtor.communication.type.title',
          context: 'debtor.communication.type.task',
          endpoint: '/debtor/:debtorId/:type',
          rightBar: DebtorCommunicationView,
          rightBarCloseGoTo: 'debtor.communication.type',
        },
      },
      {
        path: '/debtor/:debtorId/communication/:type/:typeId/edit',
        name: 'debtor.communication.type.task.edit',
        component: DebtorCommunication,
        meta: {
          title: 'debtor.communication.type.title',
          context: 'debtor.communication.type.task',
          endpoint: '/debtor/:debtorId/:type',
          rightBar: DebtorCommunicationEdit,
          rightBarCloseGoTo: 'debtor.communication.type',
        },
      },
      {
        path: '/payment-import/:type?',
        name: 'imported_payment.index',
        component: ImportedPaymentList,
        meta: {
          acl: ['cash_allocation_module'],
          endpoint: 'imported_payment',
          title: 'importedpayment.title',
          filterBar: ImportedPaymentFilterBar,
          filterIcon: 'filter',
          actions: [
            {
              name: 'imported_payment.index',
              icon: 'file',
            },
            {
              name: 'importedpayment.create',
              icon: 'upload',
            },
            {
              name: 'importedpayment.history',
              icon: 'history',
            },
            {
              name: 'importedpayment.payment.files',
              icon: 'file-invoice',
            },
            ]
        },
      },
      {
        path: '/payment-import/:type?/:id/assign',
        name: 'importedpayment.assign',
        component: ImportedPaymentView,
        meta: {
          acl: ['cash_allocation_module'],
          endpoint: 'imported_payment/:type/:id/view',
          title: 'importedpayment.assign.title',
          actions: [
            {
              name: 'imported_payment.index',
              icon: 'file',
            },
            {
              name: 'importedpayment.create',
              icon: 'upload',
            },
            {
              name: 'importedpayment.history',
              icon: 'history',
            },
            {
              name: 'importedpayment.payment.files',
              icon: 'file-invoice',
            },
          ]
        },
      },
      {
        path: '/payment-import/history',
        name: 'importedpayment.history',
        component: ImportedPaymentHistory,
        meta: {
          acl: ['cash_allocation_module'],
          endpoint: 'imported_payment/upload/history',
          title: 'importedpayment.title',
          actions: [
            {
              name: 'imported_payment.index',
              icon: 'file',
            },
            {
              name: 'importedpayment.create',
              icon: 'upload',
            },
            {
              name: 'importedpayment.history',
              icon: 'history',
            },
            {
              name: 'importedpayment.payment.files',
              icon: 'file-invoice',
            },
          ]
        },
      },
      {
        path: '/payment-import/payment-files',
        name: 'importedpayment.payment.files',
        component: PaymentFile,
        meta: {
          acl: ['cash_allocation_module'],
          endpoint: 'imported_payment/payment_files',
          title: 'importedpayment.title',
          actions: [
            {
              name: 'imported_payment.index',
              icon: 'file',
            },
            {
              name: 'importedpayment.create',
              icon: 'upload',
            },
            {
              name: 'importedpayment.history',
              icon: 'history',
            },
            {
              name: 'importedpayment.payment.files',
              icon: 'file-invoice',
            },
          ]
        },
      },
      {
        path: '/payment-import/create',
        name: 'importedpayment.create',
        component: ImportedPaymentCreate,
        meta: {
          acl: ['cash_allocation_module'],
          title: 'importedpayment.upload.title',
          gotoAfterSubmit: 'importedpayment.history',
          actions: [
            {
              name: 'imported_payment.index',
              icon: 'file',
            },
            {
              name: 'importedpayment.create',
              icon: 'upload',
            },
            {
              name: 'importedpayment.history',
              icon: 'history',
            },
            {
              name: 'importedpayment.payment.files',
              icon: 'file-invoice',
            },
          ]
        },
      },
      {
        path: '/mailbox',
        name: 'mailbox',
        component: EmailList,
        meta: {
          endpoint: 'email',
          title: 'mailbox.title',
        },
      },
      {
        path: '/reporting-export',
        name: 'report.export',
        component: ReportList,
        meta: {
          title: 'reporting-export',
          actions: [
            {
              name: 'report.statistic',
              icon: 'chart-area',
            },
            {
              name: 'report.export.create',
              icon: 'plus',
            },
          ],
        },
      },
      {
        path: '/reporting-export/create',
        name: 'report.export.create',
        component: ReportForm,
        meta: {
          endpoint: 'report/create',
          title: 'reporting-export.create',
          gotoAfterSubmit: 'report.export',
        },
      },
      {
        path: '/reporting-statistics',
        name: 'report.statistic',
        component: ReportStatisticsView,
        meta: {
          title: 'reporting-statistics',
          actions: [
            {
              name: 'report.export',
              icon: 'file',
            },
            {
              name: 'report.statistic.edit',
              icon: 'edit',
            }
          ]
        },
      },
      {
        path: '/reporting-statistics/edit',
        name: 'report.statistic.edit',
        component: ReportStatisticsEdit,
        meta: {
          title: 'reporting-statistics.edit',
        },
      },
      {
        path: '/reporting-statistics/missing-data/:dataType',
        name: 'report.statistic.missing-data.view',
        component: MissingDataList,
        meta: {
          title: 'reporting-statistics.missing-data.view',
        },
      },
      {
        path: '/mailbox/:emailId',
        name: 'email.view',
        component: EmailList,
        meta: {
          title: 'mailbox.view.title',
          endpoint: 'email',
          rightBar: EmailView,
          rightBarCloseGoTo: 'mailbox',
        },
      },
    ],
  },
  {
    path: '/subscription',
    component: Subscription,
    name: 'wizard',
    children: [
      {
        path: 'details',
        name: 'wizard.info',
        component: SubscriptionForm,
        meta: {
          endpoint: '/subscription/creditor/edit',
        },
      },
      {
        path: 'plan',
        name: 'wizard.subscription',
        component: SubscriptionForm,
        meta: {
          endpoint: '/subscription/create',
        },
      },
      {
        path: 'claim',
        name: 'wizard.claim',
        component: SubscriptionForm,
        meta: {
          acl: ['api_subscription_wizard_claim'],
          endpoint: '/subscription/claim/create',
        },
      },
      {
        path: 'claim/success',
        name: 'wizard.claim.confirm',
        component: SubscriptionPayment,
      },
      {
        path: 'debtor/create',
        name: 'wizard.debtor.create',
        component: SubscriptionFormDebtor,
        meta: {
          acl: ['api_subscription_wizard_claim'],
          endpoint: '/subscription/debtor/create',
        },
      },
    ],
  },
  {
    path: '/debtorportal',
    component: DebtorPortal,
    children: [
      {
        path: '/',
        name: 'debtorportal',
        component: DebtorPortalDashboard,
        meta: {
          secured: false,
        },
      },
      {
        path: '/payment/:payment/pay',
        name: 'payment-handler',
        component: PaymentHandler,
        meta: {
          secured: false,
        },
      },
      {
        path: 'invalid-token',
        name: 'debtorportal.invalid-token',
        component: DebtorPortalInvalidTokenHandler,
        meta: {
          secured: false,
        },
      },
      {
        path: 'verify',
        name: 'debtorportal.verify',
        component: DebtorPortalVerify,
        meta: {
          secured: false,
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'auth.login',
    meta: {
      secured: false,
    },
    component: Login,
  },
  {
    path: '/mfa',
    name: 'auth.mfa',
    meta: {
      secured: false,
    },
    component: Mfa,
  },
  {
    path: '/verify',
    name: 'auth.verify',
    meta: {
      secured: false,
    },
    component: Verify,
  },
  {
    path: '/user/forgotpassword',
    name: 'auth.forgotpassword',
    meta: {
      secured: false,
    },
    component: ForgotPassword,
  },
  {
    path: '/user/password',
    name: 'auth.password',
    meta: {
      secured: false,
    },
    component: SetPassword,
  },
  {
    path: '/support',
    name: 'support.index',
    component: SupportMain,
  },
  {
    path: '*',
    name: '404',
    component: NotFound,
  },
];
const debtorportalRoutes: RouteConfig[] = [
  {
    path: '/',
    component: DebtorPortal,
    children: [
      {
        path: '/',
        name: 'debtorportal',
        component: DebtorPortalDashboard,
        meta: {
          secured: false,
        },
      },
      {
        path: 'payment/:payment/pay',
        name: 'payment-handler',
        component: PaymentHandler,
        meta: {
          secured: false,
        },
      },
      {
        path: 'verify',
        name: 'debtorportal.verify',
        component: DebtorPortalVerify,
        meta: {
          secured: false,
        },
      },
    ],
    meta: {
      secured: false,
    },
  },
  {
    path: '*',
    name: '404',
    component: NotFound,
    meta: {
      secured: false,
    },
  },
];
export const routes = process.env.VUE_APP_DEBTORPORTAL === 'true' ? debtorportalRoutes : allRoutes;

const router = new Router({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  const securedRoute = to.meta?.secured !== false;
  const userHasToken = !!store.state.auth.token;

  if (securedRoute && !userHasToken) {

    return next({ name: 'auth.login' });
  }
  if (to.name === 'auth.mfa' && !store.state.auth.mfa) {
    return next({ name: 'dashboard.index' });
  }
  if (to.name === 'auth.login' && userHasToken) {
    return next({ name: 'dashboard.index' });
  }

  // check if route is allowed, by comparing route acl with acl from server
  // allow route to proceed
  //    1. route has no acl
  //    2. route has acl and matched acl = true
  const aclServer = store.state.auth.acl && new Map(Object.entries(store.state.auth.acl));
  let allowed: boolean = true;
  const matched: boolean[] = [];
  to.matched.filter(record => {
    if (Object.keys(record.meta).length && record.meta.acl) {
      allowed = false;
      for (const parent of aclServer.keys()) {
        const keys: { [key: string]: string } = aclServer.get(parent) as any;
        matched.push(record.meta.acl.find((routeKey: string) => keys[routeKey]) || false);
      }
    }
  });

  if (allowed || (!allowed && matched.some(match => match))) {
    next();
  } else {
    next({ name: 'auth.login' });
  }
});

export default router;
