<template>
  <div class="card table-responsive">
    <div class="card-body">
      <data-table
        ref="settlementplanList"
        :endpoint="endpoint"
        model="settlementplan"
        :disable-pagination="isDebtorView"
        :disable-bulk-actions="isDebtorView"
        :disable-search-fields="isDebtorView"
        :disable-sort="isDebtorView"
        :disable-column-options="isDebtorView"
        :route-params="{ type: {key:'type'} }"
        :route-context="$route.meta?.context"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { EmitterAction } from '@/models';
import { ListComponent } from '@/mixins/ListComponent';

@Component({
  components: {},
})
export default class DebtorGroupSettlementPlanList extends mixins(ListComponent) {

  protected get endpoint() {
    const { groupId } = this.$route.params;
    return `debtor/group/${groupId}/settlementplan${this.isDebtorView ? '?filters[isClosed]=0' : ''}`;
  }

  protected get isDebtorView() {
    return this.$route.name && this.$route.name.indexOf('debtor.group.view') !== -1;
  }
  protected actionRoute(action: EmitterAction) {
    return `${action.context}.${this.model}.${action.emit}`;
  }
}
</script>

<style scoped lang="scss">
.nav-link {
  padding: 1rem 1rem;
}
</style>
