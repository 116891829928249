<template>
  <div>
    <warnings v-bind="{ warnings, model }" />
    <div class="card">
      <div class="card-head py-0 px-0 justify-content-between">
        <div class="d-flex justify-start">
          <router-link
            :to="{ name: 'imported_payment.index', params: { type } }"
            class="nav-link"
            v-for="type in types"
            :key="type"
            :class="{ active: $route.params.type === type }"
          >{{ $t(`receivable.${type}`) }}</router-link>
        </div>
      </div>
      <div class="card-body">
        <data-table
          :key="$route.params.type"
          ref="imported_paymentList"
          :endpoint="endpoint"
          :model="model"
          :route-context="'imported_payment'"
          :emitable-record-keys="['type']"
          :route-params="{ type: $route.params.type }"
          @action="doAction"
          @bulkAction="doBulkAction"
          :enableBulkSelection="true"
        >
            <template v-if="type === 'payments' || type === 'on-hold'" #nested-table="{ record, opened}">
              <imported-payment-suggestions
                v-if="opened.includes(record.uuid)"
                :stringArray='record.suggestions'
                :imported-payment-uuid='record.uuid'
              />
            </template>
        </data-table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ListComponent } from '@/mixins/ListComponent';
import { EmitterAction } from '@/models';
import ImportedPaymentSuggestions from '@/components/views/ImportedPaymentSuggestions.vue';
import Warnings from '@/components/global/Warnings.vue';
import { EventBus } from '@/services/EventBus';
import { appendQueryParams } from '@/utils/queryString';
import { storage } from '@/services/LocalStorage';
import { pickBy } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import SepaOptionsCreate from '@/views/importedpayment/payment-file/SepaOptionsCreate.vue';

@Component({
  components: {
    ImportedPaymentSuggestions,
    Warnings
  },
})
export default class ImportedPaymentList extends mixins(ListComponent) {
  protected model: string = 'imported_payment';
  private types: string[] = ['payments', 'on-hold'];
  private filters: string = ''
  protected mounted() {
    this.setDataTable();
    if (!isEmpty(storage.get('imported_payment_filters'))) {
      this.filters = appendQueryParams('', { filters: pickBy(storage.get('imported_payment_filters')) });
    }

    EventBus.$on('imported_payment_filters', this.loadFilters)
    if(!this.types.includes(this.$route.params.type)) {
      this.setDefaultType();
    }
  }

  protected loadFilters(filters: any) {
    this.filters = appendQueryParams('', { filters: pickBy(filters) });
  }

  private setDefaultType() {
    const params = this.$route.params;
    const type = this.types[0];

    this.$router.replace({
      params: {...params, type},
    });
  }

  protected get endpoint() {
    const type = ['payments', 'on-hold'].includes(this.type) ? this.type : 'payments';
    return `/imported_payment/${type}` + this.filters;
  }

  protected get type() {
    return this.$route.params.type?.toLowerCase();
  }

  protected async doAction(action: EmitterAction) {
    if (action.emit === 'view') {
      await this.$router.push({ name: 'importedpayment.assign', params: {type: this.type, id: action.id } });
    }

    if (action.emit === 'delete' && this.endpoint) {
      await this.delete(`${this.endpoint}/${action.id}/delete`);
    }

    if (action.emit === 'reactivate' && this.endpoint) {
      await this.showConfirmation(this.$t('imported_payment.reactivate.confirmation') as string)
        .then(() => {
          this.put({
            endpoint: `${this.endpoint}/${action.id}/reactivate`,
            feedback: {
              success: 'imported_payment.reactivate.success',
              error: 'imported_payment.reactivate.error'
            }
          });
        });
    }
  }

  protected async doBulkAction(action: EmitterAction) {
    if (action.emit === 'accept_greens') {
      const payload = { accept_green_imported_payment: {domain: action.selectDomain }};
      await this.post({endpoint: `imported_payment/accept_greens`, payload });
    }

    if (action.emit === 'imported_payment_on_hold') {
      const payload = {on_hold_imported_payment: {domain: action.selectDomain }};
      await this.put({endpoint: `imported_payment/payments/on-hold`, payload, feedback: {success: 'imported_payment.on_hold.success', error: 'imported_payment.on_hold.error'}});
    }

    if (action.emit === 'reactivate') {
      const payload = {reactivate_imported_payment: {domain: action.selectDomain }};
      await this.put({endpoint: `imported_payment/on-hold/reactivate`, payload, feedback: {success: 'imported_payment.reactivate.success', error: 'imported_payment.reactivate.error'}});
    }

    if (action.emit === 'delete') {
      const payload = {delete_imported_payment: {domain: action.selectDomain }};

      await this.delete(appendQueryParams(`imported_payment/delete`, payload));
    }

    if(action.emit === 'generate_payment_file') {
      await this.$store.dispatch('bars/openRightBar', {
        component: SepaOptionsCreate,
        title: this.$t(`payment.${action.emit}`),
        properties: {
          domain: action.selectDomain
        }
      });
      return;
    }

    this.reload();
  }
}
</script>

<style scoped lang="scss">
</style>
