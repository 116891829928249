<script lang='ts'>
import { Component, Prop, Watch } from 'vue-property-decorator';
import { FormComponent } from '@/mixins/FormComponent';
import { mixins } from 'vue-class-component';
import { AxiosResponse } from 'axios';
import api from '@/api';
import { TemplateTranslation } from '@/models/TemplateTranslation';

@Component
export default class EmailResponse extends mixins(FormComponent) {
  @Prop({ required: true }) protected endpoint!: string;
  @Prop({ required: true }) protected email!: string;
  @Prop({required: true}) protected action!: string;

  protected model: any = {};
  protected reloadAfterSubmit:boolean = false;
  protected created() {
    this.loadData();
  }

  protected afterLoad(response: AxiosResponse) {
    const title = this.schema?.title;

    if (title) {
      this.$store.commit('bars/setRightBarTitle', this.$t(title + '.header'));
    }
    this.$store.commit('bars/setRightBarFormSubmit', this.submit);
  }

  @Watch('model', { deep: true })
  protected async modelChanged(newModel: any, oldModel: any) {

    if (!this.$route.params.debtorId) {
      return;
    }
    const debtorId = this.$route.params.debtorId;

    let recipientName = 'recipient';
    if (newModel.contactTo) {
      recipientName = 'contactTo';
    }

    let newRecipient = newModel[recipientName];
    let oldRecipient = oldModel[recipientName];

    if (Array.isArray(newRecipient)) {
      if (!newRecipient.length) {
        newRecipient = undefined;
      } else {
        newRecipient = newRecipient[0];
      }
    }

    if (Array.isArray(oldRecipient)) {
      if (!oldRecipient.length) {
        oldRecipient = undefined;
      } else {
        oldRecipient = oldRecipient[0];
      }
    }

    if (!newRecipient || !newModel.template) {
      return;
    }

    if (newRecipient === oldRecipient && newModel.template === oldModel.template) {
      return;
    }

    const endpoint = `debtor/${debtorId}/template/${newModel.template}/contact/${newRecipient}/translation/${this.email}/${this.action}`;
    const result = await api.get<TemplateTranslation>(endpoint);

    if (result.data && result.status === 200) {
      this.updateTranslation(result.data);
    }
    if (result?.data.error) {
      this.$toasted.error(this.$t(result.data.error) as string);
    }
  }
  private updateTranslation(templateTranslation: TemplateTranslation): void {
      this.model.content = templateTranslation.content;

      if (null != templateTranslation.subject && templateTranslation.subject.length > 0) {
      this.model.subject = templateTranslation.subject;
    }
  }
  }
</script>
