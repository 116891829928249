<template>
  <data-table
    :model="model"
    :endpoint="endpoint"
    @action="doAction"
    @bulkAction="doBulkAction"
    :route-context="$route.meta?.context"
    ref="taskList"
  />
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ActionListMixin } from '@/mixins/ActionListMixin';
import { EmitterAction } from '@/models';

@Component
export default class ActionList extends mixins(ActionListMixin) {
}
</script>

<style scoped lang="scss">
</style>
