<template>
  <div>
    <loader :loading="loading"/>
    <form @submit.prevent="submit" class="form" v-if="schema" :ref="schema.title">
      <json-form :schema="schema" v-model="model" :errors="errors"/>
      <button type="submit" class="btn btn-primary btn-sm w-100">
        <Icon prefix="fas" name="save" class="mr-2"/>
        {{ $t('save') }}
      </button>
    </form>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { AxiosResponse } from 'axios';
import { FormComponent } from '@/mixins/FormComponent';

@Component({})
export default class SepaOptionsCreate extends mixins(FormComponent) {
  protected model: any = {};
  protected selectDomain: any = {};
  protected endpoint!: string;
  protected reloadAfterSubmit: boolean = false;

  protected async created() {
    this.$store.commit('bars/setRightBarTitle', 'loading')
    this.selectDomain = this.rightBarProperties.domain;
    this.endpoint = `/imported_payment/on-hold/generate-payment-file?domain=${this.selectDomain}`;
    await this.loadData();
    if (this.loading === 'error') {
      this.$store.commit('bars/closeRightBar');
    }
  }

  protected afterLoad(response: AxiosResponse) {
    const title: string = this.schema?.title ?? this.$route.meta?.title;

    this.$store.commit('bars/setRightBarTitle', this.$t(title + '.header'));

    this.$store.commit('bars/setRightBarFormSubmit', this.submit);
  }

  protected afterSubmit(success: boolean, response: AxiosResponse) {
    if (success) {
      this.$store.commit('bars/closeRightBar');
    }
  }

  private get rightBarProperties() {
    return this.$store.state.bars.rightBarProperties;
  }
}
</script>

<style scoped lang="scss">
</style>
