<template>
  <div>
    <loader :loading="loading"/>
    <form @submit.prevent="openModalAndSubmit" class="form settings-form mb-3" v-if="schema">
      <div class="card h-100">
        <div class="card-head justify-content-between">
          <h2 class="h5">{{ $t(`${schema.title}.header`) }}</h2>
          <div class='d-flex'>
          <router-link
            class="btn btn-secondary mr-1 btn-sm"
            :title="$t('settings.businessunit.ftpsettings')" :to="{name: 'settings.businessunit.ftpsettings' }">{{$t('settings.businessunit.ftpsettings')}}</router-link>

          <button type="submit" class="btn btn-primary btn-sm mr-3">
            <Icon prefix="fas" name="save" class="mr-2"/>
            {{ $t('settings.save') }}
          </button>
          </div>
        </div>
        <div class="card-body">
          <json-form
            v-if="schema"
            :schema="schema"
            v-model="model"
            reload-after-submit
            :errors="errors"
          />
        </div>
      </div>
    </form>

    <div v-if="settingsEndpoint" class="card">
      <div class="card-body">
        <import-settings :endpoint="settingsEndpoint"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { SettingsFormComponent } from '@/mixins/SettingsFormComponent';
import { AxiosResponse } from 'axios';
import { mixins } from 'vue-class-component';
import ImportSettings from '@/components/settings/ImportSettings.vue';

@Component({
  components: {
    ImportSettings,
  },
})
export default class SettingsModelImportSettings extends mixins(SettingsFormComponent) {
  protected model: any = {};
  protected settingsEndpoint: string|null = null;

  protected async openModalAndSubmit() {
    this.$store.dispatch('modal/open', {
      body: this.$t(`settings.businessunit.importsettings.confirmation`) as string,
      actions: [
        {
          label: this.$t('modal.cancel') as string,
          class: 'btn-dark',
          action: async () => this.$store.dispatch('modal/close'),
        },
        {
          label: this.$t('modal.confirm') as string,
          class: 'btn-primary',
          action: async () => {
            await this.submit();
            this.$store.dispatch('modal/close');
          },
        },
      ],
    });
  }

  protected afterLoad(response: AxiosResponse) {
    this.settingsEndpoint =  response.data.settingsEndpoint;
  }

  protected afterSubmit(success: boolean, response: any) {
      if (success) {
        this.loadData();
      }
  }
}
</script>
