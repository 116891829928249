<template>
  <div class="card">
    <loader :loading="loading" />
    <div class="card-head flex justify-between py-0 pr-0">
      <h2 class="h5">Activiteitsmonitor</h2>
    </div>
    <div class="card-body overflow-auto" v-if="monitoringEvents">
      <activity-monitor-item v-for="item in sortedPaginatedEvents" :key="item.eventId" v-bind="{ item }" />
    </div>
    <div class="d-flex justify-content-center">
    <pagination v-if="totalRecords > pageSize" :current-page="page" :page-size="pageSize" :total-records="totalRecords" @change="changePage" />
    </div>
  </div>
</template>

<script lang='ts'>
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { API } from '@/mixins/API';
import ActivityMonitorItem from './ActivityMonitorItem.vue';
import { MonitorEvent } from '@/models';
import Pagination from '@/components/global/Pagination.vue';

@Component({
  components: {
    Pagination,
    ActivityMonitorItem,
  },
})
export default class ActivityMonitor extends mixins(API) {
  private monitoringEvents: MonitorEvent[] = [];
  private page: number = 1;
  private pageSize: number = 10;
  private created() {
    this.getData();
  }

  private getData() {
    this.get(this.endpoint).then(({ monitoringEvents }) => this.monitoringEvents = monitoringEvents);
  }
  private changePage(page: number) {
    this.page = page;
  }
  private get endpoint(): string {
    return `/graydon/monitoring/${this.$route.params.debtorId}`;
  }
  private get sortedPaginatedEvents(): MonitorEvent[] {
    return this.monitoringEvents
              .sort((a, b) => new Date(b.eventDate).getTime() - new Date(a.eventDate).getTime())
              .filter((event, index) =>  index >= (this.page-1)*this.pageSize && index < this.page * this.pageSize);
  }
  private get totalRecords(): number {
    return this.monitoringEvents.length;
  }
}
</script>

<style lang="scss" scoped>

</style>
