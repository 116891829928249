import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Bar } from 'vue-chartjs';
import { ChartOptions } from 'chart.js';

@Component({
  extends: Bar,
})
export default class BarChart extends Mixins(Bar) {

  @Prop({required: true})
  private data!: Record<string, number>;

  @Prop({default: ['#3cba9f','#3e95cd','#ffa161','#c45850']})
  private colors!: string[];

  private mounted() {
    this.draw();
  }
  @Watch('data')
  private draw() {
    this.renderChart({
      labels: Object.keys(this.data),
      datasets: [
        {
          backgroundColor: this.colors,
          data: Object.values(this.data),
        },
      ],
    }, this.options);
  }

  private get options(): ChartOptions {
    return {
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      scales: {
        yAxes: [{
          ticks: {
            display: false,
          },
          gridLines: {
            drawBorder: false
          },
        }],
        xAxes: [{
          ticks: {
            display: false,
          },
          gridLines: {
            display:false
          }
        }],
      }
    };
  }
}
