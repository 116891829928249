<template>
  <data-table
    :endpoint="endpoint"
    route-context="settings.businessunit.sync"
    @action="doAction"
    ref="historyList"
  />
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { SettingsListComponent } from '@/mixins/SettingsListComponent';
import DataTable from '@/plugins/data-table/DataTable.vue';

import { EmitterAction } from '@/models';
import { Dictionary } from 'vue-router/types/router';

@Component({})
export default class SettingsModelSyncHistory extends mixins(SettingsListComponent) {
  protected model: string = 'history';

  protected async doAction(action: EmitterAction): Promise<void> {
    const actionType = action.emit;
    const params = this.$route.params;

    if(actionType === 'view') {
      this.performViewAction(params, action);
    }
    else if(actionType === 'download' || actionType === 'preview') {
      const endpoint = `settings/businessunit/${params.id}/sync/${action.id}/download`;
      await this.download(endpoint);
    }
    else {
      return super.doAction(action);
    }
  }

  private performViewAction(params: Dictionary<string>, action: EmitterAction) {
    this.$router.push({
      name: `settings.businessunit.sync.view`,
      params: {
        id: params.id,
        businessUnitImport: '' + action.id,
        model: 'history',
      },
    });
  }

  protected get endpoint(): string {
    const params = this.$route.params;
    return `settings/businessunit/${params.id}/sync/history`;
  }
}
</script>

<style scoped lang="scss">
</style>
