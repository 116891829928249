<template>
  <div>
    <loader :loading="loading" />
    <form @submit.prevent="submit" class="form" v-if="schema" :ref="schema.title">
      <json-form :schema="schema" v-model="model" :errors="errors" />
      <div class="row">
        <button
          @click.prevent="gotoClaim"
          class="btn btn-white text-dark col-sm-4 justify-content-end"
        >
          {{ $t('modal.cancel') }}
        </button>
        <div class="col-sm-8">
          <button type="submit" class="btn btn-primary w-100">
            {{ $t('create') }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator';
import { FormComponent } from '@/mixins/FormComponent';
import { mixins } from 'vue-class-component';
import { routes } from '@/router';
import { AxiosResponse } from 'axios';

@Component({})
export default class SubscriptionFormDebtor extends mixins(FormComponent) {
  protected model: any = {};

  protected created() {
    this.setEndpointAndLoad();
  }

  protected async setEndpointAndLoad() {
    this.endpoint = this.$route.meta?.endpoint;
    await this.loadData();
  }
  protected afterSubmit(success: boolean, response: AxiosResponse) {
    if (success) {
      this.gotoClaim();
    }
  }
  private gotoClaim() {
    this.$router.push({
      name: 'wizard.claim',
      params: this.$route.params,
    });
  }

  protected get routeName(): string {
    return (this.$route.name && this.$route.name.split('.')[1]) || '';
  }
}
</script>
