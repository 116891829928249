<template>
  <div class="card" v-if="metric && (businessUnit || creditor)">
    <div class="card-head border-0 d-flex justify-content-center">
      <h2 class="h5 d-flex flex-row align-items-center">{{ $t('dashboard.graph.' + name) }}</h2>
    </div>
    <div class="card-body border-0 d-flex flex-row justify-content-center">
      <doughnut-chart :data="valueToPercentage" :height="200" :width="200"/>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Watch, Vue, Prop } from 'vue-property-decorator';
import { LoadingState, Metric } from '@/models';
import format from 'date-fns/format';
import DoughnutChart from '@/components/charts/DoughnutChart.vue';

@Component({
  components: {
    DoughnutChart: DoughnutChart as any,
  },
})
export default class SuccessRateChart extends Vue {
  @Prop()
  private value!: number;

  @Prop()
  private name!: string;

  get businessUnit() {
    if (this.metric) {
      return this.metric.businessUnit || '';
    }
  }

  get creditor() {
    if (this.metric) {
      return this.metric.creditor || '';
    }
  }
  get metric() {
    return this.$store.state.dashboard.metrics
      ? this.$store.state.dashboard.metrics.successRateMetrics
      : null;
  }
  
  public get valueToPercentage() : number {
    return this.value * 100;
  }
  
}
</script>

