<template>
  <div class="mapping-diagram">
    <h5>{{ $t('form.business_unit_sync.label.import_fields_mapper') }}</h5>
    <div class="row">
      <div class="col-12">
        <p>{{ $t('form.business_unit_sync.label.available_fields') }}:</p>
        <draggable 
        draggable=".tag" 
        v-model="unusedMappings" 
        class="mappings-holder" 
        group="tags" 
        animation="200" 
        :force-fallback="true" 
        :scroll="true"
        :scroll-sensitivity="scrollSentivity">
          <mapping-tag @add="addMapping(tagIndex)" :showAdd="true" v-for="(tag, tagIndex) in unusedMappings" :name="tag" :key="tagIndex" :value="tag" />
        </draggable>
      </div>
      <div class="col-12" v-if="hasError">
        <div class="invalid-feedback">
          <p v-for="error in validatationErrors" :key="error">{{ $t(error) }}</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <strong>{{ $t('form.business_unit_sync.label.imported_fields') }}:</strong>
      </div>
      <div class="col-6">
        <strong>{{ $t('form.business_unit_sync.label.mapped_fields') }}:</strong>
      </div>
    </div>
    <div class="row" v-for="(header, headerIndex) in schema.headers" :key="headerIndex" >
      <div class="col-6">
        <!-- <draggable draggable=".Field " group="mappedField s" v-model="mappedField sHeaders" animation="300"> -->
          <div class="header">
            <!-- <div class="Field -arrow">
                <icon prefix="fas" size="2x" name="long-arrow-alt-left" v-if="header.length" />
            </div>
            <div class="Field -required" v-if="Field .required && !Field .importField s.length">
                <icon size="2x" name="exclamation-circle" /> 
            </div> -->
            <div class="header-inner">
              <span>{{header}}</span>
            </div>
          </div>
        <!-- </draggable> -->
      </div>
      <div class="col-6">
        <draggable 
          animation="200"
          draggable=".tag" 
          group="tags" 
          :force-fallback="true"
          :scroll="true"
          :scroll-sensitivity="scrollSentivity*1.2"
          @add="placeholderAdd"
          @end="$forceUpdate()"
          v-model="mappings[header]"
          class="placeholder">
            <mapping-tag @remove="removeMapping(header)" :showDelete="true" v-for="(mappedHeader, mappedHeaderIndex) in mappings[header]" :name="mappedHeader" :key="mappedHeaderIndex" :value="mappedHeader" />
            <!-- <import-Field  :showDelete="true" @remove="removeImportField (subindex, Field .importField s)" v-for="(importField , subindex) in Field .importField s" :key="importField .value" :value="importField .value" :index="subindex" /> -->
        </draggable>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { some } from 'lodash';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import { Rectangle } from 'vuedraggable';
import MappingTag from './MappingDiagramTag.vue';

@Component({
  components: {
   draggable,
   MappingTag
  }
})
export default class MappingDiagram extends Vue {
  private mappings: any = {};
  private unusedMappings: any[] = [];

  @Prop({default: 700}) private scrollSentivity!: number;

  @Prop() private schema!: any;
  @Prop() private value!: any;

  @Prop() private errors!: any;


  @Watch('schema')
  private checkAvailableMappings() {
    this.mappings = {};
    this.unusedMappings = [];
    if(this.schema && this.schema?.headers && this.schema.headers?.length) {
      this.schema.headers.forEach((header:string) => {
        this.mappings[header] = [];
      });
    }
    if(this.schema.availableMappings?.length && this.schema.headers?.length) {
      this.unusedMappings = this.schema.availableMappings;
      if(this.value) {
        Object.keys(this.value).forEach(key => {
          const mappingValue = this.value[key]
          for (const mapKey of Object.keys(this.mappings)) {
            const mapArr = this.mappings[mapKey];
            if(mapKey === mappingValue.from) {
              mapArr.push(mappingValue.to);
            }
            this.unusedMappings = this.unusedMappings.filter((mappingName:string) => mappingName !== mappingValue.to)
          }; 
        });
      }
    }
    this.sortUnusedFields();
  }

  private addMapping(index:number) {
    if(this.unusedMappings?.length && this.schema.headers?.length) {
     this.schema.headers.some((header:any) => {
       if(!this.mappings[header]?.length) {
          const mapping  = this.unusedMappings?.splice(index, 1);
          this.mappings[header] = mapping;
          return true;
        }
     });
    }
    this.updateValue();
  }
  private updateValue() {
    this.sortUnusedFields();
    const mappingValues = [];
    for (const mapKey of Object.keys(this.mappings)) {
      const mapArr = this.mappings[mapKey];
      if(mapArr?.length) {
        const mappingRow:any = {'from': mapKey, 'to': mapArr[0]};
        mappingValues.push(mappingRow);
      }
    }; 

    this.$emit('input', mappingValues);
  }
  private removeMapping(name:string) {
    if(this.unusedMappings?.length && this.schema.headers?.length && this.mappings) {
      if(this.mappings.hasOwnProperty(name)) {
          const mappedName = this.mappings[name].splice(0,1).pop();
          this.unusedMappings.push(mappedName);
      }
    }
    this.updateValue();
  }

  private removeMultipleTagMappings(newIndex:number) {
    for (const mapKey of Object.keys(this.mappings)) {
      const mapArr = this.mappings[mapKey];
      if(mapArr.length > 1) {
        const keepMapping = mapArr[newIndex];
        this.unusedMappings = this.unusedMappings.concat(this.mappings[mapKey].filter((element:any, index:number) => index !== newIndex));
        this.mappings[mapKey] = [mapArr[newIndex]];
      }
    }; 
  }

  private placeholderAdd(e:any) {
    this.removeMultipleTagMappings(e.newIndex);
    this.$forceUpdate();
    this.updateValue();
  }

  private created() {
    this.checkAvailableMappings();
  }

  private mounted() {
    this.checkAvailableMappings();
    //In order to prevent the Firefox browser from opening with a new tab when dragging, this code is really effective
    document.body.ondrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
    }
  }
  private sortUnusedFields() {
    this.unusedMappings.sort((a,b) => {
      if(a < b) { return -1; }
      if(a > b) { return 1; }
      return 0;
    });
  }

  private get hasError() {
    if (this.errors?.errors?.length) {
      return true;
    }
    return false;
  }
  private get validatationErrors() {
    if (this.errors?.errors?.length) {
      return this.errors?.errors;
    }
    return [];
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/colors';
@import '~@/assets/scss/media-queries';
.mapping-diagram { 
  .mappings-holder { 
    min-height: 32px;
    background-color: lighten($mercury, 5%);
    width: 100%; 
    padding-top: .4rem;
    padding-left: .4rem;
    padding-bottom: 0;
    border-radius: .4rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    transition: all 0.2s ease;
    .tag {
      width: 100%;
    }
    @include media-breakpoint-up(sm) {
      .tag {
        width: 50%;
      }
    }
    @include media-breakpoint-up(md) {
      .tag {
        width: 33.33%;
      }
    }
    @include media-breakpoint-up(lg) {
      .tag {
        width: 25%;
      }
    }
    @include media-breakpoint-up(xxl) {
      .tag {
        width: 20%;
      }
    }
  }
  
  .placeholder {
    flex-wrap: wrap;
    min-height: 32px;
    position: relative;
    border-radius: .4rem;
    display: flex;
    margin-bottom: 1rem;
    width: 100%;
    background-color: darken($mercury, 5%);
    padding-top: .4rem;
    padding-left: .4rem;
    transition: background-color 0.1s ease, height 0.2s ease;
    .tag { 
      width: 100%;
    }
    @include media-breakpoint-up(md) {
      .tag {
        width: 50%;
      }
    }
    @include media-breakpoint-up(xxl) {
      .tag {
        width: 40%;
      }
    }
  }
  .row.required {
    .placeholder {
      background-color: #AAAAAA;
    }
    .Field -inner {
      color: white;
      background-color: #009966;
    }
  }
  .header {
    z-index: 1;
    width: 100%;
    position: relative;
    display: block;
    padding-bottom: 1rem;
    background-color: rgba(255,255,255,0);
    font-weight: 600;
    &-inner {
      align-items: center;
      justify-content: center;
      min-height: 32px;
      width: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      padding: 0.2rem 0.6rem;
      border-radius: .4rem;
      background-color: #99DDAA;
      // .mapped-icon {
      //   display: inline-block;
      //   min-width: 20px;
      //   height: 100%;
      //   background-color: rgba(0,0,0,0.5);
      // }
      span {
        flex-grow: 1;
        overflow: hidden;
      }
    }
  }
}
</style>
