<template>
  <div class="overflow-x-auto overflow-y-padding">
    <hr>
    <div class="invalid-feedback" v-if="hasError">
      <p v-for="error in hasError" :key="error">{{error}}</p>
    </div>
    <select-workflow v-model="model.workflow" :workflows="workflows"/>
    <hr>
    <h3 class="h4 text-highlight">
      {{$t('segmentation.filters-and-workflow')}}
      <a
        @click.prevent="createFilterGroup"
        v-if="!value.filtergroup && !value.workflow"
      >
        <icon class="icon-plus" name="plus"/>
      </a>
    </h3>
    <div class="conditions-and-actions" v-if="model.filtergroup">
      <filters-and-workflow
        v-model="model.filtergroup"
        :filter-types="filterTypes"
        :workflows="workflows"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { SegmentationData, SegmentationModel, WorkflowSelect, FilterTypes } from '@/models';
import SelectWorkflow from './SelectWorkflow.vue';
import FiltersAndWorkflow from './FiltersAndWorkflow.vue';

@Component({
  components: {
    SelectWorkflow,
    FiltersAndWorkflow,
  },
})
export default class Segmentation extends Vue {
  @Prop({ type: [Object], required: true })
  private extra!: {
    segmentationData: SegmentationData;
  };
  @Prop()
  private errors!: any;

  @Prop()
  private value!: any;

  private model: SegmentationModel = {
    workflow: null,
    filtergroup: null,
  };

  private created() {
    this.model = Object.assign({}, this.value);
  }
  private createFilterGroup() {
    this.model.filtergroup = Object.assign({
      filters: {
        logical_operator: 'OR',
        rules: [[]],
      },
      true: {
        workflow: null,
        filtergroup: null,
      },
      false: {
        workflow: null,
        filtergroup: null,
      },
    });
  }

  @Watch('model', { deep: true })
  private modelChanged() {
    this.$emit('input', this.model);
  }

  get workflows(): WorkflowSelect {
    return this.extra.segmentationData.workflowSelect;
  }

  get filterTypes(): FilterTypes {
    return this.extra.segmentationData.filterTypes;
  }

  get hasError(): string[] {
    let errors: string[] = [];
    if (
      this.errors &&
      this.errors.errors &&
      this.errors.errors.children &&
      this.errors.errors.children.filters &&
      this.errors.errors.children.filters.errors &&
      this.errors.errors.children.filters.errors.length
    ) {
      errors = [...this.errors.errors.children.filters.errors];
    }
    if (
      this.errors &&
      this.errors.errors &&
      this.errors.errors.errors &&
      this.errors.errors.errors.length
    ) {
      errors = [...errors, ...this.errors.errors.errors];
    }
    return errors;
  }
}
</script>

<style lang="scss" scoped>
.filters-and-workflow {
  overflow-x: auto;
  white-space: nowrap;
}

.filters-and-workflow .form-group {
  background: red;
}
.overflow-y-padding {
  // https://stackoverflow.com/questions/6421966/css-overflow-x-visible-and-overflow-y-hidden-causing-scrollbar-issue

  margin-top: -300px;
  padding-top: 300px;
  margin-bottom: -300px;
  padding-bottom: 300px;
}
</style>
