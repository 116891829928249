<template>
  <SettingsCreditorView v-if="$route.params.model === 'creditor'"/>
</template>

<script lang="ts">
import { Vue, Watch, Component } from 'vue-property-decorator';
import SettingsCreditorView from './creditor/Info.vue';

@Component({
  components: { SettingsCreditorView },
})
export default class SettingsModelView extends Vue {}
</script>
