import { Module } from 'vuex';

import { RootState} from '@/models';
import api from '@/api';
import { TaskCounts, TaskState } from '@/models/TaskState';

export const task: Module<TaskState, RootState> = {
  namespaced: true,
  state: {
    loading: false,
    counts: null,
  },
  actions: {
    async loadData({ commit, dispatch }) {
      commit('setLoading', true);
      await api.get('/task-count')
          .then(({data}) => commit('setTaskCounts', data.tasks))
          .catch(() => dispatch('throwError', { msg: 'Did not receive the correct parameters' }, { root: true }));
      commit('setLoading', false);
    },
    unloadData({ commit }) {
      commit('unsetData');
    },
  },
  mutations: {
    setLoading(state, loading: boolean) {
      state.loading = loading;
    },
    setTaskCounts(state, data: TaskCounts) {
      state.counts = data;
    },
    unsetData(state) {
      state.loading = false;
      state.counts = null;
    },
  },
};
