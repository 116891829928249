<template>
  <div class="card h-100 mb-3">
    <loader :loading="loading || handlingUserEvent" />
    <div class="card-head justify-between py-0 pr-0">
      <h2 class="h5">
        <span>
          {{ $t('debtors.overview.info.title') }}
          <icon
            v-if="userInfo"
            :title="userInfo.verified ? $t('debtors.user.verified') : $t('debtors.user.not-verified')"
            :class="{ verified: userInfo.verified, 'not-verified': !userInfo.verified }"
            name="check-circle"
            prefix="fas"
          />
        </span>
      </h2>
      <span
        v-if="debtor && debtor.interrupt_workflow"
        class="flex align-self-center text-danger font-weight-bold text-uppercase"
        >{{ $t('debtor.on_hold') }}</span
      >
      <div class="d-flex position-relative">
        <div v-if="!userInfo" @click.prevent="handleCreateUser" class="nav-link nav-link-right position-relative">
          <icon :title="$t('debtors.user.create')" name="user-plus" />
        </div>
        <div v-else class="d-flex">
          <router-link
            v-if="debtor && hasDebtorSSO"
            :to="{ name: 'debtorportal', query: { debtor: debtor.uuid, token: userInfo.token } }"
            class="nav-link nav-link-right"
            target="_blank"
          >
            <icon :title="$t('debtors.user.signin')" name="sso_signin" />
          </router-link>
          <div class="nav-link nav-link-right" @click.prevent="handleActivateUser">
            <icon v-if="userInfo.active" :title="$t('debtors.user.deactivate')" name="user-check" />
            <icon v-else :title="$t('debtors.user.activate')" name="user-times" />
          </div>
        </div>
        <router-link
          v-if="canManageDebtor"
          class="nav-link nav-link-right"
          :title="$t('debtors.overview.info.edit')"
          :to="{
            name: 'debtor.edit',
          }"
        >
          <icon name="edit" />
        </router-link>
      </div>
    </div>
    <div class="card-body" v-if="debtor">
      <div class="d-flex flex-row justify-content-between">
        <div class="d-flex">
          <span>{{ debtor.display_name }}</span>
        </div>
        <div>
          <p v-if="debtor.reference" class="text-lg font-weight-bold">
            {{ debtor.reference }}
          </p>
          <p v-if="debtor.segmentation_code" class="float-right">
            {{ debtor.segmentation_code }}
          </p>
        </div>
      </div>
      <p v-if="defaultAddress">
        <span v-if="defaultAddress.street">
          {{ defaultAddress.street }} {{ defaultAddress.house_number }} {{ defaultAddress.box_number }}
          <br />
          {{ defaultAddress.city }} {{ defaultAddress.postal_code }}
          <br />
          {{ defaultAddress.country }}
        </span>
      </p>
      <div v-if="debtor.ssn" class="d-flex flex-column">
        <span>{{ $t('debtors.ssn') }}: {{ debtor.ssn }}</span>
      </div>
      <div v-if="userOtherBusinessUnits.length" class="d-flex flex-column">
        <span>{{ $t('debtor_in_other_business_units') }}</span>
        <router-link
          v-for="item in userOtherBusinessUnits"
          :to="{ name: 'debtor.view', params: { debtorId: item.uuid } }"
          :key="item.uuid"
          >{{ item.business_unit.name }}</router-link
        >
      </div>
      <div v-if="defaultContact" class="d-flex flex-column">
        <span v-if="defaultContact.telephone">
          {{ $t('debtors.overview.info.phone') }}: {{ defaultContact.telephone }}
        </span>
        <span v-if="defaultContact.mobile">
          {{ $t('debtors.overview.info.mobile') }}: {{ defaultContact.mobile }}
        </span>
        <span v-if="defaultContact.email">
          {{ $t('debtors.overview.info.email') }}:
          <a v-bind:href="'mailto:' + defaultContact.email">{{ defaultContact.email }}</a>
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { Address, Contact, Debtor, DebtorUserInfoModel, UserOtherBusinessUnits } from '@/models';
import { API } from '@/mixins/API';
import { EventBus } from '@/services/EventBus';

@Component({})
export default class DebtorInfo extends mixins(API) {
  private userInfo: DebtorUserInfoModel | null = null;
  private handlingUserEvent: boolean = false;
  private userOtherBusinessUnits: UserOtherBusinessUnits | [] = [];

  private async created() {
    await this.loadUserInfo();
    await this.loadUserBusinessUnits();
    EventBus.$on('workflow.status.changed', this.loadDebtorState);
    EventBus.$on('workflow.status.changed', this.loadUserBusinessUnits);
  }

  private beforeDestroy() {
    EventBus.$off('workflow.status.changed', this.loadDebtorState);
    EventBus.$off('workflow.status.changed', this.loadUserBusinessUnits);
  }

  private loadDebtorState() {
    this.$store.dispatch('debtor/loadCurrentDebtor', this.$route.params.debtorId);
  }

  private async loadUserInfo() {
    const res = await this.get(`/debtor/${this.$route.params.debtorId}/user/create`);
    this.userInfo = res.user;
  }

  private async loadUserBusinessUnits() {
    const res = await this.get(`/debtor/${this.$route.params.debtorId}/contact_info`);
    this.userOtherBusinessUnits = res.otherBusinessUnits;
  }

  private async handleCreateUser() {
    if (this.handlingUserEvent) {
      return;
    }

    this.preHandle();

    const res = await this.post({
      endpoint: `/debtor/${this.$route.params.debtorId}/user/create`,
      feedback: { success: this.$t(`debtor.user.create.success`) },
    });

    this.postHandle();
  }

  private async handleActivateUser() {
    if (this.handlingUserEvent) {
      return;
    }

    this.preHandle();

    if (this.userInfo && this.userInfo.active) {
      const res = await this.post({
        endpoint: `/debtor/${this.$route.params.debtorId}/user/deactivate`,
        feedback: { success: this.$t(`debtor.user.deactivate.success`) },
      });
    } else {
      const res = await this.post({
        endpoint: `/debtor/${this.$route.params.debtorId}/user/activate`,
        feedback: { success: this.$t(`debtor.user.activate.success`) },
      });
    }

    this.postHandle();
  }

  private preHandle(): void {
    this.handlingUserEvent = true;
  }

  private async postHandle() {
    await this.loadUserInfo();
    this.handlingUserEvent = false;
  }

  get hasDebtorSSO(): boolean {
    return null != this.userInfo && this.userInfo.active && !!this.userInfo.token;
  }

  get defaultContact(): Contact | undefined {
    if (this.debtor) {
      return this.debtor.defaultContact || undefined;
    }
    return undefined;
  }

  get defaultAddress(): Address | undefined {
    if (this.debtor) {
      return this.debtor.defaultAddress || undefined;
    }
    return undefined;
  }

  get debtor(): Debtor {
    return this.$store.state.debtor.currentDebtor;
  }

  private get canManageDebtor(): boolean {
    return !!this.debtor?.acl.manage_debtors;
  }
}
</script>

<style scoped lang="scss">
.verified {
  color: var(--primary-color);
}

.not-verified {
  opacity: 0.3;
}
</style>
