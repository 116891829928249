<template>
  <data-table
    model="dispute"
    :endpoint="endpoint"
    @action="doAction"
    :ref="`${model}List`"
    route-context="dashboard.dispute"
  />
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { SettlementPlanListComponent } from '@/mixins/SettlementPlanListComponent';
import { EmitterAction } from '@/models';
@Component
export default class DisputeList extends mixins(SettlementPlanListComponent) {
  protected get endpoint() {
    return `/dashboard/disputes`;
  }
  protected actionRoute(action: EmitterAction) {
    return `${action.context}.${action.emit}`;
  }
}
</script>

<style scoped lang="scss">
</style>
