import _Vue from 'vue';

import JsonForm from './JsonForm.vue';
import JsonFormObject from './JsonFormObject.vue';
import JsonFormString from './JsonFormString.vue';
import JsonFormArray from './JsonFormArray.vue';
import JsonFormBoolean from './JsonFormBoolean.vue';

export function JsonFormPlugin(Vue: typeof _Vue, options?: {}) {
  Vue.component('json-form', JsonForm);
  Vue.component('json-form-object', JsonFormObject);
  Vue.component('json-form-string', JsonFormString);
  Vue.component('json-form-array', JsonFormArray);
  Vue.component('json-form-boolean', JsonFormBoolean);
}
