var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card h-100"},[_c('div',{staticClass:"card-head justify-between py-0 pr-0"},[_c('h2',{staticClass:"h5"},[_vm._v(_vm._s(_vm.$t('debtors.overview.tasks.title')))]),_c('div',{staticClass:"d-flex"},[(_vm.nextDebtor)?_c('router-link',{staticClass:"nav-link nav-link-right",attrs:{"to":{
          name: 'debtor.view',
          params: {
            debtorId: _vm.nextDebtor,
          },
        }}},[_c('icon',{attrs:{"name":"step-forward","title":_vm.$t('debtor.tasks-completed.next-debtor')}})],1):_vm._e(),_c('router-link',{staticClass:"nav-link nav-link-right",attrs:{"to":{
          name: 'debtor.task',
        }}},[_c('icon',{attrs:{"name":"th-list","title":_vm.$t('debtors.overview.tasks.btn.all')}})],1),_c('router-link',{staticClass:"nav-link nav-link-right",attrs:{"to":{
          name: 'debtor.view.task.create',
        }}},[_c('icon',{attrs:{"name":"plus","title":_vm.$t('debtors.overview.tasks.btn.create')}})],1)],1)]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex justify-content-end mb-3"},[_c('button',{staticClass:"btn btn-sm mr-2",class:this.filters.disableDashboardFilter ? 'btn-primary' : 'btn-light',domProps:{"textContent":_vm._s(_vm.$t(_vm.filters.disableDashboardFilter ? 'task.disableDashboardFilter.on': 'task.disableDashboardFilter.off'))},on:{"click":function($event){return _vm.toggle('disableDashboardFilter')}}},[_vm._v(" "+_vm._s(_vm.$t(`task.disableDashboardFilter`))+" ")]),_c('button',{staticClass:"btn btn-sm mr-2",class:this.filters.onlyClaimedTasks ? 'btn-primary' : 'btn-light',domProps:{"textContent":_vm._s(_vm.$t(_vm.filters.onlyClaimedTasks ? 'task.onlyClaimedTasks.on': 'task.onlyClaimedTasks.off'))},on:{"click":function($event){return _vm.toggle('onlyClaimedTasks')}}})]),_c('data-table',{ref:"taskList",attrs:{"model":_vm.model,"endpoint":_vm.endpointWithFilters,"route-context":"debtor.view","disable-column-options":"","disable-pagination":"","disable-vertical-scroll":"","disable-bulk-actions":_vm.isDebtorView},on:{"action":_vm.doAction,"bulkAction":_vm.doBulkAction,"response":_vm.handleResponse}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }