// mixin.js
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { EmitterAction } from '@/models';
import DataTable from '@/plugins/data-table/DataTable.vue';
import { ListComponent } from '@/mixins/ListComponent';
import { parseEndpoint } from '@/router';

@Component
export class EntityListComponent extends mixins(ListComponent) {
  @Prop() protected entity!: string;
  @Prop() protected property!: string;

  protected created() {
    this.model = this.property;
  }

  protected async doAction(action: EmitterAction) {
    const { params, meta } = this.$route;
    this.dataTable = this.$refs[this.model + 'List'] as DataTable;
    const id = params.id || params.debtorId;
    if (action.emit === 'delete') {
      await this.delete(`entity/${id}/${this.property}/${action.id}/delete`);
    } else {
      const newRoute = {
        name: `${action.context}.property.${action.emit}`,
        params: {
          ...params,
          ...action.params,
          model: meta?.model,
        },
      };
      this.$router.push(newRoute);
    }
  }

  protected get endpoint() {
    const { params, name, meta } = this.$route;
    const id = params.id || params.debtorId;
    if (id && this.property) {
      return `entity/${id}/${this.property}`;
    } else {
      return parseEndpoint(meta?.endpoint, params);
    }
  }
}
