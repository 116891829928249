<template>
  <div class="card">
    <div class="card-body">
      <data-table
        :model="model"
        :endpoint="$route.meta.endpoint"
        @action="doAction"
        ref="imported_payment_uploadList"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ListComponent } from '@/mixins/ListComponent';
import { EmitterAction } from '@/models';

@Component
export default class ImportedPaymentHistory extends mixins(ListComponent) {
  protected model: string = 'imported_payment_upload';

  protected async doAction(action: EmitterAction) {
      if (action.emit === 'download') {
           await this.download(`imported_payment/upload/${action.id}/download`)
      }
  }
}
</script>

<style scoped lang="scss">
</style>
