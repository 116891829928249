<template>
  <div :class="['filter-bar p-3', { open }]">
    <slot />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class FilterBar extends Vue {
  @Prop({ required: false })
  private open!: boolean;
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/variables';
@import '~@/assets/scss/colors';

.filter-bar {
  overflow: auto;
  background-color: $white;
  flex: 0 0 $filterbar-large;
  margin-left: -$filterbar-large;
  transition: margin-left ease $speed;
  &.open {
    margin-left: 0;
    transition: margin-left ease $speed;
  }

  .form-control {
    font-size: 1.1rem;
    background-color: $light;
  }
  .multiselect__tags,
  .multiselect__input,
  .multiselect__single,
  .multiselect__option {
    font-size: 1.1rem;
  }
  .multiselect__tags,
  .multiselect__input,
  .multiselect__single,
  .multiselect__content-wrapper {
    background: $light;
    border-color: $medium;
  }
  .filter-bar-open & {
    display: block;
  }
}

@media screen and (min-width: 1210px) {
  .filter-bar {
    border-right: solid 1px $medium;
    max-width: $filterbar-large;
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 1209px) {
  .filter-bar {
    form > div {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      margin-left: -2rem;
    }
    .form-group,
    .checkbox {
      flex: 1 0 0;
      padding-left: 2rem;
      width: 25%;
      max-width: 25%;
    }
    .checkbox {
      padding-top: 3rem;
      padding-left: 3rem;
    }
    .btn-block {
      display: inline-block;
      margin-top: 0;
      margin-right: 2rem;
      padding-left: 1rem;
      padding-right: 1rem;
      width: auto;
    }
  }
}

@media screen and (max-width: 870px) {
  .filter-bar {
    .form-group,
    .checkbox {
      width: 50%;
      max-width: 50%;
    }
  }
}

.btn {
  font-size: 1.2rem;
}
.btn-light {
  color: #212529;
  &:hover {
    color: #212529;
  }
}
</style>
