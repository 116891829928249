import { Instance } from 'flatpickr/dist/types/instance';

function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(min, value), max);
}

function delta(event: WheelEvent) {
  return clamp(-event.deltaY, -1, 1);
}

export class IncrementEvent extends CustomEvent<{bubbles: boolean}> {
  constructor(public delta: number) {
    super('increment', {
      bubbles: true,
    });
  }
}

function scroll(scrollEvent: WheelEvent) {
  scrollEvent.preventDefault();
  const incrementEvent = new IncrementEvent(delta(scrollEvent));
  if(scrollEvent.target != null) {
    scrollEvent.target.dispatchEvent(incrementEvent);
  }
}

export function scrollPlugin(fp: Instance) {
  function scrollMonth(scrollEvent: WheelEvent) {
    scrollEvent.preventDefault();
    const mDelta = delta(scrollEvent);
    fp.changeMonth(mDelta);
  }

  return {
    onReady() {
      if(fp.timeContainer) {
        fp.timeContainer.addEventListener('wheel', scroll);
      }

      if(fp.hasOwnProperty('yearElements')) {
        fp.yearElements.forEach((yearElement: HTMLInputElement) => {
          return yearElement.addEventListener('wheel', scroll);
        });
      }

      if(fp.hasOwnProperty('monthElements')) {
        fp.monthElements.forEach((monthElement: HTMLSpanElement) => {
            return monthElement.addEventListener('wheel', scrollMonth);
        });
      }

      fp.loadedPlugins.push('scroll');
    },

    onDestroy() {
      if(fp.timeContainer) {
        fp.timeContainer.removeEventListener('wheel', scroll);
      }

      if(fp.hasOwnProperty('yearElements')) {
        fp.yearElements.forEach((yearElement: HTMLInputElement) => {
          return yearElement.removeEventListener('wheel', scroll);
        });
      }

      if(fp.hasOwnProperty('yearElements')) {
        fp.monthElements.forEach((monthElement: HTMLSpanElement) => {
          return monthElement.removeEventListener('wheel', scrollMonth);
        });
      }
    },
  }
}
