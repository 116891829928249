<template>
  <div>
    <h3 class="h4 text-highlight">
      {{$t('segmentation.workflow-select')}}
      <a
        @click.prevent="updateWorkflow()"
        v-if="!this.$parent.value.filtergroup && !value"
      >
        <icon class="icon-plus" name="plus"/>
      </a>
    </h3>
    <div class="row mr-0" v-if="value">
      <workflow :workflows="workflows" @change="updateWorkflow($event)" :value="value">
        <a @click.prevent="removeWorkflow()" slot="footer">
          <icon name="trash-alt"/>
          {{$t('workflow.delete')}}
        </a>
      </workflow>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

import { WorkflowSelect, SegmentationWorkflow } from '@/models';
import Workflow from './Workflow.vue';

@Component({
  components: { Workflow },
})
export default class SelectWorkflow extends Vue {
  @Prop({ type: [Object], required: true })
  private workflows!: WorkflowSelect;
  @Prop({ type: [Object], required: false })
  private value!: SegmentationWorkflow | null;

  private updateWorkflow(value: SegmentationWorkflow | null) {
    if (!value) {
      value = {
        type: 'select_workflow',
        parameters: {},
      };
    }
    const workflow = Object.assign({}, value);
    this.$emit('input', workflow);
  }

  private removeWorkflow() {
    const workflow = null;
    this.$emit('input', workflow);
  }
}
</script>

<style scoped lang="scss">
a {
  cursor: pointer;
}
</style>
