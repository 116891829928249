<template>
  <div>
    <loader :loading="loading"/>
    <div class="form-group select">
      <multiselect
        :options="types"
        label="label"
        v-model="selectModel"
        deselectLabel
        hide-selected
        selectLabel
        selectedLabel
        @input="onChange"
      />
    </div>
    <json-form v-if="schema" :schema="schema" v-model="model" :errors="errors"/>
    <div class="text-right text-muted mt-1">
      <slot name="footer"/>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Rule, FilterTypes } from '@/models';
import { FormComponent } from '@/mixins/FormComponent';
import { mixins } from 'vue-class-component';

@Component({})
export default class SegmentationFilter extends mixins(FormComponent) {
  protected model: any = {};
  protected endpoint = '';

  @Prop({ required: true, type: [Object] })
  private filterTypes!: FilterTypes;
  @Prop()
  private value!: any;

  private initialModelValue: any = false;

  private selectModel: SelectModel | null = null;

  protected created() {
    if (!this.value || !this.value.type) {
      return;
    }
    this.selectModel = this.types.find(type => type.key === this.value.type) || null;
    if (this.selectModel) {
      this.initialModelValue = Object.assign({}, this.value.parameters);
      this.onChange(this.selectModel);
    }
  }

  private async onChange(e: SelectModel) {
    if (e && e.key) {
      this.endpoint = this.filterTypes[e.key].url.replace(/^\/api/, '');
      await this.loadData();
      if (this.initialModelValue) {
        this.model = Object.assign({}, this.model, this.initialModelValue);
        this.initialModelValue = false;
      }
    } else {
      this.clearData();
    }
  }

  @Watch('model')
  private onModelChange() {
    const value: Rule | null = this.selectModel
      ? {
          type: this.selectModel.key,
          parameters: this.model,
        }
      : null;
    this.$emit('change', value);
  }

  get types(): SelectModel[] {
    return Object.keys(this.filterTypes).map(key => ({
      key,
      label: this.filterTypes[key].label,
    }));
  }
}

interface SelectModel {
  key: string;
  label: string;
}
</script>

<style scoped lang="scss">
</style>
