<template>
  <div>
    <div class="d-flex mb-3 align-items-center">
      <div class="p-2 mr-1 text-muted cursor-pointer entity-button" @click="loadComponents">
        <icon name="redo" prefix="fas" size="lg" />
      </div>

      <!--TODO: REWORK TO CURRENCY SWITCHER COMPONENT -->
      <div class="d-flex select-none" v-if="availableCurrencies.length > 1">
        <div class="mr-3">
          <div class="text-xl text-muted cursor-pointer currency" v-text="activeCurrencySymbol"
               @click.self="toggleCurrencyChange" />
        </div>
        <transition name="fade">
          <div v-if="showCurrencyDropdown" class="d-flex">
            <div
              v-for="option in availableCurrencySymbols"
              :key="option.currency"
              v-text="option.symbol"
              @click="onCurrencyChange(option.currency)"
              class="text-xl text-muted cursor-pointer mx-1 currency option"
            />
          </div>
        </transition>
      </div>
    </div>
    <div
      v-for="(statisticChunk, index) in chunkedStatistics"
      :key="index"
      class="row mb-3 row-cols-1 row-cols-xl-2"
    >
      <div
        v-for="statistic in statisticChunk"
        :key="statistic.position"
        class="col mb-3 mb-xl-0"
      >
        <statistic-component-wrapper
          :type="statistic.type"
          :currency="currency" />
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { Vue, Component } from 'vue-property-decorator';
import StatisticComponentWrapper from '@/components/report/statistic/StatisticComponentWrapper.vue';
import api from '@/api';
import { chunk } from 'lodash';
import { Statistic } from '@/models/ReportStatistic';
import currencyToSymbolMap from 'currency-symbol-map';
import { reportStatisticComponentMap } from '@/utils/ReportStatisticComponentMap';
import { ReportStatisticState } from '@/models';


@Component({
  components: { StatisticComponentWrapper },
})
export default class ReportStatisticView extends Vue {

  protected statistics: Statistic[] = [];
  protected showCurrencyDropdown: boolean = false;

  private created() {
    if (this.storedComponents.length === 0) {
      return this.loadComponents();
    }

    this.statistics = this.storedComponents.sort((a, b) => a.position - b.position);
  }

  private async loadComponents() {
    await api.get<ReportStatisticState>('report/statistics', { params: { currency: this.currency } })
      .then(({ data }) => {
        data.components = data.components ?? this.getDefaultComponents();
        this.$store.dispatch('reportStatistic/storeData', data)
      })
      .catch(err => console.error(err));

    this.statistics = this.storedComponents.sort((a, b) => a.position - b.position);
  }

  private onCurrencyChange(currency: string) {
    this.$store.commit('reportStatistic/setCurrency', currency);
    this.showCurrencyDropdown = false;
  }

  private toggleCurrencyChange(): void {
    this.showCurrencyDropdown = !this.showCurrencyDropdown;
  }
  private getDefaultComponents(): Statistic[] {
    //@ts-ignore
    return Object.keys(reportStatisticComponentMap).map<Statistic>(type => ({type, enabled:true, position: 0 }));
  }
  private get activeCurrencySymbol(): string {
    return currencyToSymbolMap(this.currency) ?? this.currency;
  }

  private get availableCurrencySymbols(): Array<{ currency: string, symbol: string }> {
    return this.availableCurrencies
      .filter(item => item !== this.currency)
      .map((item) => ({ currency: item, symbol: currencyToSymbolMap(item) ?? item }));
  }

  private get chunkedStatistics(): Statistic[][] {
    return chunk(this.statistics, 2);
  }

  private get currency(): string {
    return this.$store.state.reportStatistic.currency;
  }

  private get availableCurrencies(): string[] {
    return this.$store.state.reportStatistic.available_currencies;
  }

  private get storedComponents(): Statistic[] {
    return this.$store.state.reportStatistic.components;
  }
}
</script>

<style lang="scss" scoped>

.entity-button {
  border-radius: 999px;
  transition: background-color 0.2s ease-in;

  &:hover {
    background-color: rgba(#000000, .04);
  }
}
</style>
