<template>
  <div>
    <loader :loading="loading" />
    <template v-if="!loading">
      <div v-if="type === 'sms'">
        <field label="contact_to" :value="data.contact_to" />
        <field class="field-border" label="date" value-type="datetime" :value="data.date" />
        <field type="column" :value="data.content" />
      </div>
      <div v-if="type === 'call'">
        <field label="contact_to" :value="data.contact_to" />
        <field class="field-border" value-type="datetime" label="date" :value="data.date" />
        <field label="sent_at" value-type="datetime" :value="data.sent_at" />
        <field label="date_delivered" value-type="datetime" :value="data.date_delivered" />
        <field label="send_status_message" :value="data.send_status_message" />
        <field type="column" :value="data.content" />
      </div>
      <div class="card h-100 mb-3 border-0" v-if="type === 'letter'">
        <div class="card-head pl-0 pr-0 mb-2">
          <h2 class="h4" v-if="data.name">{{ data.name }}</h2>
        </div>
        <div class="card-body pl-0 pr-0">
          <field label="created_at" value-type="datetime" :value="data.created_at" />
          <field label="date" value-type="datetime" :value="data.date" />
          <field label="recipient" :value="data.recipient.display_name" />
          <hr />
          <field type="column" :as-html="true" :value="data.content" />
          <hr v-if="data.attachment_link" />
          <field
            type="column"
            label="attachment_link"
            v-if="data.attachment_link"
            :value="data.attachment_link.attachment.original_name"
          />
        </div>
      </div>
      <div v-if="type === 'email'">
        <view-email :data="data" />
        <div class="btn-group">
          <button
            v-for="action in ['reply', 'reply-all', 'forward']"
            :key="action"
            class="btn btn-light px-2 py-1"
            @click="doEmailAction(action)"
            v-text="$t(action)"
          />
        </div>
      </div>
      <div v-if="type === 'note'">
        <view-note :data="data" />
      </div>
      <field label="send_status_message" :value="data.send_status_message" />
      <field label="sent_at" value-type="datetime" :value="data.sent_at" />
      <field label="date_delivered" value-type="datetime" :value="data.date_delivered" />
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import api from '@/api';
import { LoadingState } from '@/models';
import ViewEmail from '@/components/views/Email.vue';
import EmailResponse from '@/views/debtor/communication/EmailResponse.vue';
import Field from '@/components/global/Field.vue';
import ViewNote from '@/components/views/Note.vue';

@Component({
  components: {
    ViewEmail,
    Field,
    ViewNote
  },
})
export default class DebtorCommunicationView extends Vue {
  @Prop({ required: false, default: null })
  protected debtor!: string;

  @Prop({ required: false, default: null })
  protected communicationType!: string;

  @Prop({ required: false, default: null })
  protected communicationId!: string;

  private loading: LoadingState = true;
  private data: any = {};

  private async created() {
    await this.loadData();
  }

  private setTitle() {
    const title = this.data.subject || this.$t(this.type);
    this.$store.commit('bars/setRightBarTitle', title);
  }

  @Watch('endpoint')
  private async loadData() {
    this.loading = true;
    const res = await api.get(this.endpoint);
    this.loading = false;
    this.data = res.data;
    this.setTitle();
  }
  private doEmailAction(action: 'reply'|'reply-all'|'forward') {
    return this.$store.dispatch('bars/openRightBar', {
      component: EmailResponse,
      properties: {
        endpoint: `${this.endpoint}/${action}`,
        email: this.communicationId ?? this.$route.params.typeId,
        action,
      }
    });
  }
  private get endpoint() {
    if (this.debtor != null && this.communicationType != null && this.communicationId != null) {
      return `/debtor/${this.debtor}/${this.communicationType}/${this.communicationId}`;
    }

    return `/debtor/${this.$route.params.debtorId}/${this.$route.params.type}/${this.$route.params.typeId}`;
  }

  private get type() {
    return this.communicationType || this.$route.params.type;
  }
}
</script>

<style scoped lang="scss">
dt {
  font-weight: 400;
}
</style>
