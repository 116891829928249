import { storage } from '@/services/LocalStorage';
import { Module } from 'vuex';
import { RootState, SortBy } from '@/models';
import { ColumnContext, CustomizableColumnContext } from '@/models/ColumnContext';
import { isEmpty } from 'lodash';

interface Datatable {
  [name: string]: any;
}

interface DataTableState {
  sorts: Record<string, SortBy>;
  filters: Record<string, Datatable>;
  columnOptions: Record<string, Datatable>;
}

export const datatable: Module<DataTableState, RootState> = {
  namespaced: true,
  state: {
    sorts: storage.get('datatable.sorts') ?? {},
    filters: storage.get('datatable.filters') ?? {},
    columnOptions: storage.get('datatable.column-options') ?? {},
  },
  mutations: {
    setSortBy(state: DataTableState, payload: SortByPayload) {
      const { value, identifier } = payload;

      state.sorts[identifier] = value;

      storage.save('datatable.sorts', state.sorts);
    },
    clearSortBy(state: DataTableState, identifier: string) {
      delete state.sorts[identifier];

      storage.save('datatable.sorts', state.sorts);
    },
    setColumnOptions(state: DataTableState, payload: ColumnOptionPayload) {
      const { value, identifier } = payload;

      state.columnOptions[identifier] = { ...state.columnOptions[identifier], ...value };

      storage.save('datatable.column-options', state.columnOptions);
    },
    clearColumnOptions(state: DataTableState, identifier: string) {
      delete state.columnOptions[identifier];

      storage.save('datatable.column-options', state.columnOptions);
    },
    setFilter(state: DataTableState, payload: FilterPayload) {
      const { filter, identifier } = payload;

      state.filters[identifier] = { ...state.filters[identifier], ...filter };

      storage.save('datatable.filters', state.filters);
    },
    clearFilter(state: DataTableState, payload: { identifier: string, key: string }) {
      const { identifier, key } = payload;

      delete state.filters[identifier][key];

      if (isEmpty(state.filters[identifier])) {
        delete state.filters[identifier];
      }
      storage.save('datatable.filters', state.filters);
    },
    clearFilters(state: DataTableState, identifier: string) {
      delete state.filters[identifier];

      storage.save('datatable.filters', state.filters);
    },
  },
};

interface ColumnOptionPayload {
  value: CustomizableColumnContext,
  identifier: string
}

interface FilterPayload {
  filter: Record<string, string>,
  identifier: string
}

interface SortByPayload {
  value: SortBy,
  identifier: string,
}
