<template>
  <div>
    <div class="invalid-feedback" v-if="hasError">
      <p v-for="error in hasError" :key="error">{{ error }}</p>
    </div>
    <component
      :is="`json-form-${schema.type}`"
      :schema="schema"
      :value="value"
      :errors="errors && errors.errors"
      :labels="labels"
      @input="updateValue"
      :disable-rich-text="disableRichText"
      :disable-form="disableForm"
      :extra="extra"
    />
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { JSONSchema4 } from 'json-schema';

@Component({})
export default class JsonForm extends Vue {
  @Prop()
  private schema!: JSONSchema4;
  @Prop({ required: true })
  private value!: any;
  @Prop()
  private errors!: any;
  @Prop({ default: true })
  private labels!: boolean;
  @Prop({ default: false })
  private disableRichText!: boolean;
  @Prop({ default: false })
  private disableForm!: boolean;
  @Prop({ required: false, default: () => new Object() })
  private extra!: any;

  public updateValue(value: any) {
    const val = { ...value };
    this.$emit('input', val);
  }

  get hasError() {
    return this.errors?.errors?.errors;
  }
}
</script>
