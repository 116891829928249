<template>
  <data-table
    :model="model"
    @action="doAction"
    :endpoint="endpoint"
    :route-params="{ id: $route.params.id, subId: { key: 'uuid' } }"
    route-context="settings.businessunit.acl"
    :ref="`${model}List`"
  />
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { SettingsListComponent } from '@/mixins/SettingsListComponent';

@Component({})
export default class BusinessUnitAclList extends mixins(SettingsListComponent) {
  private created() {
    this.model = this.routeModel;
  }
}
</script>

<style scoped lang="scss">
</style>
