import { Module } from 'vuex';
import { BatchState, BatchTask, RootState } from '@/models';
import { Vue } from 'vue-property-decorator';

export const batch: Module<BatchState, RootState> = {
  namespaced: true,
  state: {
    openTasks: [
      {
        type: 'letter',
        count: 0,
        summary: [],
      },
      {
        type: 'email',
        count: 0,
        summary: [],
      },
      {
        type: 'sms',
        count: 0,
        summary: [],
      },
    ],
  },
  getters: {
    open: state => state.openTasks,
  },
  mutations: {
    setOpenTasks(state, payload: BatchTask[]) {
      payload.forEach((taskInfo) => {
        const taskIndex = state.openTasks.findIndex((taskEntry) => {
          return taskEntry.type === taskInfo.type;
        });

        if(taskIndex !== -1) {
          Vue.set(state.openTasks, taskIndex, taskInfo);
        } else {
          state.openTasks.push(taskInfo);
        }
      });
    }
  }
};
