<template>
  <div>
    <div class="card h-100">
      <div class="card-head select-none py-0 px-0 justify-between">
        <div class="d-flex">
          <a
            v-for="(value, index) in ['email', 'letter', 'sms', 'call', 'note']"
            :key="value"
            class="nav-link h5"
            :class="{ active: selectedType === value }"
            @click="selectType(value)"
          >
            <div class='row'>
              <span class=" h5 d-none d-lg-block">{{ $t(`debtor.communication.${value}`) }}</span>
              <span class='badge badge-communication ml-1'>{{ counts[value] }}</span>
              <icon class="d-lg-none" :name="getIconFromType(value)" />
            </div>
          </a>
        </div>
      </div>
      <div class="card-body">
        <data-table
          :model="selectedType"
          :endpoint="this.endpoint"
          ref="taskList"
          disable-column-options
          @action="doAction"
          @bulkAction="doBulkAction"
          disable-pagination
          disable-vertical-scroll
          disable-search-fields
          :disable-bulk-actions="isDebtorView"
          :initial-limit="5"
          route-context="debtor.group.view.communication.type"
          :route-params="{
            groupId: $route.params.groupId,
            type: selectedType,
            typeId: { key: 'uuid' },
          }"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { TaskListComponent } from '@/mixins/TaskListComponent';
import { Dictionary, LoadingState } from '@/models';
import api from '@/api';

@Component
export default class CorrespondenceList extends mixins(TaskListComponent) {
  private selectedType: string = 'email';
  private counts: any[] = [];
  public loading: LoadingState = false;
  protected model: string = 'task';
  private async selectType(type: string) {
    await this.loadData();
    this.selectedType = type;
  }

  get endpoint() {
    return `/debtor/group/${this.$route.params.groupId}/${this.selectedType}`;
  }

  protected get isDebtorView() {
    return this.$route.name && this.$route.name.indexOf('debtor.group.view') !== -1;
  }
  private getIconFromType(type: string): string {
    const options: Dictionary<string> = {email: 'at', letter: 'envelope-open', sms: 'comment-alt', call:'phone', note: 'file'};

    return options[type] ?? 'letter';
  }

  protected created() {
    this.loadData();
  }

  protected async loadData() {
    let endpoint = `/debtor/group/${this.$route.params.groupId}/communication/counts`;
    this.loading = true;

    await api.get(endpoint)
      .then(res => {
        this.counts = res.data;
        this.loading = false;
        this.afterLoad(res);
      }).catch(() => {
        this.loading = 'error';
      });
  }
}

</script>

<style scoped lang="scss">

.badge-communication {
  background-color: var(--secondary-color);
  color: white;
  border-radius: 10px;
  height: 1.5em;
  align-items: center;
}

</style>
