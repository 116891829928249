<template>
  <div>
    <loader :loading="loading"></loader>
    <form @submit.prevent="submit" v-if="schema" class="form-column">
      <json-form :schema="schema" v-model="model" :errors="errors"/>
      <button type="submit" class="btn btn-primary btn-block">
        <icon name="filter"/>
        <span v-text="$t('filterbar.filter')"/>
      </button>
      <button type="reset" class="btn btn-dark btn-block mt-3" @click="handleReset">
        <icon name="times"/>
        <span v-text="$t('filterbar.reset')"/>
      </button>
    </form>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { DashboardFilterModel } from '@/models';
import { FormComponent } from '@/mixins/FormComponent';
import { mixins } from 'vue-class-component';

@Component({})
export default class DashboardFilterBar extends mixins(FormComponent) {
  protected model: DashboardFilterModel = {};
  protected endpoint = '/dashboard/filter';

  protected created() {
    this.loadData();
  }
  protected handleReset() {
    this.model = {
          creditors: [],
          businessUnits: [],
          users: [],
          onlyManagementUsers: false,
    }
    this.submit();
  }
  protected async afterSubmit(success: boolean, response: any) {
    if (success) {
      this.$store.dispatch('bars/closeBar', 'filterBar');
      await this.$store.dispatch('task/loadData');
      await this.$store.dispatch('dashboard/loadData');
      await this.$store.dispatch('dashboard/loadFinancialInformation');
      await this.$store.dispatch('dashboard/loadMetrics');
    }
  }
}
</script>

<style scoped lang="scss">
.btn {
  font-size: 1.2rem;
}
.btn-light {
  color: #212529;
  &:hover {
    color: #212529;
  }

}
</style>
