<template>
  <td colspan='12' class='p-0'>
    <table class='nested-table'>
      <thead>
        <tr>
          <th>
            {{ $t('imported_payment.suggestion.receivable.reference.name.label')}}
          </th>
          <th>
            {{ $t('imported_payment.suggestion.receivable.date.name.label')}}
          </th>
          <th>
            {{ $t('imported_payment.suggestion.receivable.amount.name.label')}}
          </th>
          <th>
            {{ $t('imported_payment.suggestion.receivable.dueDate.name.label')}}
          </th>
          <th>
            {{ $t('imported_payment.suggestion.receivable.status.name.label')}}
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody class='string-detail-body' v-for="record in stringArray">
        <tr
          @click.prevent='doAction(record.uuid)'
          class="string-detail-tr pointer"
        >
          <td>
            {{ record.receivableReference }}
          </td>
          <td>
            {{ record.receivableDate }}
          </td>
          <td>
            {{ record.receivableAmount | currency }}
          </td>
          <td>
            {{ record.receivableDueDate }}
          </td>
          <td>
            {{ record.receivableStatus }}
          </td>
          <td rowspan='2'>
            <button @click.prevent='doAction(record.uuid)' class="btn btn-light btn-lg px-1 py-0">
              <icon prefix='fal' name="assign" class="lg" />
            </button>
          </td>
        </tr>
        <tr
          @click.prevent='doAction(record.uuid)'
          class="string-detail-line-wrapper pointer"
        >
          <td colspan='11' class='border-top-0 p-0 m-0 w-100'>
            <table class='border-top-0 w-100'>
              <tr class='string-detail-line'>
                {{ record.stringDetailLine }}
              </tr>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </td>

</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { FormComponent } from '@/mixins/FormComponent';
import ImportedPaymentAssign from '@/views/importedpayment/Assign/Form.vue';

@Component({})
export default class ImportedPaymentSuggestions extends mixins(FormComponent) {
  @Prop({ required: true })
  private stringArray!: ReceivableSuggestion[];
  @Prop({ required: true })
  private importedPaymentUuid!: string;
  private onHold!: boolean;

  private async doAction(receivableUuid: string) {
    this.onHold = this.type === 'on-hold';
    await this.$store.dispatch('bars/openRightBar', {
      component: ImportedPaymentAssign,
      title: this.$t(`imported_payment.assign`),
      properties: {
        importedPayment: {
          uuid: this.importedPaymentUuid,
        },
        onHold: this.onHold,
        receivable: {
          uuid: receivableUuid,
        }
      }
    });
  }

  protected get type() {
    return this.$route.params.type?.toLowerCase();
  }
}

interface ReceivableSuggestion {
  uuid: string;
  receivableReference: string;
  receivableDueDate: string;
  receivableDate: string;
  receivableStatus: string;
  receivableAmount: string;
  stringDetailLine: string;
}

</script>

<style scoped lang="scss">

@import '~@/assets/scss/colors';

tbody {
  tr {
    border-bottom: 1px solid $light;

    &.pointer {
      cursor: pointer;
    }

    &:nth-child(even) {
      background: transparent;
    }
    td {
      border: 0;
    }
  }
}

.table tbody + tbody {
  border-top: 1px solid $light;
}

.nested-table {
  padding: 0;
  width: 100%;
  margin: 0;
  border-bottom: 1px solid $light;
}

.string-detail-tr, .string-detail-line-wrapper{
  border-top: 0;
  border-bottom: 0;
}

.string-detail-body:hover {
  border-bottom: 1px solid var(--primary-color);
  box-shadow: none;
  transition: all 0.2s;
}

.string-detail-line, .string-detail-line:hover {
  border-top: 0;
  border-bottom: 0;
  line-height: 2;
  color: #495057;
  display: block;
  padding-left: 5px;
}
</style>
