<template>
  <div v-if="attachments">
    <div class="mb-1">
      <span class="font-weight-bold mr-1">
        <slot></slot>
      </span>
      <span class="download-link" @click="downloadAttachments">
          <icon name="download"/>
        </span>
    </div>
    <div
      v-for="attachment in attachments"
      :key="attachment.original_name"
      class="d-flex flex-column"
      @click="downloadAttachment(attachment)"
    >
      <div class="download-link">
        <icon name="download"/>
        <span class="ml-2" v-text="attachment.original_name"></span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import { API } from '@/mixins/API';
import { Component, Prop } from 'vue-property-decorator';
import { AttachmentDownload } from '@/models/AttachmentDownload';

@Component({})
export default class AttachmentDownloadList extends API {
  @Prop({default: []})
  private attachments?: AttachmentDownload[];

  private downloadAttachments() {
    if(! this.attachments) {
      return;
    }

    this.attachments.forEach((attachment: AttachmentDownload) => this.downloadAttachment(attachment));
  }

  private downloadAttachment(attachment: AttachmentDownload) {
    this.download(attachment.endpoint);
  }
}

</script>

<style scoped lang="scss">

@import '~@/assets/scss/colors';
.download-link {
  color: $dove-gray;
  &:hover {
    color: $black;
    cursor: pointer;
  }
}

</style>
