<template>
  <div class="card h-100">
    <div class="card-head justify-between py-0 pr-0">
      <h2 class="h5">{{ $t(`report-statistic.not-found.title`) }}</h2>
    </div>
    <div class="card-body text-center text-danger">
      <div>
      Context <b>{{ type }}</b> is not implemented.
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class NotFoundReportStatistic extends Vue {
  @Prop({ required: true })
  private type!: string;
}
</script>

<style lang="scss" scoped>

</style>
