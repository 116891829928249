<template>
  <div id="debtorportal" class="h-100 w-100" :style="this.$store.state.platform.styling.debtorportal">
    <!--<div class="backdrop" :style="$store.getters['platform/debtorportal'].backdrop" />-->

    <main class="container">

      <div class="d-flex flex-column flex-lg-row justify-content-between pt-4 pt-lg-5">
        <div class="col-12 col-lg-6 mt-4 mt-lg-0 d-flex justify-content-center d-lg-block">
          <img :src="this.$store.getters['platform/logo']" class="logo mb-3 mb-lg-n1" />
        </div>
        <div class="col-12 col-lg-3 align-self-start align-self-lg-end mb-n3">
          <locale-picker :authenticated="false" />
        </div>
      </div>

      <router-view />

    </main>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class DebtorPortal extends Vue {}
</script>

<style lang="scss" scoped>
@media (min-width: 2100px) {
  .container {
    max-width: 1920px;
  }
}
.logo {
  max-width: 100%;
  max-height: 100px;
}
.row {
  margin: 2rem 0;
}
.backdrop {
  position: absolute;
  height: 250px;
  z-index: -999;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--primary-color-lighter);
}
</style>
