<template>
  <div class="column-container">
    <div class="column column-small">
      <conditions
        :condition-types="conditionTypes"
        :value="value.conditions"
        @input="onConditionChange"
        @onEmptyRuleSet="clearConditionsSchema"
      />
    </div>
    <div class="column column-wide" v-if="shouldShowResults">
      <div class="conditions-and-actions-wrapper if-wrapper my-3">
        <div class="title">If true</div>
        <switcher
          :options="['Actions', 'Condition']"
          v-model="trueType"
          @input="this.onTrueTypeChange"
        />
        <actions
          v-if="trueType === 'Actions'"
          :action-types="actionTypes"
          :value="(value.true && value.true.actions) || []"
          @input="onActionsChange($event, 'true')"
        />
        <conditions-and-actions
          :condition-types="conditionTypes"
          :action-types="actionTypes"
          v-if="trueType === 'Condition' && conditionalActionsValue('true')"
          :value="conditionalActionsValue('true')"
          @input="onConditionalActionsChange($event, 'true')"
        />
      </div>
      <div class="conditions-and-actions-wrapper if-wrapper my-3">
        <div class="title">If false</div>
        <switcher
          :options="['Actions', 'Condition']"
          v-model="falseType"
          @input="this.onFalseTypeChange"
        />
        <actions
          v-if="falseType === 'Actions'"
          :action-types="actionTypes"
          :value="(value.false && value.false.actions) || []"
          @input="onActionsChange($event, 'false')"
        />
        <conditions-and-actions
          :condition-types="conditionTypes"
          :action-types="actionTypes"
          v-if="falseType === 'Condition' && conditionalActionsValue('false')"
          :value="conditionalActionsValue('false')"
          @input="onConditionalActionsChange($event, 'false')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Conditions from './Conditions.vue';
import {
  ConditionTypes,
  ActionTypes,
  ConditionalAction,
  Conditions as ConditionsType,
  Action,
} from '@/models';
import { clone } from '@/utils/clone';
import Actions from './Actions.vue';
import Switcher from './Switcher.vue';
import Workflow from './Workflow.vue';

type Bool = 'true' | 'false';

@Component({
  name: 'conditions-and-actions',
  components: { Switcher, Conditions, Actions },
})
export default class ConditionsAndActions extends Vue {
  @Prop({ type: [Object], required: true })
  private conditionTypes!: ConditionTypes;
  @Prop({ type: [Object], required: true })
  private actionTypes!: ActionTypes;
  @Prop({ type: [Object], required: true })
  private value!: ConditionalAction;

  private trueType: 'Condition' | 'Actions' = 'Actions';
  private falseType: 'Condition' | 'Actions' = 'Actions';

  private created() {
    this.trueType = this.initialType('true');
    this.falseType = this.initialType('false');
  }

  private initialType(booleanType: Bool) {
    return this.value[booleanType] && this.value[booleanType].conditionalActions.length
      ? 'Condition'
      : 'Actions';
  }
  private clearConditionsSchema() {
    if (this.isDirectChildOfWorkflow) {
      return this.$emit('onDestroy');
    }
    const group = Object.assign({
      conditions: {
        logical_operator: 'OR',
        rules: [],
      },
      true: {
        actions: [],
        conditionalActions: [],
      },
      false: {
        actions: [],
        conditionalActions: [],
      },
    });
    this.$emit('input', group);
  }
  private onConditionChange(value: ConditionsType) {
    const conditionalAction = clone(this.value);
    conditionalAction.conditions = value;
    this.$emit('input', conditionalAction);
  }

  private onConditionalActionsChange(value: ConditionalAction, type: Bool) {
    const conditionalAction = clone(this.value);
    conditionalAction[type].conditionalActions[0] = value;
    this.$emit('input', conditionalAction);
  }

  private onActionsChange(value: Action[], type: Bool) {
    const conditionalAction = clone(this.value);
    conditionalAction[type].actions = value;
    this.$emit('input', conditionalAction);
  }

  private conditionalActionsValue(type: Bool) {
    if (!this.value[type].conditionalActions[0]) {
      const conditionalAction = clone(this.value);
      conditionalAction[type].conditionalActions.push({
        conditions: {
          logical_operator: 'OR',
          rules: [],
        },
        true: {
          actions: [],
          conditionalActions: [],
        },
        false: {
          actions: [],
          conditionalActions: [],
        },
      });
      this.$emit('input', conditionalAction);
    }
    return this.value[type].conditionalActions[0];
  }

  private onTrueTypeChange() {
    this.onTypeChange('true');
  }
  private onFalseTypeChange() {
    this.onTypeChange('false');
  }

  private onTypeChange(type: Bool) {
    const conditionalAction = clone(this.value);
    conditionalAction[type] = {
      conditionalActions: [],
      actions: [],
    };
    this.$emit('input', conditionalAction);
  }

  get shouldShowResults() {
    return !!this.value.conditions.rules.filter(rule => rule.type).length;
  }
  public get isDirectChildOfWorkflow(): boolean {
    return this.$parent instanceof Workflow;
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/colors';

.if-wrapper {
  border-style: dotted;
}
</style>
