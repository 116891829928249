<template>
  <card v-bind="{ title: data.display_name }">
    <template slot="body">
      <placeholder v-if="loading" :widths="['160px', '200px', '120px', '240px']" />
      <div v-else>
        <div v-if="address">
          <div class="text-capitalize">{{ address.street }} {{ address.house_number }}</div>
          <div class="text-capitalize mb-2">{{ address.city ? `${address.city}, ${address.country}` : address.country }}</div>
        </div>
        <div v-if="contact">
          <div>{{ contact.telephone || contact.mobile || fallback }}</div>
          <div>{{ contact.email || fallback }}</div>
        </div>
      </div>
    </template>
  </card>
</template>

<script lang='ts'>
import { Vue, Component, Prop } from 'vue-property-decorator';
import Card from '@/debtorportal/components/Card.vue';
import { CardComponent } from '@/debtorportal/models/CardComponent';
import { LoadingState, Address, Contact } from '@/models';
@Component({
  components: {
    Card,
  },
})
export default class CreditorInfo extends CardComponent {
  private fallback:string = '-';
  private get address(): Address {
    return this.data.defaultAddress ?? {};
  }
  private get contact(): Contact {
    return this.data.defaultContact ?? {};
  }
}
</script>

<style>
</style>