import { isArray, isDate, isObject } from '@/utils/typeGuards';
import deepCleaner from 'deep-cleaner';
import instance from '@/api';

function combineQueryParts(parts: string[]): string {
  return parts.filter(part => part.length > 0).join('&');
}
export function removeUuidsFromEndpoint(endpoint: string) {
  const uuidRegex: RegExp = /[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/g;

  return endpoint.replace(uuidRegex, 'uuid');
}
function serializeQueryPart(prefix: string)
{
  return (entry: [string, any]) => {
    const [name, value] = entry;
    const key = prefix ? prefix + '[' + name + ']' : name;
    if(name.length === 0) {
      return '';
    }

    if (isDate(value)) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(value.toISOString());
    }

    if(isObject(value) || isArray(value)) {
      return serialize(value, key);
    }

    return encodeURIComponent(key) + '=' + encodeURIComponent(value);
  }
}

export function serialize(obj: any, prefix: string = ''): string {
  const parts = Object.entries(deepCleaner(obj))
    .map(serializeQueryPart(prefix));

  return combineQueryParts(parts);
}

export function appendQueryParams(endpoint: string, params: any) {
  if (params == null) {
    return endpoint;
  }

  const glue = (endpoint.indexOf('?') === -1) ? '?' : '&';
  const queryString = serialize(params);

  if(queryString === '') {
    return endpoint;
  }

  return endpoint + glue + queryString;
}

/**
 * Ref: https://stackoverflow.com/a/38791705
 */
export function deserialize(str: string) {
  const ret: any = {};

  function build(urlnam: string, urlval: any, obj: any) {
    let i: any;
    let k: any;
    let o: any = obj;
    let x: any;
    const rx: any = /\[([^\]]*)]/g;
    const idx: any[] = [urlnam.replace(rx, '')];
    while (true) {
      x = rx.exec(urlnam);
      if (!x) {
        break;
      }
      idx.push(x[1]);
    }
    while (true) {
      k = idx.shift();
      if (k && k.trim() === '') {
        if (o.constructor.name === 'Array') {
          k = o.length;
        } else if (o === obj) {
          k = null;
        } else {
          k = -1;
          for (i in o) {
            if (+i > k) {
              k = +i;
            }
          }
          k++;
        }
      }
      if (idx.length) {
        if (o[k] === null || typeof o[k] !== 'object') {
          o[k] = isNaN(idx[0]) ? {} : [];
        }
        o = o[k];
      } else {
        o[k] = urlval === '' ? null : urlval;
        break;
      }
    }
    return obj;
  }

  const ncnvt = true;
  let i: any;
  let k: any;
  let p: any;
  let v: any;
  const argarr: any[] = [];
  const ar: string[] = str.split('&');
  let l: any = ar.length;
  for (i = 0; i < l; i++) {
    if (ar[i] === '') {
      continue;
    }
    p = ar[i].split('=');
    k = decodeURIComponent(p[0]);
    v = p[1];
    v = (v != null) ? decodeURIComponent(v.replace(/\+/g, '%20')) : '';
    if (ncnvt && v.trim() > '' && !isNaN(v)) {
      v -= 0;
    }
    argarr.push([k, v]);  // array: key-value-pairs of all arguments
  }
  for (i = 0, l = argarr.length; i < l; i++) {
    build(argarr[i][0], argarr[i][1], ret);
  }
  return ret;
}
