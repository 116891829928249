<template>
  <div>
    <loader :loading="loading" />
    <form @submit.prevent="submit" class="form settings-form mb-3 h-100" v-if="schema">
      <div class="card">
        <div class="card-head justify-content-between py-0 pr-0">
          <h2 class="h5">{{ $t(`${schema.title}.header`) }}</h2>
          <button type="submit" class="nav-link nav-link-right">
            <Icon prefix="fas" name="save" class="mr-2" />
            {{ $t('save') }}
          </button>
        </div>
        <div class="card-body">
          <div class="mb-3 alert alert-danger" v-if="message">{{ $t(message) }}</div>
          <json-form :schema="schema" v-model="model" :errors="errors" />
          <div class="offset-sm-2 pl-1">
            <button
              type="submit"
              class="w-100 btn btn-primary btn-sm">
              <Icon prefix="fas" name="save" class="mr-2" />
              {{ $t('settings.save') }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang='ts'>
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { SettingsFormComponent } from '@/mixins/SettingsFormComponent';
import { AxiosResponse } from 'axios';
import { JSONSchema4 } from 'json-schema';

@Component({})
export default class SettingsUserEditForm extends mixins(SettingsFormComponent) {
  private message: string | null = null;
  private qrcode: string|null = null;

  protected afterLoad(response: AxiosResponse) {
    this.message = response.data?.message;
  }
  protected afterSubmit(success: boolean, response: AxiosResponse<UserEditResponse> ) {
    const { data } = response;
    if (!success) {
     return this.$toasted.error(this.$t('settings.user.error') as string);
    }
    this.$toasted.success(this.$t(this.$route.name + '.success') as string);
    if (data.qrcode) {
      return this.$store.dispatch('modal/open', {
        body: `
          <div class="alert alert-danger text-center font-weight-bold mb-3">
            ${this.$t('settings.user.mfa.google.warning')}
          </div>
          <div class="row justify-content-center">
            <img src="${data.qrcode}" />
          </div>
        `,
        actions: [
          {
            label: this.$t('settings.user.mfa.google.confirm') as string,
            class: 'btn-primary',
            action: async () => this.$store.dispatch('modal/close')
                      .then(() => this.$router.push({ name: 'settings.user' })),
          },
        ],
      });
    }
    return this.$router.push({ name: 'settings.user' });
  }
}

interface UserEditResponse {
  message?: string;
  qrcode?: string|null;
  qrform?: JSONSchema4
}
</script>

<style lang="scss" scoped>

</style>
