<template>
  <div>
    <loader :loading="loading"/>
    <form @submit.prevent="submit" class="form settings-form mb-3" v-if="schema">
      <div class="card h-100">
        <div class="card-head justify-content-between py-0 pr-0">
          <h2 class="h5">{{ $t(`${schema.title}.header`) }}</h2>
          <div class='d-flex'>
            <router-link v-if="$store.state.auth.acl.functionality_setting.manual_bulk_update" class='nav-link nav-link-right' :to="{name: 'settings.businessunit.sync.mapping'}">{{ $t('settings.manual_bulk_update') }}</router-link>
            <button type="submit" class="nav-link nav-link-right">
              <Icon prefix="fas" name="save" class="mr-2"/>
              {{ $t('settings.start_sync') }}
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="mb-3 alert alert-danger" v-if="message" v-text="message"/>
          <json-form :schema="schema" v-model="model" :errors="errors"/>
          <div class="offset-sm-2 pl-1">
            <button type="submit" class="btn btn-primary btn-sm w-100">
              <Icon prefix="fas" name="save" class="mr-2"/>
              {{ $t('settings.start_sync') }}
            </button>
          </div>
        </div>
      </div>
    </form>
    <div class="card">
      <div class="card-head justify-content-between py-0 pr-0">
          <h2 class="h5">{{ $t('settings.businessunit.sync.history') }}</h2>
        </div>
        <div class="card-body table-responsive">
          <SettingsModelSyncHistory ref="syncHistory"/>
        </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator';
import SettingsModelSyncHistory from './History.vue';
import { SettingsFormComponent } from '@/mixins/SettingsFormComponent';
import { mixins } from 'vue-class-component';
import { routes } from '@/router';
import { AxiosResponse } from 'axios';
import DataTable from '@/plugins/data-table/DataTable.vue';
import api from '@/api';

@Component({
  components: { SettingsModelSyncHistory },
})
export default class Sync extends mixins(SettingsFormComponent) {
  protected message?: string;

  protected beforeDestroy() {
    this.$store.commit('auth/restoreUser');
  }
  protected afterLoad(response: AxiosResponse) {
    if (response?.data?.owner) {
      this.$store.commit('auth/setEntity', response.data.owner)
    }

    const dataTable = (this.$refs.syncHistory as Vue).$refs.historyList;
    if (dataTable) {
      (dataTable as DataTable).reload();
    }

    this.message = response?.data?.message;
  }
}
</script>
