<template>
  <div>
      <slot>
        <div class="placeholder" v-for="(width, index) in widths" :key="index" :style="{ width }"/>
      </slot>
    </div>
</template>

<script lang='ts'>
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class Placeholder extends Vue {
  @Prop({default: () => ['160px', '200px', '120px', '240px']}) private widths!: string[];
}
</script>

<style lang="scss" scoped>
// Animation
@keyframes placeHolderShimmer {
  0% {
    background-position: -500px 0;
  }
  100% {
    background-position: 500px 0;
  }
}

.placeholder {
  height: 12px;
  margin: 1rem 0;
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f6f6;
  background: linear-gradient(to right, #f6f6f6 8%, #eee 18%, #f6f6f6 33%);
  background-size: 1000px 104px;
  position: relative;
}
</style>