<template>
  <div>
    <loader :loading="loading" />
    <div class="card h-100">
      <div class="card-head justify-content-between">
        <div class="d-flex justify-start">
          <router-link
            v-for="{ tab } in tabs"
            :to="{ name: 'settings.functionalitymanager', params: { tab } }"
            class="nav-link"
            :key="tab"
            :class="{ active: activeTab.tab === tab }"
            >{{ $t(`functionality.${tab}`) }}</router-link
          >
        </div>
      </div>
      <functionality-manager-form v-if="endpoint" :endpoint="endpoint" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import api from '@/api';
import { FunctionalityManagerForm } from '@/views/settings';

@Component({
  components: {
    FunctionalityManagerForm,
  },
})
export default class FunctionalityManagerList extends Vue {
  private tabs: any = [];
  private activeTab: any = {};
  private loading: boolean = false;

  protected async created() {
    this.loading = true;

    await api.get('functionalitymanager').then(({ data }) => (this.tabs = data));

    this.updateActiveTab();
    this.loading = false;
  }
  @Watch('$route.params.tab')
  private updateActiveTab() {
    this.activeTab =
      this.tabs.find(({ tab }: any) => tab === this.$route.params.tab) || this.tabs[0];
  }

  get endpoint() {
    return this.activeTab.endpoint;
  }
}
</script>


<style lang="scss" scoped>
.card-head {
  padding: 0 !important;
}
</style>
