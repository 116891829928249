<template>
  <div>
    <div class="m-4">
      <img
        :src="this.$store.getters['platform/logo']"
        alt="platform-logo"
        height="100"
        class="align-self-center brand-default"
      ></div>
    <div class="swagger" id="swagger"></div>
  </div>
</template>

<script lang='ts'>
import { Vue, Component } from 'vue-property-decorator';
import SwaggerUI from 'swagger-ui';
import 'swagger-ui/dist/swagger-ui.css';

@Component({})
export default class SwaggerUIComponent extends Vue {
  private mounted() {
    const url = process.env.VUE_APP_API_HOST + '/swagger.json';
    SwaggerUI({
      url,
      dom_id: '#swagger',
      withCredentials: true,
    }).preauthorizeApiKey('apiKey', this.$store.state.auth.token);

  }
}
</script>

<style lang="scss" scoped>

</style>
