var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card"},[_c('loader',{attrs:{"loading":_vm.loading}}),_c('div',{staticClass:"card-head justify-between py-0 pr-0"},[_c('h2',{staticClass:"h5"},[_vm._v(_vm._s(_vm.workflow ? _vm.workflow.name : _vm.$t('debtors.overview.process.title')))]),(_vm.canManageWorkflow)?_c('a',{staticClass:"nav-link nav-link-right",attrs:{"title":_vm.$t(`debtors.overview.process.${this.workflowInProgress ? 'interrupt' : 'resume'}`)},on:{"click":function($event){$event.preventDefault();return _vm.interruptResumeStep.apply(null, arguments)}}},[_c('icon',{attrs:{"name":_vm.progressIcon}})],1):_vm._e()]),_c('div',{staticClass:"card-body px-0",class:{'justify-content-center align-items-center': !_vm.workflowStepLinks.length }},[(_vm.workflow && _vm.workflowStepLinks.length)?_c('div',{staticClass:"process d-flex"},_vm._l((_vm.workflowStepLinks),function(step){return _c('div',{key:step.uuid,class:[
          'd-flex align-items-center process-block',
          {
            'process-done': step.processed_at,
            'process-active': step.is_current_workflow_step,
          },
        ]},[(step.due_date)?_c('div',{staticClass:"process-time p-2 pr-2 mr-3"},[_c('div',{staticClass:"day"},[_vm._v(_vm._s(_vm._f("datetime")(step.due_date,'dd')))]),_c('div',{staticClass:"month-year"},[_vm._v(_vm._s(_vm._f("datetime")(step.due_date,'MM ’yy')))])]):_c('div',{staticClass:"process-time p-2 pr-2 mr-3"},[_c('div',{staticClass:"day"},[_vm._v("!")]),_c('div',{staticClass:"month-year"},[_vm._v(_vm._s(_vm.$t('form.setting.workflow.step.interval_days.label')))])]),_c('div',{staticClass:"process-title"},[_c('b',[_vm._v(_vm._s(step.workflow_step_name))]),(!_vm.workflowInProgress)?_c('div',[_c('Icon',{attrs:{"name":"pause"}}),_c('i',[_vm._v(_vm._s(_vm.$t('workflow_hold')))])],1):_vm._e(),(step.open_tasks)?_c('div',[_c('i',[_vm._v(_vm._s(_vm.$t('open_tasks'))+": "+_vm._s(step.open_tasks))])]):_vm._e()]),(step.is_current_workflow_step && step.open_tasks)?_c('div',{staticClass:"process-actions"},[(_vm.canProcessWorkflow)?_c('a',{class:['disabled', !_vm.workflowInProgress],attrs:{"title":_vm.$t('process')},on:{"click":function($event){$event.preventDefault();return _vm.processStep(step)}}},[_c('icon',{attrs:{"name":"play-circle","size":"lg"}})],1):_vm._e()]):_vm._e()])}),0):_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.workflow ? 'no-workflow-steps' : 'no-workflow'))+" ")])]),_c('div',{staticClass:"card-footer p-2 px-3"},[_c('select-workflow',{attrs:{"allowed":_vm.canManageWorkflow},on:{"reload":_vm.loadData}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }