<template>
  <div>
    <div
      v-for="(action, index) in value"
      :key="action.uniq"
    >
      <v-action
        :action-types="actionTypes"
        @change="actionChanged(index, $event)"
        :value="action"
      >
        <a @click.prevent="removeAction(index)" slot="footer">
          <icon name="trash-alt" />
          {{$t('workflow.delete')}}
        </a>
      </v-action>
    </div>
    <button
      :class="[
        'btn',
        'btn-dark',
        'mt-1',
        'btn-block'
      ]"
      @click.prevent="add"
    >
      + ACTION
    </button>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import randomize from 'randomatic';

import { Action, ActionTypes, Rule, ConditionalAction } from '@/models';
import clone from '@/utils/clone';
import VAction from './Action.vue';

@Component({
  components: { VAction },
})
export default class Actions extends Vue {
  @Prop({ type: [Object], required: true })
  private actionTypes!: ActionTypes;
  @Prop({ type: [Array], required: true })
  private value!: Action[];

  private add() {
    const actions = clone(this.value);
    actions.push({
      type: '',
      parameters: {},
      uniq: randomize('Aa0', 10),
    });
    this.$emit('input', actions);
  }

  private actionChanged(index: number, value: Action | null) {
    const actions = clone(this.value);
    if (!value) {
      value = {
        type: '',
        parameters: {},
      };
    }
    actions[index] = Object.assign({}, actions[index], value);
    this.$emit('input', actions);
  }

  private removeAction(index: number) {
    this.value.splice(index, 1);
  }
}
</script>

<style scoped lang="scss">
</style>
