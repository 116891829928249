<template>
  <div ref="jsonEditorContainer" />
</template>

<script lang="ts">
import JSONEditor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.min.css';
import { extend } from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class JsonEditor extends Vue {
  @Prop({ default: '' })
  private value!: string;

  @Prop({ default: () => [] })
  private options!: object;

  private JSONeditor: any = null;
  private name: string = 'json-editor';

  private created() {
    if (!this.value || !(typeof this.value === 'string')) {
      this.$emit('onChange', JSON.stringify(this.$store.state.platform.styling));
    }
    this.$nextTick(function() {
      const container = this.$refs.jsonEditorContainer as HTMLElement;
      const options = extend(
        {
          onChange: this._onChange,
        },
        this.options
      );
      this.JSONeditor = new JSONEditor(container, options);
      this.JSONeditor.set(JSON.parse(this.value));
    });
  }

  private _onChange() {
    this.$emit('onChange', JSON.stringify(this.JSONeditor.get()));
  }

  private beforeDestroy() {
    if (this.JSONeditor) {
      this.JSONeditor.destroy();
      this.JSONeditor = null;
    }
  }
}
</script>

<style lang="scss">
div.jsoneditor {
  border-color: var(--primary-color);
  .jsoneditor-menu {
    background-color: var(--primary-color);
  }
  .jsoneditor-contextmenu > .jsoneditor-menu {
    background-color: white;
  }
}
</style>
