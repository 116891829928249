// eslint-disable-next-line import/prefer-default-export
export class Selection {
  private selection: string[];

  constructor(selection?: string[]) {
    this.selection = selection || [];
  }

  get values() {
    return this.selection;
  }

  get isEmpty(): boolean {
    return this.selection.length === 0;
  }

  get length(): number {
    return this.selection.length;
  }

  public isSelected(uuid: string): boolean {
    return this.contains(uuid);
  }

  public contains(uuid: string): boolean {
    return this.selection.indexOf(uuid) !== -1;
  }

  public clear(): this {
    this.selection = [];
    return this;
  }

  public set(uuids: string[]): this {
    this.selection = uuids;

    return this;
  }

  public select(uuids: string | string[]): this {
    if (Array.isArray(uuids)) {
      this.selection = [...this.selection, ...uuids];
    } else {
      this.selection = [...this.selection, uuids];
    }
    return this;
  }

  public deselect(uuids: string | string[]): this {
    if (!Array.isArray(uuids)) {
      // eslint-disable-next-line no-param-reassign
      uuids = [uuids];
    }

    this.selection = this.selection.filter((uuid: string) => uuids.indexOf(uuid) === -1);

    return this;
  }
}
