import { Module } from 'vuex';

import { RootState, BarState } from '@/models';

function isValidBar(key: string, obj: BarState): key is keyof BarState {
  return key in obj;
}

const bars: Module<BarState, RootState> = {
  namespaced: true,
  state: {
    rightBarComponent: null,
    rightBarProperties: {},
    rightBarOpen: false,
    rightBarTitle: '',
    rightBarFormSubmit: false,
    filterBarOpen: false,
    subnavBarOpen: false,
    subnavBarTree: {},
    supportBarOpen: false,
  },
  actions: {
    openBar({ commit }, bar) {
      if (bar === 'rightBar') {
        commit('openRightBar', {
          component: null,
          properties: {},
        });
      } else {
        commit('setBar', { bar, value: true });
      }
    },
    closeBar({ commit }, bar) {
      if (bar === 'rightBar') {
        commit('closeRightBar');
      } else {
        commit('setBar', { bar, value: false });
      }
    },
    toggleBar({ commit }, bar) {
      commit('setBar', { bar });
    },
    openRightBar({ commit }, data) {
      commit('openRightBar', data);
    },
    closeRightBar({ commit }) {
      commit('closeRightBar');
    },
  },
  mutations: {
    setBar(state, { bar, value }) {
      const key = `${bar}Open`;
      if (isValidBar(key, state)) {
        (state[key] as string) = value ?? !state[key];
      }

      state.rightBarFormSubmit = false;
    },
    setRightBarTitle(state, payload) {
      state.rightBarTitle = payload;
    },
    setRightBarFormSubmit(state, payload) {
      state.rightBarFormSubmit = payload;
    },
    setTree(state, payload) {
      state.subnavBarTree = payload;
    },
    openRightBar(state, data) {
      state.rightBarOpen = true;
      state.rightBarComponent = data.component;
      state.rightBarFormSubmit = false;
      state.rightBarProperties = data.properties;
      state.rightBarTitle = data.title;
    },
    closeRightBar(state) {
      state.rightBarOpen = false;
      state.rightBarComponent = null;
      state.rightBarFormSubmit = false;
      state.rightBarProperties = {};
      state.rightBarTitle = '';
    },
  },
};
export default bars;
