<template>
  <div>
    <warnings v-bind="{ warnings, model }" />
    <div class="card">
       <div v-if="!isDebtorView" class="card-head py-0 px-0 justify-content-between">
        <div class="d-flex justify-start">
          <router-link
            :to="{ name: 'debtor.receivable', params: { type, debtorId: $route.params.debtorId } }"
            class="nav-link"
            v-for="type in types"
            :key="type"
            :class="{ active: $route.params.type === type }"
            >{{ $t(`receivable.${type}`) }}</router-link
          >
        </div>
        <router-link
          v-if="$route.params.type !== 'cost' && $route.params.type !== 'imported-payment'"
          :to="{ name: 'debtor.receivable.create', params: { type: $route.params.type } }"
          class="nav-link nav-link-right"
        >
          <icon class="icon-plus" name="plus" :title="$t('debtor.receivable.' + $route.params.type + '.create')" />
        </router-link>
      </div>
      <div class="card-body">
        <data-table
          :key="$route.params.type"
          ref="receivableList"
          :endpoint="endpoint"
          :model="model"
          :route-context="'debtor'"
          :emitable-record-keys="['type']"
          :route-params="{ type: $route.params.type }"
          @action="doAction"
          @bulkAction="doBulkAction"
          :disable-pagination="isDebtorView"
          :disable-search-fields="isDebtorView"
          :disable-sort="isDebtorView"
          :enableBulkSelection="true"
          :disable-column-options="isDebtorView"
          :initial-limit="isDebtorView ? limit : false"
        >
          <template #nested-table="{ record, opened}">
            <receivable-interest-cost
              v-if="opened.includes(record.uuid)"
              :stringArray='record.suggestions'
              :invoice-uuid='record.uuid'/>
          </template>
        </data-table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ReceivableListComponent } from '@/mixins/ReceivableListComponent';
import Warnings from '@/components/global/Warnings.vue';
import ReceivableInterestCost from '@/components/views/receivables/ReceivableInterestCost.vue';

@Component({
  components: {
    ReceivableInterestCost,
    Warnings
  },
})
export default class DebtorReceivableList extends mixins(ReceivableListComponent) {
  private types: string[] = ['invoice','cost', 'payment', 'creditnote', 'imported-payment'];
  private limit: number = 5;

  protected mounted() {
    if(!this.isDebtorView && !this.types.includes(this.$route.params.type)) {
      this.setDefaultType();
    }
  }

  private setDefaultType() {
    const params = this.$route.params;
    const type = this.types[0];

    this.$router.replace({
      params: {...params, type},
    });
  }
}
</script>

<style scoped lang="scss">
.nav-link {
  padding: 1rem 2rem;
  font-size: 1.25em;
}
</style>
