<template>
  <td colspan='14' class='p-0'>
    <table class='nested-table'>
      <thead>
      <tr>
        <th class='spaceless'></th>
        <th class='col-2'>
          {{ $t("debtor.receivable.interest.reference") }}
        </th>
        <th class='col-2'>
          {{ $t("debtor.receivable.interest.type") }}
        </th>
        <th class='col-2'>
          {{ $t("debtor.receivable.interest.original_amount") }}
        </th>
        <th class='col-2'>
          {{ $t("debtor.receivable.interest.status") }}
        </th>
        <th class='col-2'>
          {{ $t("debtor.receivable.interest.open_amount") }}
        </th>
        <th colspan='2'></th>
      </tr>
      </thead>
      <tbody class='string-detail-body' v-for="record in stringArray">
      <tr
        class="string-detail-tr pointer"
      >
        <td class='spaceless'></td>
        <td class='col-2'>
          {{ record.receivableReference }}
        </td>
        <td class='col-2'>
          {{ record.costType }}
        </td>
        <td class='col-2'>
          {{ record.receivableOriginalAmount | currency }}
        </td>
        <td class='col-2'>
          {{ record.receivableStatus }}
        </td>
        <td class='col-2'>
          {{ record.receivableAmount | currency }}
        </td>
        <td></td>
        <td v-if="$route.name !== 'debtor.group.view'">
          <button @click.prevent='doAction(record.uuid)' class="float-right btn btn-light btn-lg px-1 py-0">
            <icon prefix='fal' name="edit" class="lg" />
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </td>

</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { FormComponent } from '@/mixins/FormComponent';
import DebtorReceivableEdit from '@/views/debtor/receivable/Edit.vue';

@Component({})
export default class ReceivableInterestCost extends mixins(FormComponent) {
  @Prop({ required: true })
  private stringArray!: ReceivableSuggestion[];
  @Prop({ required: true })
  private invoiceUuid!: string;
  private async doAction(receivableUuid: string) {
    await this.$store.dispatch('bars/openRightBar', {
      component: DebtorReceivableEdit,
      title: this.$t(`debtor.receivable.edit`),
      properties: {
        receivable: {
          uuid: receivableUuid,
          type: "cost",
        }
      }
    });
  }
}

interface ReceivableSuggestion {
  uuid: string;
  receivableReference: string;
  costType: string;
  receivableAmount: string;
  receivableStatus: string;
  receivableOriginalAmount: string;
}

</script>

<style scoped lang="scss">

@import '~@/assets/scss/colors';

tbody {
  tr {
    border-bottom: 1px solid $light;

    &.pointer {
      cursor: pointer;
    }

    &:nth-child(even) {
      background: transparent;
    }
    td {
      border: 0;
    }
  }
}

.table tbody + tbody {
  border-top: 1px solid $light;
}

.nested-table {
  padding: 0;
  width: 100%;
  margin: 0;
  border-bottom: 1px solid $light;
}

.string-detail-tr, .string-detail-line-wrapper{
  border-top: 0;
  border-bottom: 0;
}

.string-detail-body:hover {
  border-bottom: 1px solid var(--primary-color);
  box-shadow: none;
  transition: all 0.2s;
}

.spaceless {
  width: 9.5vw;
}

.string-detail-line, .string-detail-line:hover {
  border-top: 0;
  border-bottom: 0;
  line-height: 2;
  color: #495057;
  display: block;
  padding-left: 5px;
}
</style>
