<template>
  <card v-bind="{ disableHeader: true, loading }">
    <template slot="body">
      <placeholder v-if="loading" class="placeholder-container row justify-content-between align-items-center">
        <div class="placeholder" style="width: 40%;" />
        <div class="placeholder" style="width: 144px; height: 44px;" />
      </placeholder>
      <div v-else class="d-flex flex-column flex-md-row justify-content-between align-items-center">
          <span class="mr-md-5" v-if="data.totalAmount" v-html="$t('debtorportal.payment.description',  paymentDetails )" />
          <span class="mr-md-5" v-else v-html="$t('debtorportal.payment.mc_description',  paymentDetails )" />
          <button
            v-if="data.paymentUrl && this.paymentStatus !== 'paid'"
            class="btn btn-success text-nowrap py-2 px-3"
            v-text="$t('debtorportal.payment.button')"
            @click="openPaymentPopup(data.paymentUrl)"
          />
      </div>
    </template>
  </card>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import Card from '@/debtorportal/components/Card.vue';
import accounting from '@/utils/accounting';
import { CardComponent } from '@/debtorportal/models/CardComponent';
import currencyToSymbolMap from 'currency-symbol-map/currency-symbol-map';

@Component({
  components: {
    Card,
  },
})
export default class PaymentAction extends CardComponent {
  protected paymentPopup: Window | null = null;
  protected paymentStatus: string = '';

  protected openPaymentPopup(url: string) {
    this.paymentPopup = window.open(
      url,
      '_blank',
      'toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=400,height=700,top=100,left=100'
    );
    window.addEventListener('message', this.handlePayment);
  }

  protected handlePayment(event: MessageEvent) {
    if (this.paymentPopup && event.data?.status) {
      window.removeEventListener('message', this.handlePayment);
      this.paymentPopup.close();
      this.paymentStatus = event.data.status;

      if (this.paymentStatus === 'paid') {
        this.$toasted.success((this.$t('debtorportal.payment.succes') as string));
      } else {
        this.$toasted.error((this.$t('debtorportal.payment.failed') as string));
      }
      this.$emit('onPayment');
    }
  }
  private get paymentDetails() {
    if (this.data.totalAmount === null) {
      return this.bankDetails;
    }

    return {
      ...this.bankDetails,
      totalAmount: accounting.formatMoney(this.data.totalAmount.amount, currencyToSymbolMap(this.data.totalAmount.currency)),
    };
  }
  private get bankDetails(): object {
    return {
      iban: this.data.iban,
      bic: this.data.bic,
      recipient: this.data.recipient,
      reference: this.data.reference,
    };
  }
}
</script>
