<template>
  <div class="card">
    <div class="card-body">
      <data-table
        :model="model"
        :endpoint="endpoint"
        @action="doAction"
        @bulkAction="doBulkAction"
        ref="emailList"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { EmailListComponent } from '@/mixins/EmailListComponent';

@Component
export default class EmailList extends mixins(EmailListComponent) {}
</script>

<style scoped lang="scss">
</style>
