<template>
  <div>
    <div class="card">
      <div class="card-head">
        <h2 class="h5"> {{ $t(`settings.businessunit.corporate`) }}</h2>
      </div>
      <div class="card-body table-responsive">
        <div class="row" v-if="logos">
          <div
            class="col-sm-6"
            v-for="(logo, i) in this.logos"
            :key="i"
          >
            <div class="logo">
              <img :src="`${$root.VUE_APP_API_HOST}/api/app/logo/${logo.logo}`" class="img-fluid" :title="logo.name" :alt="logo.name">
              <div @click="deActivateLogo(logo.uuid)">
                <icon class="icon-trash pointer" name="delete"/>
              </div>
            </div>
          </div>
          <div class="col-sm-6" v-if="!logos.length">
            <router-link
              class="logo h-100"
              :to="{name: 'settings.businessunit.settings.logo.create', params:{type:selectedType}}"
            >
              <icon class="icon-plus" name="plus" size="lg"/>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

import api from '@/api';
import { Logo } from '@/models';

@Component({})
export default class BusinessUnitLogos extends Vue {
  protected types: string[] = ['corporate'];
  protected selectedType: string = this.types[0];
  @Prop({ required: true })
  protected logos!: Logo[];

  protected async deActivateLogo(uuid: string) {
    if(!await this.showConfirmationModal() ) {
      return;
    }
    const res = await api.post(`/logo/${uuid}/deactivate`);
    if (res && res.data && res.data.success) {
      this.$emit('onLogoDeleted', uuid);
      this.$toasted.success(this.$t(`delete.success`) as string);
    }
  }

  private showConfirmationModal() {
    return new Promise<boolean>((resolve) => {
      this.$store.dispatch('modal/open', {
        body: 'modal.record.delete.body',
        actions: [
          {
            label: this.$t('modal.record.delete.cancel') as string,
            class: 'btn-dark',
            action: async () => this.$store.dispatch('modal/close').then(() => resolve(false)),
          },
          {
            label: this.$t('modal.record.delete.confirm') as string,
            class: 'btn-primary',
            action: async () => {
              this.$store.dispatch('modal/close').then(() => resolve(true));
            },
          },
        ],
      });
    });
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/colors';
.logo {
  border: 1px solid $medium;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: relative;

  .icon-trash {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    cursor: pointer;
  }

  &:hover .icon-plus {
    color: black;
  }
}
</style>
