<template>
  <div class="card mb-3 border-0">
    <div class="card-body p-0 border-0">
      <small class="text-muted mb-2" v-if="data.aangetekend">{{ $t('communication.email.aangetekend') }}</small>
      <field label="creator" :value="data.creator"/>
      <field label="from_address" v-if="data.from_address" :value="data.from_address.address" class="mb-2"/>
      <field label="recipient" v-if="data.recipient" :value="data.recipient.display_name" />
      <div class="field-column" v-if="attachmentLinks && attachmentLinks.length">
        <attachment-download-list v-bind:attachments="attachmentLinks">
          {{ $t('attachment_links') }}
        </attachment-download-list>
      </div>
      <div v-for="(type, i) in ['to_addresses', 'cc_addresses','bcc_addresses']" :key="i">
        <div v-if="data[type] && data[type].length">
          <div class="font-weight-bold" v-text="$t(type)"/>
          <field
            v-for="(address, a) in data[type]"
            :key="a"
            :label="address.name"
            :translate-label="false"
            :value="address.address" />
        </div>
      </div>
      <div class="field-column pt-2" v-if="contentUrl">
        <iframe
          class="content"
          :src="contentUrl"
          width="100%"
          height="600px"
          border="0"
          scrolling="yes"
        />
      </div>
      <div class='field-row pt-2' v-if='data.attachments'>
        <ul :class="['thumbnail-list-sm']">
          <li @click.prevent='toggleShowLightBox(item)' v-for="item in data.attachments" style="width: 20%; display: inline" >
            <img class='thumbnail-image flex' :src="getImageEndpoint(item)">
          </li>
        </ul>
      </div>
    </div>
    <light-box
      v-show='opened.length > 0 && data.attachments'
      :images='imagesForLightBox'
      :id='this.opened'
      @closeLightBox='toggleShowLightBox("")'
    >
    </light-box>
  </div>
</template>


<script lang="ts">
import { Component, Prop, Watch } from 'vue-property-decorator';
import AttachmentDownloadList from '@/components/views/partial/AttachmentDownloadList.vue';
import { API } from '@/mixins/API';
import { AttachmentDownload } from '@/models/AttachmentDownload';
import { AttachmentLink } from '@/models/AttachmentLink';
import { EmailData } from '@/models/EmailData';
import Field from '@/components/global/Field.vue';
import LightBox from '@/components/global/LightBox.vue';

@Component({
  components: {
    LightBox,
    AttachmentDownloadList,
    Field
  }
})
export default class ViewEmail extends API {
  @Prop({ required: true })
  protected data!: EmailData;
  protected images: string[] = [];
  private opened: string = '';
  private created() {
    this.setTitle();
  }

  @Watch('data')
  private setTitle() {
    this.$store.commit('bars/setRightBarTitle', this.data.subject);
  }

  private get contentUrl() {
    return (
      this.data &&
      this.data.contentUrl &&
      process.env.VUE_APP_API_HOST + this.data.contentUrl + '?token=' + this.$store.state.auth.token
    );
  }

  private get attachmentLinks(): AttachmentDownload[] {
    if(null == this.data.attachment_links) {
      return [];
    }

    return this.data.attachment_links.map((attachmentLink: AttachmentLink) => {
      return {
        original_name: attachmentLink.attachment.original_name,
        endpoint: `email/${this.data.uuid}/attachment/${attachmentLink.uuid}/download`,
      };
    });
  }

  private getImageEndpoint(item: string) {
    return process.env.VUE_APP_API_HOST + item + '?token=' + this.$store.state.auth.token;
  }

  private get imagesForLightBox() {
    if(null == this.data.attachments) {
      return [];
    }

    Object.values(this.data.attachments).forEach((element: any) => {
      this.images.push(process.env.VUE_APP_API_HOST + element + "?token=" + this.$store.state.auth.token)
    })
    return this.images
  }

  private toggleShowLightBox(id: string) {
    if (this.opened?.length > 0) {
      this.opened = '';
    } else {
      id = this.getImageEndpoint(id);
      this.opened = id;
    }
  }

}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/colors';

.content {
  background-color: white;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.06);
}

.thumbnail-image {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 150px;
  height: 100px;
  contain: content;
  margin: 10px;
}

.thumbnail-image:hover {
    border: 2px solid $dark !important;
    border-radius: 4px;
    padding: 5px;
}

.thumbnail-list {
  display: grid;
  grid-template-columns:repeat(7,1fr);
  padding-left: 0;
}

.thumbnail-list-sm {
  display: inline;
  padding-left: 0;
}

</style>
