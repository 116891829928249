<template>
  <div class="card" v-if="items.length || isDebtorHistory">
    <loader :loading="loading" />
    <div class="card-head flex justify-between py-0 pr-0">
      <h2 class="h5">{{ $t('debtors.overview.history.title') }}</h2>
      <router-link
        class="nav-link nav-link-right"
        v-if="!isDebtorHistory"
        :to="{
          name: 'debtor.history',
        }"
      >
        <icon name="th-list" class="pr-2" :title="$t('debtors.overview.history.detail')" />
      </router-link>
    </div>
    <div class="card-body p-0">
      <form v-if="schema && !isDebtorHistory" class="px-3 pt-3">
        <div class="row position-relative">
          <div class="col-10">
            <json-form :schema="schema" v-model="model" :labels="false" :errors="errors" />
          </div>
        </div>
      </form>
      <div class="timeline px-3">
        <div class="d-flex timeline-item" v-for="item in filteredItems" :key="item.id" >
          <div class="py-2 text-muted text-right timeline-item--time">
            {{ item.date | datetime('dd MM ’yy') }}
            <br />
            {{ item.date | datetime('HH:mm') }}
          </div>
          <div class="py-2 timeline-item--icon">
            <div class="cursor-pointer" @click.prevent="handleItemClick(item)">
              <history-icon :type="item.communication_type"/>
            </div>
          </div>
          <div class="py-2 timeline-item--info">
            <h3 class="h5 mb-0 cursor-pointer" @click.prevent="handleItemClick(item)">
              {{ $t(`debtors.overview.history.type.${item.communication_type}`) }}
            </h3>
            <p>
              <span v-text="item.short_content"/>
              <br />
              <span class="text-muted text-italic"
                >{{ $t('debtors.overview.history.by') }} {{ item.creator }}</span
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { LoadingState, HistoryItem } from '@/models';
import { JSONSchema4 } from 'json-schema';
import HistoryIcon from './HistoryIcon.vue';
import api from '@/api';
import DebtorCommunicationView from '../communication/View.vue';
import { VueConstructor } from 'vue';
import { EventBus } from '@/services/EventBus';

@Component({
  components: {
    HistoryIcon,
  },
})
export default class History extends Vue {
  @Prop({required:false, default: false})
  protected isDebtorHistory!: boolean;
  private loading: LoadingState = false;
  private schema: JSONSchema4 | null = null;
  private errors: any;
  private items: HistoryItem[] = [];
  private model: any = {};
  private formIdentifier: string = '';
  private token: string = '';
  private filter: string[] = [];

  private onFormSubmit = () => this.loadData();

  private async created() {
    EventBus.$on('form.submit.successful', this.onFormSubmit);

    await this.loadData();
  }
  private beforeDestroy(){
    EventBus.$off('form.submit.successful', this.onFormSubmit);
  }
  @Watch('$route.params.debtorId')
  private async loadData() {
    this.loading = true;
    const res = await api.get(this.endpoint);
    if (res.data.form.schema) {
      this.schema = res.data.form.schema;
      this.formIdentifier = res.data.form.schema.title;
      this.model = res.data.form.values;
      this.token = res.data.form.csrf_token.value;
      this.items = res.data.items;
      this.errors = res.data.errors;
      return (this.loading = false);
    }
    this.loading = 'error';
  }

  @Watch('model.filter')
  private async updateFilter() {
    this.filter = [...this.model.filter];
  }
  private isEmailType(type: string) {
    return ['OutgoingEmail', 'DebtorEmail'].includes(type);
  }
  private isActiveFilter(type: string) {
    return this.filter.indexOf(type) !== -1;
  }

  private handleItemClick(item: HistoryItem) {
    if(item.communication_type === 'OutgoingEmail' || item.communication_type === 'DebtorEmail') {
      this.openCommunicationInRightBar('email', item.uuid);
    }
    else if(item.communication_type === 'Letter') {
      this.openCommunicationInRightBar('letter', item.uuid);
    }
    else if(item.communication_type === 'Call') {
      this.openCommunicationInRightBar('call', item.uuid);
    }
    else if(item.communication_type === 'Sms') {
      this.openCommunicationInRightBar('sms', item.uuid);
    }
  }

  private openItemInRightBar(component: VueConstructor, properties: Record<string, any>) {
    this.$store.dispatch('bars/openRightBar', {
      component,
      properties
    });
  }

  private openCommunicationInRightBar(communicationType: 'letter' | 'call' | 'email' | 'sms', communicationId: string) {
    this.openItemInRightBar(DebtorCommunicationView, {
      debtor: this.$route.params.debtorId,
      communicationType,
      communicationId,
    });
  }

  protected get filteredItems() {
    if (this.filter.length < 1) {
      return this.items;
    }
    return this.items.filter(
      ({ communication_type: type }) =>
        this.isActiveFilter(type) || (this.isActiveFilter('Email') && this.isEmailType(type)),
    );
  }
  protected get endpoint() {
    return `/debtor/${this.$route.params.debtorId}/task_history`;
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/colors';
@import '~@/assets/scss/elements/timeline';
.text-muted {
  opacity: 0.6;
}
</style>
