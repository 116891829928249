<template>
  <nav :class="['subnav-bar', 'py-2', { open }]">
    <slot />
  </nav>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class SubnavBar extends Vue {
  @Prop({ required: false })
  private open!: boolean;
}
</script>

<style lang="scss">
@import '~@/assets/scss/variables';
@import '~@/assets/scss/colors';

.subnav-bar {
  display: none;
  user-select: none;
  background-color: var(--primary-color);
  overflow: auto;

  .filter-bar-open & {
    display: block;
  }

  &:not(.open) {
    display: none;
  }

  ul {
    margin-bottom: 0;
  }
}

.subnav-nav--item {
  position: relative;
  display: inline-block;
  border-right: 0;
  border-left: 0;
  padding: 0rem 2rem;
  color: $white;

  a {
    display: block;
    color: inherit;
    &:hover {
      text-decoration: none;
      opacity: 0.75;
    }
    &.router-link-exact-active {
      opacity: 0.75;
    }
    &.router-link-active:not(.overview) {
      opacity: 0.75;
    }
  }
  &.disabled {
    pointer-events: none;
    > a {
      opacity: 0.3;
    }
  }
}

@media screen and (min-width: 1210px) {
  .subnav-bar {
    display: inline-block;
    width: $sidebar-large;
  }
  .subnav-nav--item {
    display: block;
  }
}
</style>