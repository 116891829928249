<template>
  <div class='support-bar d-flex flex-column justify-content-between align-items-center p-2' :class='{ open }'>
    <div class='d-flex justify-content-center flex-shrink-0'>
      <h2 class='text-center text-primary support-title'>{{ $t('supportbar.title') }}</h2>
    </div>

    <div class='d-flex flex-shrink-0 my-4'>
      <h4 class='text-center text-primary support-title'>{{ $t('supportbar.subtitle') }}</h4>
    </div>

    <div class='d-flex flex-column align-self-center'>
      <a href='https://confluence.external-share.com/content/044cb0f0-3308-45f8-80e0-5d6fef12114d'
         target='_blank' class='support-tile py-2 pr-4 d-flex align-items-center mb-3 text-decoration-none'>
        <icon name='book-open' class='tile-icon p-2' size='3x' />
        <div class='ml-2 text-primary text-lg font-semibold'>{{ $t('supportbar.tile.knowledge_base') }}</div>
      </a>
      <a href='https://confluence.external-share.com/content/044cb0f0-3308-45f8-80e0-5d6fef12114d/5799951' target='_blank'
         class='support-tile py-2 pr-4 d-flex align-items-center mb-3 text-decoration-none'>
        <icon name='sparkles' class='tile-icon p-2' size='3x' />
        <div class='ml-2 text-primary text-lg font-semibold'>{{ $t('supportbar.tile.release_notes') }}</div>
      </a>
      <a href='https://collect-online.atlassian.net/servicedesk/customer/portal/1' target='_blank'
         class='support-tile py-2 pr-4 d-flex align-items-center mb-3 text-decoration-none'>
        <icon name='question' class='tile-icon p-2' size='3x' />
        <div class='ml-2 text-primary text-lg font-semibold'>{{ $t('supportbar.tile.support_request') }}</div>
      </a>
      <router-link :to="{name: 'support.index' }" class='support-tile py-2 pr-4 d-flex align-items-center mb-3 text-decoration-none' target='_blank'>
        <icon name='brackets-curly' class='tile-icon p-2' size='3x' />
        <div class='ml-2 text-primary text-lg font-semibold'>{{ $t('supportbar.tile.api_documentation') }}</div>
      </router-link>
    </div>
  </div>
</template>

<script lang='ts'>
import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';


@Component({})
export default class SupportBar extends Vue {
  @Prop({ default: false })
  private open!: boolean;
}
</script>

<style lang='scss'>
@import '~@/assets/scss/variables';
@import '~@/assets/scss/colors';

.support-bar {
  h2.support-title {
    opacity: 0.75;
    font-size: 2.5rem;
  }

  position: fixed;
  top: $topbar-height + $titlebar-height;
  bottom: 0;
  width: $rightbar-regular;
  right: -$rightbar-regular;
  background-color: $white;
  z-index: 100;
  border-left: solid 1px $medium;
  transition: ease $speed;

  &.open {
    right: 0;
  }


  .support-tile {
    background-color: rgba(gray, 0.1);
    border-radius: 5px;
    cursor: pointer;
    opacity: 0.5;
    transition: all 0.4s ease;

    &:hover {
      opacity: 1;
    }
    .tile-icon {
      color: var(--primary-color);
    }
  }

  @media screen and (max-width: 1210px) {
    min-width: $rightbar-regular;
    width: $rightbar-regular;
  }
}
</style>
