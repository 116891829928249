<template>
  <div class="card mb-3 border-0">
    <div class="card-body p-0 border-0">
      <field label="creator" :value="data.creator"/>
      <div class="field-column pt-2" v-if="attachments && attachments.length">
        <attachment-download-list v-bind:attachments="attachments">
          {{ $t('attachment_links') }}
        </attachment-download-list>
      </div>
      <span class="font-weight-bold mr-1 pt-2">
        {{ this.$t('content')}}
      </span>
      <div class="field-column pt-1">
        {{ data.message }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch } from 'vue-property-decorator';
import AttachmentDownloadList from '@/components/views/partial/AttachmentDownloadList.vue';
import { API } from '@/mixins/API';
import { AttachmentDownload } from '@/models/AttachmentDownload';
import { AttachmentLink } from '@/models/AttachmentLink';
import { DebtorNote } from '@/models/DebtorNote';
import Field from '@/components/global/Field.vue';

@Component({
  components: {
    'attachment-download-list': AttachmentDownloadList,
    Field
  }
})
export default class ViewNote extends API {
  @Prop({ required: true })
  protected data!: DebtorNote;

  private created() {
    this.setTitle();
  }

  @Watch('data')
  private setTitle() {
    this.$store.commit('bars/setRightBarTitle', this.$t('note'));
  }

  private get attachments(): AttachmentDownload[] {
    if(null == this.data.attachment_links) {
      return [];
    }

    return this.data.attachment_links.map((attachmentLink: AttachmentLink) => {
      return {
        original_name: attachmentLink.attachment.original_name,
        endpoint: this.endpoint(attachmentLink),
      };
    });
  }

  private endpoint(attachmentLink: AttachmentLink) {
    if (this.data.debtor.uuid != null && this.data.communication_type != null && this.data.uuid != null) {
      return `/debtor/${this.data.debtor.uuid}/note/${this.data.uuid}/attachment/${attachmentLink.uuid}/download`;
    }

    return `/debtor/${this.$route.params.debtorId}/note/${this.$route.params.typeId}/attachment/${attachmentLink.uuid}/download`;
  }
}
</script>

<style scoped lang="scss">
.content {
  background-color: white;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.06);
}
</style>
