<template>
  <div>
    <loader :loading="loading"/>
    <div class="row justify-content-between mb-3" v-if="!$route.params.type">
      <div v-for="task in openTasks" class="col" :key="task.type">
        <Block
          class="h-100"
          :title="$t(`batch.type.${task.type}`) + ` (${task.count})`"
          :link="{name: 'dashboard.batch.type', params: { type: task.type }}"
        >
          <div v-if="task.summary.length">
            <div
              class="d-flex justify-content-between"
              v-for="summary in task.summary"
              :key="summary.name"
            >
              <span>{{ summary.name }}</span>
              <span>{{ summary.count }}</span>
            </div>
          </div>
          <div class="actions">
            <router-link
              class="btn btn-dark btn-sm"
              :to="{name: 'dashboard.batch.type', params: { type: task.type }}"
            >{{ $t('debtors.overview.tasks.btn.all') }}</router-link>
            <div
              class="btn btn-dark btn-sm ml-2"
              @click="doBulkActionAndUpdate({emit:'process_all', type:task.type})"
            >{{ $t('process') }}</div>
          </div>
        </Block>
      </div>
    </div>
    <div class="card h-100">
        <div class="card-head select-none py-0 px-0 justify-between">
          <div class="d-flex">
            <a
              v-for="value in ['history', 'files']"
              :key="value"
              class="nav-link h5"
              :class="{ active: selectedType === value }"
              @click="selectType(value)"
            >
              <span class=" h5 d-none d-lg-block">{{ $t(`batch.${value}.list`) }}</span>
            </a>
          </div>
      </div>
      <div class="card-body">
        <data-table
          :model="selectedType"
          :endpoint="this.endpoint"
          @action="doAction"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { BatchListMixin } from '@/mixins/BatchListMixin';
import { EmitterAction } from '@/models';

@Component
export default class BatchList extends mixins(BatchListMixin) {
  private static readonly DOWNLOAD_SINGLE_SIDED = "download_single_sided";
  private static readonly DOWNLOAD_DOUBLE_SIDED = "download_double_sided";
  private static readonly RETRY_BATCH = "retry_batch";
  private static readonly DASHBOARD_BATCH = "/dashboard/batch";
  private static readonly SINGLE = "single";
  private static readonly DOUBLE = "double";
  private static readonly INVOICE_PDF = "/invoice_pdf";
  private static readonly DOWNLOAD = "/download";
  private static readonly RETRY = "/retry";
  private static readonly FILES = 'files';
  private selectedType: string = 'history';

  private async selectType(type: string) {
    this.selectedType = type;
  }

  protected async doAction(action: EmitterAction) {
    this.setDataTable();
    let endpoint = '';

    const isSingleSidedDownload = action.emit === BatchList.DOWNLOAD_SINGLE_SIDED;
    const isDoubleSidedDownload = action.emit === BatchList.DOWNLOAD_DOUBLE_SIDED;
    const isRetryBatch = action.emit === BatchList.RETRY_BATCH;

    if (isSingleSidedDownload || isDoubleSidedDownload) {
      const isSingle = action.emit.includes(BatchList.SINGLE);
      const type = isSingle ? BatchList.SINGLE : BatchList.DOUBLE;

      endpoint = this.constructDownloadEndpoint(action.id, type);
      await this.download(endpoint);
    }

    if (isRetryBatch) {
      endpoint = BatchList.DASHBOARD_BATCH + BatchList.RETRY + `/${action.id}`;
      await this.post({
        endpoint: endpoint,
        feedback: {
          success: this.$t(`batch_retry.success`),
          error: this.$t(`batch_retry.error`),
        },
      });
    }
  }

  private constructDownloadEndpoint(id: string, type: string): string {
    let prefix = BatchList.DASHBOARD_BATCH;
    if (this.selectedType === BatchList.FILES) {
      prefix += BatchList.INVOICE_PDF;
    }

    return `${prefix}/${id}${BatchList.DOWNLOAD}/${type}`;
  }

  get endpoint() {
    switch (this.selectedType) {
      case 'history':
        return `/dashboard/batch/history`;
      case 'files':
        return `/dashboard/batch/invoice_pdf`;
    }
    return '';
  }

}
</script>

<style scoped lang="scss">
.card {
  min-height: 150px;
  display: flex;
}

.card-body {
  display: flex;
  flex: 1 1 100%;
  justify-content: space-between;
  flex-direction: column;
}
</style>
