<template>
  <div class="column-container">
    <div class="column column-small">
      <filters
        :filter-types="filterTypes"
        :value="value.filters"
        @input="onFilterChange"
        @onEmptyRuleSet="clearFilterSchema"
      />
    </div>
    <div class="column column-wide" v-if="shouldShowResults">
      <div class="conditions-and-actions-wrapper if-wrapper my-3">
        <div class="title">If true</div>
        <switcher
          :options="['Filter', 'Workflow']"
          v-model="trueType"
          @input="this.onTrueTypeChange"
        />
        <workflows
          v-if="trueType === 'Workflow'"
          :workflows="workflows"
          :value="value.true && value.true.workflow"
          @input="onWorkflowChange($event, 'true')"
        />
        <filters-and-workflow
          :filter-types="filterTypes"
          :workflows="workflows"
          v-if="trueType === 'Filter' && FiltersAndWorkflowValue('true')"
          :value="FiltersAndWorkflowValue('true')"
          @input="onFiltersAndWorkflowUpdate($event, 'true')"
        />
      </div>
      <div class="conditions-and-actions-wrapper if-wrapper my-3">
        <div class="title">If false</div>
        <switcher
          :options="['Filter', 'Workflow']"
          v-model="falseType"
          @input="this.onFalseTypeChange"
        />
        <workflows
          v-if="falseType === 'Workflow'"
          :workflows="workflows"
          :value="value.false && value.false.workflow"
          @input="onWorkflowChange($event, 'false')"
        />
        <filters-and-workflow
          :filter-types="filterTypes"
          :workflows="workflows"
          v-if="falseType === 'Filter' && FiltersAndWorkflowValue('false')"
          :value="FiltersAndWorkflowValue('false')"
          @input="onFiltersAndWorkflowUpdate($event, 'false')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
  ConditionalAction,
  Action,
  WorkflowSelect,
  FilterTypes,
  FilterGroup,
  Filters as FilterInterface,
  SegmentationWorkflow,
} from '@/models';
import { clone } from '@/utils/clone';
import Filters from './Filters.vue';
import Workflows from './Workflows.vue';
import Switcher from '../workflow/Switcher.vue';
import Segmentation from '@/plugins/segmentation/Segmentation.vue';

type Bool = 'true' | 'false';

@Component({
  name: 'filters-and-workflow',
  components: { Switcher, Filters, Workflows },
})
export default class FiltersAndWorkflow extends Vue {
  @Prop({ type: [Object], required: true })
  private filterTypes!: FilterTypes;
  @Prop({ type: [Object], required: true })
  private workflows!: WorkflowSelect;
  @Prop({ type: [Object], required: true })
  private value!: FilterGroup;

  private trueType: 'Filter' | 'Workflow' = 'Workflow';
  private falseType: 'Filter' | 'Workflow' = 'Workflow';

  private created() {
    this.trueType = this.initialType('true');
    this.falseType = this.initialType('false');
  }

  private initialType(booleanType: Bool) {
    return this.value[booleanType] && this.value[booleanType].filtergroup ? 'Filter' : 'Workflow';
  }

  private onFilterChange(value: FilterInterface) {
    const filtergroup = clone(this.value);
    filtergroup.filters = value;
    this.$emit('input', filtergroup);
  }

  private clearFilterSchema() {
    if(this.$parent instanceof Segmentation) {
      const filtergroup = null;
      this.$emit('input', filtergroup);
    }
    else {
      const filtergroup = Object.assign({
        filters: {
          logical_operator: 'OR',
          rules: [],
        },
        true: {
          workflow: null,
          filtergroup: null,
        },
        false: {
          workflow: null,
          filtergroup: null,
        },
      });
      this.$emit('input', filtergroup);
    }
  }
  private onFiltersAndWorkflowUpdate(value: FilterGroup, type: Bool) {
    const filtergroup = clone(this.value);
    filtergroup[type].filtergroup = value;
    this.$emit('input', filtergroup);
  }

  private onWorkflowChange(value: SegmentationWorkflow, type: Bool) {
    const filtergroup = clone(this.value);
    if(!filtergroup[type]) {
      filtergroup[type] = Object.assign({
        workflow: null,
        filtergroup: null,
      })
    }
    filtergroup[type].workflow = value;
    this.$emit('input', filtergroup);
  }

  private FiltersAndWorkflowValue(type: Bool) {
    if (!this.value[type].filtergroup) {
      const filtergroup = clone(this.value);
      filtergroup[type] = Object.assign({
        filtergroup: {
          filters: {
            logical_operator: 'OR',
            rules: [
              []
            ],
          },
          true: {
            workflow: null,
            filtergroup: null,
          },
          false: {
            workflow: null,
            filtergroup: null,
          },
        },
        workflow: null,
      });
      this.$emit('input', filtergroup);
    }
    return this.value[type].filtergroup;
  }

  private onTrueTypeChange() {
    this.onTypeChange('true');
  }
  private onFalseTypeChange() {
    this.onTypeChange('false');
  }

  private onTypeChange(type: Bool) {
    const filtergroup = clone(this.value);

    filtergroup[type] = Object.assign({
      filtergroup: null,
      workflow: null,
    });

    this.$emit('input', filtergroup);
  }

  get shouldShowResults() {
    return !!this.value.filters.rules.filter(rule => rule.type).length;
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/colors';

.if-wrapper {
  border-style: dotted;
}
</style>
