<template>
    <component
      :is="reportStatisticComponent"
      v-bind="{ currency, type }"
    />
</template>

<script lang='ts'>
import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  reportStatisticComponentMap,
  VueComponent,
} from '@/utils/ReportStatisticComponentMap';
import NotFoundReportStatistic from '@/components/report/statistic/NotFoundReportStatistic.vue';
import { ValidStatisticType } from '@/models/ReportStatistic';

@Component({})
export default class ReportStatisticWrapper extends Vue {
  @Prop({ required: true })
  protected type!: ValidStatisticType;

  @Prop({ required: true })
  protected currency!: string;

  protected reportStatisticComponent?: VueComponent;

  protected async created() {
    this.reportStatisticComponent = this.getComponent();
  }

  private getComponent() {
    if (this.typeIsMapped()) {
      return reportStatisticComponentMap[this.type];
    }

    return NotFoundReportStatistic;
  }

  private typeIsMapped() {
    return reportStatisticComponentMap[this.type] !== undefined;
  }
}
</script>

<style lang="scss" scoped>

</style>
