import { isArray, isBoolean, isObject, isString } from '@/utils/typeGuards';

export interface ColumnInterface {
  name: string;
  label: string;
  searchable: boolean;
  sortable: boolean;
  translatable: boolean;
  is_key: boolean;
  hidden?: boolean;
  order: number;
}

export function isColumnInterface(column: any): column is ColumnInterface {
  return isObject(column)
    && isString(column.name)
    && isString(column.label)
    && isBoolean(column.searchable)
    && isBoolean(column.sortable)
    && isBoolean(column.translatable)
    && isBoolean(column.is_key)
    ;
}

export interface StringColumnInterface extends ColumnInterface {
  type: 'string' | 'money' | 'color' | 'stringArray' | 'stringDetailLine' | 'nestedTableStringArray';
}
export interface ChoiceColumnInterface extends ColumnInterface {
  type: 'string';
  choices: Choices;
}
interface BooleanColumnInterface extends ColumnInterface {
  type: 'boolean';
}
interface Choices {
  enum: string[];
  enum_titles: string[];
}

export function isChoices(value: any): value is Choices {
  return isObject(value) && isArray(value.enum) && isArray(value.enum_titles);
}

export function isChoiceColumnInterface(column: any): column is ChoiceColumnInterface {
  return isColumnInterface(column) && isChoices( (column as any).choices);
}

export interface DatetimeColumnInterface extends ColumnInterface {
  type: 'date' | 'datetime';
}

export interface CurrencyColumnInterface extends ColumnInterface {
  type: 'money';
}

export interface StringDetailColumnInterface extends ColumnInterface {
  type: 'stringDetailLine';
}

function isDateType(value: any): value is 'date' | 'datetime' {
  return isString(value) && value === 'date' || value === 'datetime';
}

function isCurrencyType(value: any): value is 'money' {
  return isString(value) && value === 'money';
}

export function isDatetimeColumnInterface(column: any): column is DatetimeColumnInterface {
  return isColumnInterface(column) && isDateType((column as any).type);
}

export function isCurrencyColumnInterface(column: any): column is CurrencyColumnInterface {
  return isColumnInterface(column) && isCurrencyType((column as any).type);
}

export type ColumnContext = StringColumnInterface | ChoiceColumnInterface | DatetimeColumnInterface | BooleanColumnInterface | StringDetailColumnInterface | CurrencyColumnInterface;

export interface CustomizableColumnContext {
  hidden: boolean,
  order: number,
}
