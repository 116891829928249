var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card mb-4"},[_c('div',{staticClass:"card-head justify-content-between py-0 px-0"},[_c('div',{staticClass:"d-flex"},_vm._l((['email', 'letter', 'sms', 'call', 'note']),function(type){return _c('router-link',{key:type,staticClass:"nav-link",class:{ active: _vm.$route.params.type === type },attrs:{"to":{
          name: 'debtor.communication.type',
          params: { type, debtorId: _vm.$route.params.debtorId },
          query: {},
        }},nativeOn:{"click":function($event){return _vm.loadData.apply(null, arguments)}}},[_c('span',{staticClass:"h5 d-none d-lg-block"},[_vm._v(_vm._s(_vm.$t(`debtor.communication.${type}`)))]),_c('span',{staticClass:"badge badge-communication ml-1"},[_vm._v(_vm._s(_vm.counts[type]))]),_c('icon',{staticClass:"d-lg-none",attrs:{"name":_vm.getIconFromType(type)}})],1)}),1),_c('router-link',{staticClass:"nav-link nav-link-right",attrs:{"to":{ name: 'debtor.communication.type.task.create' }}},[_c('icon',{staticClass:"icon-plus",attrs:{"name":"plus","title":_vm.$t('debtor.communication.' + _vm.$route.params.type + '.create')}})],1)],1),_c('div',{staticClass:"card-body"},[_c('data-table',{key:_vm.$route.params.type,ref:"taskList",attrs:{"model":_vm.$route.params.type,"endpoint":_vm.endpoint,"route-context":"debtor.communication.type","route-params":{
        debtorId: _vm.$route.params.debtorId,
        type: _vm.$route.params.type,
        typeId: { key: 'uuid' },
      }},on:{"action":_vm.doAction}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }