<script lang="ts">
import { Component } from 'vue-property-decorator';
import { DebtorFormComponent } from '@/mixins/DebtorFormComponent';
import { mixins } from 'vue-class-component';
import { AxiosResponse } from 'axios';

@Component
export default class DebtorForm extends mixins(DebtorFormComponent) {}
</script>

<style>
</style>
