import { Dictionary } from '@/models/Dictionary';
import SettlementPlanForm from '@/views/debtor/settlementplan/Edit.vue';
import SettlementPlanView from '@/views/debtor/settlementplan/View.vue';
// mixin.js
import Component, { mixins } from 'vue-class-component';
import { EmitterAction } from '@/models';
import { ListComponent } from '@/mixins/ListComponent';

@Component
export class SettlementPlanListComponent extends mixins(ListComponent) {
  public model: string = 'settlementplan';

  protected async doAction(action: EmitterAction) {
    const settlementplan: Dictionary<string> = {
      type: action.emit,
      uuid: action.id,
    };
    if (['edit', 'view'].includes(action.emit)) {
        this.$store.dispatch('bars/openRightBar', {
          component: action.emit === 'view' ? SettlementPlanView : SettlementPlanForm,
          title: this.$t(`settlementplan.${action.emit}`),
          properties:{
            settlementplan,
          }
        });
      return;
    }

    if (action.emit === 'close') {
      const context = `modal.${this.getActionContext(action)}`;
      await this.showConfirmation(this.$t(`${context}.body`) as string)
        .then(() => this.$store.dispatch('bars/closeRightBar'))
        .then(() => {
          this.post({
            endpoint: `${this.model}/${action.id}/${action.emit}`,
            feedback: {
              success: this.$t(`${context}.success`),
              error: this.$t(`${context}.error`),
            },
          });
        });
      return;
    }
  }

  protected get endpoint() {
    const { debtorId } = this.$route.params;
    return `debtor/${debtorId}/settlementplan${this.isDebtorView ? '?filters[isClosed]=0' : ''}`;
  }

  protected get isDebtorView() {
    return this.$route.name && this.$route.name.indexOf('debtor.view') !== -1;
  }
  protected actionRoute(action: EmitterAction) {
    return `${action.context}.${this.model}.${action.emit}`;
  }
}
