<template>
  <div class="row">
    <loader v-bind="{ loading }" />
      <!-- Debtor && Creditor -->
      <div class="col-12 col-lg-8 px-0" v-if="accessVerified">
        <div class="row">
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <debtor-info v-bind="{ data: data.debtor, loading }" />
          </div>
          <div class="col-12 col-lg-6">
            <creditor-info v-bind="{ data: data.creditor, loading }" />
          </div>
        </div>
        <div class="row">
            <div class="col-12">
              <financial-info v-bind="{ data: { ...data.financialInfo, settlementPlans: data.settlementPlans, loading} }" @onPayment="loadData()" />
            </div>
        </div>
        <div class="row" v-if="data.paymentInfo && data.financialInfo.allowSummaryPayment" >
          <div class="col-12">
            <payment-action v-bind="{ data: data.paymentInfo, styling: styling.payment, loading }" @onPayment="loadData()" />
          </div>
        </div>
        <div class="row" v-if="data.financialInfo.allowOtherMethodOfPayment" >
          <div class="col-12">
            <other-payment-request/>
          </div>
        </div>
        <div class="row">
            <div class="col-12">
              <message-box />
            </div>
        </div>
      </div>

    <div class="col-12 col-lg-4 px-0" v-if="accessVerified">
      <div class="row history">
        <div class="col-12">
          <history :is-debtor-history="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import DebtorInfo from '@/debtorportal/components/DebtorInfo.vue';
import Card from '@/debtorportal/components/Card.vue';
import CreditorInfo from '@/debtorportal/components/CreditorInfo.vue';
import AdministrativeInfo from '@/debtorportal/components/AdministrativeInfo.vue';
import FinancialInfo from '@/debtorportal/components/FinancialInfo.vue';
import PaymentAction from '@/debtorportal/components/PaymentActionCard.vue';
import MessageBox from '@/debtorportal/components/MessageBox.vue';
import History from '@/debtorportal/components/CommunicationHistory.vue';
import api from '@/api';
import { LoadingState } from '@/models';
import { AxiosError } from 'axios';
import OtherPaymentRequest from '@/debtorportal/components/otherPaymentRequest.vue';

@Component({
  components: {
    OtherPaymentRequest,
    DebtorInfo,
    CreditorInfo,
    AdministrativeInfo,
    FinancialInfo,
    Card,
    PaymentAction,
    MessageBox,
    History,
  },
})
export default class DebtorPortalDashboard extends Vue {
  // TODO: STILL needs rework to DebtorPortalData contract
  private data: any = {};
  private loading: LoadingState = true;
  private accessVerified: boolean = false;

  private async created() {
    this.loading = true;
    const token = this.$route.query.token as string;

    // no token, no party.
    if (!token) {
      this.$toasted.error(this.$t('debtorportal.unauthorized') as string);
      this.$router.replace({ name: '404' });
    }

    api.defaults.headers.common.Authorization = `Bearer ${token}`;

    await this.loadData();

    await this.overridePlatformLogo();

    this.loading = false;
  }

  protected get styling() {
    return this.$store.getters['platform/debtorportal'];
  }

  private async loadData() {
    const debtor = this.$route.query.debtor as string;
    const token = this.$route.query.token as string;

    await api.get(`/debtorportal/${debtor}`)
      .then(({ data }) => this.processData(data))
      .catch((err: AxiosError) => {
        if (err.response?.status === 401) {
          this.$router.replace({ name: 'debtorportal.invalid-token', query: { debtor, token } });
        }
      });
  }

  private processData(data: any) {
    this.data = data;

    if (data?.success === 'false' && data?.redirect === 'verify') {
      const debtor = this.$route.query.debtor as string;
      const token = this.$route.query.token as string;

      this.$router.push({ name: 'debtorportal.verify', query: { debtor, token } });
    }

    this.accessVerified = true;
  }

  private overridePlatformLogo() {
    if (this.data.creditorLogo) {
      this.$store.dispatch('platform/setLogo', this.data.creditorLogo);
    }
  }
}
</script>
<style lang="scss" scoped>
.row {
  margin: 2rem 0;
}
.history {
  @media screen and (max-width: 991px) {
    margin-top: 0;
  }
}
</style>
