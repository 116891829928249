<template>
  <div>
    <form @submit.prevent="submit" v-if="!permissionNeeded">
      <json-form v-if="schema" :schema="schema" v-model="model" :errors="errors"/>
      <button class="btn btn-primary btn-block" type="submit">{{ $t('settings.save') }}</button>
    </form>
    <div v-else>
      <button
        class="btn btn-primary btn-block"
        @click.prevent="openPermissionsPopup"
      >{{ $t(`import-settings.permission-button`) }}</button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch } from 'vue-property-decorator';
import { FormComponent } from '@/mixins/FormComponent';
import { mixins } from 'vue-class-component';
import { AxiosResponse } from 'axios';

@Component
export default class MailboxSettings extends mixins(FormComponent) {
  @Prop({ required: true })
  protected endpoint!: string;
  protected model: any = {};
  protected permissionsPopup: Window | null = null;
  protected permissionNeeded: boolean = false;
  protected authRedirectUrl: string = '';

  protected async created(): Promise<void> {
    await this.loadData();
  }
  protected afterLoad(response: AxiosResponse) {
    if (response.data.authRedirectUrl) {
      this.authRedirectUrl = response.data.authRedirectUrl;
      this.permissionNeeded = true;

      this.openPermissionsPopup();
    }
  }
  // Can't override the method and call "super.loadData()" because watcher wont catch on.
  // Also dont want to watch parent method for unexpected behaviour reasons.
  @Watch('endpoint')
  protected watchedLoadData() {
    this.loadData();
  }
  protected openPermissionsPopup() {
    this.permissionsPopup = window.open(
      this.authRedirectUrl,
      this.$t(`import-settings.auth`) as string,
      'toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=400,height=600,top=100,left=100',
    );
    window.addEventListener('message', this.handlePermission);
  }

  protected handlePermission(event: MessageEvent) {
    if (this.permissionsPopup && event.data === 'success') {
      window.removeEventListener('message', this.handlePermission);
      this.permissionsPopup.close();
      this.permissionNeeded = false;

      this.$router.replace({name: 'settings.mailbox'});
    }
  }
}
</script>

<style scoped lang="scss">

</style>
