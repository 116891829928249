<template>
  <div>
    <loader :loading="loading"/>
    <form @submit.prevent="submit" class="form" v-if="schema" :ref="schema.title">
      <json-form :schema="schema" v-model="model" :errors="errors"/>
      <button type="submit" class="btn btn-primary btn-sm w-100">
        <Icon prefix="fas" name="save" class="mr-2"/>
        {{ $t('save') }}
      </button>
    </form>
  </div>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator';
import { DebtorFormComponent } from '@/mixins/DebtorFormComponent';
import { mixins } from 'vue-class-component';
import api from '@/api';
import clone from '@/utils/clone';
import { AxiosResponse } from 'axios';

type ReceivableTypes = 'invoice' | 'payment' | 'creditnote';

@Component
export default class DebtorReceivableEdit extends mixins(DebtorFormComponent) {
  protected model: any = {};
  protected endpoint!: string;

  protected setEndpointAndLoad() {
    this.endpoint = `/debtor/${this.$route.params.debtorId}/${this.receivable.type}/${this.receivable.uuid}/edit`;

    this.loadData();
  }

  public get receivable() : {uuid: string, type: string } {
    return this.$store.state.bars.rightBarProperties.receivable;
  }
}
</script>

<style scoped lang="scss">
</style>
