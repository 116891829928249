<template>
  <div class="conditions-and-actions-wrapper my-3">
    <div class="title">Conditions</div>
    <switcher :options="['AND', 'OR']" v-model="value.logical_operator" />

    <div
      class="card form-column"
      :class="{ 'mt-3': index }"
      v-for="(rule, index) in value.rules"
      :key="rule.uniq"
    >
      <div v-if="index" class="condition-type" :style="{ marginTop: index ? '-8px' : '0' }">
        {{ value.logical_operator }}
      </div>
      <div class="card-body p-0">
        <condition
          :condition-types="conditionTypes"
          @change="ruleChanged(index, $event)"
          :value="rule"
        >
          <a @click.prevent="removeRule(index)" slot="footer">
            <icon name="trash-alt" />
            {{ $t('workflow.delete') }}
          </a>
        </condition>
      </div>
    </div>
    <div
      class="text-right cursor-pointer text-secondary"
      @click.prevent="$emit('onEmptyRuleSet', value)"
      v-if="!value.rules.length && $parent.isDirectChildOfWorkflow"
    >
      <icon name="trash-alt" />
      {{ $t('workflow.delete') }}
    </div>
    <button :class="['btn', 'btn-dark', 'mt-2', 'btn-block']" @click.prevent="addRule">
      <span v-if="value.rules.length">+{{ value.logical_operator }} CONDITION</span>
      <span v-else>+ CONDITION</span>
    </button>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import randomize from 'randomatic';

import { Conditions as ConditionsType, ConditionTypes, Rule, ConditionalAction } from '@/models';
import Condition from './Condition.vue';
import Switcher from './Switcher.vue';
import clone from '@/utils/clone';
import Workflow from './Workflow.vue';

@Component({
  components: { Condition, Switcher },
})
export default class Conditions extends Vue {
  @Prop({ type: [Object], required: true })
  private conditionTypes!: ConditionTypes;
  @Prop({ type: [Object], required: true })
  private value!: ConditionsType;

  private addRule() {
    const conditions = clone(this.value);
    conditions.rules.push({
      type: '',
      parameters: {},
      uniq: randomize('Aa0', 10),
    });
    this.$emit('input', conditions);
  }

  private ruleChanged(index: number, value: Rule | null) {
    const conditions = clone(this.value);
    if (!value) {
      value = {
        type: '',
        parameters: {},
      };
    }
    conditions.rules[index] = Object.assign({}, conditions.rules[index], value);
    this.$emit('input', conditions);
  }

  private removeRule(index: number) {
    this.value.rules.splice(index, 1);
    if (!this.value.rules.length) {
      this.$emit('onEmptyRuleSet', this.value);
    }
  }
}
</script>

<style scoped lang="scss">
</style>
