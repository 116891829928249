<template>
  <div>
    <loader :loading="loading"/>
    <dl class="row" v-if="data != null">

      <dt class="col-12">{{ $t('business_unit_import.business_unit') }}</dt>
      <dd class="col-12 text-left">
        <router-link
          :to="{
          name: 'settings.businessunit.sync',
          params: {
            id: data.import.business_unit.uuid
          }
        }">{{ data.import.business_unit.name }}</router-link>
      </dd>

      <dt class="col-12">{{ $t('business_unit_import.created_at') }}</dt>
      <dd class="col-12 text-left">{{ data.import.created_at | datetime }}</dd>

      <dt class="col-12">{{ $t('business_unit_import.user') }}</dt>
      <dd class="col-12 text-left">
        {{ data.import.user }}
      </dd>

      <dt class="col-12">{{ $t('business_unit_import.status') }}</dt>
      <dd class="col-12 text-left">
        {{ $t( 'business_unit.import_status.' + data.import.status.toLowerCase() ) }}
      </dd>

      <div class="col-12" v-if="attachments.length > 0">
        <attachment-download-list v-bind:attachments="attachments">
          {{ $t('business_unit_import.attachments') }}
        </attachment-download-list>
      </div>

      <hr>

      <dt class="col-12">{{ $t('business_unit_import.logs.label.logs') }}</dt>
      <div v-if="data.logs.length > 0">
        <table class="table">
          <thead>
            <tr>
              <th>{{ $t('business_unit_import.logs.label.level') }}</th>
              <th>{{ $t('business_unit_import.logs.label.message') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="entry in data.logs">
              <td>{{ $t('business_unit_import.logs.level.' + entry.level) }}</td>
              <td>{{ entry.message }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <dd class="col-12 text-center" v-else>
        {{ $t('business_unit_import.logs.none_available') }}
      </dd>
    </dl>
  </div>
</template>

<script lang="ts">

import { Component, Vue, Watch } from 'vue-property-decorator';
import { LoadingState } from '@/models';
import api from '@/api';
import { BusinessUnitImport } from '@/models/BusinessUnitImport';
import AttachmentDownloadList from '@/components/views/partial/AttachmentDownloadList.vue';

@Component({
  components: {
    'attachment-download-list': AttachmentDownloadList,
  }
})
export default class BusinessUnitSyncView extends Vue {
  private loading: LoadingState = false;
  private data: BusinessUnitImport | null = null;

  private async created() {
    await this.loadData();
  }

  @Watch('$route.params.businessUnitImport')
  private async loadData() {
    this.loading = true;
    const res = await api.get(`settings/businessunit/${this.$route.params.id}/sync/${this.$route.params.businessUnitImport}`);
    this.loading = false;
    this.data = res.data;

    this.$store.commit('bars/setRightBarTitle', this.$t('business_unit_import.business_unit_import'));
  }

  private loadingChanged(loading: LoadingState) {
    this.loading = loading;
  }

  private reload() {
    this.loadData();
  }

  private get attachments() {
    if(null == this.data || null == this.data.import || null == this.data.import.attachment) {
      return [];
    }

    return [
      {
        original_name: this.data.import.attachment.original_name,
        endpoint: `settings/businessunit/${this.data.import.business_unit.uuid}/sync/${this.data.import.uuid}/download`
      }
    ];
  }
}

</script>

<style scoped lang="scss">
@import '~@/assets/scss/colors';

.table {
  margin-top: 15px;

  tr, th {
    margin: 0 10px;
  }

  thead {
    tr {
      border-bottom: 1px solid $dark;
    }

    th {
      padding-top: 0;
      text-transform: uppercase;
      font-size: 1.25rem;
      white-space: nowrap;
      border: 0;
      color: $text-muted;
      min-height: 40px;
    }
  }

  tbody {
    tr {
      border-top: 1px solid transparent;
      border-bottom: 1px solid $light;
    }
  }
}

</style>
