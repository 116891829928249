var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('nav',{staticClass:"titlebar navbar navbar-light"},[_c('div',{staticClass:"navbar-nav w-100 h-100"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between h-100"},[_c('div',{staticClass:"d-flex align-items-center"},[(_vm.$route.meta?.filterBar)?_c('a',{staticClass:"nav-item nav-link position-relative",class:{'modification-indicator': _vm.hasActiveFilters},attrs:{"href":_vm.$route.path},on:{"click":function($event){$event.preventDefault();return _vm.toggleFilter.apply(null, arguments)}}},[_c('icon',{attrs:{"name":_vm.$route.meta?.filterIcon,"size":"lg"}})],1):_vm._e(),_vm._l((_vm.crumbtrail),function(crumb,i){return _c('div',{key:i,staticClass:"mb-0 h3",class:{ 'pl-3': i === 0, 'text-muted': i < _vm.crumbtrail.length - 1 }},[(crumb.routeName)?_c('router-link',{attrs:{"to":{ name: crumb.routeName, params: {} }}},[_vm._v(_vm._s(crumb.title))]):_c('span',[_vm._v(_vm._s(crumb.title))]),(i < _vm.crumbtrail.length - 1)?_c('span',{staticClass:"ml-2 mr-2 text-muted"},[_vm._v("»")]):_vm._e()],1)})],2),_c('div',{staticClass:"d-flex h-100"},[_vm._l((_vm.actions),function(action){return _c('router-link',{key:action.name,class:{ 'nav-item nav-link nav-link-right': true, active: action.name === _vm.$route.name },attrs:{"to":{
            name: action.name,
            params: Object.assign({}, _vm.$route.params, action && action.params),
          },"title":_vm.$t(action.name + '.title')}},[(action.icon)?_c('icon',{attrs:{"name":action.icon,"size":"lg"}}):_c('span',[_vm._v(_vm._s(_vm.$t(action.name + '.title')))])],1)}),(_vm.$store.state.auth.acl.functionality_setting.debtor_group && _vm.$route.name === 'debtor.view' && _vm.existsInOtherBusinessUnits.length)?_c('a',{key:'debtor.group.view',class:{ 'nav-item nav-link nav-link-right': true },attrs:{"title":_vm.$t('debtor.group.view.title')},on:{"click":function($event){$event.preventDefault();return _vm.fetchGroupUuid.apply(null, arguments)}}},[_c('icon',{attrs:{"name":"users","size":"lg"}})],1):_vm._e(),(_vm.$store.state.auth.acl.functionality_setting.debtor_group && _vm.$route.name === 'debtor.group.view')?_c('router-link',{key:'debtor.view',class:{ 'nav-item nav-link nav-link-right': true },attrs:{"to":{
            name: 'debtor.view',
            params: {
              debtorId: _vm.debtorUuid
            },
          },"title":_vm.$t('debtor.view.title')}},[_c('icon',{attrs:{"name":"user","size":"lg"}})],1):_vm._e()],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }