<template>
  <json-form v-if="schema" :schema="schema" v-model="model" :errors="errors" :labels="false" />
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { LocaleModel } from '@/models';

@Component({})
export default class LocalePicker extends Vue {
  @Prop({default: true}) private authenticated!: boolean;
  private model: LocaleModel = {
    locale: null,
  };

  public created() {
    this.loadSchema();
    this.setFromState();
  }

  @Watch('model.locale')
  public modelValueChanged(locale: LocaleModel) {
    this.$store.dispatch('i18n/changeLocale', { locale, authenticated: this.authenticated });
  }

  @Watch('$store.state.i18n.locale')
  private setFromState() {
    if (this.$store.state.i18n.locale) {
      this.model = Object.assign({}, this.model, this.$store.state.i18n);
    }
  }

  private loadSchema() {
    this.$store.dispatch('i18n/getLocaleSchema', this.authenticated);
  }

  get schema() {
    return this.$store.state.i18n.schema;
  }

  get errors() {
    return this.$store.state.i18n.errors;
  }
}
</script>
<style lang="scss">
.dropdown-menu {
  min-width: inherit;
  position: absolute;
}
.btn-link {
  color: inherit;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}
.picker {
  position: relative;
}
</style>
