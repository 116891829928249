<template>
  <tr>
    <td v-for="(column, i) in columns" :key="column.name">
      <div v-if="column.is_key === true"></div>
      <date-filter v-else-if="isDateTimeColumn(column)" @update="updateFilter(column.name, $event)" :initialValue="getInitialValue(column.name)"></date-filter>
      <choice-filter v-else-if="isChoiceColumn(column)" @update="updateFilter(column.name, $event, true)" :initialValue="getInitialValue(column.name)" :routeContext="routeContext" :column="column" :choices="column.choices"></choice-filter>
      <currency-filter v-else-if="isCurrencyColumn(column)" @update="updateFilter(column.name, $event)" :initialValue="getInitialValue(column.name)"></currency-filter>
      <string-filter v-else-if="isSearchableColumn(column)" @update="updateFilter(column.name, $event)" :initialValue="getInitialValue(column.name)"></string-filter>
    </td>
    <td></td>
  </tr>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import DateFilter from '@/plugins/data-table/filters/DateFilter.vue';
import ChoiceFilter from '@/plugins/data-table/filters/ChoiceFilter.vue';
import StringFilter from '@/plugins/data-table/filters/StringFilter.vue';
import CurrencyFilter from '@/plugins/data-table/filters/CurrencyFilter.vue';
import {
  isChoiceColumnInterface,
  isDatetimeColumnInterface,
  ColumnContext,
  isCurrencyColumnInterface,
} from '@/models/ColumnContext';
import { cloneDeep } from 'lodash';
import { EventBus } from '@/services/EventBus';

@Component({
  components: {
    StringFilter,
    ChoiceFilter,
    DateFilter,
    CurrencyFilter
  },
})
export default class DataTableFilter extends Vue {
  @Prop({ required: true })
  private columns!: ColumnContext[];

  @Prop({ required: true, default: 'default'  })
  private routeContext!: string;

  @Prop({ required: false, default: () => new Object() })
  private searchSettings!: {[id:string]: string};

  private filters: {[id: string]: string} = {};

  private created() {
    this.filters = cloneDeep(this.searchSettings);
    EventBus.$on(`datatable.${this.routeContext}.filter.set`, this.updateFilter);
  }

  private getInitialValue(name: string) {
    return this.searchSettings[name];
  }

  private beforeDestroy() {
    EventBus.$off(`datatable.${this.routeContext}.filter.set`);
  }

  private updateFilter(name: string, value: string, shouldPersist: boolean = false) {
    if(value != null && value !== '') {
      this.filters[name] = value;
    }
    else {
      delete this.filters[name];
    }

    this.$emit('update', cloneDeep(this.filters));

    if (shouldPersist) {

      this.$emit('persist', name, value);
    }
  }

  private isDateTimeColumn(column: ColumnContext): boolean {
    return isDatetimeColumnInterface(column) && this.isSearchableColumn(column);
  }

  private isChoiceColumn(column: ColumnContext): boolean {
    return isChoiceColumnInterface(column) && this.isSearchableColumn(column);
  }

  private isCurrencyColumn(column: ColumnContext): boolean {
    return isCurrencyColumnInterface(column) && this.isSearchableColumn(column);
  }

  private isSearchableColumn(column: ColumnContext): boolean {
    return column.searchable;
  }

}
</script>

<style scoped lang="scss">

</style>
