<template>
  <div>
    <loader :loading="loading"/>
    <form @submit.prevent="submit" class="form" v-if="schema" :ref="schema.title">
      <json-form :schema="schema" v-model="model" :errors="errors"/>
      <button type="submit" class="btn btn-primary btn-sm w-100">
        <Icon prefix="fas" name="save" class="mr-2"/>
        {{ $t('save') }}
      </button>
    </form>
  </div>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import api from '@/api';
import clone from '@/utils/clone';
import { AxiosResponse } from 'axios';
import { FormComponent } from '@/mixins/FormComponent';
import { Dictionary } from '@/models';

@Component({})
export default class DebtorSettlementPlanEdit extends mixins(FormComponent) {
  protected model: any = {};
  protected endpoint!: string;

  protected async created() {
    const { params } = this.$route;
    this.$store.commit('bars/setRightBarTitle', 'loading')
    if (this.settlementplanType === 'edit') {
      this.endpoint = `settlementplan/${this.settlementplan.uuid}/edit`
    } else {
      this.endpoint = `/debtor/${params.debtorId}/${this.settlementplanType}/create?domain=Debtor/${params.debtorId}/Receivables`;
    }
    await this.loadData();
    if (this.loading === 'error') {
      this.$store.commit('bars/closeRightBar');
    }
  }
  protected afterLoad(response: AxiosResponse) {
    const title: string = this.schema?.title ?? this.$route.meta?.title;

    this.$store.commit('bars/setRightBarTitle', this.$t(title + '.header'));

    this.$store.commit('bars/setRightBarFormSubmit', this.submit);
  }
  private get settlementplan(): Dictionary<string> {
    return  this.$store.state.bars.rightBarProperties?.settlementplan;
  }
  private get settlementplanType(): string {
    return this.settlementplan?.type.replace(/_/g, '');
  }
}
</script>

<style scoped lang="scss">
</style>
