<template>
  <data-table v-bind="{ model, endpoint }" @action="doAction" @bulkAction="doBulkAction" :enableBulkSelection="true" :ref="`${model}List`"/>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { DebtorListComponent } from '@/mixins/DebtorListComponent';

@Component
export default class DebtorList extends mixins(DebtorListComponent) {}
</script>

<style scoped lang="scss">
</style>
