var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"card h-100"},[_c('div',{staticClass:"card-head select-none py-0 px-0 justify-between"},[_c('div',{staticClass:"d-flex"},_vm._l((['email', 'letter', 'sms', 'call', 'note']),function(value,index){return _c('a',{key:value,staticClass:"nav-link h5",class:{ active: _vm.selectedType === value },on:{"click":function($event){return _vm.selectType(value)}}},[_c('div',{staticClass:"row"},[_c('span',{staticClass:"h5 d-none d-lg-block"},[_vm._v(_vm._s(_vm.$t(`debtor.communication.${value}`)))]),_c('span',{staticClass:"badge badge-communication ml-1"},[_vm._v(_vm._s(_vm.counts[value]))]),_c('icon',{staticClass:"d-lg-none",attrs:{"name":_vm.getIconFromType(value)}})],1)])}),0),_c('div',{staticClass:"d-flex"},[_c('router-link',{staticClass:"nav-link nav-link-right",attrs:{"to":{
            name: 'debtor.communication.type',
            params: { type: _vm.selectedType },
          }}},[_c('icon',{attrs:{"name":"th-list","title":_vm.$t('debtor.overview.communication.' + _vm.selectedType + '.btn.all')}})],1),(_vm.hasCreatePermission || _vm.selectedType === 'note')?_c('router-link',{staticClass:"nav-link nav-link-right",attrs:{"to":{
            name: 'debtor.view.communication.type.task.create',
            params: {
              type: _vm.selectedType,
            },
          }}},[_c('icon',{attrs:{"name":"plus","title":_vm.$t('debtors.overview.tasks.btn.create')}})],1):_vm._e()],1)]),_c('div',{staticClass:"card-body"},[_c('data-table',{ref:"taskList",attrs:{"model":_vm.selectedType,"endpoint":this.endpoint,"disable-column-options":"","disable-pagination":"","disable-vertical-scroll":"","disable-search-fields":"","disable-bulk-actions":_vm.isDebtorView,"initial-limit":5,"route-context":"debtor.view.communication.type","route-params":{
          debtorId: _vm.$route.params.debtorId,
          type: _vm.selectedType,
          typeId: { key: 'uuid' },
        }},on:{"action":_vm.doAction,"bulkAction":_vm.doBulkAction}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }