<template>
  <div>
    <div class="card mb-3">
      <div class="card-body">
        <data-table
          v-bind="{model, endpoint, routeParams}"
          @action="doAction"
          :ref="`${model}List`"
          :route-context="$route.name"
        />
      </div>
    </div>
    <div class="card" v-show="requestsEnabled">
      <div class="card-body">
        <data-table
          ref="userRequestsList"
          v-bind="{model, endpoint: requestEndpoint}"
          @action="doRequestAction"
          @response="requestsEnabled = true"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { SettingsListComponent } from '@/mixins/SettingsListComponent';
import { EmitterAction } from '@/models';
import DataTable from '@/plugins/data-table/DataTable.vue';

@Component({})
export default class SettingsUsersList extends mixins(SettingsListComponent) {
  protected model: string = 'user';
  private requestsEnabled: boolean = false;

  protected mounted() {
    this.setDataTable();
  }

  private doRequestAction(action: EmitterAction) {
    this.post({ endpoint: `${this.requestEndpoint}/${action.id}/${action.emit}` })
      .then(() => (this.$refs.userRequestsList as DataTable).reload());
  }

  protected get requestEndpoint() {
    return 'settings/user/license_requests';
  }
}
</script>

<style scoped lang="scss">
</style>
