<template>
  <card v-bind="{ loading }">
    <template slot="title">
      <div class="d-flex align-items-center">
        <h2 class="h5" v-text="$t('debtorportal.financial_info.title')" />
      </div>
    </template>
    <template slot='actions'>
      <div class="d-flex align-items-center mr-4">
        <div
          v-if="!showOpenSettlementplans && receivables.some(receivable => receivable.archived_at)"
          v-text="$t(`debtorportal.financial_info.${!showPaidInvoices ? 'open' : 'all'}`)"
          @click="showPaidInvoices = !showPaidInvoices"
          class="ml-2 p-1 cursor-pointer select-none text-primary text-sm"
          :class="{'font-weight-bold': showPaidInvoices}"
        />
        <div
          v-if='!showOpenSettlementplans && receivables.some(receivable => !isInvoice(receivable))'
          v-text="$t(`debtorportal.financial_info.receivables.show`)"
          @click="showAllReceivables = !showAllReceivables"
          class="ml-2 p-1 cursor-pointer select-none text-primary text-sm"
          :class="{'font-weight-bold': showAllReceivables}"
        />
        <div
          v-if='!isEmpty(settlementPlans)'
          v-text="$t(`debtorportal.financial_info.open_settlement_plans.show`)"
          @click="showOpenSettlementplans = !showOpenSettlementplans"
          class="ml-2 p-1 cursor-pointer select-none text-primary text-sm"
          :class="{'font-weight-bold': showOpenSettlementplans}"
        />
      </div>
    </template>
    <template slot="body">
      <!-- Overview -->
      <placeholder v-if="loading">
        <div
          v-for="(row, index) in [{left: '80px', right:'120px'}, {left: '140px', right:'100px'}, {left: '120px', right:'80px'}, {left: '160px', right:'140px'}]"
          :key="index"
          class="d-flex justify-content-between"
        >
          <div class="placeholder" :style="{width: row.left, margin: '0.615rem 0'}" />
          <div class="placeholder" :style="{width: row.right, margin: '0.615rem 0'}" />
        </div>
      </placeholder>
      <div v-else-if="showOpenSettlementplans" class="overflow-auto overflow-md-hidden">
        <div v-for="(settlementPlan, uuid) in settlementPlans" :key="uuid" class="mb-1">
          <div class="row">
            <div class="col-12 d-flex justify-content-between align-items-center">
              <div class="d-flex flex-row align-items-start select-none cursor-pointer" @click="toggleExpandedSettlementPlan(uuid)">
                <icon :name="expandedSettlementPlan === uuid ? 'caret-down' : 'caret-right'" class="text-muted" />
                <div class="text-primary font-semibold">{{ $t(settlementPlan.settlement_plan_type) }}</div>
                <div class="ml-2 label status-label">{{ getSettlementPlanDate(settlementPlan) | date }}</div>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <div v-if="isPaymentPlan(settlementPlan)" class="text-muted text-nowrap">
                  {{ settlementPlan.amount_per_payment | currency(settlementPlan.currency) }} x
                  {{ settlementPlan.installments }}<span class="mx-2">=</span>
                  <span class="font-semibold">{{ settlementPlan.amount | currency(settlementPlan.currency) }}</span>
                </div>
                <div v-else-if="isDispute(settlementPlan)" class="text-muted font-semibold text-nowrap">
                  {{ settlementPlan.dispute_amount | currency(settlementPlan.currency) }}
                </div>
                <div v-else-if="isPromisedToPay(settlementPlan)" class="text-muted font-semibold text-nowrap">
                  {{ settlementPlan.promised_amount | currency(settlementPlan.currency) }}
                </div>
              </div>
            </div>
          </div>
            <div v-show="expandedSettlementPlan === uuid" class="row mx-2 my-1">
              <div class="col-6">
                <h3 class="h6 text-muted">{{$t('debtorportal.financial_info.settlementplan.linked_invoices')}}</h3>
                <div v-for="invoice in invoicesInSettlementplan(uuid)"
                     :key="invoice.uuid"
                     class="text-primary font-semibold"
                >
                  {{ $t('debtorportal.financial_info.receivables.invoice') }}
                  {{ invoice.receivable_reference }}
                </div>
              </div>
              <div class="col-6" v-if="settlementPlan.payment_plan_payments">
                <div class='row'>
                  <h3 class="h6 text-muted">{{$t('debtorportal.financial_info.paymentplan_payments')}}</h3>
                </div>
                <div
                  v-for="(payment, key) in settlementPlan.payment_plan_payments" :key="key"
                  class="d-flex justify-content-between align-items-center"
                >
                  <div>{{ payment.deadline_at | date }}</div>
                  <div>
                    {{ payment.amount | currency(settlementPlan.currency) }}
                  </div>
                  <div v-if='payment.paid_at == null && payment.uuid === findNextPaymentStep(settlementPlan.payment_plan_payments)'
                       class="btn btn-payment-plan btn-success" @click="handlePartialPaymentRequest(payment,uuid)"
                       v-text="$t('debtorportal.financial_info.pay_invoice')" />
                  <div v-else-if='payment.paid_at !== null'
                       class="btn btn-payment-plan btn-secondary disabled"
                       v-text="$t('debtorportal.financial_info.invoice.paid')" />
                  <div v-else class='invis-box'>

                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div v-else class="w-100 overflow-auto overflow-md-hidden">
        <div
          v-for="receivable in filteredSortedReceivables"
          :key="receivable.receivable_reference"

          class="d-flex mb-2 justify-content-between align-items-center">
          <div
            class="d-flex flex-column flex-md-row align-items-start justify-content-between position-relative">
            <div v-if="receivable.attachment_link.length" @click="handleDownloadRequest(receivable)"
                 class="d-none d-md-block position-absolute cursor-pointer select-none align-self-center pin-l">
              <icon name="download" size="lg" class="text-primary" />
            </div>
            <!-- invoice data reference / date -->
            <div
              :class="{ 'ml-md-4': filteredSortedReceivables.some(receivable => receivable.attachment_link.length) }">
              <span class="text-primary font-semibold">
                {{ $t(`debtorportal.financial_info.receivables.${typeForReceivable(receivable)}`) }}
                <template v-if="data.useInternalReference">{{ receivable.internal_reference }}</template>
              <template v-else>{{ receivable.receivable_reference }}</template>
              </span>
              <div v-if="isInvoice(receivable)">
                <span
                  class="text-muted text-sm hidden-xs">{{ $t('debtorportal.financial_info.receivables.due_date')}}</span> {{ receivable.invoice_due_date | date }}
              </div>
            </div>
            <div
              v-if="isInvoice(receivable) && isPaid(receivable)"
              class="label ml-lg-2 font-semibold bg-success border-success"
            >
              <span>{{ $t('debtorportal.financial_info.invoice.paid') }}</span>
            </div>
            <div class="ml-lg-2" v-else>
              <!-- invoice expired/open label -->
              <div
                v-if="isInvoice(receivable) && isDue(receivable) && !isPaid(receivable)"
                class="label font-semibold bg-danger border-danger mr-1"
              >
                <span>{{ $t('debtorportal.financial_info.invoice.is_due') }}</span>
              </div>
              <!-- status label -->
              <div v-if="isInvoice(receivable) && 'PAYMENT_PLAN' != receivable.status && 'PAYMENT_PLAN_PAYMENT' != receivable.status"
                   class="label status-label d-none d-inline font-semibold text-primary"
                   :class="{'action-label': receivable.open_settlement_plan}">
                  <span
                    v-text="$t(`receivable.status.choices.${receivable.status.toLowerCase()}`)"
                  />
              </div>
              <div v-if="isInvoice(receivable)&& 'PAYMENT_PLAN' === receivable.status || isInvoice(receivable) && 'PAYMENT_PLAN_PAYMENT' === receivable.status"
                   @click.prevent="showOpenSettlementplans = true" class="label status-label d-none d-inline font-semibold text-primary"
                   :class="{'action-label': receivable.open_settlement_plan}">
                  <span
                    v-text="$t(`receivable.status.choices.${receivable.status.toLowerCase()}`)"
                  />
              </div>
            </div>
          </div>
          <!-- download button -->
          <div v-if="receivable.attachment_link.length" @click="handleDownloadRequest(receivable)"
               class="d-md-none cursor-pointer select-none align-self-center">
            <icon name="download" size="lg" class="text-primary" />
          </div>
          <!-- currency -->
          <div class="d-flex justify-content-between align-items-center">
            <div class="text-muted font-semibold mr-3 text-nowrap">
              {{ amountForReceivable(receivable) | currency(receivable.currency) }}
            </div>
            <div v-if="isInvoice(receivable) && !isPaid(receivable) && data.hasPaymentProvider && isPayable(receivable)"
                 :class="[receivable.payable ? 'btn btn-success' : 'btn btn-success disabled']" @click="receivable.payable && handlePaymentRequest(receivable)"
                 v-text="$t('debtorportal.financial_info.pay_invoice')" />
          </div>
        </div>
      </div>
    </template>
  </card>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import Card from '@/debtorportal/components/Card.vue';
import { Receivable } from '@/models/Receivable';
import api from '@/api';
import { AxiosResponse } from 'axios';
import isBefore from 'date-fns/isBefore';
import { CreditNote, Invoice, Payment } from '@/models';
import { CardComponent } from '@/debtorportal/models/CardComponent';
import {
  DebtorportalDispute,
  DebtorportalPaymentPlan,
  DebtorPortalPromise,
  DebtorPortalPaymentplanPayment,
  DebtorPortalSettlementplan,
} from '@/models/Invoice';
import { isEmpty } from 'lodash';

@Component({
  methods: { isEmpty },
  components: {
    Card,
  },
})
export default class FinancialInfo extends CardComponent {
  protected paymentPopup: Window | null = null;
  protected paymentStatus: string = '';
  protected showPaidInvoices: boolean = false;
  protected showAllReceivables: boolean = false;
  protected showOpenSettlementplans: boolean = false;
  protected expandedSettlementPlan: string | null = null;

  private handlePaymentRequest(receivable: Receivable) {
    api.get(`debtorportal/${this.debtor}/receivable/${receivable.uuid}/payment/create`)
      .then(({ data }) => this.openPaymentPopup(data.url));
  }

  private handlePartialPaymentRequest(payment: DebtorPortalPaymentplanPayment, settlementPlanUuid: string) {
    let invoices = this.invoicesInSettlementplan(settlementPlanUuid);
    let invoice = invoices.find((invoice) => invoice.open_settlement_plan === settlementPlanUuid);
    if (invoice?.uuid) {
      api.get(`debtorportal/${this.debtor}/receivable/${invoice.uuid}/partial/${payment.uuid}/payment/create`)
        .then(({ data }) => this.openPaymentPopup(data.url));
    }
  }

  private findNextPaymentStep(settlementPlanPayments: DebtorPortalPaymentplanPayment[]) {
    for (let i = 0; i < settlementPlanPayments.length; i++) {
      if (settlementPlanPayments[i].paid_at === null) {
        return settlementPlanPayments[i].uuid;
      }
    }
  }

  private handleDownloadRequest(receivable: Receivable) {
    const host = process.env.VUE_APP_API_HOST;
    if (!receivable.attachment_link.length) {
      return;
    }

    this.download(`debtorportal/debtor/${this.debtor}/receivable/${receivable.uuid}/download`);
  }

  private openPaymentPopup(url: string) {
    this.paymentPopup = window.open(
      url,
      '_blank',
      'toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=400,height=700,top=100,left=100',
    );
    window.addEventListener('message', this.handlePayment);
  }

  private handlePayment(event: MessageEvent) {
    if (this.paymentPopup && event.data?.status) {

      window.removeEventListener('message', this.handlePayment);
      this.paymentPopup.close();
      this.paymentStatus = event.data.status;

      if (this.paymentStatus === 'paid') {
        this.$toasted.success((this.$t('debtorportal.payment.succes') as string));
      } else {
        this.$toasted.error((this.$t('debtorportal.payment.failed') as string));
      }

      this.$emit('onPayment');
    }
  }

  private async download(endpoint: string): Promise<boolean> {
    await api.get(endpoint)
      .then((res) => this.createDownloadLink(res))
      .then(({ data, filename }) => this.handleDownloadLink(data, filename))
      .catch(() => false);

    return true;
  }

  private async createDownloadLink(res: AxiosResponse) {
    const host = process.env.VUE_APP_API_HOST;

    const { data } = await api.get(`debtorportal/download/${res.data.attachment}`, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${res.data.token}`,
      },
    });

    return { data, filename: res.data?.filename };
  }

  private handleDownloadLink(data: Blob, filename: string) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', filename ?? 'file');
    document.body.appendChild(link);
    link.click();

    link.remove();
  }

  private isDue(invoice: Invoice): boolean {
    return isBefore(new Date(invoice.invoice_due_date), new Date());
  }

  private isPaid(invoice: Invoice): boolean {
    return ['DEBTOR_PORTAL_PAID', 'ARCHIVED'].includes(invoice.status);
  }

  private isPayable(invoice: Invoice): boolean {
    return !['LEGAL_ON_HOLD', 'PROCESSING_TO_COLLECTION', 'IN_COLLECTION', 'DISPUTE'].includes(invoice.status);
  }

  private get receivables(): Receivable[] {
    return this.data.receivables ?? [];
  }

  private get invoices(): Invoice[] {
    return this.data.receivables.filter((r: Receivable) => this.isInvoice(r)) ?? [];
  }

  private get settlementPlans(): {[uuid: string]: DebtorPortalSettlementplan } {
    return this.data.settlementPlans ?? {};
  }

  private get filteredReceivables(): Receivable[] {
    return this.receivables.filter((receivable: Receivable) => this.shouldShowReceivable(receivable));
  }

  private get filteredSortedReceivables(): Receivable[] {
    return this.filteredReceivables.sort(
      (a, b) => new Date(b.invoice_due_date).getTime() - new Date(a.invoice_due_date).getTime(),
    );
  }

  private amountForReceivable(r: Receivable): Number {
    return this.isInvoice(r)
      ? r.receivable_amount
      : -1 * r.receivable_amount;
  }

  private typeForReceivable(r: Receivable): string {
    return r.type.toLowerCase();
  }


  private isInvoice(r: Receivable): r is Invoice {
    return this.typeForReceivable(r) === 'invoice';
  }

  private isPromisedToPay(s: DebtorPortalSettlementplan): s is DebtorPortalPromise {
    return s.settlement_plan_type === 'promise_to_pay';
  }

  private isPaymentPlan(s: DebtorPortalSettlementplan): s is DebtorportalPaymentPlan {
    return s.settlement_plan_type === 'payment_plan';
  }

  private isDispute(s: DebtorPortalSettlementplan): s is DebtorportalDispute {
    return s.settlement_plan_type === 'dispute';
  }

  private isCreditNoteOrPayment(r: Receivable): r is CreditNote | Payment {
    return !this.isInvoice(r);
  }

  private shouldShowReceivable(receivable: Receivable) {
    return this.isInvoice(receivable)
      ? this.showPaidInvoices || receivable.archived_at === null
      : this.showAllReceivables;
  }

  private invoicesInSettlementplan(settlementPlanUuid: string): Invoice[] {
    return this.invoices.filter(i => i.open_settlement_plan === settlementPlanUuid);
  }

  private toggleExpandedSettlementPlan(settlementPlanUuid: string): void {
    this.expandedSettlementPlan = this.expandedSettlementPlan !== settlementPlanUuid
      ? settlementPlanUuid : null;
  }
  private getSettlementPlanDate(settlementPlan: DebtorPortalSettlementplan): Date {
    if (this.isPaymentPlan(settlementPlan)) {
      return settlementPlan.start_date;
    }
    if (this.isPromisedToPay(settlementPlan) || this.isDispute(settlementPlan)) {
      return settlementPlan.due_date;
    }
    return settlementPlan.created_at;
  }

  private get debtor(): string {
    return this.$route.query.debtor as string;
  }

}
</script>
<style lang="scss" scoped>

.label {
  display: inline;
  background-color: lightgrey;
  border: 1px solid lightgrey;
  color: white;
  font-size: .85em;
  cursor: default;
  user-select: none;
  padding: .20rem .5rem;
  border-radius: .3rem;

  &.status-label {
    background-color: transparent;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }

  &.action-label {
    cursor: pointer;

    &:hover {
      background-color: var(--primary-color-lightest);
      border-color: transparent;
    }
  }
}

.btn-payment-plan {
  padding: 0.3rem 1rem !important;
  margin: 0.3rem 1rem;
}

.invis-box {
  width: 5.5vw;
}
</style>
