<div>
  <loader :loading="loading"/>
  <form @submit.prevent="submit" class="form" v-if="schema">
      <div class="card h-100 mb-3 border-0" >
        <div class="card-body">
          <json-form :schema="schema" v-model="model" :errors="errors"/>
          <button type="submit" class="btn btn-primary btn-sm w-100">
            <Icon prefix="fas" name="save" class="mr-2"/>
            {{ $t('save') }}
          </button>
        </div>
      </div>
  </form>
</div>